
import React, { useEffect, useState } from 'react'
import { getBlogCategorieAll, getInsightCornor } from '../../../api/Symbol';
import { Link } from 'react-router-dom';
import { Typography } from "@mui/material";

import styles from './InsightCornor.module.css'
import InvexLoader from '../../../Common/InvexLoader';
import image from '../../../Common/Images/4632221.png'
import MarketInfo from '../MarketInfo/MarketInfo';
import dayjs from 'dayjs';
import InvexRoutes from '../../../../InvexRoutes';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const InsightCornor = () => {
  // Variables
  const [blogLoading, setBlogLoading] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState({
    header: "",
    key: "",
  });
  // Callbacks
  useEffect(() => {
    getBlogPosts();
  }, [])

  // Variables
  const getBlogPosts = async () => {
    try {
      setBlogLoading(true);
      // const data = await getBlogCategorieAll();
       const data = await getInsightCornor({
          url: "https://cp3.amassinginvestment.com/api/v1/blogs/all",
       });
      if (data.data.data && data.data.data.length > 0) {
        let _list = [];
        let _data = data?.data?.data ?? [];
        for (const blog of _data) {
          if (blog.name !== "Miscellaneous") {
            let _obj = { ...blog };
            let _fDate = dayjs(blog.date).format("YYYYMMDD");
            // _obj["formattedDate"] = dayjs(blog.date, "yyyyMMdd")
            _obj["formattedDate"] = Number(_fDate);
            _list.push(_obj);
          }
        }
        let _topBlogs = _list.slice(0, 16);
        let sortedDate = _topBlogs.sort((a, b) => a.formattedDate > b.formattedDate ? -1 : 1);
        // console.log("_list",_list);
        setBlogPosts(sortedDate);
        setBlogLoading(false);
      }
    } catch (error) {
        setBlogLoading(false);
    } 
  };
  const handleOpen = () => {
    setIsOpen(true)
    setSelectedKey({
      header: "Insignt Cornor",
      key: "insigntCornor"
    })
  }


  return (
    <>
      <div className={styles.flexCenter} >
        <div style={{ margin: "1rem 0rem", fontWeight: 600, fontSize: "1.5rem" }} >{"Insights Corner >>"} </div>
        <div className={styles.iconWrapper} onClick={handleOpen} >
          <img src={image} />
        </div>
      </div>
      <div className={styles.grid} >
        {!blogLoading &&
          blogPosts?.map((blogItem, index) => (
            <div className="c-article academy" key={index} style={{ borderRadius: 0, boxShadow: "none" }}>
              <div className="c-articlecontainer academy">
                <div className="c-articlecontent" style={{ padding: 0 }}>
                  <Link to={`/blog/${blogItem.slug}`}>
                    <div style={{ width: 300, height: 180, marginBottom: 5 }}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        alt={blogItem?.title}
                        src={`https://cp3.amassinginvestment.com/uploads/${blogItem.image}`}
                      />
                    </div>
                    <Typography
                      component={"h2"}
                      sx={{
                        color: "#0d1536",
                        fontSize: "16px !important",
                        textDecoration: "none",
                        lineHeight: "28px",
                        fontWeight: 700,
                      }}
                    >
                      {blogItem?.title}
                    </Typography>
                  </Link>
                  <div className="d-flex justify-content-between mt-2">
                    {/* <span style={{ color: "#f77d96", backgroundColor:"#f77d962e", padding:"0.2rem 0.5rem", borderRadius: "0.2rem" }} > 
              {foundCategory.name}
            </span> */}
                    <span>{blogItem.date && <Typography>{dayjs(blogItem.date).format("DD MMM YYYY")}</Typography>}</span>
                    <span></span>
                  </div>
                  {/* <p style={{ color: "#000" }} >
                        {blogItem?.description}
                      </p> */}
                </div>
              </div>
            </div>

          ))}
      </div>
      {blogLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <InvexLoader height={440} />
        </div>
      )}
      <div className="top_button_panel top_button_panel_light">
        <Link
          to={InvexRoutes.blog.path}
          style={{
            color: "#002664",
            fontSize: "14px",
            margin: "1rem 0rem"
          }}
        >
          <h6>
            View More <ArrowRightAltIcon />
          </h6>
        </Link>
      </div>
      {isOpen && <MarketInfo selectedKey={selectedKey} setIsOpen={setIsOpen} isOpen={isOpen} />}
    </>
  )
}

export default InsightCornor