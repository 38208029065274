import axios from "axios";
import {
  ServiceCP1V2,
  ServiceV2,
  BlogPostData,
  ServiceV3,
  BlogV3,
  MarketServices,
  ServiceCP1V1,
} from "../../services/apiService";
import { getPrimeUser, getToken, isFreeTrailSymbols, isPrimeAndOpn } from "../Common/CommonFunctions";

export const getCompanyProfileQuote = async (param) => {
  let _symbol = param.symbol;
  var { data } = await ServiceCP1V2.post(`/companyProfileQuoteMultiple/`, { symbols: _symbol });
  return data;
};


export const getCompanyProfileQuoteMultiple = async (param) => {
  var { data } = await ServiceCP1V2.post(`/companyProfileQuoteMultiple/`, param);
  return data;
};

export const getCompanyOutlookMultiple = async (param) => {
  var { data } = await ServiceCP1V2.post(`/company-outlook-multiple`, param);
  return data;
};

export const getCompanyStockPeers = async (param) => {
  var { data } = await ServiceCP1V2.post(`/stockPeers/`,param);
  return data;
};

export const getHistoricalPriceChart = async (param) => {
  var { data } = await ServiceCP1V2.post(`/historicalChart/`, param);
    return data;
};

// export const getHistoricalPriceChart = async (param) => {
//   if(isPrimeAndOpn()) {
//     var { data } = await ServiceCP1V2.post(`/historicalChart/`, param);
//     return data;
//   } else {
//     var { data } = await ServiceCP1V2.get(`/historical-price-chart-lock`, {
//       params: param,
//     });
//     return data;
//   }
// };
export const getInsightCornor = async (param, symbol) => {
  let _params = {...param}
  if (param.symbol) {
    _params["symbol"] = param?.symbol;
  }
    var { data } = await ServiceCP1V1.post(`/extra-apis`, _params);
    return data;
};
export const getMarketData = async (url) => {
    var { data } = await MarketServices.get(url);
    return data;
};

export const getValuvationPrice = async (params) => {
    var { data } = await ServiceCP1V1.post(`/get_valuation_price`, params);
    return data;
}

export const getCommodities1 = async (param) => {
    var { data } = await axios.get(`https://financialmodelingprep.com/api/v3/historical-chart/1day/OUSX?from=2023-10-10&to=2023-08-10&apikey=b1360803f80dd08bdd0211c5c004ad03`, {
      params: param,
    });
    return data; 
}


export const getHistoricalChart = async (param) => {
   var { data } = await ServiceCP1V2.post(`/historicalChart/`, param);
    return data;
};

export const getStockDividend = async (param, fromDetils = false) => {
  const getAPIData = async () => {
    let { data } = await ServiceCP1V2.post(`/stockDividend/`,param);
    return data;
  }
  if(!fromDetils) {
    return  await getAPIData()
  } else {
    const isFreeTrail = isFreeTrailSymbols(param?.symbol);

    return isFreeTrail ? await getAPIData() : []
  }
};

export const getEarnings = async (param, fromDetils = false) => {
  const getAPIData = async () => {
    let { data } = await ServiceCP1V2.post(`/earnings/`, param);
    return data;
  }
  if(!fromDetils) {
    return  await getAPIData()
  } else {
    const isFreeTrail = isFreeTrailSymbols(param?.symbol);
    
    return isFreeTrail ? await getAPIData() : []
  }

};

export const getStockMarketActives = async () => {
  var { data } = await ServiceCP1V2.get(`/stockMarketActives/`);
  return data;
};

export const getSynopsisCompanyNews = async (param) => {
  var { data } = await ServiceCP1V2.post(`/companyNews/`,param);
  return data;
};

export const getHeaderMarqueeDetails = async (param) => {
  var { data } = await ServiceCP1V2.get(`/header2/`, {
    params: param,
  });
  return data;
};

export const getStockPriceChange = async (param) => {
  var { data } = await ServiceCP1V2.get(`/stock-price-change`, {
    params: param,
  });
  return data;
};

export const getSearchResult = async (param) => {
  var { data } = await ServiceCP1V2.get(`/search/`, {
    params: param,
  });
  return data;
};

export const getValuationModel = async (param) => {
  try {
    var { data } = await ServiceCP1V2.post(`/student_version`, param)
    return data
  } catch (error) {
    console.log("error", error)
  }
};
export const getValuationProModel = async (param) => {
  // var { data } = await ServiceCP1V2.post(`/student_version`, param);
  // return data;
  try {
    var { data } = await ServiceCP1V2.post(`/pro_version`, param)
    return data
  } catch (error) {
    console.log("error", error)
  }
};
export const getSymbolQuarters = async (param) => {
  try {
    var { data } = await ServiceCP1V2.post(`/get_symbol_quarter_0`, param)
    return data
  } catch (error) {
    console.log("error", error)
  }
}
export const getSecFillings = async (param) => {
  var { data } = await ServiceCP1V2.post(`/secFilings/`, param);
  return data;
};

export const contactUs = async (param) => {
  var { data } = await ServiceCP1V2.post(`/contact_us`, param);
  return data;
};

export const cancelledSubscription = async () => {
  var { data } = await ServiceCP1V2.post(`/cancel_subscription/`);
  return data;
};
export const reactivedSubscription = async () => {
  var { data } = await ServiceCP1V2.post(`/reactivate_subscription/`);
  return data;
};

export const getETFStockData = async (param, fromDetils = false) => {
    const isFreeTrail = isFreeTrailSymbols(param?.symbol);

  const getAPIData = async () => {
    let { data } = await ServiceCP1V2.post(isFreeTrail ? `/etfStockExposure/` : `/etf-stock-exposure-lock`, param);
    return data;
  }
  if(!fromDetils) {
    return  await getAPIData()
  } else {
    return isFreeTrail ? await getAPIData() : []
  }
};

export const getTechnicalAnalysisGraphData = async (param) => {
  var { data } = await ServiceCP1V2.post(`/predict_price_graph`, param);
  return data;
};

export const getCompanyQuote = async (param) => {
  var { data } = await ServiceCP1V2.post(`/companyQuote/`, param);
  return data;
};

export const getCompanyProfile = async (param) => {
  var { data } = await ServiceCP1V2.post(`/companyProfile/`, param);
  return data;
};

export const getNewCompanyProfile = async (param) => {
  var { data } = await ServiceCP1V2.post(`/get_industry`, param);
  return data;
};

export const getRelativeValuation = async (param) => {
  var { data } = await ServiceCP1V2.post(`/relative_valuation_2`, param);
  return data;
};
export const forgotPasswordService = async (param) => {
  var { data } = await ServiceCP1V2.post(`/forgot_password`, param);
  return data;
};

export const getRelativeValuationBySymbol = async (param) => {
  var { data } = await ServiceCP1V2.post(`/rv_symbol`, param);
  return data;
};
export const getRelativeValuationByIndustry_2 = async (param) => {
  var { data } = await ServiceCP1V2.post(`/get_company_2`, param);
  return data;
};
export const gethistorical_relative_valuation = async (param) => {
  var { data } = await ServiceCP1V2.post(`/historical_relative_valuation`, param);
  return data;
};

export const getRelativeValuationByIndustry = async (param) => {
  var { data } = await ServiceCP1V2.post(`/get_company`, param);
  return data;
};

export const getComparativeValuation = async (param) => {
  var { data } = await ServiceCP1V2.post(`/relative_valuation`, param);
  return data;
};

export const getComparativeValuationBySymbol = async (param) => {
  var { data } = await ServiceCP1V2.post(`/rv_symbol`, param);
  return data;
};

export const getComparativeValuationByIndustry = async (param) => {
  var { data } = await ServiceCP1V2.post(`/get_company`, param);
  return data;
};

export const getTechnicalAanalysisImpliedData = async (param) => {
  var { data } = await ServiceCP1V2.post(`/technical_analysis_implied`, param);
  return data;
};

export const getTechnicalAanalysisHV_IVData = async (param) => {
  var { data } = await ServiceCP1V2.post(`/predict_price_hv_graph`, param);
  return data;
};

export const getTechnicalAnanlysisNew = async (param, url) => {
    var { data } = await ServiceCP1V2.post(url, param);
  return data;
}

export const getBlogPostData = async () => {
  var { data } = await ServiceCP1V2.get("/get_all_blog");
  return data;
};

export const getBlogCategories = async () => {
  var { data } = await BlogV3.get("/api/v1/blog/category");
  return data;
}

export const getBlogCategoriesList = async (id) => {
  var { data } = await BlogV3.get("/api/v1/blog/list/"+ id);
  console.log("data ====", data)
  return data;
}

export const getBlogCategorieAll = async (id) => {
  var { data } = await BlogV3.get("/api/v1/blogs/all");
  return data;
}

export const getBlogPostDetailNew = async (param) => {
  var { data } = await BlogV3.get("/api/v1/blog/" + param.trim());
  console.log("getBlogPostDetailNew data ====", data)

  return data;
};

export const getBlogPostDetail = async (param) => {
  var { data } = await ServiceV2.post("/get_blog_by_id", param);
  console.log("data ====", data)

  return data;
};

export const getBlogHeadData = async (param) => {
  var { data } = await BlogPostData.get("/rankmath/v1/getHead", {
    params: param,
  });
  return data;
};

export const getDCFSegmentData = async (param) => {
  if(isPrimeAndOpn()) {
    const { data } = await ServiceCP1V2.post("/fetch-data", param);
  return data;
  } else {
    const { data } = await ServiceCP1V2.post("/fetch-data-lock", param);
    return data;
  }
};


export const getDCFGeographicalData = async (param) => {
  if(isPrimeAndOpn()) {
    const { data } = await ServiceCP1V2.post("/fetch-data", param);
  return data;
  } else {
    const { data } = await ServiceCP1V2.post("/fetch-data-lock", param);
    return data;
  }
};

export const getSymbolQuarter = async (param) => {
  var { data } = await ServiceCP1V2.post("/get_quarters", param);
  return data;
}

export const getSymbolQuarterWithoutWall = async (param) => {
  // const res = await ServiceCP1V2.post(`/fetch-server-data`, {
  //   route: "/get_quarters",
  //   method: 'post',
  //   data: param,
  //   type: 'ServiceV2'
  // })
  // log
  var { data } = await ServiceCP1V2.post("/get_quarters_0", param);
  return data;
}

export const getChecklistsData = async (param) => {
  var { data } = await ServiceV2.post("/checklist", param);
  return data;
}

export const getChecklistCompanyData = async (param) => {
  var { data } = await ServiceV2.post("/checklist_comapny", param);
  return data;
}

export const fetchData =  (route) => {
  if(isPrimeAndOpn()) {
    return ServiceCP1V2.post(`/fetch-data`, {
      route
    })
  } else {
    return ServiceCP1V2.post(`/fetch-data-lock`, {
      route
    })
  }
}