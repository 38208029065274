import React, { useState } from "react";
import styles from './marketTable.module.css'
import { getToken, isPrimeAndOpn } from "../../Common/CommonFunctions";
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from "react-router-dom";
import UpgradeNow from "../../Common/UpgradeNow/UpgradeNow";

const MarketTable = ({ colData, data, isClickable }) => {

  // Variables
  const isLogin = isPrimeAndOpn();
 const navigate = useNavigate();
  const [subscribeModal, setSubscribeModal] = useState(false);
  const symbolClickable = isClickable ? false : true;

    // Functions 
  const handleOpen = () => {
    if (!getToken()) {
      navigate("/login")
      return
    }
     setSubscribeModal(!subscribeModal)
  }

  const renderSubscribeBtn = () => {
    return <div style={{ zIndex: 10 }} >
      <LockIcon color="gray" style={{ color: "#535353" }} />
    </div>
  }


  return (
    <div className="mb-5">
      {data && (
        <div className="table-responsive sticky-table-container sticky-first-column-container">
          <table className="table table-border table-hover">
            <thead
              className="text-center"
              style={{
                fontSize: "12px",
                backgroundColor: "#FAF5F1",
                color: "#002664",
              }}
            >
              <tr className={styles.header} >
                {colData &&
                  colData.map((row, index) => {
                    return (
                      <th className={`${styles.head} ${row.key === "title" ? styles.titleWrp : ""}`} key={index} scope="col">
                        {row?.label}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className="border-top-0 text-center">
              {data.map((row, index) => {
                return (
                  <tr key={index}>
                    {colData &&
                      colData.map((value, i) => {
                        let isValPrice = (value?.key === "valuvation" || value?.key === "pricing");
                        const isPercentage = (value?.key === "changesPercentage" || value?.key === "changes");
                        if (isPercentage) {
                          const val = row[value?.key ?? ""];
                          let numericValue = parseFloat(val);
                          return <td key={i} className={numericValue > 0 ? styles.positive : styles.nagetive}>{val}</td>
                        }
                        if (isValPrice) {
                          const ue = row[value?.key ?? ""];
                          let _val = ue === "-" ? "-" : Math.abs(ue)?.toFixed(2) + " %";
                          if (isLogin) {
                            return (
                              <>
                               
                            <td key={i} className={row[value?.key ?? ""] > 0 ? styles.nagetive : styles.positive}> {_val} </td>
                              </>
                              )
                          }
                          return (
                            <td className={styles.blurContainer} onClick={handleOpen} >  
                                  <div class={styles.blur}></div>
                                  <div class={styles.lockBTN}>{renderSubscribeBtn()}</div>
                            </td>
                            // <td style = {{ filter: "blur(10px)", backgroundColor: "#00000021", zIndex: 1, position: "relative" }} >
                            // {renderSubscribeBtn()}
                            // {/* <td style = {{ filter: "blur(10px)", backgroundColor: "#00000021" }} >-</td> */}
                            // </td>
                          )
                          
                        }
                        if (value.key === "symbol") {
                          if (symbolClickable) {
                            return (
                              <td style={{ color: "blue", cursor: "pointer", fontWeight: "600" }}>
                              <a
                              rel="noreferrer"
                              href={`/symbol/${row?.symbol}`}
                              target="_blank"
                              >
                                {row?.symbol}
                                </a>
                                </td>
                          )
                          }
                          return <td style={{ color: "black", cursor: "pointer", fontWeight: "600" }}>
                        {row?.symbol}
                         
                            </td>
                    }
                        if (value?.key === "link") return <td>
                       <a href={row?.url} target="_blank">View</a> 
                        </td>
                        if (value?.key === "impact") {
                          const val = row[value?.key ?? ""];
                          const color =  val === "Low" ? styles.nagetive : val === "Medium" ? styles.orange : styles.positive 
                          return <td key={i} className={color}  >{row[value?.key ?? ""] ?? "-"}</td>
                        }

                        return (
                          <td key={i}  >{ row[value?.key ?? ""] ?? "-"}</td>
                      
                        );
                      })}
                   
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      { subscribeModal && <UpgradeNow isOpen={subscribeModal} setIsOpen={setSubscribeModal} /> }
    </div>
  );
};

export default MarketTable;
