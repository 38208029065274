import AMZN from "../Common/Images/AMZN.svg";
import AAPL from "../Common/Images/AAPL.svg";
import BRK_A from "../Common/Images/BRK.A.svg";
import GOOGL from "../Common/Images/GOOGL.svg";
import META from "../Common/Images/FB.svg";
import MSFT from "../Common/Images/MSFT.svg";
import NVDA from "../Common/Images/NVDA.jpg";
import NFLX from "../Common/Images/NFLX.jpg";
import TSLA from "../Common/Images/TSLA.svg";
import JPM from "../Common/Images/JPM.jpg";
import DCFVALUE from "../Common/Images/DCF_value.svg";
import Industry_value from "../Common/Images/Industry_value.svg";
import ProbTech_value from "../Common/Images/Prob-tech_value.svg";
import Academy_value from "../Common/Images/Academy_value.svg";
import DCF from "../Common/Images/dcf.jpeg";
import Relative from "../Common/Images/relativeVal.jpeg";
import Sector from "../Common/Images/sector.jpeg";
import TechnicalAny from "../Common/Images/technicalAnalysis.jpeg";
import Portfolio from "../Common/Images/portfolio.jpeg";
import Screener from "../Common/Images/screener.jpeg";
import DCFCALC from "../Common/Images/dcfVal.jpeg";
import Academy from "../Common/Images/academy.jpeg";

import { TYPE } from "../V2/Symbol/Constants";
import InvexRoutes from "../../InvexRoutes";

export const DATE_FORMAT = {
  1: "YYYY/MM/DD",
  2: "MMM D, YYYY",
  3: "HH:mm",
  4: "DD MMM YYYY",
  5: "YYYY-MM-DD",
  6: "MMMM DD HH:mm A z",
};

export const CHART_TIME_DURATION = [
  { label: "1D", value: "1d" },
  { label: "1W", value: "1w" },
  { label: "1M", value: "1m" },
  { label: "1Y", value: "1y" },
  { label: "5Y", value: "5y" },
  { label: "MAX", value: "max" },
];
export const CHART_TIME_DURATION_RV_PAGE = [
  { label: "1Y", value: "1" },
  { label: "2Y", value: "2" },
  { label: "3Y", value: "3" },
  { label: "5Y", value: "5" },
  { label: "MAX", value: "max" },
];

export const CHART_TIME_DURATION_CHART_PAGE = [
  { label: "1D", value: "1" },
  { label: "1W", value: "7" },
  { label: "1M", value: "30" },
  { label: "1Y", value: "365" },
  { label: "YTD", value: "ytd" },
  { label: "5Y", value: "1825" },
  { label: "MAX", value: "18250" }, // here values is no of days
];

export const INTERVAL_DROP_DWON = [
  { label: "1 MN", value: "1min" },
  { label: "5 MN", value: "5min" },
  { label: "15 MN", value: "15min" }, // added by nilesh
  { label: "1 HR", value: "1hour" },
  { label: "4 HR", value: "4hour" },
  { label: "1 D", value: "1day" },
  { label: "1 W", value: "1week" },
  { label: "1 M", value: "1month" },
  { label: "1 YR", value: "1year" },
];

export const CHART_TYPE = [
  { label: "Line", value: "LINE_CHART" },
  { label: "Candle", value: "CANDLE_CHART" },
  { label: "Area", value: "AREA_CHART" }, // added by nilesh
  // { label: 'Bar', value: 'BAR_CHART' },
];

export const SYMBOL_LIST = [
  { label: "AAPL", value: "AAPL", image: AAPL },
  { label: "TSLA", value: "TSLA", image: TSLA },
  { label: "AMZN", value: "AMZN", image: AMZN },
  { label: "GOOGL", value: "GOOGL", image: GOOGL },
  { label: "MSFT", value: "MSFT", image: MSFT },
  { label: "META", value: "META", image: META },
  { label: "BRK-A", value: "BRK-A", image: BRK_A },
  { label: "NFLX", value: "NFLX", image: NFLX },
  { label: "NVDA", value: "NVDA", image: NVDA },
  { label: "JPM", value: "JPM", image: JPM }
]

export const TESTIMONIALS = [
  {
    name:"Jone Doe",
    description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
   {
    name:"Daniel",
    description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
    {
    name:"Jone Doe",
    description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
      {
    name:"Stan",
    description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
        {
    name:"Jone Doe",
    description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  }
]

export const PRICE_INFO = [
  {
    isFree: true,
    price: 0,
    palnName:"Free",
    freeLabel: "No signup required",
    buttonLabel: "",
    useable: "No CREDIT CARD Needed",
    isAvailable: false,
    features: [
        "10 Free symbols with all features",
        "EOD Option Chain",
        "230+ Industy Reports",
        "Stock Screener (Only Limited Features)",
        "Portfolio Gallery (Only Limited Access)",
      "DCF Calculator (Manual Version Only)",
        ]
  },
  {
    isFree: false,
    price: 24.99,
    palnName:"Yearly",
    freeLabel: "",
    buttonLabel: "Start 14 day Free Trail",
    isAvailable: true,
    useable: "(For Personal Use Only)",
    features: [
        "5M+ Global DCF Valuation Reports",
        "35K+ Global Relative Valuations",
        "Comparative Analysis",
        "30 Years+ Financial Statements and Statistics",
        "Probability-based Technical Analysis",
        "Options Analysis",
        "230+ Industy Reports",
        "Custom Stock and Options Screener",
        "Portfolio Gallery",
        "DCF Calculator",
        ]
  },
  {
    isFree: false,
    price: 34.99,
    palnName:"Monthly",
    freeLabel: "",
    buttonLabel: "Start 14 day Free Trail",
    isAvailable: true,
    useable: "(For Personal Use Only)",
    features: [
        "5M+ Global DCF Valuation Reports",
        "35K+ Global Relative Valuations",
        "Comparative Analysis",
        "30 Years+ Financial Statements and Statistics",
        "Probability-based Technical Analysis",
        "Options Analysis",
        "230+ Industy Reports",
        "Custom Stock and Options Screener",
        "Portfolio Gallery",
        "DCF Calculator",
        ]
  },
  {
    isFree: false,
    isEnterPrise: true,
    price: 34.99,
    palnName:"Enterprise B2B",
    freeLabel: "",
    buttonLabel: "Contact Us",
    isAvailable: true,
    useable: "Built For Enterprise",
    features: [
        ]
  },
  // {
  //   isFree: false,
  //   price: 35.99,
  //   palnName:"Plus",
  //   freeLabel: "",
  //   buttonLabel: "Coming Soon",
  //   isAvailable: false,
  //   useable: "(For Personal Use Only)",
  //   features: [
  //       "DCF Valuation Reports (50/month)",
  //       "Relative Valuation",
  //       "200+ Industry Reports ",
  //       "Option Analysis",
  //       "Financial Statistics",
  //       "Financial Statements",
  //       "Probabilistic Technical Analysis",
  //       "Portfolio Builder (5/month)",
  //       "Macroeconomics Indicators",
  //       "Top sectors/industries rankings",
  //       "Top Undervalued and Overvalued Stocks recommendations",
  //       "Buy, hold, and sell signals based on AI Score",
  //       "Stocks/Options Screener",
  //       "Comparative Analysis",
  //       "Video Courses and E-Books",
  //         ]
  // },
  // {
  //   isFree: false,
  //   price: 59.9,
  //   palnName:"Pro",
  //   freeLabel: "",
  //   buttonLabel: "Coming Soon",
  //   isAvailable: false,
  //   useable: "(For Personal Use Only)",
  //   features:[
  //      "2M+ DCF Valuation Reports ",
  //       "Relative Valuation",

  //       "200+ Industry Reports",
  //       "Option Analysis",
  //       "Financial Statistics",
  //       "Financial Statements",
  //       "Probabilistic Technical Analysis",
  //       "Portfolio Builder ",
  //       "Macroeconomics Indicators",
  //       "Top sectors/industries rankings",
  //       "Top Undervalued and Overvalued Stocks recommendations",
  //       "Buy, hold, and sell signals based on AI Score",
  //       "Stocks/Options Screener",
  //       "Comparative Analysis",
  //       "Video Courses and E-Books",
  //         ]
  // },

]

export const NEW_PRICE = {
  "free" :{
    fistList: {
      label: "10 Free symbols with all features",
      list:[
      "DCF Valuations", "Relative Valuations", "DCF Calculator", "Global Comparative Analysis", "30 Years+ Financial Statements and Statistics", "Probability-based Technical Analysis", "Options Analysis"
      ],
    labels:["230+ Industry Reports", "Stock Screener (Features)", "Portfolio Gallery (Limited Access)"]
    },
  },
  "month" :{
    labelList:["DCF Valuation Reports", "Relative Valuations", "DCF and Relative Screener", "DCF Calculator","Global Comparative Analysis", "30 Years+ Financial Statements and Statistics", "Probability-based Technical Analysis", "Options Analysis", "230+ Industry Reports", "Custom Stock and Options Screener", "Pre-made Portfolio Gallery"]
  },
  }

const DEFAULT_ROUTE = "/symbol/AAPL"

export const VALUATION_INFO_DATA = [
  {
    label: "DCF Valuation Reports",
    description: "Harness the power of IVY league college professor's methodology to uncover intrinsic value and investment potential.",
    img: DCF,
    url:"https://www.youtube.com/embed/lZUiyUIINio",
  },
  {
    label: "Relative Valuation",
    description: "Gain an edge with our tool that compares industry standards, enabling you to evaluate investment attractiveness with efficiency and precision.",
    img: Relative,
    url:"https://www.youtube.com/embed/Jc5cVDsWLKs"
  },
  {
    label: "Industry Analysis",
    description: "Unlock global market insights with our tool's deep dive into 230+ industries for savvy investments.",
    img: Sector,
    url:"https://www.youtube.com/embed/HWqdR7hHooM"
  },
  {
    label: "Prob-Tech Analysis",
    description: "Enhance market forecasts with our tool: blend traditional analysis with statistical probabilities for superior insights.",
    img: TechnicalAny,
    url:"https://www.youtube.com/embed/xnZ16sJq-To"
  },
  {
    label: "Advanced Screeners ",
    description: "Streamline finding investments with our Advanced Screener, filtering noise for precise, informed decisions.",
    img: Screener,
    url:"https://www.youtube.com/embed/PHMAM-_SgLM"
  },
  {
    label: "Portfolio Gallery",
    description: "Customize your investing journey with tailored portfolios and a dynamic watchlist, ensuring personal goal alignment.",
    img: Portfolio,
    url:""
  },
  {
    label: "DCF Calculator",
    description: "Evaluates your investment worth by estimating future cash flows and discounting them to present value.",
    img: DCFCALC,
    url:""
  },
  {
    label: "Academy",
    description: "Transform your finance understanding with our Academy: from basics to advanced, for confident, informed decisions.",
    img: Academy,
    url:"https://www.youtube.com/embed/0LIS-1vN0EM"
  },
]

export const VALUATION_INFO = [
  {
    heading: "DCF VALUATION",
    paragraph: "Dive into Discounted Cash Flow (DCF) valuation, a time-tested financial model championed by IVY league college professors. This methodology helps in determining the intrinsic value of an investment by considering its expected future cash flows. Why is this important? Understanding intrinsic value is vital for making smart investment decisions and gauging true investment potential. With our tools, not only will you grasp the concept, but you'll also have the resources to actively evaluate potential investments, all guided by the wisdom of top-tier academic methodologies.",


    paragraph1: {
      para: ["Dive into Discounted Cash Flow (DCF) valuation, a time-tested financial model championed by IVY league college professors.", "This methodology helps in determining the intrinsic value of an investment by considering its expected future cash flows"],
      label:"",
    },
    paragraph2: {
      para: ["Understanding intrinsic value is vital for making smart investment decisions and gauging true investment potential.", "With our tools, not only will you grasp the concept, but you'll also have the resources to actively evaluate potential investments, all guided by the wisdom of top-tier academic methodologies."],
      label:"Why is this important? ",
    },
    isReverse: false,
    viewLabel: "Visit Report",
    redirectUrl: `${DEFAULT_ROUTE}/${TYPE.dcfValuation.value}`,
    url: `${window.location.origin}/videos/dcf.mp4`
  },
  {
    heading: "RELATIVE VALUATION",
    paragraph: "Delve into the world of Relative Valuation, a critical financial approach that compares the value of an asset to similar assets within its industry. Why does this matter? By understanding how a particular asset or investment stacks up against industry benchmarks and peers, you gain a clearer perspective on its attractiveness and potential returns. Our tool simplifies this process, giving you an efficient way to assess investments with precision. Leveraging industry standards and comparisons, you'll be equipped to make investment decisions with confidence and clarity.",
      paragraph1: {
      para: ["Delve into the world of Relative Valuation, a critical financial approach that compares the value of an asset to similar assets within its industry."],
      label:"",
    },
      paragraph2: {
      para: ["By understanding how a particular asset or investment stacks up against industry benchmarks and peers, you gain a clearer perspective on its attractiveness and potential returns. ", "Our tool simplifies this process, giving you an efficient way to assess investments with precision. Leveraging industry standards and comparisons, you'll be equipped to make investment decisions with confidence and clarity."],
      label:"Why does this matter? ",
    },
    isReverse: true,
    viewLabel: "Visit Page",
    redirectUrl: `${DEFAULT_ROUTE}/${TYPE.relativeValuation.value}`,
    url: `${window.location.origin}/videos/relative.mp4`
  },
  {
    heading: "INDUSTRY ANALYSIS",
    paragraph: "Navigate the complexities of the global marketplace with our comprehensive Industry Analysis tool. Why is understanding industries essential? Each industry comes with its unique trends, challenges, and growth potentials. By delving into in-depth analysis of over 200 industries, you can discern where investment opportunities lie. Our platform provides a deep dive into these industries, granting you insights that can unlock promising investment avenues. Whether you're a seasoned investor or a beginner, our detailed analyses will empower you to make well-informed decisions in the ever-evolving world of investments.",
      paragraph1: {
      para: ["Navigate the complexities of the global marketplace with our comprehensive Industry Analysis tool. "],
      label:"",
    },
      paragraph2: {
      para: ["Each industry comes with its unique trends, challenges, and growth potentials. By delving into in-depth analysis of over 230 industries, you can discern where investment opportunities lie.","Our platform provides a deep dive into these industries, granting you insights that can unlock promising investment avenues. Whether you're a seasoned investor or a beginner, our detailed analyses will empower you to make well-informed decisions in the ever-evolving world of investments."],
      label:"Why is understanding industries essential?",
    },
    isReverse: false,
    viewLabel: "Visit Report",
    redirectUrl: `${InvexRoutes.Sectors.path}`,
    url: `${window.location.origin}/videos/industry.mp4`
  },
  {
    heading: "PROB-TECH ANALYSIS",
    paragraph: "Step into the future of market forecasting with our Probabilistic Technical Analysis tool. Why should this matter to you? Traditional technical analysis might offer patterns and trends, but adding a probabilistic layer ensures a statistical robustness to these predictions. By harnessing our sophisticated algorithms, you get a clearer picture of potential market movements, backed by data-driven probabilities. Whether you're plotting short-term trades or evaluating long-term trends, our platform equips you with insights that are both statistically sound and actionable, positioning you ahead in the investment game.",
     paragraph1: {
      para: ["Step into the future of market forecasting with our Probabilistic Technical Analysis tool."],
      label:"",
    },
      paragraph2: {
      para: ["Traditional technical analysis might offer patterns and trends, but adding a probabilistic layer ensures a statistical robustness to these predictions.", "By harnessing our sophisticated algorithms, you get a clearer picture of potential market movements, backed by data-driven probabilities. Whether you're plotting short-term trades or evaluating long-term trends, our platform equips you with insights that are both statistically sound and actionable, positioning you ahead in the investment game."],
      label:"Why should this matter to you?",
    },
    isReverse: true,
    viewLabel: "Visit Page",
    redirectUrl: `${DEFAULT_ROUTE}/${TYPE.technicalAnalysis.value}`,
    url: `${window.location.origin}/videos/prob-tech.mp4`
  },
  {
    heading: "ADVANCED SCREENERS",
    paragraph: "Simplify your investment discovery journey with our Advanced Screeners tool. Why is this essential for you? In the vast world of stocks and options, pinpointing the right investment opportunities can be like finding a needle in a haystack. Our user-friendly stock and options screener is designed to filter through the noise, enabling you to identify potential investments with speed and precision. Whether you're honing in on specific financial criteria or tracking broader market trends, our platform streamlines the process, saving you time and ensuring your investment decisions are always informed and strategic.",
      paragraph1: {
      para: ["Simplify your investment discovery journey with our Advanced Screeners tool"],
      label:"",
    },
      paragraph2: {
      para: ["In the vast world of stocks and options, pinpointing the right investment opportunities can be like finding a needle in a haystack. ", "Our user-friendly stock and options screener is designed to filter through the noise, enabling you to identify potential investments with speed and precision. Whether you're honing in on specific financial criteria or tracking broader market trends, our platform streamlines the process, saving you time and ensuring your investment decisions are always informed and strategic."],
      label:"Why is this essential for you?",
    },
    isReverse: false,
    viewLabel: "Screen now",
    redirectUrl: `${InvexRoutes.Screener.path}`,
    url: `${window.location.origin}/videos/screener.mp4`
  },
  {
    heading: "MACROECONOMICS INDICATORS",
    paragraph: "Navigate the broad financial landscape with confidence using our Macroeconomics Indicators tool. Why is this crucial for investors? Macroeconomic trends and indicators provide a snapshot of the overall health and trajectory of an economy. Being informed on these aspects ensures you're making decisions with the larger economic context in mind. Our platform offers extensive insights into vital macroeconomic parameters, from inflation rates to employment figures. With this knowledge at your fingertips, you'll be positioned to anticipate market shifts, making investment choices that are not only timely but also attuned to the broader economic climate. Stay informed, stay ahead.",
       paragraph1: {
      para: ["Navigate the broad financial landscape with confidence using our Macroeconomics Indicators tool. "],
      label:"",
    },
      paragraph2: {
      para: ["Macroeconomic trends and indicators provide a snapshot of the overall health and trajectory of an economy. Being informed on these aspects ensures you're making decisions with the larger economic context in mind. ", "Our platform offers extensive insights into vital macroeconomic parameters, from inflation rates to employment figures. With this knowledge at your fingertips, you'll be positioned to anticipate market shifts, making investment choices that are not only timely but also attuned to the broader economic climate. Stay informed, stay ahead."],
      label:"Why is this crucial for investors? ",
    },
    isReverse: true,
    viewLabel: "Visit Page",
    redirectUrl: `${InvexRoutes.MacroEconomics.path}`,
    url: `${window.location.origin}/videos/macroeconomics.mp4`
  },
  {
    heading: "CUSTOM PORTFOLIOS & ADAPTIVE WATCHLIST",
    paragraph: "",
     paragraph1: {
      para: ["Refine your investment path with custom portfolio selections and an adaptive watchlist"],
      label:"",
    },
      paragraph2: {
      para: ["Tailored Portfolio Selection: Recognizing the unique financial goals, risk tolerance, and timelines of each investor, our platform provides a wide range of pre-set portfolios. This variety ensures you can choose a portfolio that closely reflects your specific financial ambitions, setting the stage for a personalized investment journey from the start.", "Customizable Watchlist: Enhance your investment strategy with our Customizable Watchlist feature, allowing you to compile and monitor assets that match your personal preferences and strategy. This flexibility supports continuous personalization of your investment approach, ensuring your portfolio evolves alongside your financial goals for a truly individualized experience."],
      label:"Customizable Watchlist",
    },
    isReverse: false,
    viewLabel: "Start Building",
    redirectUrl: `${InvexRoutes.watchlistPortfolio.path}`,
    url:`${window.location.origin}/videos/Portfolio&Checklist.mp4`
  },
  {
    heading: "ACADEMY",
    paragraph: "Embark on a transformative learning journey with our Academy. Wondering why a strong educational foundation is crucial in the financial world? Financial statements, stock market intricacies, and investment strategies can be daunting without the right guidance. Our comprehensive educational resources are curated to break down these complexities, making the world of finance accessible and understandable for all. Whether you're a novice eager to learn the basics or a seasoned investor looking to refresh and expand your knowledge, our Academy provides invaluable insights and tutorials to guide you. Equip yourself with the knowledge to navigate the financial landscape confidently and make informed decisions every step of the way.",
      paragraph1: {
      para: ["Embark on a transformative learning journey with our Academy."],
      label:"",
    },
      paragraph2: {
      para: ["Financial statements, stock market intricacies, and investment strategies can be daunting without the right guidance. ", "Our comprehensive educational resources are curated to break down these complexities, making the world of finance accessible and understandable for all. Whether you're a novice eager to learn the basics or a seasoned investor looking to refresh and expand your knowledge, our Academy provides invaluable insights and tutorials to guide you. Equip yourself with the knowledge to navigate the financial landscape confidently and make informed decisions every step of the way."],
      label:"Why a strong educational foundation is crucial? ",
    },
    isReverse: true,
    viewLabel: "Visit Academy",
    redirectUrl: `${InvexRoutes.Academy.path + "/ultimate-guide-to-stock-market"}`,
    url: `${window.location.origin}/videos/academy.mp4`
  },
]
export const HOME_SUMMARY = [
  {
    count: "15M+",
    label: "DCF Valuation",
    url:  DCFVALUE,
  },
  {
    count: "230+",
    label: "Industry Reports",
    url:  Industry_value,
    
  },
  {
    count: "70K+",
    label: "Global Stocks",
    url:  ProbTech_value,
    
  },
  {
    count: "Free",
    label: "Financial Tutorials",
    url:  Academy_value,
    
  },
]

export const TIME_DURATION = {
  days: "days",
  months: "months",
  years: "years",
};

export const dateDiffInDays = (date1, date2) => {
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
};
