import React,{useState, useEffect} from 'react'
import styles from './freepopup.module.css'
import CheckIcon from '@mui/icons-material/Check';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import { Box, IconButton, Modal } from '@mui/material';
import { Link } from 'react-router-dom';
import InvexRoutes from '../../../InvexRoutes';
import trailImg from '../../Common/Images/upgrade.png'
import CloseIcon from '@material-ui/icons/Close';
import { isPrimeAndOpn } from '../CommonFunctions';
import UpgradeNow from '../UpgradeNow/UpgradeNow';

const UpgradePopup = () => {
  // Variables
  const [isOpen, setIsOpen] = useState(false);
  const list = [
    "5M+ Global DCF Valuation Reports & 35K+ Global Relative Valuations for comprehensive insights.",
    "Comparative Analysis & 30 Years+ Financial Statements and Statistics to inform your strategies.",
    "Exclusive Technical Analysis for probability-based predictions.",
    "230+ Industry Reports, Custom Stock and Options Screener, Options Analysis, and more to tailor your investment experience.",
    "Unrestricted Access to Portfolio Gallery, DCF Calculator, and beyond."
  ]
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const messageShown = sessionStorage.getItem('messageShown');
    let timeoutId;
    if (messageShown !== "1" && !isPrimeAndOpn()) {
      if (messageShown === "true") {
        timeoutId   = setTimeout(() => {
          setShowMessage(true);
        }, 10000); 
      }
      if (!messageShown || messageShown === 'false') {
        timeoutId = setTimeout(() => {
          setShowMessage(true);
          sessionStorage.setItem('messageShown', 'true');
        }, 10000); 
      }
      return () => clearTimeout(timeoutId);
    }
  }, []); 

  const handleClose = () => {
    sessionStorage.setItem('messageShown', '1');
    setShowMessage(false);
  };

  const handleUpgrade = () => {
    sessionStorage.setItem('messageShown', '1');
    setShowMessage(false);
    setIsOpen(true);
  }


  return (
    <>
     <Modal open={showMessage} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className={styles.definationWrapper}>
        <div className={styles.wrapper} >
        <div className={styles.closeBtnWrp} >
          <IconButton onClick={handleClose}>
            <CloseIcon />
        </IconButton>
        </div>
          <div className={styles.head} >Your Exclusive Access Awaits!</div>
      <div className={styles.mainMenu} >
            <div className={styles.leftMenu} >
              <img src={trailImg} />
        </div>
              <div className={styles.rightMenu} >
                Elevate your finance game with full access to Amassing Investment. <span className={styles.highlightText} >Upgrade</span> your account now and tap into a wealth of resources designed to empower your investment decisions.
        </div>
      </div>
      <div className={styles.listWrapper} >
        {
          list?.map((val, index) => {
            return <div key={index} className={styles.row} >
              <span className={styles.checkIcon} >
                <CheckIcon fontSize="8" />
              </span>
              <span>{val}</span>
            </div>
          })
        }
      </div>
    
      <div className={styles.btnWrp} >
        <button className={styles.btn} onClick={handleUpgrade} >Upgrade Now</button>
      </div>
      
<div className={styles.footerText} >Seize the opportunity to transform your financial insights into actionable intelligence.</div>
        </div>
      </Box>
      </Modal>
        {isOpen &&
        <UpgradeNow setIsOpen={setIsOpen} isOpen={isOpen} />
      }
      </>
  )
}

export default UpgradePopup