import React,{useEffect} from 'react'
import styles from './topUnderValued.module.css'
import MostActiveTab from '../MostActiveTab/MostActiveTab'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AtomActiveLoader, AtomActiveTableData, AtomMostActiveTab } from '../../../../Atoms/MarketAtom'
import MarketTab from '../MarketTab/MarketTab'
import { HelmetTitle, TechSEOPageOnTitle } from '../../../../helpers/TechSEO'
import InvexRoutes from '../../../../InvexRoutes'
import { fetchMarketSymbols } from '../../../api/V2/user'
import MarketTable from '../MarketTable'
import { MOST_ACTIVE_COLUMNS } from '../../Symbol/FinancialStatement/Constants'
import InvexLoader from '../../../Common/InvexLoader'

const TopUnderValued = () => {
        // Variables
  const setActiveTabIndex = useSetRecoilState(AtomMostActiveTab)
  const tableData = useRecoilValue(AtomActiveTableData)
  const loader = useRecoilValue(AtomActiveLoader)

  // Callbacks
  useEffect(() => {
    setActiveTabIndex("top-undervalued")
    // getUnderValuedData()
  }, [])
  
  // const getUnderValuedData = async () => {
  //   const payload = { portfolio_slug: "most-undervalued" }
  //   const response = await fetchMarketSymbols(payload);
  //   console.log("response",response)
  //   }

    const renderComingSoon = () => {
    return <div className={styles.comingSoonWrp} >Coming Soon...</div>
  }

       const getPageSeo = () => {
    let obj = {
      pageHeadTitle: "Today's Top Undervalued Stocks - AmassingInvestment.com",
      // pageHeadTitle: "Today's Top Undervalued Stocks",
      pageTitle: TechSEOPageOnTitle("Today's Top Undervalued Stocks - AmassingInvestment.com"),
      pageHeadDescription: "Discover Today's Most Undervalued Stocks. Stay updated, identify trends, real-time insights, news and boost ROI with informed decisions.",
         };
         
    return obj;
  };
  const seoData = getPageSeo();
 const getHelmet = () => {
    return HelmetTitle(seoData, InvexRoutes.UnderValuedStocks.path);
  };
  return (
    <>
      {getHelmet()}
    <div className='container'>
      <MarketTab />
      <MostActiveTab />
        <h1 className={styles.heading} >Today's Top Undervalued Stocks</h1>
        {loader && <InvexLoader height={240} />}
        {
          !loader  &&
       <MarketTable data={tableData ?? []} colData={MOST_ACTIVE_COLUMNS} />  
           }
      {/* {renderComingSoon()} */}
    </div>
    </>
  )
}

export default TopUnderValued