import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { FacebookPixel } from 'react-facebook-pixel';

const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
};

FacebookPixel?.init('498038962126462', options);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
