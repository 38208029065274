/* eslint-disable array-callback-return */
import moment from "moment";

const IPODate = (name) => {
    const fixedLimit = "1870-01-01";
    const currdate = moment().format("YYYY-MM-DD");
    const yesterday =  moment().subtract(1, "day").format("YYYY-MM-DD");
    const week = moment().subtract(1, "week").format("YYYY-MM-DD");
    const month = moment().subtract(1, "month").format("YYYY-MM-DD");
    const year = moment().subtract(1, "year").format("YYYY-MM-DD");
    const morethan25year = moment().subtract(25, "years").format("YYYY-MM-DD");
    const morethan10year = moment().subtract(10, "year").format("YYYY-MM-DD");
    const morethan5year = moment().subtract(5, "year").format("YYYY-MM-DD");
    const morethanayear = moment().subtract(1, "year").format("YYYY-MM-DD");

    if(name === "any") return `${fixedLimit}_${currdate}`;
    if(name === "today") return `${currdate}_${currdate}`
    if(name === "yesterday") return `${yesterday}_${yesterday}`
    if(name === "week") return `${week}_${currdate}`
    if(name === "month") return `${month}_${currdate}`
    if(name === "year") return `${year}_${currdate}`
    if(name === "morethanayear") return `${fixedLimit}_${morethanayear}`
    if(name === "morethan5year") return `${fixedLimit}_${morethan5year}`
    if(name === "morethan10year") return  `${fixedLimit}_${morethan10year}`
    if(name === "morethan25year") return `${fixedLimit}_${morethan25year}`
}
export default IPODate;

export const calculateRangePriceMinMax = (minValue, maxValue) => {
    const price_min_max = [
        {min: 0, max: 10000000, value: "0_10000000"},
        {min: 0, max: 1, value: "0_1"},
        {min: 1, max: 5, value: "1_5"},
        {min: 5, max: 10, value: "5_10"},
        {min: 10, max: 20, value: "10_20"},
        {min: 20, max: 50, value: "20_50"},
        {min: 50, max: 100, value: "50_100"},
        {min: 100, max: 500, value: "100_500"},
        {min: 500, max: 10000000, value: "500_10000000"},
    ]

    let temp = [];
    for (let i=0;i< price_min_max.length; i++) {
        if (minValue >= price_min_max[i].min && maxValue <= price_min_max[i].max) {
            temp.push(price_min_max[i].value);
        }  
    }
    return temp;
}



export const getNumber = (num) => {
    
    //var units = ["K", "Mn", "Bn","Tn", "Q"]
    /*var unit = Math.floor((num / 1.0e+1).toFixed(0).toString().length)
    var r = unit%3
    var x =  Math.abs(Number(num))/Number('1.0e+'+(unit-r)).toFixed(2)*/
    //let unit = Math.floor(((num).toFixed(0).length - 1) / 3) * 3
    // Calculate the remainder
    //var num1 = (num / ('1e'+unit)).toFixed(2)
    //var unitname = units[Math.floor(unit / 3) - 1]

    //return num1 + (unitname || '')
    //return x.toFixed(2)+ ' ' + (units[Math.floor(unit / 3) - 2] || '')

    // Nine Zeroes for Billions
    return Math.abs(Number(num)) >= 1.0e+9 ? (Math.abs(Number(num)) / 1.0e+9).toFixed(2) + "Bn"
    // Six Zeroes for Millions 
    : Math.abs(Number(num)) >= 1.0e+6

    ? (Math.abs(Number(num)) / 1.0e+6).toFixed(2) + "Mn"
    // Three Zeroes for Thousands
    : Math.abs(Number(num)) >= 1.0e+3

    ? (Math.abs(Number(num)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs(Number(num));
}

export const intToString = (value) => {
    var newValue = value;
    if (Number(value) >= 1000) {
        var suffixes = ["", "K", "Mn", "Bn","Tn", "Q"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
}

export const getFloatTwoDecimalNumber = (num) => {
    var x =  Number(num);
    return x.toFixed(2);
}

export const arrayToCustomString = (value) => {
    let finalString = '';
    if (value.length === 0) {
        finalString = "None";
    } else if (value.length === 1) {
        finalString = value[0];
    } else {
        let getArrayData = value;
        let firstValueArray = getArrayData[0];
        let lastValueArray = getArrayData[value.length - 1];
        let firstSplitArray = firstValueArray.split("_");
        let secondSplitArray = lastValueArray.split("_");
        let tempArray = [];
        tempArray.push(firstSplitArray[0]);
        tempArray.push(secondSplitArray[1]);
        finalString = tempArray.join("_");
    }
    return finalString
}

export const stringToArray = (value) => {
    let finalString = [];
    if (value.length === 0) {
        finalString = [0, 600];
    } else if (value.length === 1) {
        let valueData = value[0].split("_");
        finalString = [Number(valueData[0]), Number(valueData[1])];
    } else {
        let getArrayData = value;
        let firstValueArray = getArrayData[0];
        let lastValueArray = getArrayData[value.length - 1];
        let firstSplitArray = firstValueArray.split("_");
        let secondSplitArray = lastValueArray.split("_");
        let tempArray = [Number(firstSplitArray[0]), Number(secondSplitArray[1])];
        finalString = tempArray
    }
    return finalString
}

export const getTickerLists = (ticker_values, concat_text = "_") => {
    let finalString = '';
    ticker_values.map((item, index) => {
      finalString += item.symbol + concat_text
    });
    finalString = finalString.slice(0, -1);
    return finalString;
}
