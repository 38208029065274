
import React, { useEffect, useState } from 'react'
import { SENTIMENT_SHIFT_COLUMNS, SOCIAL_SENTIMENT_COLUMNS } from '../../Symbol/FinancialStatement/Constants'
import { Grid, Box } from "@material-ui/core";
import MarketTable from '../MarketTable';
import styles from './SocialSentiment.module.css'
import { SOCIAL_SENTIMENT_TABS } from '../../MacroEconomics/Constants';
import { getToken } from '../../../Common/CommonFunctions';
import InvexLoader from '../../../Common/InvexLoader';
import { AtomSelectedTab } from '../../../../Atoms/MarketAtom';
import { useSetRecoilState } from 'recoil';
import MarketTab from '../MarketTab/MarketTab';
import { HelmetTitle, TechSEOPageOnTitle } from '../../../../helpers/TechSEO';
import InvexRoutes from '../../../../InvexRoutes';
import image from '../../../Common/Images/4632221.png'
import MarketInfo from '../MarketInfo/MarketInfo';
import InsightCornor from '../InsightCornor/InsightCornor';
import { getInsightCornor, getValuvationPrice } from '../../../api/Symbol';

const SocialSentiment = () => {
  // Variables
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState("bullish");
  const setActive = useSetRecoilState(AtomSelectedTab);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState({
    header: "",
    key: "",
  });

  // Functions
  const getValuvation = async (symbols, newObj) => {
    try {
      let _list = [];
    const response = await getValuvationPrice({ symbols: symbols });
      let valObj = {}
      for (const valData of response) {
        valObj[valData?.symbol] = valData;
      }
      let newList = [];
      for (const data of Object.values(newObj) ?? []) {
        let isIncludes = valObj[data?.symbol];
        if (!isIncludes) {
          let _obj = { ...data };
          _obj["valuvation"] = "-";
          _obj["pricing"] = "-";
          newList.push(_obj);
        } else {
          let _newObj = { ...newObj[data?.symbol] };
          _newObj["valuvation"] = (isIncludes?.industry === "Financial Services" || _newObj?.industry === "Real Estate") ? isIncludes?.ermValuation?.toFixed(2) : isIncludes?.fcffValuation?.toFixed(2);
          _newObj["pricing"] = isIncludes?.pricing?.toFixed(2);
          _list.push(_newObj)
        }
      }
      console.log("_list",_list);

      let _mergeArr = [...newList, ..._list];
      return _mergeArr
    } catch (error) {
      console.log(error);
    }
  }
  const getData = async () => {
    setLoading(true);
    const res = await getInsightCornor({
      url: `https://financialmodelingprep.com/api/v4/social-sentiments/trending?type=${activeTabIndex}&source=stocktwits&apikey=b1360803f80dd08bdd0211c5c004ad03`,
    });
    let _list = [];
    let newObj = {};
    let _symbolsList = [];
    for (const data of res?.data ?? []) {
      let obj = { ...data };
      obj["lastSentiment"] = data?.lastSentiment?.toFixed(2);
      obj["sentiment"] = data?.sentiment?.toFixed(2);
      newObj[data?.symbol] = obj;
      _list.push(obj);
      _symbolsList.push(data?.symbol);
    }
    const resData = await getValuvation(_symbolsList, newObj);
    let _sortedData = resData?.sort((a, b) => a.rank > b.rank ? 1 : -1);
    setTableData(_sortedData);
   
    const response = await getInsightCornor({
      url: `https://financialmodelingprep.com/api/v4/social-sentiments/change?type=${activeTabIndex}&source=stocktwits&apikey=b1360803f80dd08bdd0211c5c004ad03`,
    });

    let list = [];
    let listSymbols = [];
    let _newObj = {};
    for (const data of response?.data ?? []) {
      let obj = { ...data };
      obj["sentimentChange"] = data?.sentimentChange?.toFixed(2);
      obj["sentiment"] = data?.sentiment?.toFixed(2);

      _newObj[data?.symbol] = obj;
      listSymbols.push(data?.symbol)
      list.push(obj);
    }
    console.log("listSymbols",listSymbols);
    console.log("_newObj",_newObj);
    const resData1 = await getValuvation(listSymbols, _newObj);
    let _sData = resData1?.sort((a, b) => a.rank > b.rank > 0 ? 1 : -1);

    setLoading(false);

    setTableData1(_sData);
  }

  // Callbacks
  useEffect(() => {
    setActive("social-sentiment")
  }, [])

  useEffect(() => {
    getData();

  }, [activeTabIndex])
  const renderTabView = () => {
    return <>
      <Grid item xs={12} sm={12} md={12} lg={12} className="mb-10">
        <Box sx={{ border: "1px solid #e3e3e3", borderRadius: "10px" }}>
          <div className={`${styles.tabWrapper} top_button_panel v2 mt-4 mb-3`}>
            {SOCIAL_SENTIMENT_TABS?.map(
              (key) => {
                return (
                  <>
                    <button
                      key={key.value}
                      type="button"
                      className={`btn ${styles.tabBtn} ${activeTabIndex === key?.value ? styles.activeBtn : styles.deActiveBtn}`}
                      onClick={() => {
                        setActiveTabIndex(key?.value);
                      }}
                    >
                      {key?.label}
                    </button>
                  </>
                );
              }
            )}
          </div>
        </Box>
      </Grid>
    </>
  }

  const getPageSeo = () => {
    let obj = {
      pageHeadTitle: "Market Sentiment Analysis on Social Media",
      pageTitle: TechSEOPageOnTitle("Social Sentiment | Amassing Investment"),
      pageHeadDescription: "Explore social sentiment analysis for the market with Amassing Investment. Understand investor attitudes and emotions through social media trends to guide your investment decisions.",
    };
    return obj;
  };
  const seoData = getPageSeo();
  const getHelmet = () => {
    return HelmetTitle(seoData, InvexRoutes.SocialSentiment.path);
  };

  const handleOpen = () => {
    setIsOpen(true)
    setSelectedKey({
      header: "Most Active",
      key: "socialSentimentSpotLight"
    })
  }

  return (
    <div className='container'>
      {getHelmet()}
      <MarketTab />

      <h1 style={{ margin: "1rem 0rem", fontWeight: 600, fontSize: "1.5rem", fontFamily: "merriweather" }} >Market Sentiment Analysis on Social Media - Stockwits</h1>
      {renderTabView()}
      {
        !loading &&
        <>
          <MarketTable data={tableData ?? []} colData={SOCIAL_SENTIMENT_COLUMNS} />
          <div className={styles.flex} >
            <h1 style={{ margin: "1rem 0rem", fontWeight: 600, fontSize: "1.5rem", fontFamily: "merriweather" }} >{"Sentiment Shift Spotlight >>"} </h1>
            <div className={styles.iconWrapper} onClick={handleOpen} >
              <img src={image} />
            </div>
          </div>
          <MarketTable data={tableData1 ?? []} colData={SENTIMENT_SHIFT_COLUMNS} />
        </>
      }
      {loading && <InvexLoader height={240} />}
      {isOpen && <MarketInfo selectedKey={selectedKey} setIsOpen={setIsOpen} isOpen={isOpen} />}
      {
        !loading && <InsightCornor />
      }
    </div>
  )
}

export default SocialSentiment