import React from 'react'
import styles from './MarketInfo.module.css'
import { Box, Button, Modal } from '@mui/material'
import { MARKET_MOVERS } from '../../Symbol/NewConstants'


const MarketInfo = ({ isOpen, setIsOpen, selectedKey, type }) => {
  console.log("selectedKey",selectedKey);
  // Variables
  const { key, header } = selectedKey;
  let selectedData = MARKET_MOVERS[key ?? ""];
  // Template 
  const renderNoData = () => {
    return <div className={styles.noData} >No Data Available...</div>
  }

  const renderTemplate = () => {
    return <>
         <div className={styles.contentWrapper} >
                <div className={`${styles.row}`} >
              <span className={styles.label} >Section OverView:</span> {selectedData?.sectionOverView ?? ""}
            </div>
            <div className={styles.row} >
              <span className={styles.label} >Investor Benefits:</span> {selectedData?.investorBenefits ?? ""}
            </div>
            <div className={`${styles.row}`} >
             <span className={styles.label} >Important Considerations:</span>  {selectedData?.impConsiderations ?? ""}
            </div>
            </div>
    </>
  }
  
  return (
    <div className={styles.wrapper} >
      <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Box className={styles.definationWrapper}>
          <div className={styles.subWrapper} >
            <div className={styles.header} >{header}</div>
            {
              !selectedData ? renderNoData() : 
              renderTemplate()}
            <div className={styles.footer} >
              <Button className={styles.cancelBtn} onClick={() => setIsOpen(false)} >Close</Button>
            </div>
             </div>
          </Box>
      </Modal>
    </div>
  )
}

export default MarketInfo