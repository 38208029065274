import React,{useEffect} from 'react'
import styles from './topOverValued.module.css'
import MostActiveTab from '../MostActiveTab/MostActiveTab'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AtomActiveLoader, AtomActiveTableData, AtomMostActiveTab } from '../../../../Atoms/MarketAtom'
import MarketTab from '../MarketTab/MarketTab'
import { HelmetTitle, TechSEOPageOnTitle } from '../../../../helpers/TechSEO'
import InvexRoutes from '../../../../InvexRoutes'
import MarketTable from '../MarketTable'
import { MOST_ACTIVE_COLUMNS } from '../../Symbol/FinancialStatement/Constants'
import InvexLoader from '../../../Common/InvexLoader'

const TopOverValued = () => {
    // Variables
  const setActiveTabIndex = useSetRecoilState(AtomMostActiveTab)
   const tableData = useRecoilValue(AtomActiveTableData)
  const loader = useRecoilValue(AtomActiveLoader)

  // Callbacks
  useEffect(() => {
    setActiveTabIndex("top-overvalued")
  },[])
    const renderComingSoon = () => {
    return <div className={styles.comingSoonWrp} >Coming Soon...</div>
  }
     const getPageSeo = () => {
    let obj = {
      pageHeadTitle: "Today's Top Overvalued Stocks",
      pageTitle: TechSEOPageOnTitle("Today's Top Overvalued Stocks - AmassingInvestment.com"),
      pageHeadDescription: "Discover Today's Most Overvalued Stocks. Stay updated, identify trends, real-time insights, news and boost ROI with informed decisions.",
    };
    return obj;
  };
  const seoData = getPageSeo();
  const getHelmet = () => {
    return HelmetTitle(seoData, InvexRoutes.OverpricedStocks.path);
  };


  return (
    <>
      {getHelmet()}
    <div className='container'>
      <MarketTab />
      <MostActiveTab />
        <h1 className={styles.heading} >Today's Top Overvalued Stocks</h1>
        {loader && <InvexLoader height={240} />}
        {
          !loader  &&
       <MarketTable data={tableData ?? []} colData={MOST_ACTIVE_COLUMNS} />  
           }
    </div>
    </>
  )
}

export default TopOverValued
