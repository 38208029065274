
import React, { useEffect, useState } from 'react'
import { ECONOMIC_CALENDER_COLUMNS, MARKET_GAINERS_COLUMNS } from '../../Symbol/FinancialStatement/Constants'
import axios from 'axios';
import MarketTable from '../MarketTable';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import styles from './EconomicCalender.module.css'
import { Button } from 'react-bootstrap';
import InvexLoader from '../../../Common/InvexLoader';
import { AtomSelectedTab } from '../../../../Atoms/MarketAtom';
import { useSetRecoilState } from 'recoil';
import MarketTab from '../MarketTab/MarketTab';
import { HelmetTitle, TechSEOPageOnTitle } from '../../../../helpers/TechSEO';
import InvexRoutes from '../../../../InvexRoutes';
import InsightCornor from '../InsightCornor/InsightCornor';
import { getInsightCornor } from '../../../api/Symbol';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const EconomicCalender = () => {
  // Variables
  const [tableData, setTableData] = useState({
    filteredData: [],
    allData: [],
  });
  const [options, setOptions] = useState({
    country: [],
    currency: [],
    impact: [],
  })
  const [selectedValue, setSelectedValue] = useState({
    currency: "",
    country: "US",
    impact: "",
    formattedDate: "",
  })

      const today = dayjs();
  const nextMonth = today.add(1, 'month');
  const [selectedDates, setSelectedDates] = useState([dayjs(), nextMonth]);
  const setActiveTabIndex = useSetRecoilState(AtomSelectedTab)

  const [isLoading, setIsLoading] = useState(false)
 

  // Functions
  const getData = async (sDate, eDate) => {
    console.log("sDate",sDate);
    console.log("eDate",eDate);
    setIsLoading(true);
      const res = await getInsightCornor({
          url: `https://financialmodelingprep.com/api/v3/economic_calendar?from=${sDate}&to=${eDate}&apikey=b1360803f80dd08bdd0211c5c004ad03`,
      });
    let _list = [];
    let usa = [];
    const currencyList = [];
    const countryList = [];
    const impactList = [];
    console.log("res?.data",res?.data);
    for (const data of res?.data ?? []) {
      let obj = { ...data };
      let currencyObj = {};
      let countryObj = {};
      let impactObj = {};
      currencyObj["label"] = data?.currency;
      currencyObj["value"] = data?.currency;
      currencyObj["name"] = "currency";
      countryObj["label"] = data?.country;
      countryObj["value"] = data?.country;
      countryObj["name"] = "country";
      impactObj["label"] = data?.impact;
      impactObj["value"] = data?.impact;
      impactObj["name"] = "impact";
      let time = data?.date?.slice(11);
      let _fDate = dayjs(data?.date).format("YYYYMMDD");
      let fDate = dayjs(data?.date).format("DD MMM YYYY");
      obj["time"] = time;
      obj["formattedDate"] = _fDate;
      obj["fDate"] = fDate;
      obj["changePercentage"] = data?.changePercentage?.toFixed(2) + " %";
      _list.push(obj);
      if (data?.country === "US") {
        usa.push(obj); 
      }
      const index = countryList.findIndex((val) => val.value === data?.country);
      const currencyIndex = currencyList.findIndex((val) => val.value === data?.currency);
      const impactIndex = impactList.findIndex((val) => val.value === data?.impact);
      if (index === -1) {
        countryList.push(countryObj);
      }
      if (currencyIndex === -1) {
        currencyList.push(currencyObj);
      }
      if (impactIndex === -1) {
        impactList.push(impactObj);
      }
      
    }
    setOptions({
      country: countryList,
      currency: currencyList,
      impact: impactList
    });
    setTableData({
      filteredData: usa,
      allData: _list
    });
    setIsLoading(false);
  }

  const formattedDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD")
  }

  const handleDateChange = (data) => {
     let startDate = data[0];
     let endDate = data[1];
     getData(formattedDate(startDate), formattedDate(endDate));
    setSelectedDates(data);
  };

     useEffect(() => {
    setActiveTabIndex("economic-calendar")
  },[])

  const handleChange = (value) => {
    let _selectedValue = { ...selectedValue };
    _selectedValue[value.name] = value.value;
    handleFilter(_selectedValue)
    setSelectedValue(_selectedValue);
  }

  const handleFilter = (selectedVal) => {
    let _allData = tableData.allData;
         const filteredData = _allData.filter((item) => {
           return Object.entries(selectedVal).every(([key, value]) => {
             return value === '' || String(item[key]).toLowerCase().includes(value.toLowerCase());
            });
          })
    setTableData({...tableData, ["filteredData"]:filteredData})
  }

  
  const handleReset = () => {
    setSelectedValue({
       currency: "",
      country: "",
      impact: "",
      formattedDate: "",
    })
    let _sDate = dayjs();
     getData(_sDate, nextMonth)
    setSelectedDates([_sDate, nextMonth]);
    setTableData({...tableData, ["filteredData"]:tableData.allData})
  }

  // Callbacks
  useEffect(() => {
    let sDate = formattedDate(selectedDates[0]);
    let eDate = formattedDate(selectedDates[1]);
    getData(sDate, eDate);
  }, [])

  


  // Template 
  const renderDropdown = (options, label, value) => {
    let _value = value === "" ? label : value;
    return <div className={styles.dpWrapper} >
      <div class="dropdown">
  <button style={{ minWidth: 120, backgroundColor:"#0f062b", display: "flex", justifyContent:"space-between", alignItems:"center" }} class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    {_value}
  </button>
  <ul style={{ maxHeight: 200, overflow: "auto" }}  class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    {
          options?.map((val,index) => {
            return <li key={index} onClick={() => handleChange(val)} >
              <a className="dropdown-item" >
                {val?.label}
            </a>
            </li>
          })
        }
  </ul>
</div>
    </div>
  }

  const getPageSeo = () => {
    let obj = {
      pageHeadTitle: "Global Economic Events and Announcements Calendar",
      pageTitle: TechSEOPageOnTitle("Economic Calendar | Amassing Investment"),
      pageHeadDescription: "Stay ahead of the market with Amassing Investment's comprehensive Economic Calendar. Track global economic events, announcements, and indicators to guide your investment decisions.",
    };
    return obj;
  };
  const seoData = getPageSeo();
  const getHelmet = () => {
    return HelmetTitle(seoData, InvexRoutes.EconomicCalendar.path);
  };

  return (
    <div className='container' >
      {getHelmet()}
      <MarketTab />

      <h1 style={{ margin: "1rem 0rem", fontWeight: 600, fontSize: "1.5rem",fontFamily: "merriweather" }} >Global Economic Events and Announcements Calendar</h1>
      <div className={styles.grid} >
        {renderDropdown(options?.currency, "Currency",selectedValue?.currency)}
        {renderDropdown( options?.country, "Country",selectedValue?.country)}
        {renderDropdown(options?.impact, "Impact", selectedValue?.impact)}
        <div className={styles.dateRangeWrapper} >
        <RangePicker  onChange={handleDateChange} value={selectedDates} defaultValue={selectedDates} />
        </div>
        <Button style={{  minWidth: 120, }} onClick={handleReset}>Reset</Button>
      </div>
      {
        !isLoading &&
      <MarketTable isClickable data={tableData?.filteredData ?? []} colData={ECONOMIC_CALENDER_COLUMNS} />
}
      {isLoading && <InvexLoader height={240} />}
      <InsightCornor />
    </div>
  )
}

export default EconomicCalender