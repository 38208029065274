export const useEncrypt = true
export const env = "prod"
const newServer = false
let MarketbashUrl1 = "https://cp1.invexaitechnology.com/api/v1";

const MarketbashUrls1 = "https://";

let BASE_URL_V21 = "https://api.amassinginnovation.com";

const VALUATION_URL1 = "https://backend.invexwealth.com/";

const BASE_URL_V31 = "https://cp3.invexwealth.com";

const BLOG_POST_URL1 = "https://wp.amassinginvestment.com/wp-json";

let CP1_V2_URL1 = "https://testing-node.testing.amassinginvestment.com/api/v2";
let SERVICE_V3_URL1 = "https://testing-node.testing.amassinginvestment.com";
let monthlyPlanLink1 = 'https://amassinginvestment-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=BETA-USD-Monthly&subscription_items[quantity][0]=1&layout=in_app'
let yearlyPlanLink1 = 'https://amassinginvestment-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=BETA-USD-Yearly&subscription_items[quantity][0]=1&layout=in_app'

let chargeBeePortalLink = 'https://amassinginvestment-test.chargebeeportal.com'
switch (env) {
    case "prod":
        CP1_V2_URL1 = newServer? " https://api.amassinginnovation.com": " https://api.amassinginnovation.com"
        SERVICE_V3_URL1 = newServer? "https://cp1.invexaitechnology.com":  "https://cp1.invexaitechnology.com"
        BASE_URL_V21 = newServer ? "https://api.amassinginnovation.com": "https://cp2.invexwealth.com"
        monthlyPlanLink1 = "https://amassinginvestment.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=BETA-USD-Monthly&subscription_items[quantity][0]=1&layout=in_app"
        yearlyPlanLink1 = 'https://amassinginvestment.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=BETA-USD-Yearly&subscription_items[quantity][0]=1&layout=in_app'
        chargeBeePortalLink = 'https://amassinginvestment.chargebeeportal.com'
        break;
    case "local": 
        CP1_V2_URL1 = "http://localhost:3000/api/v2"
        SERVICE_V3_URL1 = " https://api.amassinginnovation.com"
        break;
    default:
        break;
}



//symbol=AAPL&period=quarter&structure=flat&apikey=b1360803f80dd08bdd0211c5c004ad03
//symbol=AAPL&period=quarter&structure=flat&apikey=b1360803f80dd08bdd0211c5c004ad03


export const MarketbashUrl = MarketbashUrl1
export const MarketbashUrls = MarketbashUrls1
export const BASE_URL_V2 = BASE_URL_V21
export const VALUATION_URL = VALUATION_URL1
export const BASE_URL_V3 = BASE_URL_V31
export const CP1_V2_URL = CP1_V2_URL1
export const SERVICE_V3_URL = SERVICE_V3_URL1
export const BLOG_POST_URL = BLOG_POST_URL1
export const monthlyPlanLink = monthlyPlanLink1
export const yearlyPlanLink = yearlyPlanLink1
export const chargeBeePortal = chargeBeePortalLink