import { ServiceCP1V2,ServiceV4 } from "../../../services/apiService";

export const getTokenDetails = async (param) => {
  var { data } = await ServiceCP1V2.get(`/verification/${param}`);
  return data;
};

export const setUserpaassword = async (token, params) => {
  let _params = { ...params, token };
    var { data } = await ServiceCP1V2.post(`/setpassword`, _params);
    return data;
  };

export const getSectorTableData = async (param) => {
  var { data } = await ServiceCP1V2.post(`/screener_2`, param);
  return data;
};

export const getSectorRevenueData = async (param) => {
  var { data } = await ServiceCP1V2.post(`/screener_4`, param);
  return data;
};

export const setUserScreenerFilter = async (param) => {
  var { data } = await ServiceCP1V2.post(`/storeUserScreenerFilter/`, param);
  return data;
}

export const getUserScreenerFilter = async (param) => {
  var { data } = await ServiceCP1V2.post(`/fetchUserScreenerFilter/`, param);
  return data;
}

export const storeUserPortfolio = async (param) => {
  var { data } = await ServiceCP1V2.post(`/storeUserPortfolio/`, param);
  return data;
}

export const publishPortfolio = async (param) => {
  var { data } = await ServiceCP1V2.post(`/storeUserPortfolioPublish/`, param, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
}
export const updateUserPortfolioPublish = async (param) => {
  var { data } = await ServiceCP1V2.post(`/updateUserPortfolioPublish/`, param);
  return data;
}
export const listPublished = async (param) => {
  var { data } = await ServiceCP1V2.post(`/listPublished/`, param);
  return data;
}
export const getUserPortfolioLists = async (param) => {
  var { data } = await ServiceCP1V2.post(`/fetchUserPortfolioLists/`, param);
  return data;
}
export const getSuggestedPortfolio = async () => {
  var { data } = await ServiceV4.get(`/get_portfolio_symbols`);
  return data;
}

export const fetchPortfolioDetail = async (param) => {
  var { data } = await ServiceCP1V2.post(`/fetchPortfolioDetail/`, param);
  return data;
}
export const fetchMarketSymbols = async (param) => {
  var { data } = await ServiceCP1V2.post(`/marketsymbols`, param);
  return data;
}

export const deletePortfolio = async (param) => {
  var { data } = await ServiceCP1V2.post(`/deletePortfolio/`, param);
  return data;
}

export const userRegistration = async (param) => {
  var { data } = await ServiceCP1V2.post(`/user_signup`, param);
  return data;
}

export const setUserDcfCalculatorFilter = async (param) => {
  var { data } = await ServiceCP1V2.post(`/storeUserDcfCalculatorFilter/`, param);
  return data;
}

export const deleteDcfSavedFilter = async (param) => {
  var { data } = await ServiceCP1V2.post(`/storeUserDcfCalculatorFilter/`, param);
  return data;
}

export const getUserDcfCalculatorFilter = async (param) => {
  var { data } = await ServiceCP1V2.post(`/fetchUserDcfCalculatorFilter/`, param);
  return data;
}