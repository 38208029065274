import { lazy, Suspense, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import "./style.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import InvexRoutes from "./InvexRoutes";
import InvexLoader from "./component/Common/InvexLoader";
import { Toast } from "./component/Common/Toast/Toast";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
// import SuggestedPortfolio from "./component/V2/SuggestedPortfolio/SuggestedPortfolio";
import { RecoilRoot } from "recoil";
import TopUnderValued from "./component/V2/Market/TopUnderValued/TopUnderValued";
import TopOverValued from "./component/V2/Market/TopOverValued/TopOverValued";
import TopUnderPrice from "./component/V2/Market/TopUnderPrice/TopUnderPrice";
import TopOverPrice from "./component/V2/Market/TopOverPrice/TopOverPrice";
import MarketLosers from "./component/V2/Market/MarketLosers/MarketLosers";
import MostGainers from "./component/V2/Market/MostGainers/MostGainers";
import MostActive from "./component/V2/Market/MostActive/MostActive";
import MarketIndicas from "./component/V2/Market/MarketIndicas/MarketIndicas";
import EconomicCalender from "./component/V2/Market/EconomicCalender/EconomicCalender";
import EarningsCalender from "./component/V2/Market/EarningsCalender/EarningsCalender";
import SocialSentiment from "./component/V2/Market/SocialSentiment/SocialSentiment";
import NewPayment from "./component/V2/NewPayment/NewPayment";
import FreePopup from "./component/Common/FreePopup/FreePopup";
import GlobalProvider from "./component/GlobalProvider/GlobalProvider";
import MarqueeHeader from "./component/V2/NavBar/Marquee";

const Ugtsm = lazy(() => import("./component/V2/Blog/Ugtsm"));
const Contact = lazy(() => import("./component/V2/ContactUs/Contact"));
const PrivacyPolicyEu = lazy(() => import("./component/V2/Policy/PrivacyPolicyEU/PrivacyPolicyEu"));
const LegalDesclaimers = lazy(() => import("./component/V2/Policy/LegalDesclaimers/LegalDesclaimers"));
const CopyRightPolicy = lazy(() => import("./component/V2/Policy/TermsAndConditions"));
const WebsiteTerm = lazy(() => import("./component/V2/Policy/WebsiteTerm/WebsiteTerm"));
const AdsComponent = lazy(() => import("./component/Common/AddComponent"));
const Companynews = lazy(() => import("./component/V2/Symbol/Synopsis/Companynews/Companynews"));
const Ugtsmindex = lazy(() => import("./component/V2/Blog/Ugtsmindex"));
const Topholding = lazy(() => import("./component/V2/Symbol/Synopsis/Topholding/Topholding"));
const GoToTop = lazy(() => import("./ScrollToTop"));
// import GoToTop from './ScrollToTop';
const Footer = lazy(() => import("./component/Common/Footer/Footer"));
const NotFound = lazy(() => import("./component/404/404"));
const SymbolNotPublished = lazy(() => import("./component/V2/Symbol/Valuation/SymbolNotPublished"));
const Symbol = lazy(() => import("./component/V2/Symbol/SymbolDetails"));
const NewNavbar = lazy(() => import("./component/V2/NavBar/NewNavbar"));
const Options = lazy(() => import("./component/V2/Options/Options"));
const Screener = lazy(() => import("./component/V2/Screener/Screener"));
const Sector = lazy(() => import("./component/V2/Sector/Sector"));
const MacroEconomics = lazy(() => import("./component/V2/MacroEconomics/MacroEconomics"));
const Home = lazy(() => import("./component/V2/Home/HomeNew"));
const Market = lazy(() => import("./component/V2/Market/Market"));
const Dashboard = lazy(() => import("./component/V2/Dashboard/Dashboard"));
const DCFCalculator = lazy(() => import("./component/V2/DCFCalculator"));
const News = lazy(() => import("./component/V2/News/News"));
const Login = lazy(() => import("./component/V2/Login/Login"));
const ForgotPassword = lazy(() => import("./component/V2/ForgotPassword/ForgotPassword"));
const Subscription = lazy(() => import("./component/V2/Payment/index"));
const Register = lazy(() => import("./component/V2/Register/Register"));
const SetPassword = lazy(() => import("./component/V2/SetPassword/SetPassword"));
const UserProfile = lazy(() => import("./component/V2/UserProfile/UserProfile"));
const ProtectedRoute = lazy(() => import("./component/V2/ProtectedRoute/ProtectedRoute"));
const TermsAndConditions = lazy(() => import("./component/V2/Policy/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./component/V2/Policy/PrivacyPolicy"));
const CookiePolicy = lazy(() => import("./component/V2/Policy/CookiePolicy"));
const RefundPolicy = lazy(() => import("./component/V2/Policy/RefundPolicy"));
const WatchList = lazy(() => import("./component/V2/Portfolio/WatchList"));
const BlogDetail = lazy(() => import("./component/V2/BlogDetail"));
const Blog = lazy(() => import("./component/V2/Blog"));
const BlogCategories = lazy(() => import("./component/V2/Blog/BlogCategories"));
const ChecklistPortfolio = lazy(() => import("./component/V2/ChecklistPortfolio"));
const WatchlistPortfolio = lazy(() => import("./component/V2/WatchlistPortfolio"));
const CardDetails = lazy(() => import("./component/V2/CardDetails/CardDetails"));

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "G-9CPXSNRQVK" });
  }, []);



  return (
    <Provider store={store}>
      <RecoilRoot>
      <Toast />
        <div className="App">
        <BrowserRouter>
          <Suspense fallback={<InvexLoader height="100vh" />}>
            <GoToTop />
            <NewNavbar />
            {/* {<AdsComponent dataAdSlot="7259870550" />} */}
              <GlobalProvider>
      <MarqueeHeader />

              <Routes>
              <Route
                exact
                path={InvexRoutes.Home.path}
                element={
                  <>
                    <Home />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.Login.path}
                element={
                  <>
                    <Login />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.ForgotPassword.path}
                element={
                  <>
                    <ForgotPassword />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.SetPassword.path}
                element={
                  <>
                    <SetPassword />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.Register.path}
                element={
                  <>
                    <Register />
                  </>
                }
              />
              <Route
                exact
                path={InvexRoutes.Subscription.path}
                element={
                  <>
                    <Subscription />
                  </>
                }
              />
              <Route
                exact
                path={InvexRoutes.Payment.path}
                element={
                  <>
                    <NewPayment />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.TermsAndConditionPolicy.path}
                element={
                  <>
                    <TermsAndConditions />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.PrivacyPolicy.path}
                element={
                  <>
                    <PrivacyPolicy />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.RefundPolicy.path}
                element={
                  <>
                    <RefundPolicy />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.dcfCalculator.path}
                element={
                  <>
                    <DCFCalculator />
                  </>
                }
              />



                
              <Route
                exact
                path={InvexRoutes.CookiePolicy.path}
                element={
                  <>
                    <CookiePolicy />
                  </>
                }
              />
              <Route >
                <Route
                  exact
                  path={InvexRoutes.watchlistPortfolio.path}
                  element={
                    <>
                      <WatchlistPortfolio />
                    </>
                  }
                />
                <Route
                  exact
                  path={InvexRoutes.CardDetails.path}
                  element={
                    <>
                      <CardDetails />
                    </>
                  }
                />

                <Route
                  exact
                  path={InvexRoutes.watchlistPortfolio.path1}
                  element={
                    <>
                      <Screener isWatchlist={true} />
                    </>
                  }
                />
                <Route
                  exact
                  path={InvexRoutes.watchlistPortfolio.publishedpath}
                  element={
                    <>
                      <Screener isWatchlist={true} published={true} />
                    </>
                  }
                />
              </Route>
         
              <Route>
               
                <Route
                  exact
                  path={InvexRoutes.Profile.path}
                  element={
                    <>
                      <UserProfile />
                    </>
                  }
                />
                <Route exact path={InvexRoutes.SymbolNotPublished.path} element={<SymbolNotPublished />} />

                {/* Start ::  Routes */}
                <Route
                  exact
                  path={InvexRoutes.Symbol.path}
                  element={
                    <>
                      <Symbol />
                    </>
                  }
                >
                  <Route
                    exact
                    path={InvexRoutes.Symbol.path1}
                    element={
                      <>
                        <Symbol />
                      </>
                    }
                  >
                    <Route
                      exact
                      path={InvexRoutes.Symbol.path2}
                      element={
                        <>
                          <Symbol />
                        </>
                      }
                    />
                  </Route>
                </Route>

                <Route
                  exact
                  path={InvexRoutes.Options.path}
                  element={
                    <>
                      <Options />
                    </>
                  }
                >
                  <Route
                    exact
                    path=":tab"
                    element={
                      <>
                        <Options />
                      </>
                    }
                  >
                    <Route
                      exact
                      path=":id"
                      element={
                        <>
                          <Options />
                        </>
                      }
                    ></Route>
                  </Route>
                </Route>
                <Route
                  exact
                  path={InvexRoutes.Screener.path}
                  element={
                    <>
                      <Screener isWatchlist={false} />
                    </>
                  }
                />
                <Route
                  exact
                  path={InvexRoutes.checklistPortfolio.path}
                  element={
                    <>
                      <ChecklistPortfolio />
                    </>
                  }
                />
                <Route
                  exact
                  path={InvexRoutes.Sectors.path}
                  element={
                    <>
                      <Sector />
                    </>
                  }
                >
                  <Route
                    exact
                    path=":tab"
                    element={
                      <>
                        <Sector />
                      </>
                    }
                  >
                    <Route
                      exact
                      path=":id"
                      element={
                        <>
                          <Sector />
                        </>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  exact
                  path={InvexRoutes.MacroEconomics.path}
                  element={
                    <>
                      <MacroEconomics />
                    </>
                  }
                >
                  <Route
                    exact
                    path=":tab"
                    element={
                      <>
                        <MacroEconomics />
                      </>
                    }
                  >
                    <Route
                      exact
                      path=":id"
                      element={
                        <>
                          <MacroEconomics />
                        </>
                      }
                    />
                  </Route>
                  </Route>
                  <Route>
                    <Route
                      exact
                      path={InvexRoutes.SocialSentiment.path}
                      element={
                        <>
                          <SocialSentiment />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.EarningsCalendar.path}
                      element={
                        <>
                          <EarningsCalender />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.EconomicCalendar.path}
                      element={
                        <>
                          <EconomicCalender />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.MarketiIndices.path}
                      element={
                        <>
                          <MarketIndicas />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.ActiveStocks.path}
                      element={
                        <>
                          <MostActive />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.MarketGainer.path}
                      element={
                        <>
                          <MostGainers />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.MarketLosers.path}
                      element={
                        <>
                          <MarketLosers />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.UnderValuedStocks.path}
                      element={
                        <>
                          <TopUnderValued />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.OverValuedStocks.path}
                      element={
                        <>
                          <TopOverValued />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.UnderPricedStocks.path}
                      element={
                        <>
                          <TopUnderPrice />
                        </>
                      }
                    />
                    <Route
                      exact
                      path={InvexRoutes.OverpricedStocks.path}
                      element={
                        <>
                          <TopOverPrice />
                        </>
                      }
                    />
                  </Route>
                {/* <Route
                  exact
                  path={InvexRoutes.Market.path}
                  element={
                    <>
                      <Market />
                    </>
                  }
                >
                  <Route
                    exact
                    path=":id"
                    element={
                      <>
                        <Market />
                      </>
                    }
                  />
                </Route> */}
                <Route
                  exact
                  path={InvexRoutes.News.path}
                  element={
                    <>
                      <News />
                    </>
                  }
                >
                  <Route
                    exact
                    path=":tab"
                    element={
                      <>
                        <News />
                      </>
                    }
                  />
                </Route>

                <Route
                  exact
                  path={InvexRoutes.Dashboard.path}
                  element={
                    <>
                      <Dashboard />
                    </>
                  }
                />

                <Route
                  exact
                  path={InvexRoutes.WatchList.path}
                  element={
                    <>
                      <WatchList />
                    </>
                  }
                />
              </Route>

              <Route
                exact
                path={InvexRoutes.Companynews.path}
                element={
                  <>
                    <Companynews />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.Topholding.path}
                element={
                  <>
                    <Topholding />
                  </>
                }
              />
              <Route
                exact
                path={InvexRoutes.blog.path}
                element={
                  <>
                    <Blog />
                  </>
                }
              />
              <Route
                path={InvexRoutes.blogCategories.path}
                element={
                  <>
                    <BlogCategories />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.blogDetails.path}
                element={
                  <>
                    <BlogDetail />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.ContactUs.path}
                element={
                  <>
                    <Contact />
                  </>
                }
              />
              <Route
                exact
                path={InvexRoutes.PrivacyPolicyEu.path}
                element={
                  <>
                    <PrivacyPolicyEu />
                  </>
                }
              />
              <Route
                exact
                path={InvexRoutes.WebsiteTermsOfUse.path}
                element={
                  <>
                    <WebsiteTerm />
                  </>
                }
              />

              <Route
                exact
                path={InvexRoutes.LegalDesclaimers.path}
                element={
                  <>
                    <LegalDesclaimers />
                  </>
                }
              />
              <Route
                exact
                path={InvexRoutes.CopyRightPolicy.path}
                element={
                  <>
                    <CopyRightPolicy />
                  </>
                }
              />
              <Route exact path="/academy/:slug" element={<Ugtsmindex />} />
              <Route exact path="/academy/:slug/:id" element={<Ugtsm />} />
              <Route path="*" element={<NotFound />} />
                </Routes>
          </GlobalProvider>
                
            <Footer />
          </Suspense>
        </BrowserRouter>
          </div>
        </RecoilRoot>
    </Provider>
  );
}

export default App;
