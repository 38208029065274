import React, { useState } from 'react'
import styles from './UpgradeNow.module.css'
import { Box, Button, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { monthlyPlanLink, yearlyPlanLink } from '../../../config/urls';
import { getToken, getTrail, getTrailDays } from '../CommonFunctions';
import { useNavigate } from "react-router-dom";

const UpgradeNow = ({ isOpen, setIsOpen }) => {

  // Variables
  const [selectedPlan, setSelectedPaln] = useState({
    key: "yearly",
  })
  const navigate = useNavigate()
  const days = 14
  const trailsDays = getTrailDays()

  // Functions
  const handleSelectedPlan = (plan) => {
    setSelectedPaln({...selectedPlan, ["key"]: plan})
  }

  const handleOpen = () => {
    navigate(`/payment/${selectedPlan?.key}`)
    setIsOpen(false);
    // let _key = selectedPlan?.key === "monthly" ? monthlyPlanLink : yearlyPlanLink
    // window.open(_key);
  }

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className={styles.definationWrapper}>
        <div className={styles.row} >

          <div className={styles.header} >
            <div className={styles.heading} >Upgrade Your Account Now</div>
            <Button className={styles.cancelBtn} onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </Button>
          </div>
          <div className={styles.saprator} ></div>
          <div className={styles.bodyContent} >
            <div className={styles.head} >{!getTrail() ? "Your Trial Is Expired Subscribe Now" :`${!getToken() ?  days : days - trailsDays} Days Left in Your Free Trial`}</div>
            <div className={styles.exprience} >
              Experience the success alongside thousands of thrilled customers worldwide with Amassing Investment. Elevate to a premium plan to unlock...
              <div>🔓 Barrier-Free Site Access, No Interruptions 🚫</div>
              <div>👩‍💼 Premium 24/7 Customer Support at Your Service</div>
            </div>
            <div className={styles.actionWrapper} >
            <button onClick={handleOpen} className={styles.purchaseBtn} >Finalize Your Purchase - Tap Here! ✅</button>
            <p className={styles.planTitle} >Select "Monthly" or "Yearly"</p>
            <div className={styles.btnWrp} >
              <div onClick={() => handleSelectedPlan("monthly")} className={`${selectedPlan?.key === "monthly" ? styles.active : ""}  ${styles.monthlyBtn}`}>MONTHLY</div>
              <div onClick={() => handleSelectedPlan("yearly")} className={`${selectedPlan?.key === "yearly" ? styles.active : ""} ${styles.yearlyBtn}`}>YEARLY</div>
              </div>
              <div className={styles.priceWrp} >
                <div className={styles.price} >{selectedPlan?.key === "monthly" ?  "$34.99" : "$24.99"}</div>
                <div className={styles.currency} >USD</div>
                <span className={styles.month} >/Month</span>
              </div>
              <div className={styles.total} >{selectedPlan?.key === "monthly" ? "$419.88" : "$299.88"} USD per year</div>
              </div>
            <div className={styles.noteHead} >Founder's Reflection:</div>
            <div className={styles.note} > 
              As we ventured into the world of investing, we found a glaring absence - no tool offered the complete package or presented data in a simple, digestible manner. Fueled by this observation, we crafted Amassing Investment. Our aim? To make investing accessible to all. By subscribing, you not only back our endeavor but propel our mission forward. Thank you for being part of our journey.
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default UpgradeNow