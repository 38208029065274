const InvexRoutes = {
  Home: { path: "/" },
  Symbol: {
    path: "/symbol/:symbol",
    path1: "/symbol/:symbol/:tab",
    path2: "/symbol/:symbol/:tab/:subId",
    path3: "/symbol/:symbol/RELATIVE_VALUATION",
  },
  OverpricedStocks:{ path:"/market-movers/todays-most-overpriced-stocks" },
  UnderPricedStocks:{ path:"/market-movers/todays-most-underpriced-stocks" },
  OverValuedStocks:{ path:"/market-movers/todays-most-overvalued-stocks" },
  UnderValuedStocks: { path: "/market-movers/todays-most-undervalued-stocks" },
  ActiveStocks:{ path: "/market-movers/todays-most-active-stocks" },
  MarketGainer:{ path: "/market-movers/todays-top-stock-gainers" },
  MarketLosers: { path: "/market-movers/todays-top-stock-losers" },
  MarketiIndices: { path:"/market-indices" },
  EconomicCalendar: { path:"/economic-calendar" },
  EarningsCalendar: { path:"/earnings-calendar" },
  SocialSentiment: { path:"/social-sentiment" },
  // Divident: { path: "/symbol/:symbol/dividends" },
  // Earnings: { path: "/symbol/:symbol/earnings" },
  // Fulletflist: { path: "/symbol/:symbol/etf" },
  Topholding: { path: "/symbol/topholding/:symbol" },
  Companynews: { path: "/symbol/companynews/:symbol" },
  SymbolNews: { path: "/symbolnews/:symbol" },
  Options: { path: "/options" },
  Screener: { path: "/stock-screener" },
  Sectors: { path: "/sectors" },
  MacroEconomics: { path: "/macroeconomics" },
  Market: { path: "/market-movers/todays-most-active-stocks" },
  News: { path: "/news" },
  Resources: { path: "/blog" },
  Dashboard: { path: "/dashboard" },
  Login: { path: "/login" },
  ForgotPassword: { path: "/forgot-password" },
  SetPassword: { path: "/set-password/:token" },
  Register: { path: "/register" },
  Profile: { path: "/profile" },
  Subscription: { path: "/subscription" },
  SymbolNotPublished: { path: "/not-published" },
  RefundPolicy: { path: "/refund-policy" },
  CookiePolicy: { path: "/cookie-policy" },
  ContactUs: { path: "/contact-us" },
  PrivacyPolicy: { path: "/privacy-policy" },
  PrivacyPolicyEu: { path: "/privacy-notice-for-eu-residents" },
  TermsAndConditionPolicy: { path: "/terms-and-condition" },
  CopyRightPolicy: { path: "/copyright-policy" },
  WebsiteTermsOfUse: { path: "/website-terms-of-use" },
  LegalDesclaimers: { path: "/legal-disclaimers" },
  Academy: { path: "/academy" },
  Payment: { path: "/payment/:plan" },

  // Portfolio: { path: "/portfolio" },
  WatchList: { path: "/watchList/:name" },
  blog: { path: "/blog" },
  blogCategories: { path: "/blog-categories/:id" },
  blogDetails: { path: "/blog/:id" },
  checklistPortfolio: { path: "/my-checklist" },
  dcfCalculator: { path: "/dcf-calculator" },
  CardDetails: { path: "/card-detail" },
  watchlistPortfolio: { path: "/my-portfolio", path1: "/my-portfolio/:id", publishedpath: "/portfolios/:id" },
};

export default InvexRoutes;
