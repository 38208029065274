import React,{useState} from 'react'
import { AtomSelectedTab } from '../../../../Atoms/MarketAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styles from './market.module.css'
import MarketInfo from '../MarketInfo/MarketInfo';
import { Grid, Box } from "@material-ui/core";
import image from '../../../Common/Images/4632221.png'
import { MARKET_TABS } from '../../MacroEconomics/Constants';
import InvexRoutes from '../../../../InvexRoutes';
import { useNavigate } from 'react-router-dom';
import { getPrimeUser } from '../../../Common/CommonFunctions';

const MarketTab = () => {
  // Variables
    const [activeTabIndex, setActiveIndex] = useRecoilState(AtomSelectedTab)
  const [isOpen, setIsOpen] = useState(false);
   const [selectedKey, setSelectedKey] = useState({
    header: "",
    key: "",
   });
  const navigate = useNavigate();
  
    const handleInfo = (key) => {
      setIsOpen(true);
    setSelectedKey({
      header: key?.label,
      key: key?.key
    })
  }

  


  const handleRoute = (value) => {
    setActiveIndex(value)
    let path = ""
     switch (value) {
       case "market-movers":
       path = InvexRoutes.ActiveStocks.path   
         break;
      case "market-indicas":
         path = InvexRoutes.MarketiIndices.path   
         break;
      case "economic-calendar":
      path = InvexRoutes.EconomicCalendar.path   
      break;
      case "earning-calendar":
      path = InvexRoutes.EarningsCalendar.path   
      break;
      case "social-sentiment":
      path = InvexRoutes.SocialSentiment.path   
      break;
      default:
        break;
    }
    navigate(path)
  }

    const renderTabView = () => {
    return <>
      <Grid item xs={12} sm={12} md={12} lg={12} className="mb-10">
        <Box sx={{ border: "1px solid #e3e3e3", borderRadius: "10px" }}>
          <div className={`${styles.tabWrapper} top_button_panel v2 mt-4 mb-3`}>
            {MARKET_TABS?.map(
              (key) => {
                return (
                  <>
                    <button
                      key={key.value}
                      type="button"
                      className={`btn ${styles.tabBtn} ${activeTabIndex === key?.value ? styles.activeBtn : styles.deActiveBtn}`}
                      onClick={() => handleRoute(key?.value)}
                    >
                      {key?.label}
                    </button>
                      <button
                                className={`${styles.iconBtn} ${activeTabIndex === key?.value ? styles.activeIconBtn : ""}`}
                                onClick={() => handleInfo(key)}
                              >
                                <div className={styles.iconWrapper}>
                                  <img src={image} />
                                </div>
                              </button>
                  </>
                );
              }
            )}
          </div>
        </Box>
      </Grid>
    </>
  }
   const renderNote = () => {
    return <div className={styles.noteWrp} ><strong>Note: </strong> For all the DCF Valuation and Relative Valuation columns <span className={styles.greenBX} ></span> green represents Under-valued/priced and <span className={styles.redBX} ></span> red represents Over-valued/priced.</div>
  }
  return (
    <div className='mb-2' >
      {renderTabView()}
      {renderNote()}
      {isOpen && <MarketInfo selectedKey={selectedKey} setIsOpen={setIsOpen} isOpen={isOpen} />}
    </div>
  )
}

export default MarketTab
