import { Helmet } from "react-helmet";
import { isPrimeAndOpn } from "../component/Common/CommonFunctions";

export const TechSEOPageOnTitle = (title) => {
  return (
    <h1
      style={{
        fontFamily: "Merriweather",
        fontWeight: "bold",
        fontSize: "32px",
      }}
    >
      {title}
    </h1>
  );
};
export const TechSEO = {
  HOME: {
    pageHeadTitle: `AMASSINGINVESTMENT.com: Stock Valuation, Screener, Research Tools & News`,
    pageHeadDescription: `Unlock smart investing opportunities with powerful stock valuation tools like Relative and DCF Valuation, advanced screener, and comprehensive stock research.`,
    pageTitle: TechSEOPageOnTitle(``),
  },
  CONTACTUS: {
    pageHeadTitle: `Amassing Investment - Contact Us`,
    pageHeadDescription: `Amassing Investment - Contact Us`,
    pageTitle: TechSEOPageOnTitle(``),
  },
  COPYRIGHTPOLICY: {
    pageHeadTitle: `Amassing Investment - CopyRight Policy`,
    pageHeadDescription: `Amassing Investment - CopyRight Policy`,
    pageTitle: TechSEOPageOnTitle(``),
  },
  LEGALDISCLAIMER: {
    pageHeadTitle: `Amassing Investment - Legal Disclaimer`,
    pageHeadDescription: `Amassing Investment - Legal Disclaimer`,
    pageTitle: TechSEOPageOnTitle(``),
  },
  PRIVACYPOLICY: {
    pageHeadTitle: `Amassing Investment - Privacy Policy`,
    pageHeadDescription: `Amassing Investment - Privacy Policy`,
    pageTitle: TechSEOPageOnTitle(``),
  },
  PRIVACYNOTICE: {
    pageHeadTitle: `Amassing Investment - Privacy Notice For EU Residents`,
    pageHeadDescription: `Amassing Investment - Privacy Policy`,
    pageTitle: TechSEOPageOnTitle(``),
  },
  WEBSITETERMSUSE: {
    pageHeadTitle: `Amassing Investment - Website Terms of Use`,
    pageHeadDescription: `Amassing Investment - Website Terms of Use`,
    pageTitle: TechSEOPageOnTitle(``),
  },
  SECTOR_PAGE: (compayName, symbol) => {
    return {
      pageHeadTitle: `${compayName}`,
      pageHeadDescription: `${compayName}: Identify Top Stocks, Leverage Real-Time Insights, and Market Trends to Boost ROI`,
      pageTitle: TechSEOPageOnTitle(`${compayName} Stocks: Industry Analysis, Forecast, and Investment Insight`),
    };
  },
  MARKET: {
    ACTIVE_STOCKS: {
      pageHeadTitle: `Today's Most Active Stocks - AmassingInvestment.com`,
      pageHeadDescription: `Discover Today's Most Active Stocks with High Volume. Stay updated, identify trends, real-time insights, news and boost ROI with informed decisions.`,
      pageTitle: TechSEOPageOnTitle(`Today's Most Active Stocks`),
    },
    MOST_GAINERS: {
      pageHeadTitle: `Today's Top Stock Winners - AmassingInvestment.com`,
      pageHeadDescription: `Discover Today's Top Stock Gainers: Analyze price increases, track performance, leverage real-time insights, news and market trends to boost ROI.`,
      pageTitle: TechSEOPageOnTitle(`Today's Top Stock Gainers`),
    },
    MOST_LOSERS: {
      pageHeadTitle: `Today's Top Stock Losers - AmassingInvestment.com`,
      pageHeadDescription: `Discover Today's Top Stock Losers: Analyze price declines, track performance, leverage real-time insights, news and market trends to boost ROI.`,
      pageTitle: TechSEOPageOnTitle(`Today's Top Stock Losers`),
    },
    TOP_UNDER_VALUED: {
      pageHeadTitle: `Today's Top Undervalued Stocks - AmassingInvestment.com`,
      pageHeadDescription: `Discover Today's Most Undervalued Stocks. Stay updated, identify trends, real-time insights, news and boost ROI with informed decisions.`,
      pageTitle: TechSEOPageOnTitle(`Today's Top Undervalued Stocks`),
    },
    TOP_OVER_VALUED: {
      pageHeadTitle: `Today's Top Overvalued Stocks - AmassingInvestment.com`,
      pageHeadDescription: `Discover Today's Most Overvalued Stocks. Stay updated, identify trends, real-time insights, news and boost ROI with informed decisions.`,
      pageTitle: TechSEOPageOnTitle(`Today's Top Overvalued Stocks`),
    },
  },
  SCREENER: {
    pageHeadTitle: `Smart Simple Stock Screener - Discover Undervalued Stocks`,
    pageHeadDescription: `Smart Simple Stock Screener: Discover Top Growth Stocks, Uncover Opportunities, and Simplify Trades with Our Powerful Screener for Investors and Traders.`,
    pageTitle: TechSEOPageOnTitle(`Easy Powerful Smart Simple Stock Screener: Discover Undervalued Stocks`),
  },
  MYWATCHLIST: (portFolioname) => ({
    pageHeadTitle: `My Portfolio - Track and Analyze Your Investments | Amassing Investment`,
    pageHeadDescription: `Discover the power of informed investment decisions with My Portfolio at Amassing Investment. Track stock performance, analyze PnL, and manage your investment portfolio effectively. Designed for savvy investors and institutions focused on value investing.`,
    pageTitle: TechSEOPageOnTitle(`Optimize Your Investment Strategy with My Portfolio`),
    keywords: "my portfolio, investment tracking, stock analysis, financial portfolio management, value investing, Amassing Investment, profit and loss analysis, investment strategy, portfolio analytics, stock market monitoring, personal investment tracker, financial insights"
  }),
  DCFCalulator: {
    pageHeadTitle: `DCF Calculator - Discounted Cash Flow Tool | Amassing Investment`,
    pageHeadDescription: `Unlock the true value of stocks with our DCF Calculator at Amassing Investment. Simplify your equity research and make informed decisions as a retail investor. Discover the potential of your investments today!`,
    pageTitle: TechSEOPageOnTitle(`Amassing DCF Calculator - Cash Flow Analysis Engine`),
  },
  NEWS: {
    STOCK_MARKET: {
      pageHeadTitle: `Stock Market News - AmassingInvestment.com`,
      pageHeadDescription: `Stay updated with the latest stock market news, breaking updates, insights, and analysis. Make informed investment decisions with our comprehensive coverage.`,
      pageTitle: TechSEOPageOnTitle(`Stock Market News: Breaking Updates, Insights, and Expert Analysis`),
    },
    GENERAL: {
      pageHeadTitle: `General Macro Economic News - AmassingInvestment.com`,
      pageHeadDescription: `Stay informed with latest macro economic news, key updates, and insightful analysis. Gain valuable insights into economic trends.`,
      pageTitle: TechSEOPageOnTitle(`General Macro Economic News: Economy and Major News`),
    },
  },
  OPTIONS: {
    MARKET: {
      MOST_ACTIVE: {
        pageHeadTitle: "Most Active Options | Amassing Investment Options Market",
        pageTitle: TechSEOPageOnTitle("Today's Most Active Options in the Market"),
        pageHeadDescription: "Browse the most active options today. Stay on top of the options market with up-to-date data from Amassing Investment.",
      },
      HIGHEST_IV: {
        pageHeadTitle: "Highest Implied Volatility Options | Amassing Investment Options Market",
        pageTitle: TechSEOPageOnTitle("Options with the Highest Implied Volatility"),
        pageHeadDescription:
          "Discover options with the highest implied volatility. Gain valuable insights into the options market with Amassing Investment.",
      },
      EXPLODING_IV: {
        pageHeadTitle: "Exploding Implied Volatility Options | Amassing Investment Options Market",
        pageTitle: TechSEOPageOnTitle("Options with Exploding Implied Volatility"),
        pageHeadDescription: "Identify options with rapidly increasing implied volatility. Stay ahead of the market trends with Amassing Investment.",
      },
      IMPLODING_IV: {
        pageHeadTitle: "Imploding Implied Volatility Options | Amassing Investment Options Market",
        pageTitle: TechSEOPageOnTitle("Options with Imploding Implied Volatility"),
        pageHeadDescription: "Analyze options with quickly decreasing implied volatility. Take advantage of market trends with Amassing Investment.",
      },
      VOLUME_GAINERS: {
        pageHeadTitle: "Option Volume Gainers | Amassing Investment Options Market",
        pageTitle: TechSEOPageOnTitle("Top Gainers in Option Volume"),
        pageHeadDescription: "Find out which options are gaining in volume. Enhance your market strategy with data from Amassing Investment.",
      },
      VOLUME_LOSERS: {
        pageHeadTitle: "Option Volume Losers | Amassing Investment Options Market",
        pageTitle: TechSEOPageOnTitle("Top Losers in Option Volume"),
        pageHeadDescription: "Explore options experiencing a decrease in volume. Adjust your market strategy with insights from Amassing Investment.",
      },
      INTEREST_GAINERS: {
        pageHeadTitle: "Option Open Interest Gainers | Amassing Investment Options Market",
        pageTitle: TechSEOPageOnTitle("Leading Gainers in Option Open Interest"),
        pageHeadDescription: "Check out options with increasing open interest. Keep up with the latest market movements with Amassing Investment.",
      },
      INTEREST_LOSERS: {
        pageHeadTitle: "Option Open Interest Losers | Amassing Investment Options Market",
        pageTitle: TechSEOPageOnTitle("Leading Losers in Option Open Interest"),
        pageHeadDescription: "Monitor options with decreasing open interest. Stay informed about the market with insights from Amassing Investment.",
      },
    },
    TRADING: {
      pageHeadTitle: "Trading Ideas for Options | Amassing Investment Options Market",
      pageTitle: TechSEOPageOnTitle("Innovative Trading Ideas for Options"),
      pageHeadDescription:
        "Seeking inspiration for your next move in the options market? Explore diverse trading ideas at Amassing Investment and find new ways to maximize your returns.",
    },
  },
  MACRO_ECONOMICS: {
    COMMODITIES: (companyName) => {
      return {
        pageHeadTitle: `${companyName} Commodities | Amassing Investment Macroeconomics`,
        pageTitle: TechSEOPageOnTitle(`${companyName}: A Key Commodity in Macroeconomics`),
        pageHeadDescription: `Dive into the world of ${companyName.toLowerCase()} commodities and their impact on macroeconomics. Stay ahead with the latest insights and analysis on ${companyName.toLowerCase()} at Amassing Investment.`,
      };
    },
    ECONOMIC_DATA: (companyName) => {
      return {
        pageHeadTitle: `${companyName} Economic Data | Amassing Investment Macroeconomics`,
        pageTitle: TechSEOPageOnTitle(`Comprehensive ${companyName} Economic Data Analysis`),
        pageHeadDescription: `Discover detailed analysis of ${companyName} economic data and its implications on global markets. Stay informed and make strategic investment decisions with insights from Amassing Investment.`,
      };
    },
  },
  SYMBOL: {
    STOCK: {
      SYNOPSIS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${companyName} (${symbol}) Stock Overview - AmassingInvestment.com`,
          pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Summary, Price & News`),
          pageHeadDescription: `Get comprehensive analysis of ${companyName} stock (${symbol}) with stock summary, chart, ratios, earnings, dividends, top competitors, and company news.`,
        };
      },
      DCF_VALUATION: (companyName, symbol) => {
        return {
          pageHeadTitle: `${companyName} (${symbol}) Stock DCF Valuation - AmassingInvestment.com`,
          pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock DCF Valuation`),
          pageHeadDescription: `Predict Long-Term Buy Sell Hold Smart Investing with DCF Valuation Models of ${companyName} (${symbol}) stock. Explore DCF Analysis, Reports and Appraisal Insights`,
        };
      },
      RELATIVE_VALUATION: (companyName, symbol) => {
        return {
          pageHeadTitle: `${companyName} (${symbol}) Stock Relative Valuation Analysis - AmassingInvestment.com`,
          pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Relative Valuation Analysis `),
          pageHeadDescription: `Discover Smart Investing Opportunities with Relative Valuation of ${companyName} (${symbol}) stock. Informed decisions using valuation metrics and comparison analysis`,
        };
      },
      COMPARATIVE: {
        CAPITAL_STRUCTURE: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Stock Comparative Analysis - Capital Structure`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Comparative Analysis - Capital Structure `),
            pageHeadDescription: `Smart Investing Comparative Analysis of ${companyName} (${symbol}) with a focus on capital structure. Gain insights into the financial composition for informed decisions.`,
          };
        },
        PRICE_MULTIPLE: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Stock Comparative Analysis - Price Multiples`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Comparative Analysis - Price Multiples `),
            pageHeadDescription: `Smart Investing Comparative Analysis of ${companyName} (${symbol}) with a focus on price multiples. Gain insights into the financial composition for informed decisions.`,
          };
        },
        EFFICIENCY_RATIOS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Stock Comparative Analysis - Efficiency Ratios`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Comparative Analysis - Efficiency Ratios `),
            pageHeadDescription: `Smart Investing Comparative Analysis of ${companyName} (${symbol}) with a focus on efficiency ratios. Gain insights into the financial composition for informed decisions.`,
          };
        },
        RETURNS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Stock Comparative Analysis - Returns`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Comparative Analysis - Returns `),
            pageHeadDescription: `Smart Investing Comparative Analysis of ${companyName} (${symbol}) with a focus on returns. Gain insights into the financial composition for informed decisions.`,
          };
        },
        MARGINS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Stock Comparative Analysis - Margins`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Comparative Analysis - Margins `),
            pageHeadDescription: `Smart Investing Comparative Analysis of ${companyName} (${symbol}) with a focus on margins. Gain insights into the financial composition for informed decisions.`,
          };
        },
        LEVERAGE_RATIOS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Stock Comparative Analysis - Leverage Ratios`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Comparative Analysis - Leverage Ratios `),
            pageHeadDescription: `Smart Investing Comparative Analysis of ${companyName} (${symbol}) with a focus on leverage ratios. Gain insights into the financial composition for informed decisions.`,
          };
        },
        LIQUIDITY_RATIOS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Stock Comparative Analysis - Liquidity Ratios`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Comparative Analysis - Liquidity Ratios `),
            pageHeadDescription: `Smart Investing Comparative Analysis of ${companyName} (${symbol}) with a focus on liquidity ratios. Gain insights into the financial composition for informed decisions.`,
          };
        },
        EARNING_DIVIDENDS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Stock Comparative Analysis - Earnings Dividends`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Comparative Analysis - Earnings Dividends `),
            pageHeadDescription: `Smart Investing Comparative Analysis of ${companyName} (${symbol}) with a focus on earnings dividends. Gain insights into the financial composition for informed decisions.`,
          };
        },
        CASHFLOW_RATIOS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Stock Comparative Analysis - Cashflow Ratios`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Stock Comparative Analysis - Cashflow Ratios `),
            pageHeadDescription: `Smart Investing Comparative Analysis of ${companyName} (${symbol}) with a focus on cashflow ratios. Gain insights into the financial composition for informed decisions.`,
          };
        },
      },
      OPTION_ANALYSIS: {
        QUOTE: (companyName, symbol) => {
          return {
            pageHeadTitle: `Smart Investing Options Analysis of ${symbol}- Discover Undervalued Opportunities`,
            pageTitle: TechSEOPageOnTitle(`Smart Investing Options Analysis of ${symbol}- Discover Undervalued Opportunities`),
            pageHeadDescription: `Discover Smart Investing Opportunities with options analysis of ${companyName} (${symbol}) stock. Options valuation metrics and analysis for enhanced ROI outcomes.`,
          };
        },
        OPTION_CHAIN: (companyName, symbol) => {
          return {
            pageHeadTitle: `${symbol} Option Chain | Amassing Investment Symbol Data`,
            pageTitle: TechSEOPageOnTitle(`${symbol} Option Chain | Amassing Investment Symbol Data`),
            pageHeadDescription: `Dive into ${symbol}'s comprehensive option chain for an in-depth analysis. Make informed decisions on your options trading with Amassing Investment.`,
          };
        },
      },
      FINANCIAL_STATISTICS: {
        CAPITAL_STRUCTURE: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Financial Statistics - Capital Structure`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statistics - Capital Structure `),
            pageHeadDescription: `Explore ${companyName} (${symbol}) financial statistics with a focus on capital structure for informed decisions and enhanced ROI.`,
          };
        },
        PRICE_MULTIPLE: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Financial Statistics - Price Multiples`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statistics - Price Multiples `),
            pageHeadDescription: `Discover ${companyName} (${symbol}) financial statistics with a focus on price multiples for smart investing opportunities and improved ROI.`,
          };
        },
        EFFICIENCY_RATIOS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Financial Statistics - Efficiency Ratios`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statistics - Efficiency Ratios `),
            pageHeadDescription: `Gain insights into ${companyName} (${symbol}) financial statistics with a focus on efficiency ratios for informed ROI decisions.`,
          };
        },
        RETURNS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Financial Statistics - Returns`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statistics - Returns `),
            pageHeadDescription: `Gain insights into ${companyName} (${symbol}) financial statistics with a focus on Returns for informed ROI decisions.`,
          };
        },
        MARGINS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Financial Statistics - Margins`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statistics - Margins `),
            pageHeadDescription: `Explore ${companyName} (${symbol}) financial statistics with a focus on margins for smart investing opportunities and enhanced ROI.`,
          };
        },
        LEVERAGE_RATIOS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Financial Statistics - Leverage Ratios`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statistics - Leverage Ratios `),
            pageHeadDescription: `Discover ${companyName} (${symbol}) financial statistics with a focus on leverage ratios for informed decisions and improved ROI.`,
          };
        },
        LIQUIDITY_RATIOS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Financial Statistics - Liquidity Ratios`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statistics - Liquidity Ratios `),
            pageHeadDescription: `Gain insights into ${companyName} (${symbol}) financial statistics with a focus on liquidity ratios for informed ROI decisions and enhanced returns.`,
          };
        },
        EARNING_DIVIDENDS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Financial Statistics - Earnings Dividends`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statistics - Earnings Dividends `),
            pageHeadDescription: `Explore ${companyName} (${symbol}) financial statistics with a focus on earnings and dividends for informed decisions and improved ROI.`,
          };
        },
        CASHFLOW_RATIOS: (companyName, symbol) => {
          return {
            pageHeadTitle: `${companyName} (${symbol}) Financial Statistics - Cashflow Ratios`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statistics - Cashflow Ratios `),
            pageHeadDescription: `Discover ${companyName} (${symbol}) financial statistics with a focus on cash flow ratios for informed opportunities and enhanced ROI.`,
          };
        },
      },
      FINANCIAL_STATEMENT: {
        BALANCE_SHEET: (companyName, symbol) => {
          return {
            pageHeadTitle: `Balance Sheet`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statements - Balance Sheet `),
            pageHeadDescription: `Gain insights into ${companyName} (${symbol}) balance sheet for a detailed understanding of their assets, liabilities, and equity. Make informed decisions based on financial health.`,
          };
        },
        INCOME_STATEMENT: (companyName, symbol) => {
          return {
            pageHeadTitle: `Income Statement`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statements - Income Statement `),
            pageHeadDescription: `Discover ${companyName} (${symbol}) income statement for a breakdown of their revenue, expenses, and profitability. Gain insights for informed investment decisions.`,
          };
        },
        CASHFLOW_STATEMENT: (companyName, symbol) => {
          return {
            pageHeadTitle: `Cash Flow Statement`,
            pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Financial Statements - Cash Flow Statement `),
            pageHeadDescription: `Explore ${companyName} (${symbol}) cash flow statement for a comprehensive understanding of their cash flow analysis: Inflows, outflows, and liquidity. `,
          };
        },
      },
      TECHNICAL_ANALYSIS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${companyName} (${symbol}) Technical Analysis - Charts, Indicators, and Patterns`,
          pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Technical Analysis - Charts, Indicators, and Patterns `),
          pageHeadDescription: `${companyName} (${symbol}) stock through technical analysis, featuring charts, indicators, and patterns. Historical price movements and market trends for ROI decisions`,
        };
      },
      SEC: (companyName, symbol) => {
        return {
          pageHeadTitle: `${companyName} (${symbol}) SEC Filings - Reports, Forms, and Disclosures`,
          pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) SEC Filings - Reports, Forms, and Disclosures `),
          pageHeadDescription: `Access ${companyName} (${symbol}) SEC filings for comprehensive insights, reports, forms, and disclosures for informed decisions`,
        };
      },
      CHART: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} Price Chart | Amassing Investment Symbol Data`,
          pageTitle: TechSEOPageOnTitle(`Detailed Price Chart for ${symbol}`),
          pageHeadDescription: `Track real-time price movements of ${symbol} with our comprehensive chart. Make informed investment decisions with reliable data from Amassing Investment.`,
        };
      },
      EARNINGS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} Earnings | Amassing Investment Symbol Data`,
          pageTitle: TechSEOPageOnTitle(`Detailed Earnings Data for ${symbol}`),
          pageHeadDescription: `Get up-to-date and in-depth ${symbol} earnings data. Make informed investment decisions with comprehensive insights and analysis from Amassing Investment.`,
        };
      },
      DIVIDENDS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} Dividends | Amassing Investment Symbol Data`,
          pageTitle: TechSEOPageOnTitle(`Comprehensive Dividend Information for ${symbol}`),
          pageHeadDescription: `Explore detailed dividend data for ${symbol}. Stay ahead with dividend yield, history, payout ratio, and more from Amassing Investment.`,
        };
      },
      ETF: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} ETFs | Amassing Investment Symbol Data`,
          pageTitle: TechSEOPageOnTitle(`ETFs with Significant ${symbol} Exposure `),
          pageHeadDescription: `Discover ETFs with significant exposure to ${symbol}. Get detailed insights on how ${symbol} impacts your ETF investments, with comprehensive data from Amassing Investment.`,
        };
      },
      NEWS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} News | Amassing Investment Symbol Data`,
          pageTitle: TechSEOPageOnTitle(`Latest News for ${symbol} `),
          pageHeadDescription: `Stay updated with the latest news and developments for ${symbol}. Make informed investment decisions with the most recent updates and analysis from Amassing Investment.`,
        };
      },
    },
    ETF: {
      SYNOPSIS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${companyName} (${symbol}) ETF Overview - AmassingInvestment.com`,
          pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) ETF Summary, Price & News `),
          pageHeadDescription: `Get comprehensive analysis of ${companyName} (${symbol}) with ETF summary, chart, ETF country weightings,ETF sector weightings, top holdings, dividends, and company news. `,
        };
      },
      OPTION_ANALYSIS: {
        QUOTE: (companyName, symbol) => {
          return {
            pageHeadTitle: `Smart Investing Options Analysis of ${symbol} ETF - Discover Undervalued Opportunities`,
            pageTitle: TechSEOPageOnTitle(`Smart Investing Options Analysis of ${symbol} ETF - Discover Undervalued Opportunities`),
            pageHeadDescription: `Discover Smart Investing Opportunities with options analysis of ${companyName} (${symbol}) etf. Options valuation metrics and analysis for enhanced ROI outcomes.`,
          };
        },
        OPTION_CHAIN: (companyName, symbol) => {
          return {
            pageHeadTitle: `${symbol} Option Chain | Amassing Investment ETF Data`,
            pageTitle: TechSEOPageOnTitle(`${symbol} Option Chain | Amassing Investment ETF Data`),
            pageHeadDescription: `Dive into ${symbol}'s comprehensive option chain for an in-depth analysis. Make informed decisions on your options trading with Amassing Investment.`,
          };
        },
      },
      TECHNICAL_ANALYSIS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${companyName} (${symbol}) Technical Analysis - Charts, Indicators, and Patterns`,
          pageTitle: TechSEOPageOnTitle(`${companyName} (${symbol}) Technical Analysis - Charts, Indicators, and Patterns `),
          pageHeadDescription: `${companyName} (${symbol}) etf through technical analysis, featuring charts, indicators, and patterns. Historical price movements and market trends for ROI decisions`,
        };
      },
      CHART: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} Price Chart | Amassing Investment ETF Data`,
          pageTitle: TechSEOPageOnTitle(`Detailed Price Chart for ${symbol}`),
          pageHeadDescription: `Track real-time price movements of ${symbol} with our comprehensive chart. Make informed investment decisions with reliable data from Amassing Investment.`,
        };
      },
      WEIGHTINGS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} ETF Country Weightings | Amassing Investment ETF Data`,
          pageTitle: TechSEOPageOnTitle(`Country Weightings in ${symbol}  ETF`),
          pageHeadDescription: `Explore the detailed country weightings in the ${symbol} ETF. Make well-informed ETF investment decisions with comprehensive data from Amassing Investment.`,
        };
      },
      ETF_HOLDING: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} ETF Top Holdings | Amassing Investment ETF Data`,
          pageTitle: TechSEOPageOnTitle(`Top Holdings in ${symbol} ETF`),
          pageHeadDescription: `Gain insight into the top holdings in the ${symbol} ETF. Enhance your ETF investment decisions with detailed holding information from Amassing Investment.`,
        };
      },
      DIVIDENDS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} ETF Dividends | Amassing Investment ETF Data`,
          pageTitle: TechSEOPageOnTitle(`Dividend Information for ${symbol} ETF`),
          pageHeadDescription: `Explore comprehensive dividend data for the ${symbol} ETF. Stay ahead with detailed information about dividend yield, history, payout ratio, and more from Amassing Investment.`,
        };
      },
      ALTERNATIVES: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} ETFs | Amassing Investment Symbol Data`,
          pageTitle: TechSEOPageOnTitle(`ETFs with Significant ${symbol} Exposure`),
          pageHeadDescription: `Discover ETFs with significant exposure to ${symbol}. Get detailed insights on how ${symbol} impacts your ETF investments, with comprehensive data from Amassing Investment.`,
        };
      },
      NEWS: (companyName, symbol) => {
        return {
          pageHeadTitle: `${symbol} ETF News | Amassing Investment ETF Data`,
          pageTitle: TechSEOPageOnTitle(`Latest News for ${symbol} ETF`),
          pageHeadDescription: `Stay updated with the latest news impacting the ${symbol} ETF. Follow market trends, updates, and analysis to help guide your investment decisions, brought to you by Amassing Investment.`,
        };
      },
    },
  },
  RESOURCES: {
    UGTSM: {
      INDEX: {
        pageHeadTitle: `Your Comprehensive Guide to the Stock Market with Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Ultimate Guide to Stock Market - Amassing Investment Academy`),
        pageHeadDescription: `Dive into Amassing Investment's ultimate guide to understand the intricacies of the stock market. Whether you're a newbie or seasoned investor, gain insights and strategies to maximize your returns.`,
      },
      INTRO_INVESTMENT: {
        pageHeadTitle: `Introduction to Investment: Starting Your Stock Market Journey | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Introduction to Investment: Starting Your Stock Market Journey | Amassing Investment Academy`),
        pageHeadDescription: `New to the world of stock market investing? Amassing Investment offers a comprehensive introduction to investment principles, strategies, and the first steps towards growing your wealth.`,
      },
      INVESTMENT_PSYCOLOGY: {
        pageHeadTitle: `Psychology Behind Investing - Deep Dive into Behavioral Finance | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Psychology Behind Investing - Deep Dive into Behavioral Finance | Amassing Investment Academy`),
        pageHeadDescription: `Unlock the secrets of behavioral finance with Amassing Investment. Explore how emotions and cognitive biases can influence investment decisions and learn strategies to stay objective for better financial outcomes.`,
      },
      COMPOUNDING_RETURN: {
        pageHeadTitle: `Compounding Returns Explained - The Eighth Wonder of the Investing World | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Compounding Returns Explained - The Eighth Wonder of the Investing World | Amassing Investment Academy`),
        pageHeadDescription: `Discover the power of compounding returns with Amassing Investment. Unveil how reinvesting earnings can exponentially grow your wealth over time and the strategies to make the most of it.`,
      },
      FINANCIAL_STATEMENT: {
        pageHeadTitle: `Understanding Financial Statements: A Deep Dive for Investors | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Understanding Financial Statements: A Deep Dive for Investors | Amassing Investment Academy`),
        pageHeadDescription: `Navigate the complexities of company financial statements with Amassing Investment. Learn to analyze balance sheets, income statements, and cash flow to make informed investment decisions.`,
      },
      VALUATIN101: {
        pageHeadTitle: `Valuation 101: Basics of Stock Market Valuation | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Valuation 101: Basics of Stock Market Valuation | Amassing Investment Academy`),
        pageHeadDescription: `Begin your journey into stock market valuation with Amassing Investment's Valuation 101. Grasp core valuation techniques and principles to accurately assess a stock's intrinsic value and make smarter investment decisions.`,
      },
      OPTINS_DERIVATIVES: {
        pageHeadTitle: `Options and Derivatives: A Comprehensive Introduction | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Options and Derivatives: A Comprehensive Introduction | Amassing Investment Academy`),
        pageHeadDescription: `Unlock the potential of options and derivatives with Amassing Investment. Delve into the mechanics, strategies, and risk management techniques of these financial instruments to elevate your stock market portfolio.`,
      },
    },
    ISB: {
      INDEX: {
        pageHeadTitle: `Mastering Financial Statements: Your Complete Guide to Financial Reporting and Analysis`,
        pageTitle: TechSEOPageOnTitle(
          `The Ultimate Guide to Financial Statements: Decoding Balance Sheets, Income, & Cash Flow | Amassing Investment Academy`
        ),
        pageHeadDescription: `Dive deep into the world of financial statements with Amassing Investment. From understanding balance sheets to income statements and cash flow analysis, equip yourself with the knowledge to make informed financial decisions.`,
      },
      INCOME_STATEMENT: {
        pageHeadTitle: `Income Statement Explained: Unpacking Revenues, Expenses & Profit | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Income Statement Explained: Unpacking Revenues, Expenses & Profit | Amassing Investment Academy`),
        pageHeadDescription: `Deep dive into the Income Statement with Amassing Investment. Understand the intricacies of revenues, expenses, and net profit to gauge a company's financial health and make informed decisions.`,
      },
      BALANCE_SHEET: {
        pageHeadTitle: `Balance Sheet Demystified: Assets, Liabilities & Equity Insights | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Balance Sheet Demystified: Assets, Liabilities & Equity Insights | Amassing Investment Academy`),
        pageHeadDescription: `Unlock the secrets of the Balance Sheet with Amassing Investment. Dive into the intricacies of assets, liabilities, and equity to comprehensively assess a company's financial stability and growth potential.`,
      },
      CASH_FLOW: {
        pageHeadTitle: `Cash Flow Statement Unveiled: Understanding Inflows, Outflows & Financial Liquidity | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(
          `Cash Flow Statement Unveiled: Understanding Inflows, Outflows & Financial Liquidity | Amassing Investment Academy`
        ),
        pageHeadDescription: `Delve into the Cash Flow Statement with Amassing Investment. Explore the dynamics of cash inflows, outflows, and the essential role they play in assessing a company's financial liquidity and future prospects.`,
      },
    },
    UGTFS: {
      INDEX: {
        pageHeadTitle: `Introduction to Financial Statistics: Laying the Foundation | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Starting with the Basics: An Introductory Guide to Financial Statistics`),
        pageHeadDescription: `Begin your journey into the world of financial statistics with Amassing Investment. Uncover the foundational principles and understand how data shapes the financial landscape, guiding decisions and predictions.`,
      },
      INTRO_TO_FIN_STAT: {
        pageHeadTitle: `Introduction to Financial Statistics: Laying the Foundation | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Introduction to Financial Statistics: Laying the Foundation | Amassing Investment Academy`),
        pageHeadDescription: `Begin your journey into the world of financial statistics with Amassing Investment. Uncover the foundational principles and understand how data shapes the financial landscape, guiding decisions and predictions.`,
      },
      CAP_STRUCTURE: {
        pageHeadTitle: `Capital Structure in Financial Statistics: Equity, Debt & Optimization | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Capital Structure in Financial Statistics: Equity, Debt & Optimization | Amassing Investment Academy`),
        pageHeadDescription: `Explore the intricacies of capital structure with Amassing Investment. Dive deep into how companies balance equity and debt, and understand the statistical tools and methods used to optimize financial decisions.`,
      },
      PRICE_MULTIPLE: {
        pageHeadTitle: `Price Multiples in Financial Statistics: Valuation Tools Demystified | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Price Multiples in Financial Statistics: Valuation Tools Demystified | Amassing Investment Academy`),
        pageHeadDescription: `Delve into the world of price multiples with Amassing Investment. Discover how these pivotal valuation tools, like P/E and P/B ratios, can offer insights into a company's financial health and investment potential.`,
      },
      EFFICIENCY_RATIOS: {
        pageHeadTitle: `Efficiency Ratios in Financial Statistics: Gauging Operational Excellence | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Efficiency Ratios in Financial Statistics: Gauging Operational Excellence | Amassing Investment Academy`),
        pageHeadDescription: `Step into the realm of efficiency ratios with Amassing Investment. Learn how these critical metrics, such as asset turnover and inventory turnover, shed light on a company's operational prowess and financial health.`,
      },
      RETURNS: {
        pageHeadTitle: `Returns in Financial Statistics: Measuring Investment Performance | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Returns in Financial Statistics: Measuring Investment Performance | Amassing Investment Academy`),
        pageHeadDescription: `Navigate the multifaceted world of returns with Amassing Investment. Unearth how different return metrics, from ROI to ROE, play a crucial role in gauging investment success and shaping future strategies.`,
      },
      MARGINS: {
        pageHeadTitle: `Profit Margins in Financial Statistics: Decoding Profitability Metrics | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Profit Margins in Financial Statistics: Decoding Profitability Metrics | Amassing Investment Academy`),
        pageHeadDescription: `Dive into the significance of profit margins with Amassing Investment. Discover how key metrics, such as gross, operating, and net margins, play a pivotal role in assessing a company's financial health and profitability.`,
      },
      LEVERAGE_RATIOS: {
        pageHeadTitle: `Leverage Ratios in Financial Statistics: Understanding Risk & Capital Structure | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Earnings & Dividends in Financial Statistics: Profits, Payouts & Investor Returns | Amassing Investment Academy`),
        pageHeadDescription: `Uncover the role of leverage ratios in financial statistics with Amassing Investment. Learn how these metrics, such as the debt-to-equity ratio, help assess a company's financial risk and guide prudent investment choices.`,
      },
      LIQUIDITY_RATIOS: {
        pageHeadTitle: `Liquidity Ratios in Financial Statistics: Assessing Short-Term Solvency | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Liquidity Ratios in Financial Statistics: Assessing Short-Term Solvency | Amassing Investment Academy`),
        pageHeadDescription: `Delve into liquidity ratios with Amassing Investment. Explore how key metrics, like the current and quick ratios, are essential in evaluating a company's capacity to handle short-term financial obligations.`,
      },
      EARNING_DIVIDENDS: {
        pageHeadTitle: `Earnings & Dividends in Financial Statistics: Profits, Payouts & Investor Returns | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Earnings & Dividends in Financial Statistics: Profits, Payouts & Investor Returns | Amassing Investment Academy`),
        pageHeadDescription: `Journey through the landscape of earnings and dividends with Amassing Investment. Understand how these vital metrics, from EPS to dividend yield, influence investment decisions and signal a company's financial well-being.`,
      },
      CASHFLOW_RATIOS: {
        pageHeadTitle: `Cash Flow Ratios in Financial Statistics: Evaluating Liquidity & Solvency | Amassing Investment Academy`,
        pageTitle: TechSEOPageOnTitle(`Cash Flow Ratios in Financial Statistics: Evaluating Liquidity & Solvency | Amassing Investment Academy`),
        pageHeadDescription: `Embark on a deep dive into cash flow ratios with Amassing Investment. Grasp the nuances of how these ratios, like the cash flow coverage or operating cash flow ratio, illuminate a company's financial strength and its ability to weather economic downturns.`,
      },
    },
    BLOGS: {
      pageHeadTitle: "Amassing Investment Blogs: Expert Insights on Finance & Investment Strategies",
        pageTitle: TechSEOPageOnTitle(
          "Amassing Investment Blogs: Expert Insights on Finance & Investment Strategies"
        ),
        pageHeadDescription: `Delve into Amassing Investment's rich collection of blogs. Each article   offers deep insights, curated by experts, to guide you through the dynamic world of finance and investments. Stay informed, stay ahead.`,
    }
  },
};

export const HelmetTitle = ({ pageHeadTitle,pageTitle, pageHeadDescription,keywords }, route = null) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{pageHeadTitle}</title>
    {/* <title>{pageHeadTitle}</title> */}
    <link rel="canonical" href={`${window.location.origin}${route ? `${route}` : ""}`} />
    <meta name="description" content={pageHeadDescription}></meta>
    <meta name="keywords" content={keywords}></meta>
  </Helmet>
);

export const showAds = () => {
  if (window.location.host.includes("localhost")) return false;
  else if (!isPrimeAndOpn()) return true;
  return false;
};
