import React, { useEffect, useState } from 'react'
import { MARKET_GAINERS_COLUMNS } from '../../Symbol/FinancialStatement/Constants'
import axios from 'axios';
import MarketTable from '../MarketTable';
import InvexLoader from '../../../Common/InvexLoader';
import { AtomSelectedTab } from '../../../../Atoms/MarketAtom';
import { useSetRecoilState } from 'recoil';
import MarketTab from '../MarketTab/MarketTab';
import { HelmetTitle, TechSEOPageOnTitle } from '../../../../helpers/TechSEO';
import InvexRoutes from '../../../../InvexRoutes';
import InsightCornor from '../InsightCornor/InsightCornor';
import { getInsightCornor } from '../../../api/Symbol';

const MarketIndicas = () => {
  // Variables
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const setActiveTabIndex = useSetRecoilState(AtomSelectedTab)

  // Functions
  let symbols = {
    "^GSPC": { symbol: "^GSPC", country: "USA", id: 1 },
    "^IXIC": { symbol: "^IXIC", country: "USA", id: 2 },
    "^DJI": { symbol: "^DJI", country: "USA", id: 3 },
    "^RUT": { symbol: "^RUT", country: "USA", id: 4 },
    "^VIX": { symbol: "^VIX", country: "USA", id: 5 },
    "^TNX": { symbol: "^TNX", country: "USA", id: 6 },
    "^FTSE": { symbol: "^FTSE", country: "UK", id: 7 },
    "^GDAXI": { symbol: "^GDAXI", country: "Germany", id: 8 },
    "^FCHI": { symbol: "^FCHI", country: "France", id: 9 },
    "IMOEX.ME": { symbol: "IMOEX.ME", country: "Russia", id: 10 },
    "^N225": { symbol: "^N225", country: "Japan", id: 11 },
    "^HSI": { symbol: "^HSI", country: "Hong Kong", id: 12 },
    "^STI": { symbol: "^STI", country: "Singapore", id: 13 },
    "AXJO": { symbol: "AXJO", country: "Australia", id: 14 },
    "^BSESN": { symbol: "^BSESN", country: "India", id: 15 },
    "^NZ50": { symbol: "^NZ50", country: "New Zealand", id: 16 },
    "^TWII": { symbol: "^TWII", country: "Taiwan", id: 17 },
    "^GSPTSE": { symbol: "^GSPTSE", country: "Canada", id: 18 },
    "^BVSP": { symbol: "^BVSP", country: "Brazil", id: 19 },
    "^MERV": { symbol: "^MERV", country: "Argentina", id: 20 },
    "000001.SS": { symbol: "000001.SS", country: "China", id: 21 },
  }

  const getData = async () => {
    setIsLoading(true);
    // const res = await axios({
    //   method: "get",
    //   url: "https://financialmodelingprep.com/api/v3/quotes/index?apikey=b1360803f80dd08bdd0211c5c004ad03",
    // });
      const res = await getInsightCornor({
          url: `https://financialmodelingprep.com/api/v3/quotes/index?apikey=b1360803f80dd08bdd0211c5c004ad03`,
        });
    let _list = [];
    for (const data of res?.data ?? []) {
      let _symbol = { ...symbols[data?.symbol] };
      if (Object.keys(_symbol)?.length) {
        let obj = {};
        obj["price"] = data?.price?.toFixed(2);
        obj["changesPercentage"] = data?.changesPercentage?.toFixed(2) + " %";
        obj["name"] = data?.name;
        obj["symbol"] = data?.symbol;
        obj["rangeHigh"] = data?.yearHigh;
        obj["rangeLow"] = data?.yearLow;
        obj["country"] = _symbol?.country;
        obj["id"] = _symbol?.id;
        _list.push(obj);
      }
    }
      let _sortedOrder = _list.sort((a, b) => {
            return a.id > b.id ? 1 : -1;
          });
      setTableData(_sortedOrder);
    setIsLoading(false);
  }

    useEffect(() => {
    setActiveTabIndex("market-indicas")
  },[])

  // Callbacks
  useEffect(() => {
    getData();
  },[])

    const getPageSeo = () => {
    let obj = {
      pageHeadTitle: "Global and Regional Market Indices Insights and Analysis",
      pageTitle: TechSEOPageOnTitle("Market Indices | Amassing Investment"),
      pageHeadDescription: "Explore an in-depth overview of market indices with Amassing Investment. From the S&P 500 to the NASDAQ, get insights and analysis to guide your investment strategy.",
    };
    return obj;
  };
  const seoData = getPageSeo();
  const getHelmet = () => {
    return HelmetTitle(seoData, InvexRoutes.MarketiIndices.path);
  };

  return (
    <div className='container' >
      {getHelmet()}
      <MarketTab />

      <h1 style={{margin: "1rem 0rem", fontWeight: 600, fontSize: "1.5rem",fontFamily: "merriweather"  }} >Global and Regional Market Indices Insights and Analysis</h1>
      {
        !isLoading &&
        <MarketTable isClickable data={tableData ?? []} colData={MARKET_GAINERS_COLUMNS} />
      }
      {isLoading && <InvexLoader height={240} />}
   <InsightCornor />
    </div>
  )
}

export default MarketIndicas