import React, { useEffect, useState } from 'react';
import invexLoader from '../../Common/Images/loader.png';
import loader from '../../Common/Images/invexLoader.gif';

const InvexLoader = ({ height }) => {
const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 5000);

    return () => clearTimeout(timer);
    }, []);
  
  return (
    <div
      className='d-flex justify-content-center align-items-center flex-column'
      style={{ height: height }}
    >
       {isLoading ? (
        <div>
          <img src={loader} style={{ height: '100px' }} />
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center flex-column' >
               <div className='mb-4' >"Your initial visit may take about 10 seconds as we update you with the latest <br /> financial insights. We appreciate your patience—smooth browsing awaits!"</div>
      <img src={invexLoader} style={{ height: '100px' }} />
        </div>
      )}
    </div>
  );
};

export default InvexLoader;
