/* eslint-disable no-unused-vars */
import IPODate from "./utils";
import img1 from '../../Common/Images/5243a78334772bc58b0b29fe34273c6f.png'
import img2 from '../../Common/Images/60a2820b024e4b6fcaa8a7c17d5f162c.png'
import centerImg from '../../Common/Images/div.lazy-background-image.png'
import img3 from '../../Common/Images/c512a6abc0eaa0158e5184c488265074.png'
import img4 from '../../Common/Images/3b32e57ce0b1aac85c5292d83ac3e3a8.png'
import img5 from '../../Common/Images/22557a56fa1cbc2c672023279ed86b9c.png'
import img6 from '../../Common/Images/0ecf295479ac15f7dcf12863ef6cac33.png'
import img7 from '../../Common/Images/03145a1d0a27954332a066f97d71dc51.png'
import img8 from '../../Common/Images/a1de4f2de94c80f04627a3caee73f538.png'
import img9 from '../../Common/Images/3846afda2bd17a9adc8340f4747944ee.png'
import img10 from '../../Common/Images/3157d9e27f1832b701001e9c40dd6f2b.png'

export const INIT_PARAM = {
  price: "0_46657429600_0",
  currency: "USD",
  beta: "-259202.84_104551.516_0",
  volavg: "-10000_10186911674_0",
  mktcap: "-10000_18446744072943000000_0",
  lastdiv: "-10000_66527998377984_0",
  // changes: "-10000_10000",
  exchangeShortName: "any",
  sector: "any",
  industry: "any",
  country: "any",
  fullTimeEmployees: "0_4200000_0",
  ipoDate: `${IPODate("any")}`,
  bookValuePerShareTTM: "None",
  capexPerShareTTM: "None",
  capexToDepreciationTTM: "None",
  capexToOperatingCashFlowTTM: "None",
  capexToRevenueTTM: "None",
  capitalExpenditureCoverageRatioTTM: "None",
  effectiveTaxRateTTM: "None",
  enterpriseValueTTM: "None",
  intangiblesToTotalAssetsTTM: "None",
  investedCapitalTTM: "None",
  marketCapTTM: "None",
  netCurrentAssetValueTTM: "None",
  revenuePerShareTTM: "None",
  stockBasedCompensationToRevenueTTM: "None",
  tangibleAssetValueTTM: "None",
  tangibleBookValuePerShareTTM: "None",
  workingCapitalTTM: "None",
  enterpriseValueMultipleTTM: "None",
  evToFreeCashFlowTTM: "None",
  evToOperatingCashFlowTTM: "None",
  evToSalesTTM: "None",
  grahamNumberTTM: "None",
  priceEarningsRatioTTM: "None",
  priceEarningsToGrowthRatioTTM: "None",
  priceToBookRatioTTM: "None",
  priceToFreeCashFlowsRatioTTM: "None",
  priceToOperatingCashFlowsRatioTTM: "None",
  priceToSalesRatioTTM_x: "None",
  assetTurnoverTTM: "None",
  fixedAssetTurnoverTTM: "None",
  inventoryTurnoverTTM_x: "None",
  payablesTurnoverTTM_x: "None",
  receivablesTurnoverTTM_x: "None",
  returnOnAssetsTTM: "None",
  returnOnCapitalEmployedTTM: "None",
  returnOnEquityTTM: "None",
  returnOnTangibleAssetsTTM: "None",
  roicTTM: "None",
  ebtPerEbitTTM: "None",
  grossProfitMarginTTM: "None",
  netIncomePerEBTTTM: "None",
  netProfitMarginTTM: "None",
  operatingProfitMarginTTM: "None",
  pretaxProfitMarginTTM: "None",
  researchAndDevelopementToRevenueTTM: "None",
  salesGeneralAndAdministrativeToRevenueTTM: "None",
  cashFlowCoverageRatiosTTM: "None",
  debtEquityRatioTTM: "None",
  debtToAssetsTTM: "None",
  interestCoverageTTM_x: "None",
  interestDebtPerShareTTM: "None",
  longTermDebtToCapitalizationTTM: "None",
  netDebtToEBITDATTM: "None",
  shortTermCoverageRatiosTTM: "None",
  totalDebtToCapitalizationTTM: "None",
  cashConversionCycleTTM: "None",
  cashPerShareTTM_y: "None",
  cashRatioTTM: "None",
  currentRatioTTM_y: "None",
  daysOfInventoryOutstandingTTM: "None",
  daysOfPayablesOutstandingTTM: "None",
  daysOfSalesOutstandingTTM: "None",
  operatingCycleTTM: "None",
  quickRatioTTM: "None",
  dividendPaidAndCapexCoverageRatioTTM: "None",
  dividendYieldTTM_x: "None",
  earningsYieldTTM: "None",
  netIncomePerShareTTM: "None",
  freeCashFlowOperatingCashFlowRatioTTM: "None",
  freeCashFlowPerShareTTM_x: "None",
  freeCashFlowYieldTTM: "None",
  incomeQualityTTM: "None",
  operatingCashFlowPerShareTTM_x: "None",
  operatingCashFlowSalesRatioTTM: "None",
  returOnEquityTTM: "None",
  relative_fy0_base: "None",
  relative_fy0_best: "None",
  relative_fy0_worst: "None",
  relative_fy2_base: "None",
  relative_fy2_best: "None",
  relative_fy2_worst: "None",
  relative_fy5_base: "None",
  relative_fy5_best: "None",
  relative_fy5_worst: "None",
  relative_fy10_base: "None",
  relative_fy10_best: "None",
  relative_fy10_worst: "None",
  fcff_fy0_base: "None",
  fcff_fy0_best: "None",
  fcff_fy0_worst: "None",
  fcff_fy2_base: "None",
  fcff_fy2_best: "None",
  fcff_fy2_worst: "None",
  fcff_fy5_base: "None",
  fcff_fy5_best: "None",
  fcff_fy5_worst: "None",
  fcff_fy10_base: "None",
  fcff_fy10_best: "None",
  fcff_fy10_worst: "None",
  erm_fy0_base: "None",
  erm_fy0_best: "None",
  erm_fy0_worst: "None",
  erm_fy2_base: "None",
  erm_fy2_best: "None",
  erm_fy2_worst: "None",
  erm_fy5_base: "None",
  erm_fy5_best: "None",
  erm_fy5_worst: "None",
  erm_fy10_base: "None",
  erm_fy10_best: "None",
  erm_fy10_worst: "None",
};
export const NEW_FILTERS_TO_BE_FILTERED = [
  "salesGeneralAndAdministrativeToRevenueTTM",
  "bookValuePerShareTTM",
  "capexPerShareTTM",
  "capexToDepreciationTTM",
  "capexToOperatingCashFlowTTM",
  "capexToRevenueTTM",
  "capitalExpenditureCoverageRatioTTM",
  "effectiveTaxRateTTM",
  "enterpriseValueTTM",
  "intangiblesToTotalAssetsTTM",
  "investedCapitalTTM",
  "marketCapTTM",
  "netCurrentAssetValueTTM",
  "revenuePerShareTTM",
  "stockBasedCompensationToRevenueTTM",
  "tangibleAssetValueTTM",
  "tangibleBookValuePerShareTTM",
  "workingCapitalTTM",
  "enterpriseValueMultipleTTM",
  "evToFreeCashFlowTTM",
  "evToOperatingCashFlowTTM",
  "evToSalesTTM",
  "grahamNumberTTM",
  "priceEarningsRatioTTM",
  "priceEarningsToGrowthRatioTTM",
  "priceToBookRatioTTM",
  "priceToFreeCashFlowsRatioTTM",
  "priceToOperatingCashFlowsRatioTTM",
  "priceToSalesRatioTTM_x",
  "assetTurnoverTTM",
  "fixedAssetTurnoverTTM",
  "inventoryTurnoverTTM_x",
  "payablesTurnoverTTM_x",
  "receivablesTurnoverTTM_x",
  "returnOnAssetsTTM",
  "returnOnCapitalEmployedTTM",
  "returnOnEquityTTM",
  "returnOnTangibleAssetsTTM",
  "ebtPerEbitTTM",
  "grossProfitMarginTTM",
  "netIncomePerEBTTTM",
  "netProfitMarginTTM",
  "operatingProfitMarginTTM",
  "pretaxProfitMarginTTM",
  "researchAndDevelopementToRevenueTTM",
  "cashFlowCoverageRatiosTTM",
  "debtEquityRatioTTM",
  "debtToAssetsTTM",
  "interestCoverageTTM_x",
  "interestDebtPerShareTTM",
  "longTermDebtToCapitalizationTTM",
  "netDebtToEBITDATTM",
  "shortTermCoverageRatiosTTM",
  "totalDebtToCapitalizationTTM",
  "roicTTM",
  "cashConversionCycleTTM",
  "cashPerShareTTM_y",
  "cashRatioTTM",
  "currentRatioTTM_y",
  "daysOfInventoryOutstandingTTM",
  "daysOfPayablesOutstandingTTM",
  "daysOfSalesOutstandingTTM",
  "operatingCycleTTM",
  "quickRatioTTM",
  "dividendPaidAndCapexCoverageRatioTTM",
  "dividendPayoutRatioTTM",
  "dividendYieldTTM_x",
  "earningsYieldTTM",
  "netIncomePerShareTTM",
  "freeCashFlowOperatingCashFlowRatioTTM",
  "freeCashFlowPerShareTTM_x",
  "freeCashFlowYieldTTM",
  "incomeQualityTTM",
  "operatingCashFlowPerShareTTM_x",
  "operatingCashFlowSalesRatioTTM",
  "dcffcffvaluationTTM",
  "dcfermvaluationTTM",
];

export const Currency = {
  USD: {
    exchange: [
      "HKSE",
      "London Stock Exchange",
      "LSE",
      "CCC",
      "Shanghai",
      "New York Stock Exchange",
      "Nasdaq",
      "Other OTC",
      "NASDAQ Global Market",
      "Nasdaq Capital Market",
      "NASDAQ Capital Market",
      "NASDAQ Global Select",
      "American Stock Exchange",
      "Nasdaq Global Select",
      "New York Stock Exchange Arca",
      "IOB",
      "BATS",
      "Brussels",
      "SES",
      "Toronto Stock Exchange",
      "NasdaqGS",
      "Amsterdam",
      "Swiss Exchange",
    ],
    country: [
      {
        value: "HK",
        label: "HK - (HongKong)",
      },
      {
        value: "CN",
        label: "CN - (China)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "LU",
        label: "LU - (Luxembourg)",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "NL",
        label: "NL - (Netherlands)",
      },
      {
        value: "BM",
        label: "BM - (Bermuda)",
      },
      {
        value: "SE",
        label: "SE - (Sweden)",
      },
      {
        value: "DE",
        label: "DE - (Germany)",
      },
      {
        value: "FR",
        label: "FR - (France)",
      },
      {
        value: "TW",
        label: "TW - (Taiwan)",
      },
      {
        value: "AR",
        label: "AR - (Argentina)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "IL",
        label: "IL - (Israel)",
      },
      {
        value: "CH",
        label: "CH - (Switzerland)",
      },
      {
        value: "IE",
        label: "IE - (Ireland)",
      },
      {
        value: "VI",
        label: "VI - (U.S. Virgin Islands)",
      },
      {
        value: "BR",
        label: "BR - (Brazil)",
      },
      {
        value: "KY",
        label: "KY - (Cayman Islands)",
      },
      {
        value: "NZ",
        label: "NZ - (New Zealand)",
      },
      {
        value: "ES",
        label: "ES - (Spain)",
      },
      {
        value: "AU",
        label: "AU - (Australia)",
      },
      {
        value: "AT",
        label: "AT - (Austria)",
      },
      {
        value: "JP",
        label: "JP - (Japan)",
      },
      {
        value: "PE",
        label: "PE - (Peru)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
      {
        value: "GI",
        label: "GI - (Gibraltar)",
      },
      {
        value: "BE",
        label: "BE - (Belgium)",
      },
      {
        value: "ZA",
        label: "ZA - (South Africa)",
      },
      {
        value: "TR",
        label: "TR - (Turkey)",
      },
      {
        value: "CL",
        label: "CL - (Chile)",
      },
      {
        value: "GR",
        label: "GR - (Greece)",
      },
      {
        value: "IS",
        label: "IS - (Iceland)",
      },
      {
        value: "DK",
        label: "DK - (Denmark)",
      },
      {
        value: "MX",
        label: "MX - (Mexico)",
      },
      {
        value: "AE",
        label: "AE - (United Arab Emirates)",
      },
      {
        value: "UY",
        label: "UY - (Uruguay)",
      },
      {
        value: "IT",
        label: "IT - (Italy)",
      },
      {
        value: "JE",
        label: "JE - (Jersey)",
      },
      {
        value: "RU",
        label: "RU - (Russian Federation)",
      },
      {
        value: "CO",
        label: "CO - (Colombia)",
      },
      {
        value: "TH",
        label: "TH - (Thailand)",
      },
      {
        value: "IN",
        label: "IN - (India)",
      },
      {
        value: "PH",
        label: "PH - (Philippines)",
      },
      {
        value: "MY",
        label: "MY - (Malaysia)",
      },
      {
        value: "PA",
        label: "PA - (Panama)",
      },
      {
        value: "PR",
        label: "PR - (Puerto Rico)",
      },
      {
        value: "GG",
        label: "GG - (Guernsey)",
      },
      {
        value: "EG",
        label: "EG - (Egypt)",
      },
      {
        value: "CY",
        label: "CY - (Cyprus)",
      },
      {
        value: "MC",
        label: "MC - (Monaco)",
      },
      {
        value: "NO",
        label: "NO - (Norway)",
      },
      {
        value: "MO",
        label: "MO - (Macao)",
      },
      {
        value: "PT",
        label: "PT - (Portugal)",
      },
      {
        value: "CR",
        label: "CR - (Costa Rica)",
      },
      {
        value: "FI",
        label: "FI - (Finland)",
      },
      {
        value: "BS",
        label: "BS - (Bahamas)",
      },
      {
        value: "IM",
        label: "IM - (Isle of Man)",
      },
      {
        value: "MT",
        label: "MT - (Malta)",
      },
      {
        value: "KR",
        label: "KR - (Korea-South)",
      },
      {
        value: "KZ",
        label: "KZ - (Kazakhstan)",
      },
      {
        value: "JO",
        label: "JO - (Jordan)",
      },
      {
        value: "ID",
        label: "ID - (Indonesia)",
      },
      {
        value: "SK",
        label: "SK - (Slovakia)",
      },
      {
        value: "AI",
        label: "AI - (Anguilla)",
      },
      {
        value: "PL",
        label: "PL - (Poland)",
      },
      {
        value: "VG",
        label: "VG - (Virgin Islands (British))",
      },
      {
        value: "LT",
        label: "LT - (Lithuania)",
      },
    ],
  },
  CNY: {
    exchange: ["Shenzhen", "Shanghai", "HKSE", "FTSE Index"],
    country: [
      {
        value: "CN",
        label: "CN - (China)",
      },
      {
        value: "MN",
        label: "MN - (Mongolia)",
      },
      {
        value: "HK",
        label: "HK - (HongKong)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
    ],
  },
  KRW: {
    exchange: ["KSE", "KOSDAQ"],
    country: [
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "KR",
        label: "KR - (Korea-South)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
    ],
  },
  HKD: {
    exchange: ["HKSE", "London Stock Exchange", "Shenzhen"],
    country: [
      {
        value: "HK",
        label: "HK - (HongKong)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "CN",
        label: "CN - (China)",
      },
      {
        value: "DE",
        label: "DE - (Germany)",
      },
      {
        value: "VN",
        label: "VN - (Vietnam)",
      },
      {
        value: "CY",
        label: "CY - (Cyprus)",
      },
      {
        value: "MO",
        label: "MO - (Macao)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "LU",
        label: "LU - (Luxembourg)",
      },
      {
        value: "MN",
        label: "MN - (Mongolia)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "AU",
        label: "AU - (Australia)",
      },
      {
        value: "KY",
        label: "KY - (Cayman Islands)",
      },
      {
        value: "JP",
        label: "JP - (Japan)",
      },
      {
        value: "IL",
        label: "IL - (Israel)",
      },
      {
        value: "IT",
        label: "IT - (Italy)",
      },
      {
        value: "TH",
        label: "TH - (Thailand)",
      },
      {
        value: "MY",
        label: "MY - (Malaysia)",
      },
      {
        value: "KH",
        label: "KH - (Cambodia)",
      },
      {
        value: "TW",
        label: "TW - (Taiwan)",
      },
      {
        value: "KR",
        label: "KR - (Korea-South)",
      },
    ],
  },
  EUR: {
    exchange: [
      "Frankfurt",
      "XETRA",
      "London Stock Exchange",
      "IOB",
      "Milan",
      "Stuttgart",
      "Irish",
      "Dusseldorf",
      "Madrid Stock Exchange",
      "Paris",
      "Amsterdam",
      "Helsinki",
      "Vienna",
      "Brussels",
      "Athens",
      "Stockholm Stock Exchange",
      "Lisbon",
      "SES",
      "Munich",
      "Tallinn",
      "Swiss Exchange",
      "Hamburg",
      "Berlin",
    ],
    country: [
      {
        value: "CN",
        label: "CN - (China)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "DE",
        label: "DE - (Germany)",
      },
      {
        value: "FI",
        label: "FI - (Finland)",
      },
      {
        value: "FR",
        label: "FR - (France)",
      },
      {
        value: "NL",
        label: "NL - (Netherlands)",
      },
      {
        value: "BE",
        label: "BE - (Belgium)",
      },
      {
        value: "PT",
        label: "PT - (Portugal)",
      },
      {
        value: "ES",
        label: "ES - (Spain)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "AT",
        label: "AT - (Austria)",
      },
      {
        value: "GA",
        label: "GA - (Gabon)",
      },
      {
        value: "IT",
        label: "IT - (Italy)",
      },
      {
        value: "LU",
        label: "LU - (Luxembourg)",
      },
      {
        value: "MC",
        label: "MC - (Monaco)",
      },
      {
        value: "ZA",
        label: "ZA - (South Africa)",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "IE",
        label: "IE - (Ireland)",
      },
      {
        value: "TH",
        label: "TH - (Thailand)",
      },
      {
        value: "BM",
        label: "BM - (Bermuda)",
      },
      {
        value: "TR",
        label: "TR - (Turkey)",
      },
      {
        value: "SE",
        label: "SE - (Sweden)",
      },
      {
        value: "GR",
        label: "GR - (Greece)",
      },
      {
        value: "CH",
        label: "CH - (Switzerland)",
      },
      {
        value: "GG",
        label: "GG - (Guernsey)",
      },
      {
        value: "JE",
        label: "JE - (Jersey)",
      },
      {
        value: "DK",
        label: "DK - (Denmark)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
      {
        value: "NO",
        label: "NO - (Norway)",
      },
      {
        value: "EE",
        label: "EE - (Estonia)",
      },
      {
        value: "VG",
        label: "VG - (Virgin Islands (British))",
      },
      {
        value: "SN",
        label: "SN - (Senegal)",
      },
      {
        value: "MA",
        label: "MA - (Morocco)",
      },
      {
        value: "KZ",
        label: "KZ - (Kazakhstan)",
      },
      {
        value: "MT",
        label: "MT - (Malta)",
      },
      {
        value: "IS",
        label: "IS - (Iceland)",
      },
      {
        value: "AR",
        label: "AR - (Argentina)",
      },
      {
        value: "UY",
        label: "UY - (Uruguay)",
      },
      {
        value: "ZM",
        label: "ZM - (Zambia)",
      },
      {
        value: "CW",
        label: "CW - (Curaçao)",
      },
      {
        value: "IM",
        label: "IM - (Isle of Man)",
      },
      {
        value: "HK",
        label: "HK - (HongKong)",
      },
      {
        value: "IL",
        label: "IL - (Israel)",
      },
      {
        value: "RO",
        label: "RO - (Romania)",
      },
      {
        value: "IN",
        label: "IN - (India)",
      },
      {
        value: "LV",
        label: "LV - (Latvia)",
      },
      {
        value: "MX",
        label: "MX - (Mexico)",
      },
    ],
  },
  PLN: {
    exchange: ["Warsaw Stock Exchange"],
    country: [
      {
        value: "PL",
        label: "PL - (Poland)",
      },
      {
        value: "CY",
        label: "CY - (Cyprus)",
      },
      {
        value: "LU",
        label: "LU - (Luxembourg)",
      },
      {
        value: "NL",
        label: "NL - (Netherlands)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "LT",
        label: "LT - (Lithuania)",
      },
      {
        value: "ES",
        label: "ES - (Spain)",
      },
      {
        value: "BG",
        label: "BG - (Bulgaria)",
      },
      {
        value: "EE",
        label: "EE - (Estonia)",
      },
      {
        value: "AU",
        label: "AU - (Australia)",
      },
      {
        value: "AT",
        label: "AT - (Austria)",
      },
      {
        value: "UA",
        label: "UA - (Ukraine)",
      },
      {
        value: "SI",
        label: "SI - (Slovenia)",
      },
      {
        value: "FR",
        label: "FR - (France)",
      },
      {
        value: "HU",
        label: "HU - (Hungary)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "SE",
        label: "SE - (Sweden)",
      },
      {
        value: "JE",
        label: "JE - (Jersey)",
      },
      {
        value: "DE",
        label: "DE - (Germany)",
      },
      {
        value: "SK",
        label: "SK - (Slovakia)",
      },
      {
        value: "IT",
        label: "IT - (Italy)",
      },
    ],
  },
  CHF: {
    exchange: ["London Stock Exchange", "Swiss Exchange", "IOB"],
    country: [
      {
        value: "CH",
        label: "CH - (Switzerland)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "IT",
        label: "IT - (Italy)",
      },
      {
        value: "NL",
        label: "NL - (Netherlands)",
      },
      {
        value: "LI",
        label: "LI - (Liechtenstein)",
      },
      {
        value: "IE",
        label: "IE - (Ireland)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "AT",
        label: "AT - (Austria)",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "FR",
        label: "FR - (France)",
      },
      {
        value: "IL",
        label: "IL - (Israel)",
      },
    ],
  },
  SEK: {
    exchange: [
      "IOB",
      "London Stock Exchange",
      "Stockholm Stock Exchange",
      "Stockholm",
    ],
    country: [
      {
        value: "SE",
        label: "SE - (Sweden)",
      },
      {
        value: "CH",
        label: "CH - (Switzerland)",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "DK",
        label: "DK - (Denmark)",
      },
      {
        value: "DE",
        label: "DE - (Germany)",
      },
      {
        value: "MT",
        label: "MT - (Malta)",
      },
      {
        value: "IS",
        label: "IS - (Iceland)",
      },
      {
        value: "PL",
        label: "PL - (Poland)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "JE",
        label: "JE - (Jersey)",
      },
      {
        value: "FI",
        label: "FI - (Finland)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "LI",
        label: "LI - (Liechtenstein)",
      },
      {
        value: "IE",
        label: "IE - (Ireland)",
      },
      {
        value: "NO",
        label: "NO - (Norway)",
      },
      {
        value: "LU",
        label: "LU - (Luxembourg)",
      },
    ],
  },
  CAD: {
    exchange: [
      "London Stock Exchange",
      "Toronto Stock Exchange",
      "TSXV",
      "NEO",
      "Canadian Sec",
    ],
    country: [
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "IL",
        label: "IL - (Israel)",
      },
      {
        value: "MU",
        label: "MU - (Mauritius)",
      },
      {
        value: "AU",
        label: "AU - (Australia)",
      },
      {
        value: "CL",
        label: "CL - (Chile)",
      },
      {
        value: "CY",
        label: "CY - (Cyprus)",
      },
      {
        value: "CO",
        label: "CO - (Colombia)",
      },
      {
        value: "BM",
        label: "BM - (Bermuda)",
      },
      {
        value: "PA",
        label: "PA - (Panama)",
      },
      {
        value: "JE",
        label: "JE - (Jersey)",
      },
      {
        value: "HK",
        label: "HK - (HongKong)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "CK",
        label: "CK - (Cook Islands)",
      },
      {
        value: "FR",
        label: "FR - (France)",
      },
      {
        value: "IE",
        label: "IE - (Ireland)",
      },
      {
        value: "IM",
        label: "IM - (Isle of Man)",
      },
      {
        value: "PE",
        label: "PE - (Peru)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
      {
        value: "BB",
        label: "BB - (Barbados)",
      },
      {
        value: "BR",
        label: "BR - (Brazil)",
      },
      {
        value: "MN",
        label: "MN - (Mongolia)",
      },
      {
        value: "VG",
        label: "VG - (Virgin Islands (British))",
      },
      {
        value: "KY",
        label: "KY - (Cayman Islands)",
      },
      {
        value: "BS",
        label: "BS - (Bahamas)",
      },
    ],
  },
  NOK: {
    exchange: ["London Stock Exchange", "Oslo Stock Exchange", "OSE"],
    country: [
      {
        value: "NO",
        label: "NO - (Norway)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "DK",
        label: "DK - (Denmark)",
      },
      {
        value: "BM",
        label: "BM - (Bermuda)",
      },
      {
        value: "CY",
        label: "CY - (Cyprus)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "CH",
        label: "CH - (Switzerland)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
      {
        value: "SE",
        label: "SE - (Sweden)",
      },
      {
        value: "AU",
        label: "AU - (Australia)",
      },
      {
        value: "NL",
        label: "NL - (Netherlands)",
      },
      {
        value: "MT",
        label: "MT - (Malta)",
      },
      {
        value: "IS",
        label: "IS - (Iceland)",
      },
      {
        value: "HK",
        label: "HK - (HongKong)",
      },
      {
        value: "FI",
        label: "FI - (Finland)",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "AE",
        label: "AE - (United Arab Emirates)",
      },
      {
        value: "LU",
        label: "LU - (Luxembourg)",
      },
    ],
  },
  DKK: {
    exchange: ["London Stock Exchange", "IOB", "Copenhagen"],
    country: [
      {
        value: "DK",
        label: "DK - (Denmark)",
      },
      {
        value: "SE",
        label: "SE - (Sweden)",
      },
      {
        value: "GL",
        label: "GL - (Greenland)",
      },
      {
        value: "FI",
        label: "FI - (Finland)",
      },
      {
        value: "IS",
        label: "IS - (Iceland)",
      },
      {
        value: "CH",
        label: "CH - (Switzerland)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "MT",
        label: "MT - (Malta)",
      },
    ],
  },
  JPY: {
    exchange: ["Tokyo", "London Stock Exchange", "Fukuoka"],
    country: [
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "JP",
        label: "JP - (Japan)",
      },
    ],
  },
  GBp: {
    exchange: ["London Stock Exchange"],
    country: [
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "IE",
        label: "IE - (Ireland)",
      },
      {
        value: "LU",
        label: "LU - (Luxembourg)",
      },
      {
        value: "JE",
        label: "JE - (Jersey)",
      },
      {
        value: "GI",
        label: "GI - (Gibraltar)",
      },
      {
        value: "AU",
        label: "AU - (Australia)",
      },
      {
        value: "GG",
        label: "GG - (Guernsey)",
      },
      {
        value: "AZ",
        label: "AZ - (Azerbaijan)",
      },
      {
        value: "IM",
        label: "IM - (Isle of Man)",
      },
      {
        value: "FI",
        label: "FI - (Finland)",
      },
      {
        value: "RU",
        label: "RU - (Russian Federation)",
      },
      {
        value: "VG",
        label: "VG - (Virgin Islands (British))",
      },
      {
        value: "FK",
        label: "FK - (Falkland Islands (Malvinas))",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "NL",
        label: "NL - (Netherlands)",
      },
      {
        value: "CY",
        label: "CY - (Cyprus)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
      {
        value: "LT",
        label: "LT - (Lithuania)",
      },
      {
        value: "ZA",
        label: "ZA - (South Africa)",
      },
      {
        value: "ES",
        label: "ES - (Spain)",
      },
      {
        value: "IL",
        label: "IL - (Israel)",
      },
      {
        value: "BD",
        label: "BD - (Bangladesh)",
      },
      {
        value: "UA",
        label: "UA - (Ukraine)",
      },
      {
        value: "MU",
        label: "MU - (Mauritius)",
      },
      {
        value: "CH",
        label: "CH - (Switzerland)",
      },
      {
        value: "KY",
        label: "KY - (Cayman Islands)",
      },
      {
        value: "BM",
        label: "BM - (Bermuda)",
      },
      {
        value: "NZ",
        label: "NZ - (New Zealand)",
      },
      {
        value: "MX",
        label: "MX - (Mexico)",
      },
      {
        value: "IN",
        label: "IN - (India)",
      },
      {
        value: "CN",
        label: "CN - (China)",
      },
      {
        value: "AE",
        label: "AE - (United Arab Emirates)",
      },
      {
        value: "HK",
        label: "HK - (HongKong)",
      },
      {
        value: "MN",
        label: "MN - (Mongolia)",
      },
      {
        value: "MY",
        label: "MY - (Malaysia)",
      },
      {
        value: "FR",
        label: "FR - (France)",
      },
      {
        value: "TG",
        label: "TG - (Togo)",
      },
      {
        value: "AT",
        label: "AT - (Austria)",
      },
      {
        value: "NG",
        label: "NG - (Nigeria)",
      },
      {
        value: "GE",
        label: "GE - (Georgia)",
      },
      {
        value: "DK",
        label: "DK - (Denmark)",
      },
      {
        value: "DE",
        label: "DE - (Germany)",
      },
      {
        value: "VN",
        label: "VN - (Vietnam)",
      },
      {
        value: "ZM",
        label: "ZM - (Zambia)",
      },
    ],
  },
  AUD: {
    exchange: [
      "Berlin",
      "London Stock Exchange",
      "Australian Securities Exchange",
      "ASX",
    ],
    country: [
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "AU",
        label: "AU - (Australia)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
      {
        value: "NZ",
        label: "NZ - (New Zealand)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
      {
        value: "CH",
        label: "CH - (Switzerland)",
      },
      {
        value: "HK",
        label: "HK - (HongKong)",
      },
      {
        value: "PG",
        label: "PG - (Papua New Guinea)",
      },
      {
        value: "IL",
        label: "IL - (Israel)",
      },
      {
        value: "IE",
        label: "IE - (Ireland)",
      },
      {
        value: "MY",
        label: "MY - (Malaysia)",
      },
      {
        value: "ES",
        label: "ES - (Spain)",
      },
      {
        value: "LU",
        label: "LU - (Luxembourg)",
      },
      {
        value: "VG",
        label: "VG - (Virgin Islands (British))",
      },
      {
        value: "DE",
        label: "DE - (Germany)",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "RU",
        label: "RU - (Russian Federation)",
      },
      {
        value: "NL",
        label: "NL - (Netherlands)",
      },
      {
        value: "FR",
        label: "FR - (France)",
      },
      {
        value: "MN",
        label: "MN - (Mongolia)",
      },
      {
        value: "BM",
        label: "BM - (Bermuda)",
      },
      {
        value: "GG",
        label: "GG - (Guernsey)",
      },
    ],
  },
  ILS: {
    exchange: ["London Stock Exchange"],
    country: [
      {
        value: "IL",
        label: "IL - (Israel)",
      },
    ],
  },
  SAR: {
    exchange: ["Saudi"],
    country: [
      {
        value: "SA",
        label: "SA - (Saudi Arabia)",
      },
      {
        value: "AE",
        label: "AE - (United Arab Emirates)",
      },
    ],
  },
  TWD: {
    exchange: ["Taiwan", "Taipei Exchange"],
    country: [
      {
        value: "TW",
        label: "TW - (Taiwan)",
      },
      {
        value: "CN",
        label: "CN - (China)",
      },
      {
        value: "KY",
        label: "KY - (Cayman Islands)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "TH",
        label: "TH - (Thailand)",
      },
      {
        value: "HK",
        label: "HK - (HongKong)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
    ],
  },
  INR: {
    exchange: ["National Stock Exchange of India"],
    country: [
      {
        value: "IN",
        label: "IN - (India)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
    ],
  },
  HUF: {
    exchange: ["Budapest"],
    country: [
      {
        value: "HU",
        label: "HU - (Hungary)",
      },
    ],
  },
  ZAc: {
    exchange: ["Johannesburg"],
    country: [
      {
        value: "MU",
        label: "MU - (Mauritius)",
      },
      {
        value: "ZA",
        label: "ZA - (South Africa)",
      },
      {
        value: "MT",
        label: "MT - (Malta)",
      },
      {
        value: "BW",
        label: "BW - (Botswana)",
      },
      {
        value: "GG",
        label: "GG - (Guernsey)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "JE",
        label: "JE - (Jersey)",
      },
      {
        value: "IM",
        label: "IM - (Isle of Man)",
      },
      {
        value: "CY",
        label: "CY - (Cyprus)",
      },
    ],
  },
  SGD: {
    exchange: ["SES"],
    country: [
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
      {
        value: "CN",
        label: "CN - (China)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "MY",
        label: "MY - (Malaysia)",
      },
      {
        value: "ID",
        label: "ID - (Indonesia)",
      },
      {
        value: "AU",
        label: "AU - (Australia)",
      },
      {
        value: "TH",
        label: "TH - (Thailand)",
      },
    ],
  },
  IDR: {
    exchange: ["Jakarta Stock Exchange"],
    country: [
      {
        value: "ID",
        label: "ID - (Indonesia)",
      },
    ],
  },
  BRL: {
    exchange: ["S\u00e3o Paulo"],
    country: [
      {
        value: "BR",
        label: "BR - (Brazil)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
    ],
  },
  MXN: {
    exchange: ["Mexico"],
    country: [
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "MX",
        label: "MX - (Mexico)",
      },
      {
        value: "CN",
        label: "CN - (China)",
      },
      {
        value: "ES",
        label: "ES - (Spain)",
      },
    ],
  },
  THB: {
    exchange: ["Thailand"],
    country: [
      {
        value: "TH",
        label: "TH - (Thailand)",
      },
    ],
  },
  ILA: {
    exchange: ["Tel Aviv"],
    country: [
      {
        value: "IL",
        label: "IL - (Israel)",
      },
      {
        value: "NL",
        label: "NL - (Netherlands)",
      },
      {
        value: "SG",
        label: "SG - (Singapore)",
      },
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "CY",
        label: "CY - (Cyprus)",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
    ],
  },
  TRY: {
    exchange: ["Istanbul Stock Exchange"],
    country: [
      {
        value: "TR",
        label: "TR - (Turkey)",
      },
    ],
  },
  CLP: {
    exchange: ["Santiago"],
    country: [
      {
        value: "CL",
        label: "CL - (Chile)",
      },
    ],
  },
  QAR: {
    exchange: ["Qatar"],
    country: [
      {
        value: "QA",
        label: "QA - (Qatar)",
      },
    ],
  },
  NZD: {
    exchange: ["NZSE"],
    country: [
      {
        value: "NZ",
        label: "NZ - (New Zealand)",
      },
      {
        value: "AU",
        label: "AU - (Australia)",
      },
    ],
  },
  ARS: {
    exchange: ["Buenos Aires"],
    country: [
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "CA",
        label: "CA - (Canada)",
      },
      {
        value: "AR",
        label: "AR - (Argentina)",
      },
    ],
  },
  AED: {
    exchange: ["Dubai"],
    country: [
      {
        value: "AE",
        label: "AE - (United Arab Emirates)",
      },
    ],
  },
  ISK: {
    exchange: ["Iceland"],
    country: [
      {
        value: "IS",
        label: "IS - (Iceland)",
      },
    ],
  },
  CZK: {
    exchange: ["Prague"],
    country: [
      {
        value: "US",
        label: "US - (USA)",
      },
      {
        value: "SE",
        label: "SE - (Sweden)",
      },
    ],
  },
  GBP: {
    exchange: ["Amsterdam", "London Stock Exchange"],
    country: [
      {
        value: "GG",
        label: "GG - (Guernsey)",
      },
      {
        value: "GB",
        label: "GB - (United Kingdom)",
      },
    ],
  },
};

export const EXCHANGE_NAME = [
  { label: "AMEX", value: "AMEX" },
  { label: "NASDAQ", value: "NASDAQ" },
  { label: "NYSE", value: "NYSE" },
  { label: "OTC", value: "OTC" },
];

export const PRICE = [
  { label: "Any", value:INIT_PARAM.price },
  { label: "Under $1", value: "0_1" },
  { label: "$1 to $5", value: "1_5" },
  { label: "$5 to $10", value: "5_10" },
  { label: "$10 to $20", value: "10_20" },
  { label: "$20 to $50", value: "20_50" },
  { label: "$50 to $100", value: "50_100" },
  { label: "$100 to $500", value: "100_500" },
  { label: "Above $500", value: "500_10000000" },
];

export const CURRENCY = [
  { label: "USD", value: "USD" },
  { label: "CNY", value: "CNY" },
  { label: "KRW", value: "KRW" },
  { label: "HKD", value: "HKD" },
  { label: "EUR", value: "EUR" },
  { label: "PLN", value: "PLN" },
  { label: "CHF", value: "CHF" },
  { label: "SEK", value: "SEK" },
  { label: "CAD", value: "CAD" },
  { label: "NOK", value: "NOK" },
  { label: "DKK", value: "DKK" },
  { label: "JPY", value: "JPY" },
  { label: "GBP", value: "GBP" },
  { label: "AUD", value: "AUD" },
  { label: "ILS", value: "ILS" },
  { label: "SAR", value: "SAR" },
  { label: "TWD", value: "TWD" },
  { label: "INR", value: "INR" },
  { label: "HUF", value: "HUF" },
  { label: "ZAC", value: "ZAC" },
  { label: "SGD", value: "SGD" },
  { label: "IDR", value: "IDR" },
  { label: "BRL", value: "BRL" },
  { label: "MXN", value: "MXN" },
  { label: "THB", value: "THB" },
  { label: "ILA", value: "ILA" },
  { label: "TRY", value: "TRY" },
  { label: "CLP", value: "CLP" },
  { label: "OAR", value: "OAR" },
  { label: "NZD", value: "NZD" },
  { label: "ARS", value: "ARS" },
  { label: "AED", value: "AED" },
  { label: "ISK", value: "ISK" },
  { label: "CZK", value: "CZK" },
];

export const BETA = [
  { label: "Any", value: INIT_PARAM.beta },
  { label: "Under 0", value: "-10000000_0" },
  { label: "0 to 0.5", value: "0_0.5" },
  { label: "0.5 to 1", value: "0.5_1" },
  { label: "1 to 1.5", value: "1_1.5" },
  { label: "1.5 to 2", value: "1.5_2" },
  { label: "Above 2", value: "2_10000000" },
];

export const VOLAVG = [
  { label: "Any", value: INIT_PARAM.volavg },
  { label: "Under 50K", value: "0_50000" },
  { label: "50K to 200K", value: "50000_200000" },
  { label: "200K to 500K", value: "200000_500000" },
  { label: "500K to 1M", value: "500000_1000000" },
  { label: "1M to 5M", value: "1000000_5000000" },
  { label: "5M to 10M", value: "5000000_10000000" },
  { label: "10M to 20M", value: "10000000_20000000" },
  { label: "Over 20M", value: "20000000_219557650" },
];

export const MKTCAP = [
  { label: "Any", value: INIT_PARAM.mktcap },
  { label: "Mega Cap (170B and above)", value: "170000000000_8385896800256" },
  { label: "Large Cap (35B to 170B)", value: "35000000000_170000000000" },
  { label: "Medium Cap (6B and 35B)", value: "6000000000_35000000000" },
  { label: "Small Cap (1.8B and 6B)", value: "1800000000_6000000000" },
  { label: "Micro Cap (0 and 1.8B)", value: "0_1800000000" },
];

export const RELATIVEVALUAION = [
  { label: "Best", value: "relative_best" },
  { label: "Base", value: "relative_base" },
  { label: "Worst", value: "relative_worst" },
];

export const LASTDIV = [
  { label: "Any", value: INIT_PARAM.lastdiv },
  { label: "Very High ($1.0 and above)", value: "1_380" },
  { label: "High ($0.5 to $1)", value: "0.5_1" },
  { label: "Medium ($0.3 to $0.5)", value: "0.3_0.5" },
  { label: "Low ($0.1 to $0.3)", value: "0.1_0.3" },
  { label: "Very Low ($0.1 and below)", value: "0.1_0" },
];

export const CHANGES = [
  { label: "Any", value: "-10000_10000" },
  { label: "Above 10%", value: "10_100" },
  { label: "5% to 10%", value: "5_10" },
  { label: "0 to 5% ", value: "0_5" },
  { label: "-5% to 0", value: "-5_0" },
  { label: "-10% to -5%", value: "-10_-5" },
  { label: "Below -10%", value: "-10_-100" },
];

export const RELATIVE_BASE = [
  { label: "Highly Underpriced (more than 100%)", value: "-10000000_-100" },
  { label: "Moderately Underpriced (-50% to -100%)", value: "-100_-50" },
  { label: "Lowly Underpriced (0 to -50%)", value: "-50_0" },
  { label: "Lowly Overpriced (0 to 50%)", value: "0_50" },
  { label: "Moderately Overpriced (50% to 100%)", value: "50_100" },
  { label: "Highly Overpriced (more than 100%)", value: "100_10000000" },
];

export const RELATIVE_BEST = [
  { label: "Highly Underpriced (more than 100%)", value: "-10000000_-100" },
  { label: "Moderately Underpriced (-50% to -100%)", value: "-100_-50" },
  { label: "Lowly Underpriced (0 to -50%)", value: "-50_0" },
  { label: "Lowly Overpriced (0 to 50%)", value: "0_50" },
  { label: "Moderately Overpriced (50% to 100%)", value: "50_100" },
  { label: "Highly Overpriced (more than 100%)", value: "100_10000000" },
];

export const RELATIVE_WORST = [
  { label: "Highly Underpriced (more than 100%)", value: "-10000000_-100" },
  { label: "Moderately Underpriced (-50% to -100%)", value: "-100_-50" },
  { label: "Lowly Underpriced (0 to -50%)", value: "-50_0" },
  { label: "Lowly Overpriced (0 to 50%)", value: "0_50" },
  { label: "Moderately Overpriced (50% to 100%)", value: "50_100" },
  { label: "Highly Overpriced (more than 100%)", value: "100_10000000" },
];

export const FULLTIMEEMPLOYEES = [
  { label: "Any", value: INIT_PARAM.fullTimeEmployees },
  { label: "Very High (16K and above)", value: "16000_2300000" },
  { label: "High (4K to 16K)", value: "4000_16000" },
  { label: "Medium (1.5K to 4K)", value: "1500_4000" },
  { label: "Low (500 to 1.5K)", value: "500_1500" },
  { label: "Very Low (0 to 500)", value: "0_500" },
];

export const IPODATE = [
  { label: "Any", value: `${IPODate("any")}` },
  { label: "Today", value: `${IPODate("today")}` },
  { label: "Yesterday", value: `${IPODate("yesterday")}` },
  { label: "This Week", value: `${IPODate("week")}` },
  { label: "This Month", value: `${IPODate("month")}` },
  { label: " This Year", value: `${IPODate("year")}` },
  { label: "More than a year ago", value: `${IPODate("morethanayear")}` },
  { label: "More than 5 year", value: `${IPODate("morethan5year")}` },
  { label: "More than 10 year", value: `${IPODate("morethan10year")}` },
  { label: "More than 25 year", value: `${IPODate("morethan25year")}` },
];

//Capital Structure

export const BOOKVALUE_PERSHARE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_500" },
];

export const CAPEX_PERSHARE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
];

export const CAPEX_TO_DEPRECIATION = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
];

export const CAPEX_TO_OPERATING_CASHFLOW = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
];

export const CAPEX_TO_REVENUE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
];

export const CAPITAL_EXPENDITURE_COVERAGERATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const EFFECTIVE_TAXRATE = [
  { label: "Any", value: "0_246" },
  { label: "Above 30%", value: "0_30" },
  { label: "20% to 30%", value: "20_30" },
  { label: "10% to 20%", value: "10_20" },
  { label: "Below 10%", value: "10_246" },
];

export const ENTER_PRISEVALUE = [
  { label: "Any", value: "0_171720340937299" },
  { label: "Very High", value: "170000000000_171720340937299" },
  { label: "High", value: "35000000000_170000000000" },
  { label: "Medium", value: "6000000000_35000000000" },
  { label: "Low", value: "1800000000_6000000000" },
  { label: "Very Low", value: "0_1800000000" },
  { label: "Negative", value: "0_-59386576646030" },
];

export const INTANGIBLES_TO_TOTALASSETS = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const INVESTED_CAPITAL = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const NET_CURRENT_ASSETVALUE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const REVENUE_PERSHARE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const STOCKBASED_COMPENSATION_TO_REVENUE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const TANGIBLE_ASSETVALUE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const TANGIBLE_BOOKVALUE_PERSHARE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const WORKING_CAPITAL = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

//Pricing

export const ENTERPRISE_VALUEMULTIPLE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const EV_TOFREECASHFLOW = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const EVTOOPERATINGCASHFLOW = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const EVTOSALES = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const GRAHAMNUMBER = [
  { label: "Any", value: "0_10000000" },
  { label: "Under $1", value: "0_1" },
  { label: "$1 to $5", value: "1_5" },
  { label: "$5 to $10", value: "5_10" },
  { label: "$10 to $20", value: "10_20" },
  { label: "$20 to $50", value: "20_50" },
  { label: "$50 to $100", value: "50_100" },
  { label: "$100 to $500", value: "100_500" },
  { label: "Above $500", value: "500_10000000" },
];

export const PRICEEARNINGSRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const PRICEEARNINGSTOGROWTHRATION = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const PRICETOBOOKRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const PRICETOFREECASEFLOWSRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const PRICETOOPERATINGCASHFLOWSRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const PRICETOSALESRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

//Efficiencv Ratios

export const ASSETTURNOVER = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const FIXEDASSETTURNOVER = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const INVENTORYTURNOVER = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const PAYABLESTURNOVER = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const RECEIVABLESTURNOVER = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

//Returns

export const RETURNONASSETS = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const RETURNONCAPITALEMPLOYED = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const RETURNONEQUITY = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const RETURNONTANGIBLEASSETS = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

//Margins

export const EBTPEREBIT = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const GROSSPROFITMARGING = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const NETINCOMEPEREBT = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const NETPROFITMARGIN = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const OPERATINGPROFITMARGIN = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const PRETAXPROFITMARGIN = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const RESEARCHANDDDEVELOPEMENTTOREVENUE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const SALESGENERALANDADMINISTRATIVETOREVENUE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

//Leverage Ratios

export const CASEFLOWCOVERAGERATIOS = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const DEBTEQUITYRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const DEBTTOASSETS = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const INTERESTCOVERAGE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const INTERESTDEBTPERSHARE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const LONGTERMDEBTTOCAPITALIZATION = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const NETDEBTTOEBITDA = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const SHORTTERMCOVERAGERATIOS = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const TOTALDEBTTOCAPITALIZATION = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

// Liquidity Ratios

export const CASHCONVERSION_CYCLE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const CASHPERSHARE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const CASHRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];
export const CURRENTRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const DAYS_OF_INVENTORY_OUTSTANDING = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];
export const DAYS_OF_PAY_ABLES_OUTSTANDING = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const DAYS_OF_SALES_OUTSTANDING = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const OPERATING_CYCLE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const QUICKRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

// Earnings & Dividends

export const DIVIDEND_PAID_AND_CAPEX_COVERAGERATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const DIVIDEND_PAYOUT_RATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const DIVIDEND_YIEND = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];
export const EARNINGS_YIELD = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const NET_INCOMEPERSHARE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

// Cash Flow Ratios

export const FREE_CASHFLLOW_OPERATINGCASH_FLOWRATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const FREECASH_FLOWPERSHARE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const FREE_CASH_FLOWYIELD = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const INCOME_QUALITY = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const OPERATINGCASH_FLOWPERSHARE = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

export const OPERATING_CASH_FLOW_SALES_RATIO = [
  { label: "Any", value: "0_2300000" },
  { label: "Very High", value: "16000_2300000" },
  { label: "High", value: "4000_16000" },
  { label: "Medium", value: "1500_4000" },
  { label: "Low", value: "500_1500" },
  { label: "Very Low", value: "0_500" },
  { label: "Negative", value: "0_2300000" },
];

//type => (1 => range), (2 => dropdown)

export const BASIC_COMPANY_FACTS = [
  {
    label: "Price",
    value: "price",
    type: 2,
    tooltip:
      "A share price is the price of a single share of a number of saleable equity shares of a company.",
      isLine: true,
  },
  {
    label: "Beta",
    value: "beta",
    type: 2,
    tooltip:
      "A stock's anticipated movement in relation to changes in the entire market is measured by the concept of beta. A stock with a beta larger than 1.0 is thought to be more volatile than the overall market, whereas one with a beta below 1.0 is thought to be less volatile.",
      isLine: true,
 
  },
  {
    label: "Volume Average",
    value: "volavg",
    type: 2,
    tooltip:
      "It's nothing but the average number of shares traded within a day in a given stock.",
      isLine: true,
    
  },
  {
    label: "Market Cap",
    value: "MktCap",
    type: 2,
    tooltip:
      "Market cap—or market capitalization—refers to the total value of all a company's shares of stock.",
      isLine: true,
 
  },
  {
    label: "Last Div",
    value: "LastDiv",
    type: 2,
    tooltip: "It's the dividend paid in the last quarter.",
      isLine: true,
    
  },
  // {
  //   label: "Changes",
  //   value: "Changes",
  //   type: 2,
  //   tooltip: "It's the one day percentage change in the stock price.",
  // },
  {
    label: "Exchange",
    value: "exchangeShortName",
    type: 2,
    tooltip:
      "A stock exchange is a marketplace, where financial securities issued by companies are bought and sold.",
      isLine: true,
  
  },
  {
    label: "Sector",
    value: "sector",
    type: 2,
    tooltip:
      "In order to make it simple to compare businesses with comparable business concepts, we group stocks into sectors. You can choose companies in the sectors that interest you when investing.",
      isLine: true,
 
  },
  {
    label: "Industry",
    value: "industry",
    type: 2,
    tooltip:
      "The definition of a 'industry' is more specific than sector and refers to a group of businesses that engage in comparable commercial activities.",
      isLine: true,
  
  },
  {
    label: "Country",
    value: "country",
    type: 2,
    tooltip: "It's the country where the company is incorporated.",
      isLine: true,
  
  },
  {
    label: "Full Time Employees",
    value: "fullTimeEmployees",
    type: 2,
    tooltip:
      "It's the total number of employees that a company has at present.",
      isLine: true,
  
  },
  {
    label: "IPO Date",
    value: "ipoDate",
    type: 2,
    tooltip:
      "An initial public offering (IPO) is the event when a privately held organization initially offers stock shares in the company on a public stock exchange.",
      isLine: true,
  
  },
  {
    label: "Currency",
    value: "currency",
    type: 2,
    tooltip: "Get Data From this currency",
      isLine: true,
  
  },
];

export const CAPITAL_STRUCTURE_COLUMNS = [
  {
    value: "bookValuePerShareTTM",
    key: "bookValuePerShareTTM",
    label: "Book Value Per Share",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Book Value Per Share (BVPS) is a financial ratio that indicates a per share estimation of the book value of a company. It is calculated by dividing a company's total book value by its number of shares outstanding.</p>
    <p><strong>Formula:</strong> Book Value per Share = (Total Shareholders' Equity - Preferred Stock) / Number of Common Shares Outstanding</p>
    <p><strong>Relevance to Investor:</strong> BVPS is a valuable measure for investors as it provides an insight into the company's intrinsic value, independent of its stock price. It offers a clear perspective of what a company’s shares would be worth if it were to liquidate all its assets and pay off all its liabilities today.</p>
    <p><strong>Investor Application:</strong> Investors use BVPS to assess whether a company's shares are overpriced or underpriced. If the BVPS is higher than the stock's current market price, it could indicate that the stock is undervalued and may be a good buying opportunity. Conversely, if BVPS is lower than the market price, the stock may be overpriced.</p>
    </>,
  },
  {
    value: "capexPerShareTTM",
    key: "capexPerShareTTM",
    label: "Capex Per Share",
    type: "CURRENCY",
    tooltip:  <><p><strong>Definition:</strong> Capex Per Share is a financial ratio that measures a company's capital expenditures (Capex) in relation to its number of outstanding shares. It's calculated by dividing a company's total capital expenditures by its number of shares outstanding.</p>
    <p><strong>Formula:</strong> Capex Per Share = Total Capital Expenditure / Number of Shares Outstanding </p>
    <p><strong>Relevance to Investor:</strong> This ratio provides an indication of how much a company is investing in its future growth. A higher Capex Per Share may suggest that the company is actively investing in new projects or infrastructure, which could lead to increased revenue in the future.</p>
    <p><strong>Investor Application:</strong> Investors can use Capex Per Share to compare a company's investment level to its competitors. If a company's Capex Per Share is significantly higher, it may suggest that the company is positioning itself for future growth. However, investors should also consider other factors, such as the company's profitability and debt levels, as high capital expenditure could also lead to increased financial risk if the investments do not yield expected returns.</p></>,
  },
  {
    value: "capexToDepreciationTTM",
    key: "capexToDepreciationTTM",
    label: "Capex To Depreciation",
    tooltip: <><p><strong>Definition:</strong> Capex to Depreciation is a ratio that measures a company's investment in its property, plant, and equipment (PPE) relative to the depreciation of its existing assets. It's calculated by dividing a company's capital expenditures (Capex) by its depreciation expense.</p>
    <p><strong>Formula:</strong> Capex to Depreciation = Total Capital Expenditure / Total Depreciation </p>
    <p><strong>Relevance to Investor:</strong> This ratio indicates whether a company is investing enough in its business to maintain its operating capacity. A ratio greater than one indicates that the company is investing more than it's depreciating, which could mean it's expanding. </p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to evaluate a company's growth strategy. A company with a high Capex to Depreciation ratio might be aggressively investing for growth, while a company with a low ratio may be focusing more on maintaining its current operations. </p>
    </>,
    type: "CURRENCY",
  },
  {
    value: "capexToOperatingCashFlowTTM",
    key: "capexToOperatingCashFlowTTM",
    label: "Capex To Operating Cash Flow",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Capex to Operating Cash Flow ratio measures the amount of a company's operating cash flow that is being spent on capital expenditures (Capex). It's calculated by dividing the company's capital expenditures by its operating cash flow.</p>
    <p><strong>Formula:</strong> Capex to Operating Cash Flow = Total Capital Expenditure / Operating Cash Flow</p>
    <p><strong>Relevance to Investor:</strong> This ratio is useful for investors as it provides insights into how much of the company's cash flow from operations is being reinvested back into the business. A high ratio may suggest that a significant portion of the company's cash flow is being used for growth and expansion.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to evaluate a company's growth strategy. If the Capex to Operating Cash Flow ratio is high, it suggests that the company is heavily investing in its business, which could potentially lead to higher earnings in the future. However, if this investment is not translating into higher profits, it could indicate inefficiency or overinvestment.</p>
    </>,
  },
  {
    value: "capexToRevenueTTM",
    key: "capexToRevenueTTM",
    label: "Capex To Revenue",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Capex to Revenue is a ratio that measures the proportion of a company's revenue that is spent on capital expenditures (Capex). It is calculated by dividing a company's capital expenditures by its total revenue. </p>
    <p><strong>Formula:</strong> Capex to Revenue = Total Capital Expenditure / Total Revenue </p>
    <p><strong>Relevance to Investor:</strong> This ratio provides an indication of a company's strategic focus. A high Capex to Revenue ratio might indicate a company investing heavily in future growth, whereas a low ratio might suggest a company prioritizing profitability over expansion.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to evaluate a company's growth strategy. A high Capex to Revenue ratio might suggest that a company is investing heavily in its future, which could potentially lead to greater returns in the long run. However, a high ratio also entails higher risk, as it shows a significant portion of the company's revenue is being spent on investments that may or may not yield future profits.</p>
    </>,
  },
  {
    value: "capitalExpenditureCoverageRatioTTM",
    key: "capitalExpenditureCoverageRatioTTM",
    label: "Capital Expenditure Coverage Ratio",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Capital Expenditure Coverage Ratio is a measure of a company's ability to fund its capital expenditures from its operating cash flow. It's calculated by dividing a company's operating cash flow by its capital expenditures (Capex). </p>
    <p><strong>Formula:</strong> Capital Expenditure Coverage Ratio = Operating Cash Flow / Total Capital Expenditure </p>
    <p><strong>Relevance to Investor:</strong> This ratio is crucial for investors as it gives an indication of a company's financial health and sustainability. A high ratio implies that the company can comfortably finance its Capex from its operating cash flow, which is a positive sign for investors. </p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to evaluate a company's financial stability. A high Capital Expenditure Coverage Ratio suggests that the company is generating enough cash to fund its investments, implying a lower financial risk. Conversely, a low ratio could signal financial strain, as the company may have to resort to borrowing to fund its Capex. </p>
    </>,
  },
  {
    value: "effectiveTaxRateTTM",
    key: "effectiveTaxRateTTM",
    label: "Effective Tax Rate",
    type: "PERCENTAGE",
    tooltip: <><p><strong>Definition:</strong> The Effective Tax Rate is a measure of a company's total taxes paid as a percentage of its taxable income. It's calculated by dividing the company's income tax expenses by its earnings before tax. </p>
    <p><strong>Fomula:</strong> Effective Tax Rate = Income Tax Expenses / Earnings Before Tax</p>
    <p><strong>Relevance to Investor:</strong> Understanding a company's Effective Tax Rate helps investors anticipate potential changes in a company's after-tax profits due to changes in tax laws or tax planning strategies. </p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to factor in the impact of taxes on a company's profitability. Companies with high Effective Tax Rates may have lower net income margins compared to companies with lower Effective Tax Rates, all other things being equal. Additionally, significant changes in the Effective Tax Rate over time can indicate changes in a company's tax planning strategies or changes in tax laws.</p>
    </>,
  },
  {
    value: "enterpriseValueTTM",
    key: "enterpriseValueTTM",
    label: "Enterprise Value",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Enterprise Value (EV) is a measure of a company's total value, including not just its equity but also its debt and excluding its cash and cash equivalents. It's calculated by adding a company's market capitalization, debt, and minority interest, and subtracting its cash and cash equivalents. </p>
    <p><strong>Formula:</strong> Enterprise Value = Market Capitalization + Debt + Minority Interest - Cash and Cash Equivalents </p>
    <p><strong>Relevance to Investor:</strong> EV provides a more comprehensive picture of a company's worth than market capitalization alone, as it considers both the company's debt and cash. This is particularly relevant for investors when comparing companies with different capital structures. </p>
    <p><strong>Investor Application:</strong> Investors can use EV to evaluate a company's total value and compare it with others in the same industry. A company with a lower EV compared to others might be undervalued, which could represent a potential investment opportunity. However, investors should also consider other financial metrics and factors about the company and its industry.</p>
    </>,
  },
  {
    value: "intangiblesToTotalAssetsTTM",
    key: "intangiblesToTotalAssetsTTM",
    label: "Intangibles To Total Assets",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Intangibles to Total Assets ratio measures the proportion of a company's assets that are made up of intangible assets. It's calculated by dividing the company's total intangible assets by its total assets.</p>
    <p><strong>Formula:</strong> Intangibles to Total Assets = Total Intangible Assets / Total Assets</p>
    <p><strong>Relevance to Investor:</strong> This ratio is useful for investors as it provides insights into a company's asset composition. Companies with a high proportion of intangible assets may have a different risk profile compared to companies with more tangible assets, as intangible assets can be harder to value and liquidate.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to assess the nature of a company's assets. Companies with a high ratio of intangibles to total assets might be heavily reliant on things like brand reputation, patents, or intellectual property. Depending on the industry, this could be either a strength or a vulnerability.</p></>,
  },
  {
    value: "investedCapitalTTM",
    key: "investedCapitalTTM",
    label: "Invested Capital",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Invested Capital represents the total amount of money that has been invested into a company. It's calculated as the sum of a company's equity, long-term debt, and short-term debt, minus its cash and cash equivalents.</p>
    <p><strong>Formula:</strong> Invested Capital = Equity + Long-term Debt + Short-term Debt - Cash and Cash Equivalents</p>
    <p><strong>Relevance to Investor:</strong> Invested Capital provides a measure of the total capital invested in a company, which is useful for understanding a company's financial structure and for comparing companies within the same industry.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to gauge a company's size, risk profile, and growth potential. A company with a high level of invested capital might have more resources to grow and generate profits, but it might also be taking on more risk, especially if a large portion of the invested capital is financed by debt.</p>
    </>,
  },
  
  {
    value: "netCurrentAssetValueTTM",
    key: "netCurrentAssetValueTTM",
    label: "Net Current Asset Value",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Net Current Asset Value (NCAV) is a measure of a company's current assets minus its total liabilities. It's a conservative valuation metric that excludes a company's long-term assets.</p>
    <p><strong>Formula:</strong> NCAV = Current Assets - Total Liabilities</p>
    <p><strong>Relevance to Investor:</strong> NCAV provides insight into a company's liquidity and short-term financial health. A positive NCAV implies that a company can cover all of its liabilities with its current assets alone, without relying on future earnings or long-term assets.</p>
<p><strong>Investor Application:</strong> Investors can use NCAV to evaluate a company's liquidity. Companies with a high NCAV are generally considered financially healthy and less risky, as they have a safety net of assets to cover their liabilities. Conversely, a low or negative NCAV might be a warning sign of financial distress.</p>
</>,
  },
  {
    value: "revenuePerShareTTM",
    key: "revenuePerShareTTM",
    label: "Revenue Per Share",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Revenue Per Share (RPS) is a financial ratio that measures the amount of a company's revenue earned per share of its stock.</p>
    <p><strong>Formula:</strong> RPS = Total Revenue / Number of Shares Outstanding</p>
    <p><strong>Relevance to Investor:</strong> RPS provides a per-share measure of a company's revenue, which can be useful for comparing companies of different sizes or for tracking a company's revenue growth over time on a per-share basis.</p>
    <p><strong>Investor Application:</strong> Investors can use RPS to evaluate a company's revenue performance relative to its stock. A higher RPS generally indicates a more profitable company, all other things being equal. It can be especially useful for comparing companies within the same industry.</p>
    </>,
  },
  {
    value: "stockBasedCompensationToRevenueTTM",
    key: "stockBasedCompensationToRevenueTTM",
    label: "Stock Based Compensation To Revenue",
    tooltip: <><p><strong>Definition:</strong> Stock Based Compensation to Revenue ratio measures the proportion of a company's revenue that is given to employees as stock-based compensation. This ratio helps to assess the extent to which a company is using stock-based compensation to attract and retain employees.</p>
    <p><strong>Formula:</strong> Stock Based Compensation to Revenue = Stock Based Compensation / Total Revenue</p>
    <p><strong>Relevance to Investor:</strong> This ratio is important to investors as it indicates how much of a company's revenue is being returned to employees in the form of stock compensation. A higher ratio may indicate that the company is heavily reliant on equity to compensate its employees, which could lead to stock dilution.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to assess a company's compensation strategy and potential for stock dilution. If a company has a high Stock Based Compensation to Revenue ratio, it could potentially be a warning sign of dilution of the ownership interest of existing shareholders.</p>
    </>,
    type: "CURRENCY",
  },
  {
    value: "tangibleAssetValueTTM",
    key: "tangibleAssetValueTTM",
    label: "Tangible Asset Value",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Tangible Asset Value (TAV) is the value of a company's physical assets, such as buildings, machinery, and inventory. It's calculated by subtracting a company's intangible assets and liabilities from its total assets.</p>
    <p><strong>Formula:</strong> TAV = Total Assets - Intangible Assets - Liabilities</p>
    <p><strong>Relevance to Investor:</strong> TAV is important to investors because it provides insight into the value of a company's physical assets. Companies with high TAV are typically asset-intensive and may be more resilient during financial downturns.</p>
    <p><strong>Investor Application:</strong> Investors can use TAV to assess a company's financial stability. Companies with a high TAV may be seen as less risky, since they have physical assets that can be sold if the company runs into financial difficulties. However, these companies may also require significant capital expenditures to maintain their assets.</p>
    </>,
  },
  {
    value: "tangibleBookValuePerShareTTM",
    key: "tangibleBookValuePerShareTTM",
    label: "Tangible Book Value Per Share",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Tangible Book Value Per Share (TBVPS) is a per-share measure of a company's tangible assets, i.e., physical assets such as buildings, equipment, and inventory, minus all its liabilities.</p>
    <p><strong>Formula:</strong> TBVPS = (Total Assets - Intangible Assets - Total Liabilities) / Number of Shares Outstanding</p>
    <p><strong>Relevance to Investor:</strong> TBVPS provides insight into what shareholders could theoretically expect to receive per share if the company were to be liquidated. Companies with a higher TBVPS might be considered undervalued, representing potential investment opportunities.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to assess the per-share value of a company's tangible assets. If the market price per share is significantly below the TBVPS, it may suggest the stock is undervalued. Conversely, a higher market price may indicate an overvalued stock.</p>
    </>,
  },
  {
    value: "workingCapitalTTM",
    key: "workingCapitalTTM",
    label: "Working Capital",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Working Capital is a measure of a company's operational liquidity and short-term financial health. It's calculated as the difference between a company's current assets and current liabilities.</p>
    <p><strong>Formula:</strong> Working Capital = Current Assets - Current Liabilities</p>
    <p><strong>Relevance to Investor:</strong> Working Capital provides insight into a company's short-term financial health and its ability to cover its short-term liabilities with its short-term assets. A positive working capital indicates that a company can pay off its short-term liabilities with its short-term assets.</p>
    <p><strong>Investor Application:</strong> Investors can use Working Capital to gauge a company's liquidity and operational efficiency. A company with high working capital is generally considered to have good short-term financial health, but a consistently high working capital might indicate that the company is not using its assets efficiently.</p>
    </>,
  },
];

export const PRICING_COLUMNS = [
  {
    value: "enterpriseValueMultipleTTM",
    key: "enterpriseValueMultipleTTM",
    label: "Enterprise Value Multiple",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Enterprise Value Multiple, also known as the EV multiple, is a ratio that compares a company's enterprise value to its earnings before interest, taxes, depreciation, and amortization (EBITDA).</p>
    <p><strong>Formula:</strong> Enterprise Value Multiple = Enterprise Value / EBITDA</p>
    <p><strong>Relevance to Investor:</strong> The EV Multiple provides a way to compare the value of companies regardless of their capital structure. A lower EV Multiple might suggest that a company is undervalued relative to its earnings.</p>
    <p><strong>Investor Application:</strong> Investors can use the EV Multiple to compare companies within the same industry. It can help identify companies that may be undervalued or overvalued based on their earnings.</p>
    </>,
  },
  {
    value: "evToFreeCashFlowTTM",
    key: "evToFreeCashFlowTTM",
    label: "EV To Free Cash Flow",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Enterprise Value to Free Cash Flow (EV/FCF) ratio compares a company's enterprise value to its free cash flow.</p>
    <p><strong>Formula:</strong> EV/FCF = Enterprise Value / Free Cash Flow </p>
    <p><strong>Relevance to Investor:</strong> The EV/FCF ratio provides insight into the relationship between a company's value and its ability to generate cash. A lower ratio could suggest that the company is undervalued relative to its cash-generating ability. </p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to assess a company's valuation and compare it with others in the same industry. A company with a lower EV/FCF ratio may be more attractive as it suggests more value for each dollar of cash generated.</p>
    </>,
  },
  {
    value: "evToOperatingCashFlowTTM",
    key: "evToOperatingCashFlowTTM",
    type: "CURRENCY",
    label: "EV To Operating Cash Flow",
    tooltip: <><p><strong>Definition:</strong> The Enterprise Value to Operating Cash Flow (EV/OCF) ratio is a valuation measure that compares a company's total enterprise value to its operating cash flow.</p>
    <p><strong>Formula:</strong> EV/OCF = Enterprise Value / Operating Cash Flow </p>
    <p><strong>Relevance to Investor:</strong> The EV/OCF ratio provides investors with a measure of a company's value relative to the cash it generates from its operations. A lower ratio could indicate that a company is undervalued relative to its cash flow.</p>
    <p><strong>Investor Application:</strong> Investors can use the EV/OCF ratio to assess the relative value of different companies. Companies with a lower EV/OCF ratio may be more attractive because they generate more cash flow relative to their enterprise value.</p>
    </>,
  },
  {
    value: "evToSalesTTM",
    key: "evToSalesTTM",
    label: "EV To Sales",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Enterprise Value to Sales (EV/Sales) ratio is a valuation measure that compares a company's enterprise value to its revenue.</p>
    <p><strong>Formula:</strong> EV/Sales = Enterprise Value / Total Revenue</p>
    <p><strong>Relevance to Investor:</strong> The EV/Sales ratio provides investors with a measure of a company's value relative to its sales. A lower ratio might suggest that a company is undervalued.</p>
    <p><strong>Investor Application:</strong> Investors can use the EV/Sales ratio to evaluate a company's value relative to its sales. This can be particularly useful when comparing companies in the same industry.</p>
    </>,
  },
  {
    value: "grahamNumberTTM",
    key: "grahamNumberTTM",
    label: "Graham Number",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Graham Number is a figure that measures a stock's fundamental value by taking into account earnings per share and book value per share. It's named after Benjamin Graham, the father of value investing.</p>
    <p><strong>Formula:</strong> Graham Number = Square Root of (22.5 * Earnings Per Share * Book Value Per Share)</p>
    <p><strong>Relevance to Investor:</strong> The Graham Number is relevant to investors as it provides a simplified method for identifying potentially undervalued stocks. Stocks trading at a price lower than their Graham Number may be considered undervalued.</p>
    <p><strong>Investor Application:</strong> Investors can use the Graham Number as part of a fundamental analysis strategy to find stocks that may be undervalued. It should be used in conjunction with other metrics and analysis methods to make comprehensive investment decisions.</p>
    </>,
  },
  {
    value: "priceEarningsRatioTTM",
    key: "priceEarningsRatioTTM",
    label: "Price Earnings Ratio",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Price to Earnings (P/E) Ratio is a valuation ratio of a company's current share price compared to its per-share earnings.</p>
    <p><strong>Formula:</strong> P/E Ratio = Market Value Per Share / Earnings Per Share (EPS)</p>
    <p><strong>Relevance to Investor:</strong> The P/E ratio is one of the most widely used ratios in investing because it provides a simple way to compare the price of a stock to its earnings. A high P/E ratio could mean that a stock's price is high relative to earnings and possibly overvalued, whereas a low P/E might indicate the opposite.</p>
    <p><strong>Investor Application:</strong> Investors often use the P/E ratio to determine the relative value of a company's shares in an apples-to-apples comparison. It can also be used to compare a company against its own historical record or to compare aggregate markets against one another or over time.</p>
    </>,
  },
  {
    value: "priceEarningsToGrowthRatioTTM",
    key: "priceEarningsToGrowthRatioTTM",
    label: "Price Earnings To Growth Ratio",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Price Earnings to Growth (PEG) ratio is a valuation metric that enhances the P/E ratio by including expected growth in earnings.</p>
    <p><strong>Formula:</strong> PEG Ratio = P/E Ratio / Annual EPS Growth</p>
    <p><strong>Relevance to Investor:</strong> The PEG ratio can help investors understand whether the stock's P/E ratio is justified by its earnings growth rate. A lower PEG ratio might indicate that the stock is undervalued, given its earnings performance.</p>
    <p><strong>Investor Application:</strong> Investors can use the PEG ratio to compare the relative trade-off between the price of a stock, the earnings generated per share (EPS), and the company's expected growth. In general, the lower the PEG ratio, the better the value, because the investor would be paying less for each unit of earnings growth.</p>
    </>,
  },
  {
    value: "priceToBookRatioTTM",
    key: "priceToBookRatioTTM",
    label: "Price To Book Ratio",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Price to Book (P/B) ratio compares a company's market value to its book value.</p>
    <p><strong>Formula:</strong> P/B Ratio = Market Price Per Share / Book Value Per Share</p>
    <p><strong>Relevance to Investor:</strong> The P/B ratio shows the value the market places on the company relative to its actual worth (book value). A lower P/B ratio could indicate that the stock is undervalued, while a higher P/B might suggest that the stock is overvalued.</p>
    <p><strong>Investor Application:</strong> Investors can use the P/B ratio to find undervalued stocks. A low P/B ratio might indicate a potentially undervalued stock. However, investors should consider other financial metrics and factors before making investment decisions.</p></>,
  },
  {
    value: "priceToFreeCashFlowsRatioTTM",
    key: "priceToFreeCashFlowsRatioTTM",
    label: "Price To Free Cash Flows Ratio",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Price to Free Cash Flow (P/FCF) ratio measures the market's expectation of a company's financial health and profitability in the future. It compares a company's market price to its level of free cash flow.</p>
    <p><strong>Formula:</strong> P/FCF Ratio = Market Price Per Share / Free Cash Flow Per Share</p>
    <p><strong>Relevance to Investor:</strong> This ratio indicates how much cash a company is able to generate after spending the money required to maintain or expand its asset base. A lower P/FCF may suggest that a company is undervalued and its stock is relatively cheap.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to identify companies that are generating more cash than is reflected in their market price. This could provide an investment opportunity if other financial indicators and factors align.</p>
    </>,
  },
  {
    value: "priceToOperatingCashFlowsRatioTTM",
    key: "priceToOperatingCashFlowsRatioTTM",
    label: "Price To Operating Cash Flows Ratio",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Price to Operating Cash Flow (P/OCF) ratio is a valuation measure comparing a company's market price to its operating cash flow per share.</p>
    <p><strong>Formula:</strong> P/OCF Ratio = Market Price Per Share / Operating Cash Flow Per Share</p>
    <p><strong>Relevance to Investor:</strong> The P/OCF ratio provides insight into a company's financial health by indicating how well its cash flow is covering its share price. A lower P/OCF could suggest that a company is undervalued.</p>
    <p><strong>Investor Application:</strong> Investors can use the P/OCF ratio to assess the relative value of different companies. Companies with a lower P/OCF may be more attractive because they generate more cash flow relative to their market price.</p>
    </>,
  },
  {
    value: "priceToSalesRatioTTM_x",
    key: "priceToSalesRatioTTM_x",
    label: "Price To Sales Ratio",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> The Price to Sales (P/S) ratio compares a company's stock price to its revenues, effectively measuring the value placed on each dollar of a company's sales or revenues.</p>
    <p><strong>Formula:</strong> P/S Ratio = Market Cap / Total Revenue</p>
    <p><strong>Relevance to Investor:</strong> The P/S ratio provides a useful measure for comparing current stock prices to historical sales per share figures or to the sales figures of other companies. A low P/S ratio could suggest that the stock is undervalued, or it might be an indication of a fundamental issue with the company.</p>
<p><strong>Investor Application:</strong> Investors can use the P/S ratio as part of a broader analysis of a company's financial health and performance. A lower P/S ratio could potentially indicate an investment opportunity, but this ratio should be compared with other financial metrics to make a comprehensive investment decision.</p>
</>,
  },
];

export const EFFICIENCY_RATIO_COLUMNS = [
  {
    value: "assetTurnoverTTM",
    key: "assetTurnoverTTM",
    label: "Asset Turnover",
    tooltip: <>
<p><strong>Definition:</strong> Asset Turnover is a financial ratio that measures a company's ability to generate sales from its assets by comparing net sales with average total assets.</p>
<p><strong>Formula:</strong> Asset Turnover = Net Sales / Average Total Assets</p>
<p><strong>Relevance to Investor:</strong> Asset Turnover helps investors understand how effectively a company is using its assets to generate sales. A higher asset turnover ratio is generally better, indicating that the company is more efficiently using its assets.</p>
<p><strong>Investor Application:</strong> Investors can use the asset turnover ratio to compare the operational efficiency of different companies in the same industry. If a company has a higher ratio than its peers, it may indicate a competitive advantage in managing its assets.</p></>,
    type: "CURRENCY",
  },
  {
    value: "fixedAssetTurnoverTTM",
    key: "fixedAssetTurnoverTTM",
    label: "Fixed Asset Turnover",
    tooltip: <>
<p><strong>Definition:</strong> The Fixed Asset Turnover ratio measures a company's ability to generate net sales from fixed-asset investments, specifically property, plant, and equipment (PP&E).</p>
<p><strong>Formula:</strong> Fixed Asset Turnover = Net Sales / Average Net Fixed Assets </p>
<p><strong>Relevance to Investor:</strong> The Fixed Asset Turnover ratio is useful for investors to understand how efficiently a company uses its fixed assets to generate sales. A higher ratio can indicate that a company has been more effective in using investments in fixed assets to generate revenues.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to evaluate a company's operational efficiency in managing its fixed assets. A company with a high Fixed Asset Turnover ratio may be a more efficient operator in its industry.</p></>,
    type: "CURRENCY",
  },
  {
    value: "inventoryTurnoverTTM_x",
    key: "inventoryTurnoverTTM_x",
    label: "Inventory Turnover",
    tooltip: <>
<p><strong>Definition:</strong> The Inventory Turnover ratio is a measure of the number of times inventory is sold or used in a time period.</p>
<p><strong>Formula:</strong> Inventory Turnover = Cost of Goods Sold / Average Inventory</p>
<p><strong>Relevance to Investor:</strong> This ratio is important to investors as it shows how quickly a company is able to sell its inventory. A higher inventory turnover ratio indicates efficient management of inventory because more sales are being generated from each dollar of inventory.</p>
<p><strong>Investor Application:</strong> Investors can use the Inventory Turnover ratio to compare companies in the same industry. A company with a high Inventory Turnover ratio typically has a shorter shelf-life for its products, which may suggest higher sales and earnings potential.</p></>,
  },
  {
    value: "payablesTurnoverTTM_x",
    key: "payablesTurnoverTTM_x",
    label: "Payables Turnover",
    tooltip: <>
<p><strong>Definition:</strong> The Payables Turnover ratio is a short-term liquidity metric that quantifies the rate at which a company pays off its suppliers. It measures how many times a company pays its creditors over a certain period.</p>
<p><strong>Formula:</strong> Payables Turnover = Cost of Goods Sold / Average Accounts Payable</p>
<p><strong>Relevance to Investor:</strong> The Payables Turnover ratio shows how quickly a company pays off its suppliers. A lower ratio may suggest that a company takes longer to pay off its suppliers, which could signal cash flow problems.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to gauge the short-term liquidity of a company. When combined with the Inventory Turnover and Receivables Turnover ratios, investors can get a more comprehensive picture of a company's operating efficiency.
</p>
</>,
    type: "CURRENCY",
  },
  {
    value: "receivablesTurnoverTTM_x",
    key: "receivablesTurnoverTTM_x",
    label: "Receivables Turnover",
    tooltip: <>
<p><strong>Definition:</strong> The Receivables Turnover ratio is an accounting measure used to quantify a firm's effectiveness in extending credit and collecting debts.</p>
<p><strong>Formula:</strong> Receivables Turnover = Net Credit Sales / Average Accounts Receivable</p>
<p><strong>Relevance to Investor:</strong> This ratio indicates how efficiently a company manages its accounts receivable and collects debt from its customers. A higher ratio implies a shorter time between sales and cash collection, indicating efficient credit and collection processes.</p>
<p><strong>Investor Application:</strong> Investors can use the Receivables Turnover ratio as part of their overall analysis of a company's liquidity, operational efficiency, and cash flow management. It is particularly useful in comparing companies within the same industry, as it can highlight the effectiveness of their credit policies and collection efforts.</p></>,
    type: "CURRENCY",
  },
];

export const RETURN_COLUMNS = [
  {
    value: "returnOnAssetsTTM",
    key: "returnOnAssetsTTM",
    label: "Return On Assets",
    type: "PERCENTAGE",
    tooltip: <><p><strong>Definition:</strong> Return on Assets (ROA) is a financial ratio that shows the percentage of profit a company earns in relation to its overall resources.</p>
<p><strong>Formula:</strong> ROA = Net Income / Total Assets</p>
<p><strong>Relevance to Investor:</strong> ROA indicates how effectively a company is converting the money it has to invest into net income. The higher the ROA, the better, because the company is earning more money on fewer assets.</p>
<p><strong>Investor Application:</strong> Investors use this ratio when comparing the profitability of different companies. A higher ROA might indicate a company is more efficient at using its assets to generate profit.</p></>,
  },
  {
    value: "returnOnCapitalEmployedTTM",
    key: "returnOnCapitalEmployedTTM",
    label: "Return On Capital Employed",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> Return on Capital Employed (ROCE) is a financial ratio that measures a company's profitability and the efficiency with which its capital is employed.</p>
<p><strong>Formula:</strong> ROCE = EBIT / Capital Employed (where Capital Employed = Total Assets - Current Liabilities)</p>
<p><strong>Relevance to Investor:</strong> ROCE indicates how well a company is using its capital to generate profits. A higher ROCE shows that the company is using its capital efficiently and making good investment decisions.</p>
<p><strong>Investor Application:</strong> Investors use this ratio to evaluate how effectively a company is using its available capital to generate profit. A company with a high ROCE is often a more attractive investment because it shows that the company is efficient at turning capital into profits.</p></>,
  },
  {
    value: "returnOnEquityTTM",
    key: "returnOnEquityTTM",
    label: "Return On Equity",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> Return on Equity (ROE) is a financial ratio that measures the profitability of a company in relation to the equity held by shareholders.</p>
<p><strong>Formula:</strong> ROE = Net Income / Shareholder's Equity</p>
<p><strong>Relevance to Investor:</strong> ROE indicates how effective a company is at generating profits from its shareholders' equity. A higher ROE means that the company is more efficient at generating profits relative to its shareholders' equity.</p>
<p><strong>Investor Application:</strong> Investors use the ROE ratio to compare the profitability of different companies in the same industry. A company with a high ROE is seen as more likely to generate cash internally and less likely to need outside financing.</p></>,
  },
  {
    value: "returnOnTangibleAssetsTTM",
    key: "returnOnTangibleAssetsTTM",
    label: "Return On Tangible Assets",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> Return on Tangible Assets (ROTA) is a financial ratio that shows how efficiently a company can generate a profit from its tangible assets.</p>
<p><strong>Formula:</strong> ROTA = Net Income / (Total Assets – Intangible Assets)</p>
<p><strong>Relevance to Investor:</strong> ROTA is used to understand how effectively a company is using its tangible assets (like plant, property, and equipment) to generate profit. A higher ROTA means the company is more efficient at generating profits from its tangible assets.</p>
<p><strong>Investor Application:</strong> Investors can use ROTA to compare the operational efficiency of different companies in the same industry. A company with a high ROTA can be seen as an efficient operator that is capable of turning its physical assets into profit.</p></>,
  },
];

export const MARGIN_COLUMNS = [
  {
    value: "ebtPerEbitTTM",
    key: "ebtPerEbitTTM",
    label: "EBT Per EBIT",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> EBT Per EBIT (Earnings Before Tax Per Earnings Before Interest and Taxes) is a ratio that measures a company's profitability before tax and interest expenses are taken into account.</p>
<p><strong>Formula:</strong> EBT Per EBIT = Earnings Before Tax / Earnings Before Interest and Taxes</p>
<p><strong>Relevance to Investor:</strong> This ratio provides insights into the impact of tax and interest expenses on a company's profitability. A higher ratio indicates that a smaller proportion of earnings is being absorbed by these expenses.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to assess the effect of taxes and interest expenses on a company's profits. If the ratio is high, the company is retaining a significant proportion of its earnings, which could translate into higher potential dividends or reinvestment opportunities.</p></>,
  },
  {
    value: "grossProfitMarginTTM",
    key: "grossProfitMarginTTM",
    label: "Gross Profit Margin",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> The Gross Profit Margin is a financial metric that shows the percentage of revenue that exceeds the cost of goods sold (COGS). It represents the proportion of each dollar of revenue that the company retains as gross profit.</p>
<p><strong>Formula:</strong> Gross Profit Margin = (Gross Profit / Revenue) * 100%</p>
<p><strong>Relevance to Investor:</strong> Gross Profit Margin is a key indicator of a company's financial health and business model efficiency. It shows how efficiently a company is using its resources to produce goods and services.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to compare companies in the same industry and to understand the efficiency of a company's operations. A high Gross Profit Margin indicates that a company is generating a significant amount of gross profit from every dollar of revenue, which can be used for other expenses, saving, or reinvestment.</p></>,
  },
  {
    value: "netIncomePerEBTTTM",
    key: "netIncomePerEBTTTM",
    label: "Net Income Per EBT",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> Net Income Per Earnings Before Tax (EBT) is a ratio that shows how much of a company's EBT is retained as net income.</p>
<p><strong>Formula:</strong> Net Income Per EBT = Net Income / Earnings Before Tax</p>
<p><strong>Relevance to Investor:</strong> This ratio shows the proportion of earnings before tax that a company is able to retain after accounting for all expenses and taxes. A higher ratio implies that the company retains a larger portion of its earnings, which could lead to higher dividends or potential reinvestment.</p>
<p><strong>Investor Application:</strong> This ratio helps investors understand the profitability of a company and how effectively it manages its expenses and tax obligations. It can be used to compare the financial health and efficiency of different companies within the same industry.</p></>,
  },
  {
    value: "netProfitMarginTTM",
    key: "netProfitMarginTTM",
    label: "Net Profit Margin",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> The Net Profit Margin is a financial metric that shows what percentage of each dollar earned by the company is translated into profits.</p>
<p><strong>Formula:</strong> Net Profit Margin = (Net Income / Revenue) * 100%</p>
<p><strong>Relevance to Investor:</strong> This ratio indicates the company's profitability after all expenses, including taxes and interest. A higher Net Profit Margin means that the company is more profitable and has better control over its costs relative to its competitors.</p>
<p><strong>Investor Application:</strong> Investors use the Net Profit Margin to identify how effectively a company converts revenue into profit. Companies with high Net Profit Margins are often good investment candidates as they are capable of generating a substantial portion of profit from their revenue.</p></>,
  },
  {
    value: "operatingProfitMarginTTM",
    key: "operatingProfitMarginTTM",
    label: "Operating Profit Margin",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> The Operating Profit Margin is a profitability ratio that measures what proportion of a company's revenue remains after paying for variable costs of production.</p>
<p><strong>Formula:</strong> Operating Profit Margin = (Operating Income / Revenue) * 100%</p>
<p><strong>Relevance to Investor:</strong> This ratio shows how much profit a company makes per dollar of sales before interest and taxes. A high Operating Profit Margin indicates that the company has lower fixed costs and a better gross profit, and can afford to invest in other areas of its business.</p>
<p><strong>Investor Application:</strong> Investors can use the Operating Profit Margin to compare the profitability of different companies in the same industry. A high Operating Profit Margin indicates that a company is managing its operating costs well and is more likely to pay dividends.</p></>,
  },
  {
    value: "pretaxProfitMarginTTM",
    key: "pretaxProfitMarginTTM",
    label: "Pretax Profit Margin",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> The Pretax Profit Margin is a profitability ratio that assesses a company's profitability before taking into account the tax expense.</p>
<p><strong>Formula:</strong> Pretax Profit Margin = (Pretax Profit / Revenue) * 100%</p>
<p><strong>Relevance to Investor:</strong> This ratio shows how much of a company's revenues are left over before taxes are accounted for. A higher Pretax Profit Margin indicates that the company is managing its operations efficiently and is better positioned to withstand economic downturns.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to compare the operational efficiency of different companies in the same industry. A higher Pretax Profit Margin could indicate a potentially more profitable investment opportunity.</p></>,
  },
  {
    value: "researchAndDevelopementToRevenueTTM",
    key: "researchAndDevelopementToRevenueTTM",
    label: "Research and Development to Revenue",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> The Research and Development to Revenue ratio is a measure of how much of a company's revenue is spent on research and development activities.</p>
<p><strong>Formula:</strong> Research and Development to Revenue = (Research and Development Expenses / Revenue) * 100%</p>
<p><strong>Relevance to Investor:</strong> This ratio indicates how much a company is investing in its future growth and innovation. For technology and pharmaceutical companies in particular, a higher ratio may be a positive sign as it could indicate that the company is investing in new products or services.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to understand how much a company is investing in research and development relative to its size. A higher ratio could indicate that a company is poised for growth and innovation in the future.</p></>,
  },
  {
    value: "salesGeneralAndAdministrativeToRevenueTTM",
    key: "salesGeneralAndAdministrativeToRevenueTTM",
    label: "Sales, General and Administrative to Revenue",
    type: "PERCENTAGE",
    tooltip: <>
<p><strong>Definition:</strong> The Sales, General and Administrative to Revenue ratio, often abbreviated as SG&A to Revenue, measures the percentage of a company's revenue spent on SG&A expenses.</p>
<p><strong>Formula:</strong> SG&A to Revenue = (SG&A Expenses / Revenue) * 100%</p>
<p><strong>Relevance to Investor:</strong> This ratio provides insight into a company's operational efficiency. A lower ratio indicates that a larger percentage of revenue is retained as profit.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to assess a company's operational efficiency. If the ratio is high, it could indicate that the company is not effectively managing its administrative expenses and sales costs, which could impact profitability.</p></>,
  },
];

export const LEVERAGE_RATIO_COLUMNS = [
  {
    value: "cashFlowCoverageRatiosTTM",
    key: "cashFlowCoverageRatiosTTM",
    label: "Cash Flow Coverage Ratios",
    tooltip: <><p><strong>Definition:</strong> The Cash Flow Coverage Ratio, also known as Cash Flow to Debt Ratio, measures a company's ability to cover its total debt with its cash flow from operations.</p>
    <p><strong>Formula:</strong> Cash Flow Coverage Ratio = Operating Cash Flow / Total Debt</p>
    <p><strong>Relevance to Investor:</strong> This ratio is important to investors because it shows a company's ability to pay off its debt using the cash it generates from its business operations. A higher ratio is generally more favorable as it indicates that the company is better positioned to fulfill its debt obligations.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to assess the financial risk associated with a company. If the ratio is low, it may indicate that the company could struggle to cover its debt obligations, which could lead to financial distress or even bankruptcy.</p>
    </>,
  },
  {
    value: "debtEquityRatioTTM",
    key: "debtEquityRatioTTM",
    label: "Debt Equity Ratio",
    tooltip: <><p><strong>Definition:</strong> The Debt to Equity Ratio is a financial ratio indicating the relative proportion of shareholders' equity and debt used to finance a company's assets.</p>
    <p><strong>Formula:</strong> Debt to Equity Ratio = Total Debt / Total Shareholders' Equity</p>
    <p><strong>Relevance to Investor:</strong> This ratio is important to investors as it provides insight into the company's financial leverage and risk. A high ratio suggests that the company has aggressively financed its growth with debt, which can lead to volatile earnings due to the additional interest expense.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to compare the capital structure of different companies in the same industry. A lower ratio usually implies a more financially stable company, reducing the risk of investment.</p>
    </>,
  },
  {
    value: "debtToAssetsTTM",
    key: "debtToAssetsTTM",
    label: "Debt To Assets",
    tooltip: <><p><strong>Definition:</strong> The Debt to Asset Ratio measures the proportion of a company's assets that are financed by debt.</p>
    <p><strong>Formula:</strong> Debt to Asset Ratio = Total Debt / Total Assets</p>
    <p><strong>Relevance to Investor:</strong> This ratio provides an indication of the company's leverage. A higher ratio means that more of the company's assets are financed by debt, which can be risky if the company runs into financial difficulty.</p>
    <p><strong>Investor Application:</strong> This ratio can help investors understand the financial risk of a company. If the ratio is high, it may indicate that the company has too much debt, which could lead to financial instability.</p>
    </>,
  },
  {
    value: "interestCoverageTTM_x",
    key: "interestCoverageTTM_x",
    label: "Interest Coverage",
    tooltip: <><p><strong>Definition:</strong> The Interest Coverage Ratio is a financial ratio that measures a company's ability to make interest payments on its debt in a timely manner.</p>
    <p><strong>Formula:</strong> Interest Coverage Ratio = EBIT / Interest Expense</p>
    <p><strong>Relevance to Investor:</strong> This ratio provides an indication of the company's debt service capacity. A higher ratio indicates that the company is better positioned to meet its interest obligations, which reduces the risk of financial distress.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to assess the risk associated with a company's debt. If the ratio is low, it may indicate that the company could struggle to cover its interest payments, which could lead to financial distress.</p>
    </>,
  },
  {
    value: "interestDebtPerShareTTM",
    key: "interestDebtPerShareTTM",
    label: "Interest Debt Per Share",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Interest Debt Per Share measures the amount of interest-bearing debt a company has for each outstanding share of common stock.</p>
    <p><strong>Formula:</strong> Interest Debt Per Share = Interest-Bearing Debt / Number of Outstanding Shares</p>
    <p><strong>Relevance to Investor:</strong> This ratio provides an indication of the company's leverage on a per-share basis. A higher ratio indicates a higher level of debt, which could be risky if the company runs into financial difficulty.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to understand the financial risk associated with a company on a per-share basis. If the ratio is high, it may indicate that the company has too much debt, which could impact the company's financial stability.</p>
    </>,
  },
  {
    value: "longTermDebtToCapitalizationTTM",
    key: "longTermDebtToCapitalizationTTM",
    label: "Long Term Debt To Capitalization",
    tooltip: <><p><strong>Definition:</strong> The Long Term Debt to Capitalization Ratio is a ratio that shows the financial leverage of a firm, indicating the degree to which the company's capital is financed through debt.</p>
    <p><strong>Formula:</strong> Long Term Debt to Capitalization Ratio = Long Term Debt / (Long Term Debt + Shareholders' Equity)</p>
    <p><strong>Relevance to Investor:</strong> This ratio provides investors with an understanding of a company's financial structure and risk profile. A high ratio indicates that a company is heavily financed by debt, which could be risky during periods of rising interest rates or economic downturns.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to compare the leverage of different companies within the same industry. A lower ratio usually indicates a company is less dependent on debt for growth, suggesting a lower risk of financial distress.</p>
    </>,
  },
  {
    value: "netDebtToEBITDATTM",
    key: "netDebtToEBITDATTM",
    label: "Net Debt To EBITDA",
    tooltip: <><p><strong>Definition:</strong> The Net Debt to EBITDA Ratio is a leverage ratio that indicates how many years it would take for a company to pay back its debt if net debt and EBITDA are held constant.</p>
    <p><strong>Formula:</strong> Net Debt to EBITDA Ratio = (Short Term Debt + Long Term Debt - Cash & Cash Equivalents) / EBITDA</p>
    <p><strong>Relevance to Investor:</strong> This ratio gives investors a clearer picture of a company's ability to meet its debt obligations. A high ratio may indicate that a company is over-leveraged, signaling higher financial risk.</p>
    <p><strong>Investor Application:</strong> Investors use this ratio to assess a company's leverage and overall financial health. If the ratio is high, it indicates that the company might have difficulty in paying off its debt, making it a riskier investment.</p>
    </>,
  },
  {
    value: "shortTermCoverageRatiosTTM",
    key: "shortTermCoverageRatiosTTM",
    label: "Short Term Coverage Ratios",
    tooltip: <><p><strong>Definition:</strong> The Short Term Coverage Ratio, also known as the "current cash debt coverage ratio," measures a company's ability to pay off its current liabilities with its operating cash flow.</p>
    <p><strong>Formula:</strong> Short Term Coverage Ratio = Operating Cash Flow / Current Liabilities</p>
    <p><strong>Relevance to Investor:</strong> This ratio provides an indication of a company's liquidity and short-term debt-paying ability. A higher ratio indicates that the company is in a better position to cover its short-term debt.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to assess the liquidity risk associated with a company. If the ratio is low, it may indicate that the company could struggle to cover its short-term liabilities, which could lead to financial distress.</p></>,
  },
  {
    value: "totalDebtToCapitalizationTTM",
    key: "totalDebtToCapitalizationTTM",
    label: "Total Debt To Capitalization",
    tooltip: <><p><strong>Definition:</strong> The Total Debt to Capitalization Ratio is a measure of a company's financial leverage. It indicates the proportion of a company's capital that is funded by debt.</p>
    <p><strong>Formula:</strong> Total Debt to Capitalization Ratio = Total Debt / (Total Debt + Shareholder's Equity)</p>
    <p><strong>Relevance to Investor:</strong> This ratio provides an indication of a company's capital structure and its reliance on debt as a source of financing. A higher ratio could imply a higher risk of financial distress in times of economic downturn or rising interest rates.</p>
    <p><strong>Investor Application:</strong> Investors can use this ratio to compare the leverage of different companies within the same industry. A lower ratio usually indicates a company is less dependent on debt for growth, suggesting a lower risk of financial distress.</p>
    </>,
  },
];

export const LIQUIDITY_RATIO_COLUMNS = [
  {
    value: "cashConversionCycleTTM",
    key: "cashConversionCycleTTM",
    label: "Cash Conversion Cycle (CCC)",
    tooltip: <><p><strong>Definition:</strong> The Cash Conversion Cycle (CCC) is a metric that expresses the time (in days) it takes for a company to convert its investments in inventory and other resources into cash flows from sales.</p>
<p><strong>Formula:</strong> CCC = Days of Inventory Outstanding (DIO) + Days of Sales Outstanding (DSO) - Days of Payables Outstanding (DPO)</p>
<p><strong>Relevance to Investor:</strong> The CCC gives investors an idea of the company's management efficiency with its assets. A lower CCC means that the company's management is efficient at converting its investments into cash, and it has less money tied up in inventory.</p>
<p><strong>Investor Application:</strong> An investor can compare the CCC of different companies within the same industry to get a better understanding of management efficiency. A lower CCC is usually preferable as it indicates the company can quickly turn its products into cash.</p></>,
  },
  {
    value: "cashPerShareTTM_y",
    key: "cashPerShareTTM_y",
    label: "Cash Per Share",
    tooltip: <><p><strong>Definition:</strong> Cash Per Share (CPS) is a company's total cash divided by its total number of shares outstanding.</p>
<p><strong>Formula:</strong> CPS = Total Cash / Total Number of Shares Outstanding</p>
<p><strong>Relevance to Investor:</strong> CPS is an indicator of a company's financial health. A higher CPS could imply the company has more flexibility to invest in growth, pay dividends, or weather downturns.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to compare the financial strength of different companies. A higher ratio indicates the company has more cash available to pay its obligations, potentially making it a safer investment.</p></>,
  },
  {
    value: "cashRatioTTM",
    key: "cashRatioTTM",
    label: "Cash Ratio",
    tooltip: <><p><strong>Definition:</strong> The Cash Ratio is a measure of a company's liquidity and ability to meet short-term liabilities. It is more conservative than other liquidity ratios because it only considers the most liquid assets - cash and cash equivalents.</p>
<p><strong>Formula:</strong> Cash Ratio = (Cash + Cash Equivalents) / Current Liabilities</p>
<p><strong>Relevance to Investor:</strong> This ratio provides an indication of a company's ability to cover its short-term liabilities without the need to sell or liquidate other assets. A higher ratio indicates better short-term financial health.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to assess the liquidity risk associated with a company. A higher ratio indicates that the company can easily cover its short-term liabilities, making it a potentially safer investment.</p></>,
  },
  {
    value: "currentRatioTTM_y",
    key: "currentRatioTTM_y",
    label: "Current Ratio",
    tooltip: <><p><strong>Definition:</strong> The Current Ratio is a liquidity ratio that measures a company's ability to pay off its short-term liabilities with its short-term assets.</p>
<p><strong>Formula:</strong> Current Ratio = Current Assets / Current Liabilities</p>
<p><strong>Relevance to Investor:</strong> This ratio provides an indication of a company's short-term liquidity. A ratio greater than 1 suggests that the company has more than enough resources to meet its short-term obligations.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to compare the short-term liquidity of different companies. If the ratio is less than 1, it may indicate that the company could have trouble meeting its short-term obligations, which could lead to financial distress.</p></>,
  },
  {
    value: "daysOfInventoryOutstandingTTM",
    key: "daysOfInventoryOutstandingTTM",
    label: "Days Of Inventory Outstanding (DIO)",
    tooltip: <><p><strong>Definition:</strong> Days of Inventory Outstanding (DIO) is a measure of how long a company holds its inventory before selling it.</p>
<p><strong>Formula:</strong> DIO = (Average Inventory / Cost of Goods Sold) * 365</p>
<p><strong>Relevance to Investor:</strong> This measure provides an indication of a company's inventory management efficiency. A lower DIO suggests that a company maintains lower levels of inventory relative to its sales, signifying better inventory turnover and management.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to compare inventory management efficiency of different companies within the same industry. A lower DIO typically indicates a company can quickly turn its inventory into sales, which is a positive sign for the business.</p></>,
  },
  {
    value: "daysOfPayablesOutstandingTTM",
    key: "daysOfPayablesOutstandingTTM",
    label: "Days Of Payables Outstanding (DPO)",
    tooltip: <><p><strong>Definition:</strong> Days of Payables Outstanding (DPO) measures the average number of days it takes a company to pay its bills and invoices to its trade creditors, which can include suppliers.</p>
<p><strong>Formula:</strong> DPO = (Accounts Payable / Cost of Goods Sold) * 365</p>
<p><strong>Relevance to Investor:</strong> A higher DPO indicates that a company takes longer to pay its bills, which can be beneficial for its cash flow. However, an excessively high DPO may be a red flag that a company is delaying payments to improve its cash flow.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to understand how a company manages its payables. Comparing DPO across companies in the same industry can provide insights into different cash management strategies.</p></>,
  },
  {
    value: "daysOfSalesOutstandingTTM",
    key: "daysOfSalesOutstandingTTM",
    label: "Days Of Sales Outstanding (DSO)",
    tooltip: <><p><strong>Definition:</strong> Days of Sales Outstanding (DSO) represents the average number of days it takes for a company to collect payment after a sale has been made.</p>
<p><strong>Formula:</strong> DSO = (Accounts Receivable / Total Credit Sales) * 365</p>
<p><strong>Relevance to Investor:</strong> This ratio provides an indication of the effectiveness of a company's credit policy and collection efforts. A lower DSO suggests that the company collects its outstanding receivables quickly, which is beneficial for its cash flow.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to compare the credit collection efficiency of different companies within the same industry. A lower DSO is generally more desirable as it means the company has a shorter cash conversion cycle.</p></>,
  },
  {
    value: "operatingCycleTTM",
    key: "operatingCycleTTM",
    label: "Operating Cycle (OC)",
    tooltip: <><p><strong>Definition:</strong> The Operating Cycle is the average time it takes for a company to convert its inventory into cash.</p>
<p><strong>Formula:</strong> Operating Cycle = DIO + DSO</p>
<p><strong>Relevance to Investor:</strong> This metric provides an indication of a company's operational efficiency and liquidity. A shorter operating cycle means that a company can quickly turn its inventory into cash.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to compare the operational efficiency of different companies within the same industry. A shorter operating cycle is generally more desirable as it suggests the company has a more efficient cash conversion process.</p></>,
  },
  {
    value: "quickRatioTTM",
    key: "quickRatioTTM",
    label: "Quick Ratio",
    tooltip: <><p><strong>Definition:</strong> The Quick Ratio, also known as the Acid-Test Ratio, measures a company's ability to meet its short-term obligations with its most liquid assets.</p>
<p><strong>Formula:</strong> Quick Ratio = (Cash + Marketable Securities + Accounts Receivable) / Current Liabilities</p>
<p><strong>Relevance to Investor:</strong> The Quick Ratio provides a stricter measure of a company's liquidity as it excludes inventory from current assets. This is important because in a distressed scenario, inventory may not be easily convertible to cash. A higher Quick Ratio indicates a better liquidity position and financial health.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to compare the liquidity of different companies. A higher Quick Ratio indicates the company can more readily cover its current liabilities, thus indicating a lower risk of financial distress.</p></>,
  },
];

export const EARNING_DIVIDEND_COLUMNS = [
  {
    value: "dividendPaidAndCapexCoverageRatioTTM",
    key: "dividendPaidAndCapexCoverageRatioTTM",
    label: "Dividend Paid And Capex Coverage Ratio",
    tooltip: <><p><strong>Definition:</strong> This ratio measures a company's ability to fund its capital expenditures and dividend payments from its net income.</p>
<p><strong>Formula:</strong> Dividend Paid and Capex Coverage Ratio = Net Income / (Dividends Paid + Capital Expenditure)</p>
<p><strong>Relevance to Investor:</strong> This ratio is essential for income investors who are interested in the company's ability to sustain its dividend payments. A higher ratio indicates that the company generates sufficient net income to cover both dividends and capital expenditures.</p>
<p><strong>Investor Application:</strong> Investors should use this ratio to assess a company's capacity to maintain or increase dividends and capital spending. If the ratio is consistently lower than 1, it may indicate a risk to the company's dividend payment or future growth prospects.</p></>,
  },

  {
    value: "dividendYieldTTM_x",
    key: "dividendYieldTTM_x",
    label: "Dividend Yield",
    type: "PERCENTAGE",
    tooltip: <><p><strong>Definition:</strong> Dividend Yield is a financial ratio that shows how much a company pays out in dividends each year relative to its stock price.</p>
<p><strong>Formula:</strong> Dividend Yield = Annual Dividends per Share / Price per Share</p>
<p><strong>Relevance to Investor:</strong> Dividend Yield is an important consideration for income investors. A higher yield may suggest a good income-generating investment. However, extremely high yields can also be a sign of financial distress.</p>
<p><strong>Investor Application:</strong> Investors can use the Dividend Yield to compare the dividend returns of different companies. It's important to consider the dividend yield in the context of the company's industry and dividend payout history.</p></>,
  },
  {
    value: "earningsYieldTTM",
    key: "earningsYieldTTM",
    label: "Earnings Yield",
    type: "PERCENTAGE",
    tooltip: <><p><strong>Definition:</strong> Earnings Yield is the ratio of a company's earnings per share for the most recent 12-month period divided by its current market price per share.</p>
<p><strong>Formula:</strong> Earnings Yield = Earnings per Share / Market Price per Share</p>
<p><strong>Relevance to Investor:</strong> The Earnings Yield can be useful when comparing the earnings potential of different investments. It's essentially the inverse of the P/E ratio.</p>
<p><strong>Investor Application:</strong> Investors can use the Earnings Yield to compare the profitability of different companies. A higher Earnings Yield indicates a potentially undervalued company or a higher return on investment.</p></>,
  },
  {
    value: "netIncomePerShareTTM",
    key: "netIncomePerShareTTM",
    label: "Net Income Per Share",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Net Income Per Share, also known as Earnings Per Share (EPS), is the portion of a company's profit allocated to each outstanding share of common stock.</p>
<p><strong>Formula:</strong> Net Income Per Share = Net Income / Total Shares Outstanding</p>
<p><strong>Relevance to Investor:</strong> This ratio gives investors a picture of a company's profitability on a per-share basis. It's one of the most widely used indicators of a company's profitability and is often used by investors to compare performance between companies.</p>
<p><strong>Investor Application:</strong> Investors can use Net Income Per Share to compare the profitability of different companies. A higher EPS indicates more value because investors would receive a higher return on their investment.</p></>,
  },
];

export const CASH_FLOW_RATIO_COLUMNS = [
  {
    value: "freeCashFlowOperatingCashFlowRatioTTM",
    key: "freeCashFlowOperatingCashFlowRatioTTM",
    label: "Free Cash Flow Operating Cash Flow Ratio",
    tooltip: <><p><strong>Definition:</strong> This ratio indicates a company's ability to convert operating cash flow into free cash flow.</p>
<p><strong>Formula:</strong> Free Cash Flow to Operating Cash Flow Ratio = Free Cash Flow / Operating Cash Flow</p>
<p><strong>Relevance to Investor:</strong> This ratio is important because it shows how efficient a company is at generating free cash flow from its operations. A higher ratio is generally better as it indicates a higher margin of safety for investors.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to assess a company's operational efficiency and its ability to generate surplus cash that can be used for growth opportunities or returned to shareholders.</p></>,
  },
  {
    value: "freeCashFlowPerShareTTM_x",
    key: "freeCashFlowPerShareTTM_x",
    label: "Free Cash Flow Per Share",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Free Cash Flow Per Share (FCF Per Share) is a ratio that compares a company's free cash flow to its total number of shares outstanding.</p>
<p><strong>Formula:</strong> Free Cash Flow Per Share = Free Cash Flow / Total Shares Outstanding</p>
<p><strong>Relevance to Investor:</strong> This metric gives investors a clearer picture of a company's financial flexibility and ability to reinvest in its business, pay down debt, buy back stock, or pay dividends.</p>
<p><strong>Investor Application:</strong> Investors can use this ratio to compare the cash generation efficiency of different companies on a per-share basis. A higher FCF per share indicates a company with more financial flexibility, which could mean a better investment.</p></>,
  },
  {
    value: "freeCashFlowYieldTTM",
    key: "freeCashFlowYieldTTM",
    label: "Free Cash Flow Yield",
    type: "PERCENTAGE",
    tooltip: <><p><strong>Definition:</strong> Free Cash Flow Yield is a financial solvency ratio that compares the free cash flow per share a company is capable of generating against its market price per share.</p>
<p><strong>Formula:</strong> Free Cash Flow Yield = Free Cash Flow Per Share / Market Price Per Share</p>
<p><strong>Relevance to Investor:</strong> It's an important indicator of the financial health of a company, and a higher yield is usually seen as a good sign.</p>
<p><strong>Investor Application:</strong> The ratio can be used to determine the return on investment if all the free cash flow were paid out as dividends. However, this is rarely the case as companies often reinvest into their business.</p></>,
  },
  {
    value: "incomeQualityTTM",
    key: "incomeQualityTTM",
    label: "Income Quality",
    tooltip: <><p><strong>Definition:</strong> Income Quality is a measure of the sustainability and reliability of a company's earnings. High-quality income is stable and sustainable, with a significant portion coming from core business operations.</p>
<p><strong>Formula:</strong> There's no set formula for Income Quality, as it's more of an analytical concept. However, one way to approach it is by looking at the consistency of a company's earnings over time and the proportion of income derived from core operations.</p>
<p><strong>Relevance to Investor:</strong> High-quality income provides investors with more certainty about future income streams, which can support higher valuations and more stable returns.</p>
<p><strong>Investor Application:</strong> Investors can assess Income Quality by analyzing a company's income statement, looking for stable, predictable income streams and minimal one-time or non-operational items.</p></>,
  },
  {
    value: "operatingCashFlowPerShareTTM_x",
    key: "operatingCashFlowPerShareTTM_x",
    label: "Operating Cash Flow Per Share",
    type: "CURRENCY",
    tooltip: <><p><strong>Definition:</strong> Operating Cash Flow Per Share is a measure of the amount of cash generated by a company's normal business operations per share.</p>
<p><strong>Formula:</strong> Operating Cash Flow Per Share = Operating Cash Flow / Total Shares Outstanding</p>
<p><strong>Relevance to Investor:</strong> This metric gives investors an idea of a company's ability to generate cash from its operations on a per-share basis, providing a measure of financial strength and flexibility.</p>
<p><strong>Investor Application:</strong> A higher ratio indicates that the company generates more cash from its operations, which could be used for investments, debt payments, or returned to shareholders, making it potentially a better investment.</p></>,
  },
  {
    value: "operatingCashFlowSalesRatioTTM",
    key: "operatingCashFlowSalesRatioTTM",
    label: "Operating Cash Flow Sales Ratio",
    tooltip: <><p><strong>Definition:</strong> This ratio provides an indication of a company's ability to convert sales into cash.</p>
<p><strong>Formula:</strong> Operating Cash Flow to Sales Ratio = Operating Cash Flow / Net Sales</p>
<p><strong>Relevance to Investor:</strong> This ratio shows the proportion of a company's revenue remaining after operating expenses. It's a measure of efficiency that tells investors about the company's ability to turn sales into cash, which can be used for investing or covering liabilities.</p>
<p><strong>Investor Application:</strong> A higher ratio could be a positive sign for investors as it indicates the company is capable of generating a higher amount of cash from its sales. This could potentially translate into greater returns for investors through dividends or business growth.</p></>,
  },
];

export const RELATIVE_VALUATION = [
  {
    value: "relative_fy0_best",
    label: "Best Valuation",
    tooltip: ``,
    type: "relative_fy0_best",
    key:"relativeValuationBestCaseTTM",
  },
  {
    value: "relative_fy0_base",
    label: "Base Valuation",
    tooltip: ``,
    type: "relative_fy0_base",
    key:"relativeValuationBaseCaseTTM",
  },
  {
    value: "relative_fy0_worst",
    label: "Worst Valuation",
    tooltip: ``,
    type: "relative_fy0_worst",
    key:"relativeValuationWorstCaseTTM",
  }
];
export const DCF_VALUATION = [
  {
    value: "fcff_fy0_best",
    label: "FCFF Best Valuation",
    tooltip: ``,
    type: "fcff_best",
    key: "dcfValuationBestCaseFcffTTM"
  },
  {
    value: "fcff_fy0_base",
    label: "FCFF Base Valuation",
    tooltip: ``,
    type: "fcff_base",
    key: "dcfValuationBaseCaseFcffTTM"

  },
  {
    value: "fcff_fy0_worst",
    label: "FCFF Worst Valuation",
    tooltip: ``,
    type: "fcff_worst",
    key: "dcfValuationWorstCaseFcffTTM"

  },
  {
    value: "erm_fy0_best",
    label: "ERM Best Valuation",
    tooltip: ``,
    type: "erm_best",
    key: "dcfValuationBestCaseErmTTM"

  },
  {
    value: "erm_fy0_base",
    label: "ERM Base Valuation",
    tooltip: ``,
    type: "erm_base",
    key: "dcfValuationBaseCaseErmTTM"

  },
  {
    value: "erm_fy0_worst",
    label: "ERM Worst Valuation",
    tooltip: ``,
    type: "erm_worst",
    key: "dcfValuationWorstCaseErmTTM"

  },
];

export const DCF_FCFF_VALUATION = [
  {
    value: "fcff_base",
    label: "FCFF Base Valuation",
    tooltip: ``,
    type: "fcff_base",
  },
  {
    value: "fcff_best",
    label: "FCFF Best Valuation",
    tooltip: ``,
    type: "fcff_best",
  },
  {
    value: "fcff_worst",
    label: "FCFF Worst Valuation",
    tooltip: ``,
    type: "fcff_worst",
  }
];

export const DCF_ERM_VALUATION = [
  {
    value: "erm_base",
    label: "ERM Base Valuation",
    tooltip: ``,
    type: "erm_base",
  },
  {
    value: "erm_best",
    label: "ERM Best Valuation",
    tooltip: ``,
    type: "erm_best",
  },
  {
    value: "erm_worst",
    label: "ERM Worst Valuation",
    tooltip: ``,
    type: "erm_worst",
  }
];

export const IMPLIED_VOLATILITY_FACTS = [
  {
    value: "iv30",
    label: "IV 30",
    tooltip: ``,
    type: "iv30",
  },
  {
    value: "iv60",
    label: "IV 60",
    tooltip: ``,
    type: "iv60",
  },
  {
    value: "iv90",
    label: "IV 90",
    tooltip: ``,
    type: "iv90",
  },
  {
    value: "iv120",
    label: "IV 120",
    tooltip: ``,
    type: "iv120",
  },
  {
    value: "iv150",
    label: "IV 150",
    tooltip: ``,
    type: "iv150",
  },
  {
    value: "iv180",
    label: "IV 180",
    tooltip: ``,
    type: "iv180",
  },
  {
    value: "iv360",
    label: "IV 360",
    tooltip: ``,
    type: "iv360",
  },
];

export const VOLUME_FACTS = [
  {
    value: "callVolume",
    label: "Call Volume",
    tooltip: ``,
    type: "callVolume",
  },
  {
    value: "putVolume",
    label: "Put Volume",
    tooltip: ``,
    type: "putVolume",
  },
  {
    value: "optionVolume",
    label: "Options Volume",
    tooltip: ``,
    type: "optionVolume",
  },
  {
    value: "callPutVolume",
    label: "Call-Put Volume Ratio",
    tooltip: ``,
    type: "callPutVolume",
  },
];

export const OPEN_INTEREST_FACTS = [
  {
    value: "callOpenInterest",
    label: "Call OI",
    tooltip: ``,
    type: "callOpenInterest",
  },
  {
    value: "putOpenInterest",
    label: "Put OI",
    tooltip: ``,
    type: "putOpenInterest",
  },
  {
    value: "optionOpenInterest",
    label: "Options OI",
    tooltip: ``,
    type: "optionOpenInterest",
  },
  {
    value: "callPutOpenInterest",
    label: "Call-Put OI Ratio",
    tooltip: ``,
    type: "callPutOpenInterest",
  },
];

export const ALL_OPTION_TRADING_FILTERS = {
  impliedVolatility: IMPLIED_VOLATILITY_FACTS,
  volume: VOLUME_FACTS,
  openinterset: OPEN_INTEREST_FACTS,
  basicCompanyFacts: BASIC_COMPANY_FACTS,
};

export const ALL_SCREENER_FILTERS = {
  basicCompanyFacts: BASIC_COMPANY_FACTS,
  capitalStructure: CAPITAL_STRUCTURE_COLUMNS,
  priceMultiples: PRICING_COLUMNS,
  efficiencyRatio: EFFICIENCY_RATIO_COLUMNS,
  return: RETURN_COLUMNS,
  margin: MARGIN_COLUMNS,
  leverageRatio: LEVERAGE_RATIO_COLUMNS,
  liquidityRatio: LIQUIDITY_RATIO_COLUMNS,
  earningDividend: EARNING_DIVIDEND_COLUMNS,
  cashFlowRatio: CASH_FLOW_RATIO_COLUMNS,
  relativeValuation: RELATIVE_VALUATION,
  DcfValuation: DCF_VALUATION,
};
export const ALL_SCREENER_FILTERS_FOR_TAB = {
  portfolioTracker: BASIC_COMPANY_FACTS,
  basicCompanyFacts: BASIC_COMPANY_FACTS,
  capitalStructure: CAPITAL_STRUCTURE_COLUMNS,
  priceMultiples: PRICING_COLUMNS,
  efficiencyRatio: EFFICIENCY_RATIO_COLUMNS,
  return: RETURN_COLUMNS,
  margin: MARGIN_COLUMNS,
  leverageRatio: LEVERAGE_RATIO_COLUMNS,
  liquidityRatio: LIQUIDITY_RATIO_COLUMNS,
  earningDividend: EARNING_DIVIDEND_COLUMNS,
  cashFlowRatio: CASH_FLOW_RATIO_COLUMNS,
  relativeValuation: RELATIVE_VALUATION,
  DcfValuation: DCF_VALUATION,
};

export const DCF_SCREENER_FILTER = {
  fcffDCFValuation: DCF_FCFF_VALUATION,
  ermDCFValuation: DCF_ERM_VALUATION
}
export const holdingsCols = [
  {
    label: "Symbol",
    name:"symbol"
  },
  {
    label: "Current Price",
    name:"currentPrice"
  },
  {
    label: "Change %",
    name:"charges"
  },
  {
    label: "Shares",
    name:"shares"
  },
  {
    label: "Cost Basis",
    name:"costBasis"
  },
  {
    label: "Trade Cost",
    name:"tradeCost"
  },
  {
    label: "Today's P/L",
    name:"todayPNL"
  },
  {
    label: "Realized P/L",
    name:"realizedPNL"
  },
  {
    label: "Unrealized P/L",
    name:"unrealizedPNL"
  },
  {
    label: "Net P/L",
    name:"netPNL"
  },
  {
    label: "Net P/L %",
    name:"netPNLPer"
  },
  {
    label: "Update",
    name:"update"
  },
]

export const ALL_SCREENER_FILTERS_TOOLTIP = {
  basicCompanyFacts: '',
  capitalStructure: <><p>Capital structure refers to the mix of a company's long-term debt, specific short-term debt, common equity, and preferred equity. It's the firm's strategy for financing its assets through a combination of equity, debt, and hybrid securities. The capital structure decision significantly impacts the value of the firm and its risk profile.</p></>,
  priceMultiples: <p>Price Multiples are commonly used financial ratios that help investors evaluate the value of a company's stock in relation to key business metrics such as earnings, sales, and assets. They are useful tools for comparing the relative value of different stocks and for determining whether a stock might be over- or undervalued.</p>,
  efficiencyRatio: <p>Efficiency ratios, also known as activity ratios, give insights into the efficiency of a company's operations. They show how well a company utilizes its assets and manages its liabilities internally. Efficiency ratios include asset turnover, fixed asset turnover, inventory turnover, payables turnover, and receivables turnover. Each of these ratios provides different insights into a company's operational efficiency, and investors can use them to better understand a company's operational performance and compare it to other companies in the same industry.</p>,
  return: <p>The concept of "returns" is central to investing. Returns represent the financial gain or profitability of an investment over a certain period. This chapter delves into different types of returns that investors look at when evaluating potential investments, including Return on Assets, Return on Capital Employed, Return on Equity, and Return on Tangible Assets.</p>,
  margin: <p>Margins, in the context of financial analysis, refer to a company's profitability. They demonstrate the efficiency with which a company converts sales into profits. This chapter introduces various types of margins and how they are calculated, including EBT Per EBIT, Gross Profit Margin, Net Income Per EBT, Net Profit Margin, Operating Profit Margin, Pretax Profit Margin, Research and Development to Revenue, and Sales, General, and Administrative to Revenue.</p>,
  leverageRatio: <p>Leverage ratios provide insight into the financial structure of a company, especially the extent to which the firm is using debt to finance its activities. These ratios are crucial for both the company's management and potential investors as they can indicate the financial risk associated with the company's debt levels.</p>,
  liquidityRatio: <p>Liquidity Ratios are financial metrics used to determine a company's ability to pay off its short-term debts obligations. Generally, the higher the value of the ratio, the larger the margin of safety that the company possesses to cover short-term debts.</p>,
  earningDividend: <p>In this chapter, we focus on the earnings of a company and the dividends it pays to its shareholders. Earnings are a measure of a company's profitability, and dividends are a portion of the company's earnings distributed to shareholders. Together, they provide a comprehensive view of a company's financial health and its ability to provide returns to its shareholders.</p>,
  cashFlowRatio: <p>Cash Flow Ratios provide insights about a company's cash flow, which is the net amount of cash and cash-equivalents being transferred in and out of a business. These ratios help to determine a company's liquidity, solvency, and overall financial health, thereby playing a crucial role in understanding the company's ability to maintain and grow its operations.</p>,
  relativeValuation: '',
  DcfValuation: '',
}

const PAGE_NUMBER = 1;

export const IMPLIED_TRADING_PARAM = {
  iv30: "",
  iv60: "",
  iv90: "",
  iv120: "",
  iv150: "",
  iv180: "",
  iv360: "",
  callVolume: "",
  putVolume: "",
  optionVolume: "",
  callPutVolume: "",
  callOpenInterest: "",
  putOpenInterest: "",
  optionOpenInterest: "",
  callPutOpenInterest: "",
};

export const INIT_TRADING_PARAM = {
  Price: "None",
  currency: "USD",
  Beta: "None",
  VolAvg: "None",
  MktCap: "None",
  LastDiv: "None",
  // Changes: "None",
  exchangeShortName: "None",
  sector: "None",
  industry: "None",
  country: "None",
  fullTimeEmployees: "None",
  ipoDate: "None",
};



export const FILTER_ARRAY_CHANGE = [
  "bookValuePerShareTTM",
  "capexPerShareTTM",
  "capexToDepreciationTTM",
  "capexToOperatingCashFlowTTM",
  "capexToRevenueTTM",
  "capitalExpenditureCoverageRatioTTM",
  "effectiveTaxRateTTM",
  "enterpriseValueTTM",
  "intangiblesToTotalAssetsTTM",
  "investedCapitalTTM",
  "marketCapTTM",
  "netCurrentAssetValueTTM",
  "revenuePerShareTTM",
  "stockBasedCompensationToRevenueTTM",
  "tangibleAssetValueTTM",
  "tangibleBookValuePerShareTTM",
  "workingCapitalTTM",
  "enterpriseValueMultipleTTM",
  "evToFreeCashFlowTTM",
  "evToOperatingCashFlowTTM",
  "evToSalesTTM",
  "grahamNumberTTM",
  "priceEarningsRatioTTM",
  "priceEarningsToGrowthRatioTTM",
  "priceToBookRatioTTM",
  "priceToFreeCashFlowsRatioTTM",
  "priceToOperatingCashFlowsRatioTTM",
  "priceToSalesRatioTTM_x",
  "assetTurnoverTTM",
  "fixedAssetTurnoverTTM",
  "inventoryTurnoverTTM_x",
  "payablesTurnoverTTM_x",
  "receivablesTurnoverTTM_x",
  "returnOnAssetsTTM",
  "returnOnCapitalEmployedTTM",
  "returnOnEquityTTM",
  "returnOnTangibleAssetsTTM",
  "roicTTM",
  "ebtPerEbitTTM",
  "grossProfitMarginTTM",
  "netIncomePerEBTTTM",
  "netProfitMarginTTM",
  "operatingProfitMarginTTM",
  "pretaxProfitMarginTTM",
  "researchAndDevelopementToRevenueTTM",
  "salesGeneralAndAdministrativeToRevenueTTM",
  "cashFlowCoverageRatiosTTM",
  "debtEquityRatioTTM",
  "debtToAssetsTTM",
  "interestCoverageTTM_x",
  "interestDebtPerShareTTM",
  "longTermDebtToCapitalizationTTM",
  "netDebtToEBITDATTM",
  "shortTermCoverageRatiosTTM",
  "totalDebtToCapitalizationTTM",
  "cashConversionCycleTTM",
  "cashPerShareTTM_y",
  "cashRatioTTM",
  "currentRatioTTM_y",
  "daysOfInventoryOutstandingTTM",
  "daysOfPayablesOutstandingTTM",
  "daysOfSalesOutstandingTTM",
  "operatingCycleTTM",
  "quickRatioTTM",
  "dividendPaidAndCapexCoverageRatioTTM",
  "dividendYieldTTM_x",
  "earningsYieldTTM",
  "netIncomePerShareTTM",
  "freeCashFlowOperatingCashFlowRatioTTM",
  "freeCashFlowPerShareTTM_x",
  "freeCashFlowYieldTTM",
  "incomeQualityTTM",
  "operatingCashFlowPerShareTTM_x",
  "operatingCashFlowSalesRatioTTM",
  "returOnEquityTTM",
  "relative_base",
  "relative_best",
  "relative_worst",
];

export const CUSTOM_INIT_PARAMS = {
  price: [INIT_PARAM.price],
  beta: [INIT_PARAM.beta],
  volavg: [INIT_PARAM.volavg],
  mktcap: [INIT_PARAM.mktcap],
  lastdiv: [INIT_PARAM.lastdiv],
  // changes: ["-10000_10000"],
  exchangeShortName: [],
  industry: [],
  sector: [],
  country: [],
  fullTimeEmployees: [INIT_PARAM.fullTimeEmployees],
  ipoDate: [`${IPODate("any")}`],
  currency: ["USD"],
  bookValuePerShareTTM: [],
  capexPerShareTTM: [],
  capexToDepreciationTTM: [],
  capexToOperatingCashFlowTTM: [],
  capexToRevenueTTM: [],
  capitalExpenditureCoverageRatioTTM: [],
  effectiveTaxRateTTM: [],
  enterpriseValueTTM: [],
  intangiblesToTotalAssetsTTM: [],
  investedCapitalTTM: [],
  marketCapTTM: [],
  netCurrentAssetValueTTM: [],
  revenuePerShareTTM: [],
  stockBasedCompensationToRevenueTTM: [],
  tangibleAssetValueTTM: [],
  tangibleBookValuePerShareTTM: [],
  workingCapitalTTM: [],
  enterpriseValueMultipleTTM: [],
  evToFreeCashFlowTTM: [],
  evToOperatingCashFlowTTM: [],
  evToSalesTTM: [],
  grahamNumberTTM: [],
  priceEarningsRatioTTM: [],
  priceEarningsToGrowthRatioTTM: [],
  priceToBookRatioTTM: [],
  priceToFreeCashFlowsRatioTTM: [],
  priceToOperatingCashFlowsRatioTTM: [],
  priceToSalesRatioTTM_x: [],
  assetTurnoverTTM: [],
  fixedAssetTurnoverTTM: [],
  inventoryTurnoverTTM_x: [],
  payablesTurnoverTTM_x: [],
  receivablesTurnoverTTM_x: [],
  returnOnAssetsTTM: [],
  returnOnCapitalEmployedTTM: [],
  returnOnEquityTTM: [],
  returnOnTangibleAssetsTTM: [],
  ebtPerEbitTTM: [],
  grossProfitMarginTTM: [],
  netIncomePerEBTTTM: [],
  netProfitMarginTTM: [],
  operatingProfitMarginTTM: [],
  pretaxProfitMarginTTM: [],
  researchAndDevelopementToRevenueTTM: [],
  salesGeneralAndAdministrativeToRevenueTTM: [],
  cashFlowCoverageRatiosTTM: [],
  debtEquityRatioTTM: [],
  debtToAssetsTTM: [],
  interestCoverageTTM_x: [],
  interestDebtPerShareTTM: [],
  longTermDebtToCapitalizationTTM: [],
  netDebtToEBITDATTM: [],
  shortTermCoverageRatiosTTM: [],
  totalDebtToCapitalizationTTM: [],
  cashConversionCycleTTM: [],
  cashPerShareTTM_y: [],
  cashRatioTTM: [],
  currentRatioTTM_y: [],
  daysOfInventoryOutstandingTTM: [],
  daysOfPayablesOutstandingTTM: [],
  daysOfSalesOutstandingTTM: [],
  operatingCycleTTM: [],
  quickRatioTTM: [],
  dividendPaidAndCapexCoverageRatioTTM: [],
  dividendYieldTTM_x: [],
  earningsYieldTTM: [],
  netIncomePerShareTTM: [],
  freeCashFlowOperatingCashFlowRatioTTM: [],
  freeCashFlowPerShareTTM_x: [],
  freeCashFlowYieldTTM: [],
  incomeQualityTTM: [],
  operatingCashFlowPerShareTTM_x: [],
  operatingCashFlowSalesRatioTTM: [],
  returOnEquityTTM: [],
  relative_fy0_base: [],
  relative_fy0_best: [],
  relative_fy0_worst: [],
  relative_fy2_base: [],
  relative_fy2_best: [],
  relative_fy2_worst: [],
  relative_fy5_base: [],
  relative_fy5_best: [],
  relative_fy5_worst: [],
  relative_fy10_base: [],
  relative_fy10_best: [],
  relative_fy10_worst: [],
  fcff_fy0_base: [],
  fcff_fy0_best: [],
  fcff_fy0_worst: [],
  fcff_fy2_base: [],
  fcff_fy2_best: [],
  fcff_fy2_worst: [],
  fcff_fy5_base: [],
  fcff_fy5_best: [],
  fcff_fy5_worst: [],
  fcff_fy10_base: [],
  fcff_fy10_best: [],
  fcff_fy10_worst: [],
  erm_fy0_base: [],
  erm_fy0_best: [],
  erm_fy0_worst: [],
  erm_fy2_base: [],
  erm_fy2_best: [],
  erm_fy2_worst: [],
  erm_fy5_base: [],
  erm_fy5_best: [],
  erm_fy5_worst: [],
  erm_fy10_base: [],
  erm_fy10_best: [],
  erm_fy10_worst: [],
  priceSlider: [0, 600],
};

export const CUSTOM_INIT_TRADING_PARAMS = {
  Price: [],
  Beta: [],
  VolAvg: [],
  MktCap: [],
  LastDiv: [],
  // Changes: [],
  exchangeShortName: [],
  industry: [],
  sector: [],
  country: [],
  fullTimeEmployees: [],
  ipoDate: [],
  currency: ["USD"],
};

export const INIT_TRADING_PARAMS = {
  Price: false,
  currency: true,
  Beta: false,
  VolAvg: false,
  MktCap: false,
  LastDiv: false,
  // Changes: false,
  exchangeShortName: false,
  sector: false,
  industry: false,
  country: false,
  fullTimeEmployees: false,
  ipoDate: false,
  iv30: false,
  iv60: false,
  iv90: false,
  iv120: false,
  iv150: false,
  iv180: false,
  iv360: false,
  callVolume: false,
  putVolume: false,
  optionVolume: false,
  callPutVolume: false,
  callOpenInterest: false,
  putOpenInterest: false,
  optionOpenInterest: false,
  callPutOpenInterest: false,
};

export const INIT_PARAMS = {
  price: true,
  currency: true,
  beta: true,
  volavg: true,
  mktcap: true,
  lastdiv: true,
  // changes: true,
  exchangeShortName: true,
  sector: true,
  industry: true,
  country: true,
  fullTimeEmployees: true,
  ipoDate: true,
  bookValuePerShareTTM: true,
  capexPerShareTTM: true,
  capexToDepreciationTTM: true,
  capexToOperatingCashFlowTTM: true,
  capexToRevenueTTM: true,
  capitalExpenditureCoverageRatioTTM: true,
  effectiveTaxRateTTM: true,
  enterpriseValueTTM: true,
  intangiblesToTotalAssetsTTM: true,
  investedCapitalTTM: true,
  marketCapTTM: true,
  netCurrentAssetValueTTM: true,
  revenuePerShareTTM: true,
  stockBasedCompensationToRevenueTTM: true,
  tangibleAssetValueTTM: true,
  tangibleBookValuePerShareTTM: true,
  workingCapitalTTM: true,
  enterpriseValueMultipleTTM: true,
  evToFreeCashFlowTTM: true,
  evToOperatingCashFlowTTM: true,
  evToSalesTTM: true,
  grahamNumberTTM: true,
  priceEarningsRatioTTM: true,
  priceEarningsToGrowthRatioTTM: true,
  priceToBookRatioTTM: true,
  priceToFreeCashFlowsRatioTTM: true,
  priceToOperatingCashFlowsRatioTTM: true,
  priceToSalesRatioTTM_x: true,
  assetTurnoverTTM: true,
  fixedAssetTurnoverTTM: true,
  inventoryTurnoverTTM_x: true,
  payablesTurnoverTTM_x: true,
  receivablesTurnoverTTM_x: true,
  returnOnAssetsTTM: true,
  returnOnCapitalEmployedTTM: true,
  returnOnEquityTTM: true,
  returnOnTangibleAssetsTTM: true,
  ebtPerEbitTTM: true,
  grossProfitMarginTTM: true,
  netIncomePerEBTTTM: true,
  netProfitMarginTTM: true,
  operatingProfitMarginTTM: true,
  pretaxProfitMarginTTM: true,
  researchAndDevelopementToRevenueTTM: true,
  salesGeneralAndAdministrativeToRevenueTTM: true,
  cashFlowCoverageRatiosTTM: true,
  debtEquityRatioTTM: true,
  debtToAssetsTTM: true,
  interestCoverageTTM_x: true,
  interestDebtPerShareTTM: true,
  longTermDebtToCapitalizationTTM: true,
  netDebtToEBITDATTM: true,
  shortTermCoverageRatiosTTM: true,
  totalDebtToCapitalizationTTM: true,
  cashConversionCycleTTM: true,
  cashPerShareTTM_y: true,
  cashRatioTTM: true,
  currentRatioTTM_y: true,
  daysOfInventoryOutstandingTTM: true,
  daysOfPayablesOutstandingTTM: true,
  daysOfSalesOutstandingTTM: true,
  operatingCycleTTM: true,
  quickRatioTTM: true,
  dividendPaidAndCapexCoverageRatioTTM: true,
  dividendYieldTTM_x: true,
  earningsYieldTTM: true,
  netIncomePerShareTTM: true,
  freeCashFlowOperatingCashFlowRatioTTM: true,
  freeCashFlowPerShareTTM_x: true,
  freeCashFlowYieldTTM: true,
  incomeQualityTTM: true,
  operatingCashFlowPerShareTTM_x: true,
  operatingCashFlowSalesRatioTTM: true,
  returOnEquityTTM: true,
  relative_fy0_base: true,
  relative_fy0_best: true,
  relative_fy0_worst: true,
  relative_fy2_base: true,
  relative_fy2_best: true,
  relative_fy2_worst: true,
  relative_fy5_base: true,
  relative_fy5_best: true,
  relative_fy5_worst: true,
  relative_fy10_base: true,
  relative_fy10_best: true,
  relative_fy10_worst: true,
  fcff_fy0_base: true,
  fcff_fy0_best: true,
  fcff_fy0_worst: true,
  fcff_fy2_base: true,
  fcff_fy2_best: true,
  fcff_fy2_worst: true,
  fcff_fy5_base: true,
  fcff_fy5_best: true,
  fcff_fy5_worst: true,
  fcff_fy10_base: true,
  fcff_fy10_best: true,
  fcff_fy10_worst: true,
  erm_fy0_base: true,
  erm_fy0_best: true,
  erm_fy0_worst: true,
  erm_fy2_base: true,
  erm_fy2_best: true,
  erm_fy2_worst: true,
  erm_fy5_base: true,
  erm_fy5_best: true,
  erm_fy5_worst: true,
  erm_fy10_base: true,
  erm_fy10_best: true,
  erm_fy10_worst: true,
};

export const COLUMNS = {
  Price: {},
  Beta: {},
  VolAvg: {},
  MktCap: {},
  LastDiv: {},
  Range: {},
  // Changes: {},
  companyName: {},
  currency: {},
  cik: {},
  isin: {},
  cusip: {},
  exchange: {},
  exchangeShortName: {},
  industry: {},
  website: {},
  description: {},
  CEO: {},
  sector: {},
  country: {},
  fullTimeEmployees: {},
  phone: {},
  address: {},
  city: {},
  state: {},
  zip: {},
  DCF_diff: {},
  DCF: {},
  image: {},
  ipoDate: {},
  defaultImage: {},
  isEtf: {},
  isActivelyTrading: {},
  isFund: {},
  isAdr: {},
  symbol: {},
  revenuePerShareTTM: {},
  netIncomePerShareTTM: {},
  operatingCashFlowPerShareTTM_x: {},
  freeCashFlowPerShareTTM_x: {},
  cashPerShareTTM_x: {},
  bookValuePerShareTTM: {},
  tangibleBookValuePerShareTTM: {},
  shareholdersEquityPerShareTTM: {},
  interestDebtPerShareTTM: {},
  marketCapTTM: {},
  enterpriseValueTTM: {},
  peRatioTTM_x: {},
  priceToSalesRatioTTM_x: {},
  pocfratioTTM: {},
  pfcfRatioTTM: {},
  pbRatioTTM: {},
  ptbRatioTTM: {},
  evToSalesTTM: {},
  enterpriseValueOverEBITDATTM: {},
  evToOperatingCashFlowTTM: {},
  evToFreeCashFlowTTM: {},
  earningsYieldTTM: {},
  freeCashFlowYieldTTM: {},
  debtToEquityTTM: {},
  debtToAssetsTTM: {},
  netDebtToEBITDATTM: {},
  currentRatioTTM_x: {},
  interestCoverageTTM_x: {},
  incomeQualityTTM: {},
  dividendYieldTTM_x: {},
  dividendYieldPercentageTTM: {},
  payoutRatioTTM_x: {},
  salesGeneralAndAdministrativeToRevenueTTM: {},
  researchAndDevelopementToRevenueTTM: {},
  intangiblesToTotalAssetsTTM: {},
  capexToOperatingCashFlowTTM: {},
  capexToRevenueTTM: {},
  capexToDepreciationTTM: {},
  stockBasedCompensationToRevenueTTM: {},
  grahamNumberTTM: {},
  roicTTM: {},
  returnOnTangibleAssetsTTM: {},
  grahamNetNetTTM: {},
  workingCapitalTTM: {},
  tangibleAssetValueTTM: {},
  netCurrentAssetValueTTM: {},
  investedCapitalTTM: {},
  averageReceivablesTTM: {},
  averagePayablesTTM: {},
  averageInventoryTTM: {},
  daysSalesOutstandingTTM: {},
  daysPayablesOutstandingTTM: {},
  daysOfInventoryOnHandTTM: {},
  receivablesTurnoverTTM_x: {},
  payablesTurnoverTTM_x: {},
  inventoryTurnoverTTM_x: {},
  roeTTM: {},
  capexPerShareTTM: {},
  dividendPerShareTTM_x: {},
  debtToMarketCapTTM: {},
  dividendYielTTM: {},
  dividendYielPercentageTTM: {},
  peRatioTTM_y: {},
  pegRatioTTM: {},
  payoutRatioTTM_y: {},
  currentRatioTTM_y: {},
  quickRatioTTM: {},
  cashRatioTTM: {},
  daysOfSalesOutstandingTTM: {},
  daysOfInventoryOutstandingTTM: {},
  operatingCycleTTM: {},
  daysOfPayablesOutstandingTTM: {},
  cashConversionCycleTTM: {},
  grossProfitMarginTTM: {},
  operatingProfitMarginTTM: {},
  pretaxProfitMarginTTM: {},
  netProfitMarginTTM: {},
  effectiveTaxRateTTM: {},
  returnOnAssetsTTM: {},
  returnOnEquityTTM: {},
  returnOnCapitalEmployedTTM: {},
  netIncomePerEBTTTM: {},
  ebtPerEbitTTM: {},
  ebitPerRevenueTTM: {},
  debtRatioTTM: {},
  debtEquityRatioTTM: {},
  longTermDebtToCapitalizationTTM: {},
  totalDebtToCapitalizationTTM: {},
  interestCoverageTTM_y: {},
  cashFlowToDebtRatioTTM: {},
  companyEquityMultiplierTTM: {},
  receivablesTurnoverTTM_y: {},
  payablesTurnoverTTM_y: {},
  inventoryTurnoverTTM_y: {},
  fixedAssetTurnoverTTM: {},
  assetTurnoverTTM: {},
  operatingCashFlowPerShareTTM_y: {},
  freeCashFlowPerShareTTM_y: {},
  cashPerShareTTM_y: {},
  operatingCashFlowSalesRatioTTM: {},
  freeCashFlowOperatingCashFlowRatioTTM: {},
  cashFlowCoverageRatiosTTM: {},
  shortTermCoverageRatiosTTM: {},
  capitalExpenditureCoverageRatioTTM: {},
  dividendPaidAndCapexCoverageRatioTTM: {},
  priceBookValueRatioTTM: {},
  priceToBookRatioTTM: {},
  priceToSalesRatioTTM_y: {},
  priceEarningsRatioTTM: {},
  priceToFreeCashFlowsRatioTTM: {},
  priceToOperatingCashFlowsRatioTTM: {},
  priceCashFlowRatioTTM: {},
  priceEarningsToGrowthRatioTTM: {},
  priceSalesRatioTTM: {},
  dividendYieldTTM_y: {},
  enterpriseValueMultipleTTM: {},
  priceFairValueTTM: {},
  dividendPerShareTTM_y: {},
  relative_fy0_base: {},
  relative_fy0_best: {},
  relative_fy0_worst: {},
  relative_fy2_base: {},
  relative_fy2_best: {},
  relative_fy2_worst: {},
  relative_fy5_base: {},
  relative_fy5_best: {},
  relative_fy5_worst: {},
  relative_fy10_base: {},
  relative_fy10_best: {},
  relative_fy10_worst: {},
  fcff_fy0_base: {},
  fcff_fy0_best: {},
  fcff_fy0_worst: {},
  fcff_fy2_base: {},
  fcff_fy2_best: {},
  fcff_fy2_worst: {},
  fcff_fy5_base: {},
  fcff_fy5_best: {},
  fcff_fy5_worst: {},
  fcff_fy10_base: {},
  fcff_fy10_best: {},
  fcff_fy10_worst: {},
  erm_fy0_base: {},
  erm_fy0_best: {},
  erm_fy0_worst: {},
  erm_fy2_base: {},
  erm_fy2_best: {},
  erm_fy2_worst: {},
  erm_fy5_base: {},
  erm_fy5_best: {},
  erm_fy5_worst: {},
  erm_fy10_base: {},
  erm_fy10_best: {},
  erm_fy10_worst: {},
};

export const COUNTRY_LIST = [
  "AE",
  "AI",
  "AR",
  "AT",
  "AU",
  "BE",
  "BG",
  "BM",
  "BR",
  "BS",
  "CA",
  "CH",
  "CL",
  "CN",
  "CO",
  "CR",
  "CY",
  "DE",
  "DK",
  "DO",
  "EG",
  "ES",
  "FI",
  "FR",
  "GB",
  "GG",
  "GI",
  "GR",
  "HK",
  "ID",
  "IE",
  "IL",
  "IN",
  "IS",
  "IT",
  "JE",
  "JO",
  "JP",
  "KR",
  "KY",
  "KZ",
  "LT",
  "LU",
  "MC",
  "MO",
  "MT",
  "MX",
  "MY",
  "NL",
  "NO",
  "NZ",
  "PA",
  "PE",
  "PH",
  "PL",
  "PR",
  "PT",
  "RU",
  "SE",
  "SG",
  "SK",
  "TH",
  "TR",
  "TW",
  "US",
  "UY",
  "VI",
  "ZA",
];

export const SECTOR_LIST = [
  "Basic Materials",
  "Communication Services",
  "Conglomerates",
  "Consumer Cyclical",
  "Consumer Defensive",
  "Energy",
  "Financial",
  "Financial Services",
  "Healthcare",
  "Industrials",
  "Real Estate",
  "Technology",
  "Utilities",
];

export const INDUSTRY_LIST = [
  "Advertising Agencies",
  "Aerospace & Defense",
  "Agricultural Inputs",
  "Airlines",
  "Airports & Air Services",
  "Aluminum",
  "Apparel Manufacturing",
  "Apparel Retail",
  "Asset Management",
  "Auto & Truck Dealerships",
  "Auto Manufacturers",
  "Auto Parts",
  "Banks",
  "Banks—Diversified",
  "Banks—Regional",
  "Beverages—Brewers",
  "Beverages—Non-Alcoholic",
  "Beverages—Wineries & Distilleries",
  "Biotechnology",
  "Broadcasting",
  "Building Materials",
  "Building Products & Equipment",
  "Business Equipment & Supplies",
  "Capital Markets",
  "Chemicals",
  "Closed-End Fund - Equity",
  "Coking Coal",
  "Communication Equipment",
  "Computer Hardware",
  "Confectioners",
  "Conglomerates",
  "Consulting Services",
  "Consumer Electronics",
  "Copper",
  "Credit Services",
  "Department Stores",
  "Diagnostics & Research",
  "Discount Stores",
  "Drug Manufacturers—General",
  "Drug Manufacturers—Specialty & Generic",
  "Education & Training Services",
  "Electrical Equipment & Parts",
  "Electronic Components",
  "Electronic Gaming & Multimedia",
  "Electronics & Computer Distribution",
  "Engineering & Construction",
  "Entertainment",
  "Farm & Heavy Construction Machinery",
  "Farm Products",
  "Financial Conglomerates",
  "Financial Data & Stock Exchanges",
  "Food Distribution",
  "Footwear & Accessories",
  "Furnishings, Fixtures & Appliances",
  "Gambling",
  "Gold",
  "Grocery Stores",
  "Health Information Services",
  "Healthcare Plans",
  "Home Improvement Retail",
  "Homebuilding & Construction",
  "Household & Personal Products",
  "Independent Oil & Gas",
  "Industrial Distribution",
  "Information Technology Services",
  "Infrastructure Operations",
  "Insurance Brokers",
  "Insurance Specialty",
  "Insurance—Diversified",
  "Insurance—Life",
  "Insurance—Property & Casualty",
  "Insurance—Reinsurance",
  "Insurance—Specialty",
  "Integrated Freight & Logistics",
  "Internet Content & Information",
  "Internet Retail",
  "Leisure",
  "Lodging",
  "Lumber & Wood Production",
  "Luxury Goods",
  "Marine Shipping",
  "Medical Care Facilities",
  "Medical Devices",
  "Medical Distribution",
  "Medical Instruments & Supplies",
  "Metal Fabrication",
  "Mortgage Finance",
  "Oil & Gas Drilling",
  "Oil & Gas E&P",
  "Oil & Gas Equipment & Services",
  "Oil & Gas Integrated",
  "Oil & Gas Midstream",
  "Oil & Gas Refining & Marketing",
  "Other Industrial Metals & Mining",
  "Other Precious Metals & Mining",
  "Packaged Foods",
  "Packaging & Containers",
  "Paper & Paper Products",
  "Personal Services",
  "Pharmaceutical Retailers",
  "Pollution & Treatment Controls",
  "Publishing",
  "REIT—Diversified",
  "REIT—Healthcare Facilities",
  "REIT—Hotel & Motel",
  "REIT—Industrial",
  "REIT—Mortgage",
  "REIT—Office",
  "REIT—Residential",
  "REIT—Retail",
  "REIT—Specialty",
  "Railroads",
  "Real Estate Services",
  "Real Estate—Development",
  "Real Estate—Diversified",
  "Recreational Vehicles",
  "Rental & Leasing Services",
  "Residential Construction",
  "Resorts & Casinos",
  "Restaurants",
  "Retail Apparel & Specialty",
  "Scientific & Technical Instruments",
  "Security & Protection Services",
  "Semiconductor Equipment & Materials",
  "Semiconductors",
  "Shell Companies",
  "Silver",
  "Software—Application",
  "Software—Infrastructure",
  "Solar",
  "Specialty Business Services",
  "Specialty Chemicals",
  "Specialty Industrial Machinery",
  "Specialty Retail",
  "Staffing & Employment Services",
  "Steel",
  "Telecom Services",
  "Telecom Services - Foreign",
  "Textile Manufacturing",
  "Thermal Coal",
  "Tobacco",
  "Tools & Accessories",
  "Travel Services",
  "Trucking",
  "Uranium",
  "Utilities—Diversified",
  "Utilities—Independent Power Producers",
  "Utilities—Regulated Electric",
  "Utilities—Regulated Gas",
  "Utilities—Regulated Water",
  "Utilities—Renewable",
  "Waste Management",
];

//Response Column(key) List
export const COLUMN_LIST = [
  "Price",
  "Beta",
  "VolAvg",
  "MktCap",
  "LastDiv",
  "Range",
  "Changes",
  "companyName",
  "currency",
  "cik",
  "isin",
  "cusip",
  "exchange",
  "exchangeShortName",
  "industry",
  "website",
  "description",
  "CEO",
  "sector",
  "country",
  "fullTimeEmployees",
  "phone",
  "address",
  "city",
  "state",
  "zip",
  "DCF_diff",
  "DCF",
  "image",
  "ipoDate",
  "defaultImage",
  "isEtf",
  "isActivelyTrading",
  "isFund",
  "isAdr",
  "symbol",
  "revenuePerShareTTM",
  "netIncomePerShareTTM",
  "operatingCashFlowPerShareTTM_x",
  "freeCashFlowPerShareTTM_x",
  "cashPerShareTTM_x",
  "bookValuePerShareTTM",
  "tangibleBookValuePerShareTTM",
  "shareholdersEquityPerShareTTM",
  "interestDebtPerShareTTM",
  "marketCapTTM",
  "enterpriseValueTTM",
  "peRatioTTM_x",
  "priceToSalesRatioTTM_x",
  "pocfratioTTM",
  "pfcfRatioTTM",
  "pbRatioTTM",
  "ptbRatioTTM",
  "evToSalesTTM",
  "enterpriseValueOverEBITDATTM",
  "evToOperatingCashFlowTTM",
  "evToFreeCashFlowTTM",
  "earningsYieldTTM",
  "freeCashFlowYieldTTM",
  "debtToEquityTTM",
  "debtToAssetsTTM",
  "netDebtToEBITDATTM",
  "currentRatioTTM_x",
  "interestCoverageTTM_x",
  "incomeQualityTTM",
  "dividendYieldTTM_x",
  "dividendYieldPercentageTTM",
  "payoutRatioTTM_x",
  "salesGeneralAndAdministrativeToRevenueTTM",
  "researchAndDevelopementToRevenueTTM",
  "intangiblesToTotalAssetsTTM",
  "capexToOperatingCashFlowTTM",
  "capexToRevenueTTM",
  "capexToDepreciationTTM",
  "stockBasedCompensationToRevenueTTM",
  "grahamNumberTTM",
  "roicTTM",
  "returnOnTangibleAssetsTTM",
  "grahamNetNetTTM",
  "workingCapitalTTM",
  "tangibleAssetValueTTM",
  "netCurrentAssetValueTTM",
  "investedCapitalTTM",
  "averageReceivablesTTM",
  "averagePayablesTTM",
  "averageInventoryTTM",
  "daysSalesOutstandingTTM",
  "daysPayablesOutstandingTTM",
  "daysOfInventoryOnHandTTM",
  "receivablesTurnoverTTM_x",
  "payablesTurnoverTTM_x",
  "inventoryTurnoverTTM_x",
  "roeTTM",
  "capexPerShareTTM",
  "dividendPerShareTTM_x",
  "debtToMarketCapTTM",
  "dividendYielTTM",
  "dividendYielPercentageTTM",
  "peRatioTTM_y",
  "pegRatioTTM",
  "payoutRatioTTM_y",
  "currentRatioTTM_y",
  "quickRatioTTM",
  "cashRatioTTM",
  "daysOfSalesOutstandingTTM",
  "daysOfInventoryOutstandingTTM",
  "operatingCycleTTM",
  "daysOfPayablesOutstandingTTM",
  "cashConversionCycleTTM",
  "grossProfitMarginTTM",
  "operatingProfitMarginTTM",
  "pretaxProfitMarginTTM",
  "netProfitMarginTTM",
  "effectiveTaxRateTTM",
  "returnOnAssetsTTM",
  "returnOnEquityTTM",
  "returnOnCapitalEmployedTTM",
  "netIncomePerEBTTTM",
  "ebtPerEbitTTM",
  "ebitPerRevenueTTM",
  "debtRatioTTM",
  "debtEquityRatioTTM",
  "longTermDebtToCapitalizationTTM",
  "totalDebtToCapitalizationTTM",
  "interestCoverageTTM_y",
  "cashFlowToDebtRatioTTM",
  "companyEquityMultiplierTTM",
  "receivablesTurnoverTTM_y",
  "payablesTurnoverTTM_y",
  "inventoryTurnoverTTM_y",
  "fixedAssetTurnoverTTM",
  "assetTurnoverTTM",
  "operatingCashFlowPerShareTTM_y",
  "freeCashFlowPerShareTTM_y",
  "cashPerShareTTM_y",
  "operatingCashFlowSalesRatioTTM",
  "freeCashFlowOperatingCashFlowRatioTTM",
  "cashFlowCoverageRatiosTTM",
  "shortTermCoverageRatiosTTM",
  "capitalExpenditureCoverageRatioTTM",
  "dividendPaidAndCapexCoverageRatioTTM",
  "priceBookValueRatioTTM",
  "priceToBookRatioTTM",
  "priceToSalesRatioTTM_y",
  "priceEarningsRatioTTM",
  "priceToFreeCashFlowsRatioTTM",
  "priceToOperatingCashFlowsRatioTTM",
  "priceCashFlowRatioTTM",
  "priceEarningsToGrowthRatioTTM",
  "priceSalesRatioTTM",
  "dividendYieldTTM_y",
  "enterpriseValueMultipleTTM",
  "priceFairValueTTM",
  "dividendPerShareTTM_y",
  "relative_base",
  "relative_best",
  "relative_worst",
  "fcff_fy1_base",
  "fcff_fy1_best",
  "fcff_fy1_worst",
  "erm_fy1_base",
  "erm_fy1_best",
  "erm_fy1_worst"
];

export const REQUEST_FIRST_LETTER_CHANGE = [
  "Price",
  "Beta",
  "VolAvg",
  "MktCap",
  "LastDiv",
  "Range",
  "Changes",
];

export const SECTOR_WISE_INDUSTRY = {
  "Basic Materials": [
    "Aluminum",
    "Gold",
    "Other Industrial Metals & Mining",
    "Silver",
    "Building Materials",
    "Chemicals",
    "Other Precious Metals & Mining",
    "Copper",
    "Specialty Chemicals",
    "Coking Coal",
    "Steel",
    "Agricultural Inputs",
    "Paper & Paper Products",
    "Lumber & Wood Production",
    "Independent Oil & Gas",
  ],
  "Communication Services": [
    "Telecom Services",
    "Advertising Agencies",
    "Entertainment",
    "Internet Content & Information",
    "Electronic Gaming & Multimedia",
    "Broadcasting",
    "Publishing",
  ],
  Conglomerates: ["Conglomerates"],
  "Consumer Cyclical": [
    "Specialty Retail",
    "Auto & Truck Dealerships",
    "Travel Services",
    "Lodging",
    "Gambling",
    "Packaging & Containers",
    "Footwear & Accessories",
    "Auto Parts",
    "Apparel Retail",
    "Textile Manufacturing",
    "Internet Retail",
    "Leisure",
    "Restaurants",
    "Personal Services",
    "Furnishings, Fixtures & Appliances",
    "Department Stores",
    "Resorts & Casinos",
    "Home Improvement Retail",
    "Auto Manufacturers",
    "Residential Construction",
    "Recreational Vehicles",
    "Luxury Goods",
    "Apparel Manufacturing",
    "Entertainment",
    "Retail Apparel & Specialty",
    "Homebuilding & Construction",
    "Gold",
  ],
  "Consumer Defensive": [
    "Education & Training Services",
    "Household & Personal Products",
    "Beverages—Brewers",
    "Grocery Stores",
    "Farm Products",
    "Packaged Foods",
    "Beverages—Non-Alcoholic",
    "Food Distribution",
    "Beverages—Wineries & Distilleries",
    "Discount Stores",
    "Tobacco",
    "Confectioners",
    "Alternative Protein Sources"
  ],
  Energy: [
    "Oil & Gas Refining & Marketing",
    "Oil & Gas E&P",
    "Oil & Gas Midstream",
    "Thermal Coal",
    "Oil & Gas Equipment & Services",
    "Oil & Gas Integrated",
    "Uranium",
    "Oil & Gas Drilling",
  ],
  // Financial: ['Closed-End Fund - Equity'],
  "Financial Services": [
    "Shell Companies",
    "Insurance—Life",
    "Asset Management",
    "Banks—Diversified",
    "Banks—Regional",
    "Capital Markets",
    "Insurance—Diversified",
    "Insurance—Specialty",
    "Insurance—Property & Casualty",
    "Credit Services",
    "Insurance Brokers",
    "Financial Data & Stock Exchanges",
    "Mortgage Finance",
    "Insurance—Reinsurance",
    "Banks",
    "Financial Conglomerates",
    "Insurance Specialty",
  ],
  Healthcare: [
    "Diagnostics & Research",
    "Biotechnology",
    "Drug Manufacturers—General",
    "Medical Distribution",
    "Medical Devices",
    // "Drug Manufacturers—Specialty & Generic",
    "Drug Manufacturers - Specialty & Generic",
    // "Health Information Services",
    "Healthcare Information Services",
    "Medical Care Facilities",
    "Medical Instruments & Supplies",
    "Healthcare Plans",
    "Pharmaceutical Retailers",
  ],
  Industrials: [
    "Airlines",
    "Rental & Leasing Services",
    "Building Products & Equipment",
    "Airports & Air Services",
    "Electrical Equipment & Parts",
    "Conglomerates",
    "Specialty Business Services",
    "Infrastructure Operations",
    "Business Equipment & Supplies",
    "Aerospace & Defense",
    "Engineering & Construction",
    "Pollution & Treatment Controls",
    "Staffing & Employment Services",
    "Industrial Distribution",
    "Security & Protection Services",
    "Farm & Heavy Construction Machinery",
    "Specialty Industrial Machinery",
    "Integrated Freight & Logistics",
    "Metal Fabrication",
    "Railroads",
    "Marine Shipping",
    "Consulting Services",
    "Waste Management",
    "Trucking",
    "Tools & Accessories",
  ],
  "Real Estate": [
    "REIT—Mortgage",
    "REIT—Diversified",
    "Real Estate Services",
    "REIT—Residential",
    "REIT—Retail",
    "Real Estate—Development",
    "REIT—Specialty",
    "REIT—Hotel & Motel",
    "REIT—Office",
    "Real Estate—Diversified",
    "REIT—Healthcare Facilities",
    "REIT—Industrial",
  ],
  Technology: [
    "Communication Equipment",
    "Semiconductors",
    "Consumer Electronics",
    "Scientific & Technical Instruments",
    "Solar",
    "Software—Application",
    "Software—Infrastructure",
    "Semiconductor Equipment & Materials",
    "Information Technology Services",
    "Electronics & Computer Distribution",
    "Computer Hardware",
    "Electronic Components",
    "Telecom Services - Foreign",
  ],
  Utilities: [
    "Utilities—Renewable",
    "Utilities—Regulated Electric",
    "Utilities—Diversified",
    "Utilities—Independent Power Producers",
    "Utilities—Regulated Water",
    "Utilities—Regulated Gas",
  ],
};

export const NEW_SECTOR_INDUSTRY = {
  "Basic Materials": [
    "Agricultural Chemicals",
    "Chemicals - Major Diversified",
    "Specialty Chemicals",
    "Sustainable Packaging Solutions",
    "AgriTech & Precision Farming",
  ],
  "Communication Services": [
    "Diversified Communication Services",
    "Entertainment - Diversified",
    "Marketing Services",
    "E-Sports & Competitive Gaming",
    "Digital Content Creation & Distribution",
    "Broadcasting - TV"
  ],
  "Consumer Cyclical": [
   "Consumer Services", 
   "Internet Retail", 
   "Personal Services", 
   "Sustainable Fashion & Apparel", 
   "Cultural & Experience-Based Travel", 
   "Luxury Goods & Services", 
  ],
  "Consumer Defensive": [
    "Food - Major Diversified",
    "Food & Staples Retailing",
    "Personal Products",
    "Alternative Protein Sources",
    "Nutraceuticals & Functional Foods",
    "Pet Care & Products",
    "Educational Technology & Online Learning"
  ],
  "Energy": [
    "Independent Oil & Gas",
    "Renewable Energy Production & Services"
  ],
  "Financial Services": [
    "Closed-End Fund - Equity",
    "Insurance",
    "Financial Technology (Fintech)"
  ],
  "Healthcare": [
    "Drug Manufacturers—General",
    "Health Care Equipment & Services",
    "Medical Laboratories & Research",
    "Pharmaceuticals, Biotechnology & Life Sciences",
    "Cannabis & Hemp Products",
    "Health & Wellness Retail",
    "Telemedicine Services",
    "Gene Therapy & Genomic Medicine"
  ],
  "Industrials": [
    "Air Delivery & Freight Services",
"Building Materials",
"Building Products & Equipment",
"Business Services",
"Commercial & Professional Services",
"Diversified Machinery",
"Electronics Wholesale",
"General Contractors",
"Homebuilding & Construction",
"Industrial Equipment Wholesale",
"Specialty Industrial Machinery",
"Staffing & Outsourcing Services",
"Transportation",
"Ocean Technology & Services",
"Waste Management & Recycling Services",
"3D Printing & Additive Manufacturing",
"Aerospace & Defense Services",
"Urban Mobility Solutions",
"Micro-Mobility Services",
"Event Technology Services",
  ],
  "Real Estate": [
    "Property Management"
  ],
  "Technology": [
    "Business Software & Services",
"Data Storage Devices",
"Diversified Electronics",
"Electronic Components",
"Electronic Equipment",
"Electronics & Computer Distribution",
"Internet Software & Services",
"Scientific & Technical Instruments",
"Security Software & Services",
"Semiconductor Equipment & Materials",
"Semiconductor- Memory Chips",
"Technical & System Software",
"Technology Hardware & Equipment",
"Virtual Reality & Augmented Reality",
"Cybersecurity Consulting",
"Smart Home Technology & Services",
"Cloud Computing Services",
"Artificial Intelligence Solutions",
"Blockchain Technology & Services",
  ],
  "Utilities": [
    "Electric Utilities",
    "Utilities Regulated"
  ]
}







export const CountryListWithCode = {
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas (the)",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia (Plurinational State of)",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory (the)",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  KY: "Cayman Islands (the)",
  CF: "Central African Republic (the)",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands (the)",
  CO: "Colombia",
  KM: "Comoros (the)",
  CD: "Congo (the Democratic Republic of the)",
  CG: "Congo (the)",
  CK: "Cook Islands (the)",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czechia",
  CI: "Côte d'Ivoire",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic (the)",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands (the) [Malvinas]",
  FO: "Faroe Islands (the)",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories (the)",
  GA: "Gabon",
  GM: "Gambia (the)",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  VA: "Holy See (the)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran (Islamic Republic of)",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Korea (the Democratic People's Republic of)",
  KR: "Korea (the Republic of)",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic (the)",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands (the)",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia (Federated States of)",
  MD: "Moldova (the Republic of)",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands (the)",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger (the)",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands (the)",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine, State of",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines (the)",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  MK: "Republic of North Macedonia",
  RO: "Romania",
  RU: "Russian Federation (the)",
  RW: "Rwanda",
  RE: "Réunion",
  BL: "Saint Barthélemy",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin (French part)",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten (Dutch part)",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan (the)",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands (the)",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates (the)",
  GB: "United Kingdom of Great Britain and Northern Ireland (the)",
  UM: "United States Minor Outlying Islands (the)",
  US: "United States of America (the)",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela (Bolivarian Republic of)",
  VN: "Viet Nam",
  VG: "Virgin Islands (British)",
  VI: "Virgin Islands (U.S.)",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  AX: "Åland Islands",
};

export const CAPITAL_STRUCTURE_TAB = [
  "bookValuePerShareTTM",
  "capexPerShareTTM",
  "capexToDepreciationTTM",
  "capexToOperatingCashFlowTTM",
  "capexToRevenueTTM",
  "capitalExpenditureCoverageRatioTTM",
  "effectiveTaxRateTTM",
  "enterpriseValueTTM",
  "intangiblesToTotalAssetsTTM",
  "investedCapitalTTM",
  // "marketCapTTM",
  "netCurrentAssetValueTTM",
  "revenuePerShareTTM",
  "stockBasedCompensationToRevenueTTM",
  "tangibleAssetValueTTM",
  "tangibleBookValuePerShareTTM",
  "workingCapitalTTM",
];

export const PRICE_TAB = [
  "enterpriseValueMultipleTTM",
  "evToFreeCashFlowTTM",
  "evToOperatingCashFlowTTM",
  "evToSalesTTM",
  "grahamNumberTTM",
  "priceEarningsRatioTTM",
  "priceEarningsToGrowthRatioTTM",
  "priceToBookRatioTTM",
  "priceToFreeCashFlowsRatioTTM",
  "priceToOperatingCashFlowsRatioTTM",
  "priceToSalesRatioTTM_x",
];

export const EFFICIENCY_RATIO_TAB = [
  "assetTurnoverTTM",
  "fixedAssetTurnoverTTM",
  "inventoryTurnoverTTM_x",
  "payablesTurnoverTTM_x",
  "receivablesTurnoverTTM_x",
];

export const RETURN_TAB = [
  "returnOnAssetsTTM",
  "returnOnCapitalEmployedTTM",
  "returnOnEquityTTM",
  "returnOnTangibleAssetsTTM",
];

export const MARGIN_TAB = [
  "ebtPerEbitTTM",
  "grossProfitMarginTTM",
  "netIncomePerEBTTTM",
  "netProfitMarginTTM",
  "operatingProfitMarginTTM",
  "pretaxProfitMarginTTM",
  "researchAndDevelopementToRevenueTTM",
  "salesGeneralAndAdministrativeToRevenueTTM",
];

export const LEVERAGE_TAB = [
  "cashFlowCoverageRatiosTTM",
  "debtEquityRatioTTM",
  "debtToAssetsTTM",
  "interestCoverageTTM_x",
  "interestDebtPerShareTTM",
  "longTermDebtToCapitalizationTTM",
  "netDebtToEBITDATTM",
  "shortTermCoverageRatiosTTM",
  "totalDebtToCapitalizationTTM",
];

export const LIQUIDITY_RATIO_TAB = [
  "cashConversionCycleTTM",
  "cashPerShareTTM_y",
  "cashRatioTTM",
  "currentRatioTTM_y",
  "daysOfInventoryOutstandingTTM",
  "daysOfPayablesOutstandingTTM",
  "daysOfSalesOutstandingTTM",
  "operatingCycleTTM",
  "quickRatioTTM",
];

export const EARNING_DIVIDEND_TAB = [
  "dividendPaidAndCapexCoverageRatioTTM",
  "dividendPayoutRatio",
  "dividendYieldTTM_x",
  "earningsYieldTTM",
  "netIncomePerShareTTM",
];

export const CASH_FLOW_RATIO_TAB = [
  "freeCashFlowOperatingCashFlowRatioTTM",
  "freeCashFlowPerShareTTM_x",
  "freeCashFlowYieldTTM",
  "incomeQualityTTM",
  "operatingCashFlowPerShareTTM_x",
  "operatingCashFlowSalesRatioTTM",
];

export const RELATIVE_VALUATION_TAB = [
  "relative_fy0_base",
  "relative_fy0_best",
  "relative_fy0_worst",
  "relative_fy2_base",
  "relative_fy2_best",
  "relative_fy2_worst",
  "relative_fy5_base",
  "relative_fy5_best",
  "relative_fy5_worst",
  "relative_fy10_base",
  "relative_fy10_best",
  "relative_fy10_worst",
  
];

export const DCF_VALUATION_TAB = [
  "fcff_fy0_base",
  "fcff_fy0_best",
  "fcff_fy0_worst",
  "fcff_fy2_base",
  "fcff_fy2_best",
  "fcff_fy2_worst",
  "fcff_fy5_base",
  "fcff_fy5_best",
  "fcff_fy6_worst",
  "fcff_fy10_base",
  "fcff_fy10_best",
  "fcff_fy10_worst",
  "erm_fy0_base",
  "erm_fy0_best",
  "erm_fy0_worst",
  "erm_fy2_base",
  "erm_fy2_best",
  "erm_fy2_worst",
  "erm_fy5_base",
  "erm_fy5_best",
  "erm_fy5_worst",
  "erm_fy10_base",
  "erm_fy10_best",
  "erm_fy10_worst"
];

export const RELATIVE_VALUATION_DATA_TAB = {
  "relative_fy0_base": {
    "Highly Underpriced(more than 100%)": "-5000_-100",
    "Moderately Underpriced(50% to 100%)": "-100_-50",
    "Lowly Underpriced(0% to 50%)": "-50_0",
    "Lowly Overpriced(0 to 25%)": "0_25",
    "Moderately Overpriced(25% to 50%)": "25_50",
    "Highly Overpriced(more than 50%)": "50_100",
    "Any": "-5000_100"
  },
  "relative_fy0_best": {
    "Highly Underpriced(more than 100%)": "-5000_-100",
    "Moderately Underpriced(50% to 100%)": "-100_-50",
    "Lowly Underpriced(0% to 50%)": "-50_0",
    "Lowly Overpriced(0 to 25%)": "0_25",
    "Moderately Overpriced(25% to 50%)": "25_50",
    "Highly Overpriced(more than 50%)": "50_100",
    "Any": "-5000_100"
  },
  "relative_fy0_worst": {
    "Highly Underpriced(more than 100%)": "-5000_-100",
    "Moderately Underpriced(50% to 100%)": "-100_-50",
    "Lowly Underpriced(0% to 50%)": "-50_0",
    "Lowly Overpriced(0 to 25%)": "0_25",
    "Moderately Overpriced(25% to 50%)": "25_50",
    "Highly Overpriced(more than 50%)": "50_100",
    "Any": "-5000_100"
  },
  "relative_fy2_base": {
    "Highly Underpriced(more than 121%)": "-5000_-121",
    "Moderately Underpriced(71% to 121%)": "-121_-71",
    "Lowly Underpriced(21% to 71%)": "-71_-51",
    "Lowly Overpriced(4% to 21%)": "-21_4",
    "Moderately Overpriced(4% to 29%)": "4_29",
    "Highly Overpriced(more than 29%)": "29_100",
    "Any": "-5000_100"
  },
  "relative_fy2_best": {
    "Highly Underpriced(more than 121%)": "-5000_-121",
    "Moderately Underpriced(71% to 121% )": "-121_-71",
    "Lowly Underpriced(21% to 71%)": "-71_-51",
    "Lowly Overpriced(4% to 21%)": "-21_4",
    "Moderately Overpriced(4% to 29%)": "4_29",
    "Highly Overpriced(more than 29%)": "29_100",
    "Any": "-5000_100"
  },
  "relative_fy2_worst": {
    "Highly Underpriced(more than 121%)": "-5000_-121",
    "Moderately Underpriced(71% to 121%)": "-121_-71",
    "Lowly Underpriced(21% to 71%)": "-71_-51",
    "Lowly Overpriced(4% to 21%)": "-21_4",
    "Moderately Overpriced(4% to 29%)": "4_29",
    "Highly Overpriced(more than 29%)": "29_100",
    "Any": "-5000_100"
  },
  "relative_fy5_base": {
    "Highly Underpriced(more than 161%)": "-5000_-161",
    "Moderately Underpriced(111% to 161%)": "-161_-111",
    "Lowly Underpriced(61% to 111%)": "-111_-61",
    "Lowly Overpriced(36% to 61%)": "-61_-36",
    "Moderately Overpriced(11% to 36%)": "-36_-11",
    "Highly Overpriced(more than 11%)": "-11_100",
    "Any": "-5000_100"
  },
  "relative_fy5_best": {
    "Highly Underpriced(more than 161%)": "-5000_-161",
    "Moderately Underpriced(111% to 161%)": "-161_-111",
    "Lowly Underpriced(61% to 111%)": "-111_-61",
    "Lowly Overpriced(36% to 61%)": "-61_-36",
    "Moderately Overpriced(11% to 36%)": "-36_-11",
    "Highly Overpriced(more than 11%)": "-11_100",
    "Any": "-5000_100"
  },
  "relative_fy5_worst": {
    "Highly Underpriced(more than 161%)": "-5000_-161",
    "Moderately Underpriced(111% to 161%)": "-161_-111",
    "Lowly Underpriced(61% to 111%)": "-111_-61",
    "Lowly Overpriced(36% to 61%)": "-61_-36",
    "Moderately Overpriced(11% to 36%)": "-36_-11",
    "Highly Overpriced(more than 11%)": "-11_100",
    "Any": "-5000_100"
  },
  "relative_fy10_base": {
    "Highly Underpriced(more than 260%)": "-5000_-260",
    "Moderately Underpriced(210% to 260%)": "-260_-210",
    "Lowly Underpriced(160% to 210%)": "-210_-160",
    "Lowly Overpriced(135% to 160%)": "-160_-135",
    "Moderately Overpriced(110% to 135%)": "-135_-110",
    "Highly Overpriced(more than 110%)": "-110_100",
    "Any": "-5000_100"
  },
  "relative_fy10_best": {
    "Highly Underpriced(more than 260%)": "-5000_-260",
    "Moderately Underpriced(210% to 260%)": "-260_-210",
    "Lowly Underpriced(160% to 210%)": "-210_-160",
    "Lowly Overpriced(135% to 160%)": "-160_-135",
    "Moderately Overpriced(110% to 135%)": "-135_-110",
    "Highly Overpriced(more than 110%)": "-110_100",
    "Any": "-5000_100"
  },
  "relative_fy10_worst": {
    "Highly Underpriced(more than 260%)": "-5000_-260",
    "Moderately Underpriced(210% to 260%)": "-260_-210",
    "Lowly Underpriced(160% to 210%)": "-210_-160",
    "Lowly Overpriced(135% to 160%)": "-160_-135",
    "Moderately Overpriced(110% to 135%)": "-135_-110",
    "Highly Overpriced(more than 110%)": "-110_100",
    "Any": "-5000_100"
  },
};

export const DCF_FCFF_ERM_VALUATION_TAB = {
  "fcff_fy0_base": {
    "Highly UnderValued(more than 100%)": "-5000_-100",
    "Moderately UnderValued(50% to 100%)": "-100_-50",
    "Lowly UnderValued(0% to 50%)": "-50_0",
    "Lowly OverValued(0 to 25%)": "0_25",
    "Moderately OverValued(25% to 50%)": "25_50",
    "Highly OverValued(more than 50%)": "50_100",
    "Any": "-5000_100"
  },
  "fcff_fy0_best": {
    "Highly UnderValued(more than 100%)": "-5000_-100",
    "Moderately UnderValued(50% to 100%)": "-100_-50",
    "Lowly UnderValued(0% to 50%)": "-50_0",
    "Lowly OverValued(0 to 25%)": "0_25",
    "Moderately OverValued(25% to 50%)": "25_50",
    "Highly OverValued(more than 50%)": "50_100",
    "Any": "-5000_100"
  },
  "fcff_fy0_worst": {
    "Highly UnderValued(more than 100%)": "-5000_-100",
    "Moderately UnderValued(50% to 100%)": "-100_-50",
    "Lowly UnderValued(0% to 50%)": "-50_0",
    "Lowly OverValued(0 to 25%)": "0_25",
    "Moderately OverValued(25% to 50%)": "25_50",
    "Highly OverValued(more than 50%)": "50_100",
    "Any": "-5000_100"
  },
  "fcff_fy2_base": {
    "Highly UnderValued(more than 121%)": "-5000_-121",
    "Moderately UnderValued(71% to 121%)": "-121_-71",
    "Lowly UnderValued(21% to 71%)": "-71_-51",
    "Lowly OverValued(4% to 21% to )": "-21_4",
    "Moderately OverValued(4% to 29%)": "4_29",
    "Highly OverValued(more than 29%)": "29_100",
    "Any": "-5000_100"
  },
  "fcff_fy2_best": {
    "Highly UnderValued(more than 121%)": "-5000_-121",
    "Moderately UnderValued(71% to 121%)": "-121_-71",
    "Lowly UnderValued(21% to 71%)": "-71_-51",
    "Lowly OverValued(4% to 21%)": "-21_4",
    "Moderately OverValued(4% to 29%)": "4_29",
    "Highly OverValued(more than 29%)": "29_100",
    "Any": "-5000_100"
  },
  "fcff_fy2_worst": {
    "Highly UnderValued(more than 121%)": "-5000_-121",
    "Moderately UnderValued(71% to 121%)": "-121_-71",
    "Lowly UnderValued(21% to 71%)": "-71_-51",
    "Lowly OverValued(4% to 21%)": "-21_4",
    "Moderately OverValued(4% to 29%)": "4_29",
    "Highly OverValued(more than 29%)": "29_100",
    "Any": "-5000_100"
  },
  "fcff_fy5_base": {
    "Highly UnderValued(more than 161%)": "-5000_-161",
    "Moderately UnderValued(111% to 161%)": "-161_-111",
    "Lowly UnderValued(61% to 111%)": "-111_-61",
    "Lowly OverValued(36% to 61%)": "-61_-36",
    "Moderately OverValued(11% to 36%)": "-36_-11",
    "Highly OverValued(more than 11%)": "-11_100",
    "Any": "-5000_100"
  },
  "fcff_fy5_best": {
    "Highly UnderValued(more than 161%)": "-5000_-161",
    "Moderately UnderValued(111% to 161%)": "-161_-111",
    "Lowly UnderValued(61% to 111%)": "-111_-61",
    "Lowly OverValued(36% to 61%)": "-61_-36",
    "Moderately OverValued(11% to 36%)": "-36_-11",
    "Highly OverValued(more than 11%)": "-11_100",
    "Any": "-5000_100"
  },
  "fcff_fy5_worst": {
    "Highly UnderValued(more than 161%)": "-5000_-161",
    "Moderately UnderValued(111% to 161%)": "-161_-111",
    "Lowly UnderValued(61% to 111%)": "-111_-61",
    "Lowly OverValued(36% to 61%)": "-61_-36",
    "Moderately OverValued(11% to 36%)": "-36_-11",
    "Highly OverValued(more than 11%)": "-11_100",
    "Any": "-5000_100"
  },
  "fcff_fy10_base": {
    "Highly UnderValued(more than 260%)": "-5000_-260",
    "Moderately UnderValued(210% to 260%)": "-260_-210",
    "Lowly UnderValued(160% to 210%)": "-210_-160",
    "Lowly OverValued(135% to 160%)": "-160_-135",
    "Moderately OverValued(110% to 135%)": "-135_-110",
    "Highly OverValued(more than -110%)": "-110_100",
    "Any": "-5000_100"
  },
  "fcff_fy10_best": {
    "Highly UnderValued(more than 260%)": "-5000_-260",
    "Moderately UnderValued(210% to 260%)": "-260_-210",
    "Lowly UnderValued(160% to 210%)": "-210_-160",
    "Lowly OverValued(135% to 160%)": "-160_-135",
    "Moderately OverValued(110% to 135%)": "-135_-110",
    "Highly OverValued(more than -110%)": "-110_100",
    "Any": "-5000_100"
  },
  "fcff_fy10_worst": {
    "Highly UnderValued(more than 260%)": "-5000_-260",
    "Moderately UnderValued(210% to 260%)": "-260_-210",
    "Lowly UnderValued(160% to 210%)": "-210_-160",
    "Lowly OverValued(135% to 160%)": "-160_-135",
    "Moderately OverValued(110% to 135%)": "-135_-110",
    "Highly OverValued(more than -110%)": "-110_100",
    "Any": "-5000_100"
  },
  "erm_fy0_base": {
    "Highly UnderValued(more than 100%)": "-5000_-100",
    "Moderately UnderValued(50% to 100%)": "-100_-50",
    "Lowly UnderValued(0% to 50%)": "-50_0",
    "Lowly OverValued(0 to 25%)": "0_25",
    "Moderately OverValued(25% to 50%)": "25_50",
    "Highly OverValued(more than 50%)": "50_100",
    "Any": "-5000_100"
  },
  "erm_fy0_best": {
    "Highly UnderValued(more than 100%)": "-5000_-100",
    "Moderately UnderValued(50% to 100%)": "-100_-50",
    "Lowly UnderValued(0% to 50%)": "-50_0",
    "Lowly OverValued(0 to 25%)": "0_25",
    "Moderately OverValued(25% to 50%)": "25_50",
    "Highly OverValued(more than 50%)": "50_100",
    "Any": "-5000_100"
  },
  "erm_fy0_worst": {
    "Highly UnderValued(more than 100%)": "-5000_-100",
    "Moderately UnderValued(50% to 100%)": "-100_-50",
    "Lowly UnderValued(0% to 50%)": "-50_0",
    "Lowly OverValued(0 to 25%)": "0_25",
    "Moderately OverValued(25% to 50%)": "25_50",
    "Highly OverValued(more than 50%)": "50_100",
    "Any": "-5000_100"
  },
  "erm_fy2_base": {
    "Highly UnderValued(more than 121%)": "-5000_-121",
    "Moderately UnderValued(71% to 121%)": "-121_-71",
    "Lowly UnderValued(21% to 71%)": "-71_-51",
    "Lowly OverValued(4% to 21%)": "-21_4",
    "Moderately OverValued(4% to 29%)": "4_29",
    "Highly OverValued(more than 29%)": "29_100",
    "Any": "-5000_100"
  },
  "erm_fy2_best": {
    "Highly UnderValued(more than 121%)": "-5000_-121",
    "Moderately UnderValued(71% to 121%)": "-121_-71",
    "Lowly UnderValued(21% to 71%)": "-71_-51",
    "Lowly OverValued(4% to 21%)": "-21_4",
    "Moderately OverValued(4% to 29%)": "4_29",
    "Highly OverValued(more than 29%)": "29_100",
    "Any": "-5000_100"
  },
  "erm_fy2_worst": {
    "Highly UnderValued(more than 121%)": "-5000_-121",
    "Moderately UnderValued(71% to 121%)": "-121_-71",
    "Lowly UnderValued(21% to 71%)": "-71_-51",
    "Lowly OverValued(4% to 21%)": "-21_4",
    "Moderately OverValued(4% to 29%)": "4_29",
    "Highly OverValued(more than 29%)": "29_100",
    "Any": "-5000_100"
  },
  "erm_fy5_base": {
    "Highly UnderValued(more than 161%)": "-5000_-161",
    "Moderately UnderValued(111% to 161%)": "-161_-111",
    "Lowly UnderValued(61% to 111%)": "-111_-61",
    "Lowly OverValued(36% to 61%)": "-61_-36",
    "Moderately OverValued(11% to 36%)": "-36_-11",
    "Highly OverValued(more than 11%)": "-11_100",
    "Any": "-5000_100"
  },
  "erm_fy5_best": {
    "Highly UnderValued(more than 161%)": "-5000_-161",
    "Moderately UnderValued(111% to 161%)": "-161_-111",
    "Lowly UnderValued(61% to 111%)": "-111_-61",
    "Lowly OverValued(36% to 61%)": "-61_-36",
    "Moderately OverValued(11% to 36%)": "-36_-11",
    "Highly OverValued(more than 11%)": "-11_100",
    "Any": "-5000_100"
  },
  "erm_fy5_worst": {
    "Highly UnderValued(more than 161%)": "-5000_-161",
    "Moderately UnderValued(111% to 161%)": "-161_-111",
    "Lowly UnderValued(61% to 111%)": "-111_-61",
    "Lowly OverValued(36% to 61%)": "-61_-36",
    "Moderately OverValued(11% to 36%)": "-36_-11",
    "Highly OverValued(more than 11%)": "-11_100",
    "Any": "-5000_100"
  },
  "erm_fy10_base": {
    "Highly UnderValued(more than 260%)": "-5000_-260",
    "Moderately UnderValued(210% to 260%)": "-260_-210",
    "Lowly UnderValued(160% to 210%)": "-210_-160",
    "Lowly OverValued(135% to 160%)": "-160_-135",
    "Moderately OverValued(110% to 135%)": "-135_-110",
    "Highly OverValued(more than -110%)": "-110_100",
    "Any": "-5000_100"
  },
  "erm_fy10_best": {
    "Highly UnderValued(more than 260%)": "-5000_-260",
    "Moderately UnderValued(210% to 260%)": "-260_-210",
    "Lowly UnderValued(160% to 210%)": "-210_-160",
    "Lowly OverValued(135% to 160%)": "-160_-135",
    "Moderately OverValued(110% to 135%)": "-135_-110",
    "Highly OverValued(more than -110%)": "-110_100",
    "Any": "-5000_100"
  },
  "erm_fy10_worst": {
    "Highly UnderValued(more than 260%)": "-5000_-260",
    "Moderately UnderValued(210% to 260%)": "-260_-210",
    "Lowly UnderValued(160% to 210%)": "-210_-160",
    "Lowly OverValued(135% to 160%)": "-160_-135",
    "Moderately OverValued(110% to 135%)": "-135_-110",
    "Highly OverValued(more than -110%)": "-110_100",
    "Any": "-5000_100"
  },
}

/* eslint-disable array-callback-return */
export const WATCHLIST_CURRENCY = [
  { label: "USD", value: "USD" },
  { label: "CNY", value: "CNY" },
  { label: "KRW", value: "KRW" },
  { label: "HKD", value: "HKD" },
  { label: "EUR", value: "EUR" },
  { label: "PLN", value: "PLN" },
  { label: "CHF", value: "CHF" },
  { label: "SEK", value: "SEK" },
  { label: "CAD", value: "CAD" },
  { label: "NOK", value: "NOK" },
  { label: "DKK", value: "DKK" },
  { label: "JPY", value: "JPY" },
  { label: "GBP", value: "GBP" },
  { label: "AUD", value: "AUD" },
  { label: "ILS", value: "ILS" },
  { label: "SAR", value: "SAR" },
  { label: "TWD", value: "TWD" },
  { label: "INR", value: "INR" },
  { label: "HUF", value: "HUF" },
  { label: "ZAC", value: "ZAC" },
  { label: "SGD", value: "SGD" },
  { label: "IDR", value: "IDR" },
  { label: "BRL", value: "BRL" },
  { label: "MXN", value: "MXN" },
  { label: "THB", value: "THB" },
  { label: "ILA", value: "ILA" },
  { label: "TRY", value: "TRY" },
  { label: "CLP", value: "CLP" },
  { label: "OAR", value: "OAR" },
  { label: "NZD", value: "NZD" },
  { label: "ARS", value: "ARS" },
  { label: "AED", value: "AED" },
  { label: "ISK", value: "ISK" },
  { label: "CZK", value: "CZK" },
];

export const getTickerLists = (ticker_values) => {
let finalString = '';
ticker_values?.map((item, index) => {
  finalString += item.symbol + ","
});
finalString = finalString.slice(0, -1);
return finalString;
}

export const FIN_CONSTANT1 = [
  { img: img1, label:"15 MILLION+", desc:"DCF Valuation" },
  { img: img2, label:"INSTITUTIONAL", desc:"Level Analysis" },
  // { img: img3, label:"AUTOMATE", desc:"Financial Processes" },
  // { img: img4, label:"WHITE LABELED", desc:"Customization" },
  ]
  // { img: centerImg, label:"center", desc:"" },
export const FIN_CONSTANT2 = [
  // { img: img1, label:"15 MILLION+", desc:"DCF Valuation" },
  // { img: img2, label:"INSTITUTIONAL", desc:"Level Analysis" },
  { img: img3, label:"AUTOMATE", desc:"Financial Processes" },
  { img: img4, label:"WHITE LABELED", desc:"Customization" },
  ]

export const B2B_SOLUTION = [
  {
    img: img10,
    label: "Automated Financial Processes",
    desc:"Streamlined financial operations through automated processes, reducing manual effort and enhancing efficiency."
  },
  {
    img: img9,
    label: "Customizable Financial Solutions",
    desc:"Tailored financial solutions designed to meet the specific needs and objectives of businesses."
  },
  {
    img: img8,
    label: "White-labeled Solutions",
    desc:"Branding options allowing businesses to present the platform as their own, ensuring consistency and professionalism in client interactions."
  },
  {
    img: img7,
    label: "Model Financial Analysis Tools",
    desc:"Model 100+ financial analysis tools and methodologies, empowering businesses to make informed decisions."
  },
  {
    img: img6,
    label: "REST API Integration",
    desc:"Seamlessly integrate our REST API into your existing platforms to streamline data retrieval, analysis, and execution."
  },
  {
    img: img5,
    label: "HFT Trading Automation Suite",
    desc:"Leverage our expertise in algorithmic trading to execute complex strategies with precision and speed."
  }
]

export const LEADING_FIN_DATA = [
  {
    service: "Raw Financial Data Access",
    amsssing: true,
    leading: true,
    isFirst: true,
    bloomberg: {
      check: true,
      text:"(Stock Exchanges - 130)"
    },
    captialIQ: {
      check: true,
      text:"(Stock Exchanges - 100)"
    },
    factSet: {
      check: true,
      text:"(Stock Exchanges - 90)"
    }
  },
  {
    service: "Financial Modeling Automation",
    amsssing: true,
    leading: false,
     bloomberg: {
      check: false,
      text:""
    },
    captialIQ: {
      check: false,
      text:""
    },
    factSet: {
      check: false,
         text:""
    }
  },
  {
    service: "Customizable Financial Models",
    amsssing: true,
    leading: false,
    bloomberg: {
      check: "no",
      text:"Limited"
    },
    captialIQ: {
      check: "no",
      text:"Limited"
    },
    factSet: {
      check: "no",
         text:"Limited"
    }
  },
  {
    service: "Customizable Portfolio Kit",
    amsssing: true,
    leading: false,
     bloomberg: {
      check: false,
      text:""
    },
    captialIQ: {
      check: false,
      text:""
    },
    factSet: {
      check: false,
         text:""
    }
  },
  {
    service: "Advanced Stock Screener",
    amsssing: true,
    leading: false,
    msg: "With DCF/ Relative",
     bloomberg: {
      check: true,
      text:""
    },
    captialIQ: {
      check: true,
      text:""
    },
    factSet: {
      check: true,
         text:""
    }
  },
  {
    service: "DCF Reports (Aswath Damodaran Model)",
    amsssing: true,
    leading: true,
    msg: "Automated (15M+)",
     bloomberg: {
      check: false,
      text:"(Manual/Analyst-Driven)"
    },
    captialIQ: {
      check: false,
      text:"(Manual/Analyst-Driven)"
    },
    factSet: {
      check: false,
         text:"(Manual/Analyst-Driven)"
    }
  },
  {
    service: "HFT Trading Automation Suite",
    amsssing: true,
    leading: true,
    msg: "Limited",
      bloomberg: {
      check: "no",
      text:"Limited"
    },
    captialIQ: {
      check: false,
      text:""
    },
    factSet: {
      check: false,
         text:""
    }
  },
  {
    service: "REST API Integration",
    amsssing: true,
    leading: false,
     bloomberg: {
      check: true,
      text:""
    },
    captialIQ: {
      check: true,
      text:""
    },
    factSet: {
      check: true,
         text:""
    }
  },
  {
    service: "White-Labeled Solutions",
    amsssing: true,
    leading: false,
    bloomberg: {
      check: false,
      text:""
    },
    captialIQ: {
      check: false,
      text:""
    },
    factSet: {
      check: false,
         text:""
    }
  },
  {
    service: "Industry Reports",
    amsssing: true,
    leading: false,
    msg: "230+ Industries",
    bloomberg: {
      check: false,
      text:""
    },
    captialIQ: {
      check: false,
      text:""
    },
    factSet: {
      check: false,
         text:""
    }
  },
  {
    service: "Dedicated Support Hours (Based on Tier)",
    amsssing: true,
    leading: false,
    msg: "15 to 18+",
    leftIcon: true,
    bloomberg: {
      check: false,
      text:""
    },
    captialIQ: {
      check: false,
      text:""
    },
    factSet: {
      check: false,
         text:""
    }
  },
]
