import React, { useEffect, useState } from 'react'
import { EARNING_CALENDER_COLUMNS } from '../../Symbol/FinancialStatement/Constants'
import axios from 'axios';
import MarketTable from '../MarketTable';
import InvexLoader from '../../../Common/InvexLoader';
import { format, subDays } from "date-fns";
import styles from './earningCalender.module.css'
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import { AtomSelectedTab } from '../../../../Atoms/MarketAtom';
import { useSetRecoilState } from 'recoil';
import MarketTab from '../MarketTab/MarketTab';
import { HelmetTitle, TechSEOPageOnTitle } from '../../../../helpers/TechSEO';
import InvexRoutes from '../../../../InvexRoutes';
import InsightCornor from '../InsightCornor/InsightCornor';
import { getInsightCornor } from '../../../api/Symbol';
const { RangePicker } = DatePicker;

const EarningsCalender = () => {
  // Variables
  const [tableData, setTableData] = useState({
    allData: [],
    filteredData: [],
  });
  const setActiveTabIndex = useSetRecoilState(AtomSelectedTab)

  const [isLoading, setIsLoading] = useState(false);
    const today = dayjs();
  const nextMonth = today.add(1, 'month');
  const [selectedDates, setSelectedDates] = useState([dayjs(), nextMonth]);
  

  // Functions

  const getData = async (sDate, eDate) => {
       const startDate = dayjs(sDate).format("YYYY-MM-DD");
    const endDate = dayjs(eDate).format("YYYY-MM-DD");
    setIsLoading(true)

       const res = await getInsightCornor({
          url: `https://financialmodelingprep.com/api/v4/earning-calendar-confirmed?from=${startDate}&to=${endDate}&apikey=b1360803f80dd08bdd0211c5c004ad03`,
       });
    console.log("res",res);
    let _list = [];
    for (const data of res?.data ?? []) {
      let obj = { ...data };
      let fDate = dayjs(data?.date).format("DD MMM YYYY");
      let publicationDate = dayjs(data?.publicationDate).format("DD MMM YYYY");
      obj["fDate"] = fDate;
      obj["publicationDate"] = publicationDate;
      obj["time"] = data?.time + " " + data?.when;
      _list.push(obj);
    }
    let _sortedData = _list.sort((a, b) => a.date > b.date ? 1 : -1);
    setIsLoading(false);
    setTableData({
      filteredData: _sortedData,
      allData: _sortedData
    });
  }


  // Callbacks
  useEffect(() => {
    getData(selectedDates[0], selectedDates[1]);
    setActiveTabIndex("earning-calendar")
  }, []);





  const handleDateChange = (dates, dateStrings) => {
    let startDate = dates[0];
    let endDate = dates[1];
     getData(startDate, endDate);
    setSelectedDates(dates);
  };
  



  const handleReset = () => {
    let _sDate = dayjs();
    getData(_sDate, nextMonth)
    setSelectedDates([_sDate, nextMonth]);
    setTableData({...tableData, ["filteredData"]: tableData?.allData})
  }

      const getPageSeo = () => {
    let obj = {
      pageHeadTitle: "Earnings Reports and Announcements Calendar",
      pageTitle: TechSEOPageOnTitle("Earnings Calendar | Amassing Investment"),
      pageHeadDescription: "Keep track of upcoming earnings announcements and reports with Amassing Investment's Earnings Calendar. Stay informed and make data-driven investment decisions.",
    };
    return obj;
  };
  const seoData = getPageSeo();
  const getHelmet = () => {
    return HelmetTitle(seoData, InvexRoutes.EarningsCalendar.path);
  };


  const handleNoData = () => {
    return <div style={{
      display: "flex", alignItems: "center", justifyContent: "center", minHeight: 250, fontWeight: 600
    }} >No Data Available...</div>
  }
  return (
    <div className='container' >
{getHelmet()}
      <MarketTab />
      <h1 style={{ margin: "1rem 0rem", fontWeight: 600, fontSize: "1.5rem",fontFamily: "merriweather" }} >Earnings Reports and Announcements Calendar</h1>
      <div className={styles.filterWrp} >
        <RangePicker  onChange={handleDateChange} value={selectedDates} defaultValue={selectedDates} />
         {/* <Button style={{  minWidth: 120, marginLeft: 10, marginRight: 10 }} onClick={handleFilter} >Filter</Button> */}
        <Button style={{  minWidth: 120,marginLeft: 10, }} onClick={handleReset}>Reset</Button>
      </div>

      <MarketTable data={tableData?.filteredData ?? []} colData={EARNING_CALENDER_COLUMNS} />
      {isLoading && <InvexLoader height={240} />}
      {
       (!isLoading && tableData?.filteredData?.length === 0) && handleNoData()  
      }
      <InsightCornor />
    </div>
  )
}

export default EarningsCalender