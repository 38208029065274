import React, { useRef, useState } from 'react'
import styles from './newPayment.module.css'
import { PaymentInputsContainer } from 'react-payment-inputs';
import { toastNotify } from '../../Common/Toast/Toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import InvexRoutes from '../../../InvexRoutes';
import { Box, Modal } from '@mui/material';
import { subscribeService } from '../../api/V3/authService';
import { getDecodeToken, getToken, getTrailDays, removeToken, setToken } from '../../Common/CommonFunctions';
import visa from '../../Common/Images/visa.svg';
import mastercard from '../../Common/Images/mastercard.svg';
import amex from '../../Common/Images/amex.svg';
import unionpay from '../../Common/Images/unionpay.svg';
import cvv from '../../Common/Images/cvv.png';

const NewPayment = () => {
  // Variables
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    coupon: "",
    zip: "",
  })
  const [open, setOpen] = useState(false);
  const { plan } = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const days = 14
  const trailsDays = getTrailDays()
  const tokenInfo = getDecodeToken();
  const navigate = useNavigate();
  // Functions
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "cardNumber" && value.length > 20) {
      return toastNotify("Card Number Should Not More Then 16 Digit", "error")
    }
    let _formData = { ...formData };
    _formData[name] = value;
    setFormData(_formData);
  }

  const validate = () => {
    if (!formData?.firstName) return "First Name Should Not Be Empty!"
    if (!formData?.lastName) return "Last Name Should Not Be Empty!"
    if (!formData?.zip) return "Zip Should Not Be Empty!"
    return true
  }


  const handleStartTrail = async () => {
    let isValidate = validate();
    if (isValidate === true) {

      setLoading(true);
      try {
        let _expiryDate = formData?.expiryDate?.split("/");
        let expMonth = _expiryDate[0]?.trim();
        let expYear = `20${_expiryDate[1]?.trim()}`;
        let cardNo = formData?.cardNumber?.replace(/ /g, '');
        const _payload = {
          number: Number(cardNo),
          expiry_month: Number(expMonth),
          expiry_year: Number(expYear),
          cvv: Number(formData?.cvc),
          plan: plan,
          first_name: formData?.firstName,
          last_name: formData?.lastName,
          coupon: formData?.coupon,
          zip: formData?.zip,
        }
        const res = await subscribeService(_payload);
        if (res?.status === 500) {
          toastNotify(res?.data, "error")
          setLoading(false);
          return;
        }
        if (res?.status === 200) {
          setLoading(false);
          // setToken(res?.token)
          toastNotify(res?.data, "success")
          removeToken();
          navigate("/login");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      toastNotify(isValidate, "error")
    }
  }

  // Template 
  const renderInput = (label, name, value, placeholder) => {
    return <div className={styles.inputWrapper} >
      <label>{label}</label>
      <input className={styles.input} onChange={handleChange} name={name} value={value} placeholder={placeholder} />
    </div>
  }
  console.log("plan", plan);
  const handleClick = (selectedVal) => {
    setOpen(true)
    setSelectedOption(selectedVal);
  }
  const remainDays = !getToken() ? days : days - trailsDays;
  const renderCardImg = () => {
    return <div className={styles.cardImgs} >
      <img src={visa} />
      <img src={mastercard} />
      <img src={amex} />
      <img src={unionpay} />
    </div>
  }
  return (
    <>
      <div className={styles.wrapper} >
        <div className={styles.leftMenu} >
          <div>
            <div className={styles.heading} >Try Amassing Investment Premium</div>
            {
              getToken() && remainDays > 0 &&
              <>
                <div className={styles.remingDays} >{`${remainDays}  days free`}</div>
                {/* <div className={styles.smText} >Then $89.99 per year</div> */}
                <div className={styles.smText} >Then {plan === "monthly" ? "$34.99" : "$299.88"} Per {plan === "monthly" ? "Month" : "Year"}</div>
              </>
            }
          </div>
          <div>
            {
              getToken() && remainDays > 0 &&
              <div className={styles.row} >
                <div className={styles.label} >Amassing Investment Premium</div>
                <div className={styles.value} >{`${remainDays}  days Free`}</div>
              </div>
            }
            <div className={styles.row} >
              <div></div>
              <div className={styles.xsmText} >{ plan === "monthly" ? "$34.99" : "$299.88"} / {plan === "monthly" ? "Month" : "Year"} After</div>
            </div>
          </div>
          <div className={styles.saprator} ></div>
          <div className={styles.totalSec} >
            <div className={styles.row} >
              <div className={styles.smText} >Total after trial</div>
              <div className={styles.smText} >{plan === "monthly" ? `$34.99`: "$24.99" } / Month</div>
            </div>
            {
              remainDays < 0 &&
            <div className={styles.row} >
              <div className={styles.footerText} >Total due today</div>
              <div className={styles.footerText} >{plan === "monthly" ? `$34.99`: "$299.88" }</div>
            </div>
            }
          </div>
        </div>
        <div className={styles.rightMenu} >
          <div className={styles.emailWrapper} >
            <label>Email</label>
            <div>{tokenInfo?.email}</div>
          </div>
          <div className={styles.paymentWrapper} >
            <div className={styles.cardInfo}>
              <div className={styles.head} >Card Information</div>
              <div>
                <PaymentInputsContainer>
                  {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                    <div>

                        <div style={{ position:"relative", display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                           <input className={`${styles.input} ${styles.paymentCard}`} name='cardNumber' {...getCardNumberProps({ onChange: handleChange })} value={formData.cardNumber} />
                        {renderCardImg()}
                        </div>
                      <div className={styles.cardExpiryWrp} >
                        <input className={styles.input} name='expiryDate' {...getExpiryDateProps({ onChange: handleChange })} value={formData.expiryDate} />
                                            <div style={{ position:"relative", display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                          <input className={`${styles.input} ${styles.cvc}`} name='cvc' {...getCVCProps({ onChange: handleChange })} value={formData.cvc} />
                          <div className={styles.cvvImgWrp} >
                              <img src={cvv} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </PaymentInputsContainer>
              </div>
            </div>
            <div className={styles.cardHolder} >
              {renderInput("First Name", "firstName", formData.firstName, "Enter First Name")}
              {renderInput("Last Name", "lastName", formData.lastName, "Enter Last Name")}
              {renderInput("Coupon", "coupon", formData.coupon, "Enter coupon")}
              {renderInput("Zip", "zip", formData.zip, "Enter Zip")}
            </div>
            <div className={styles.btnWrapper} >
              <button className={styles.freeTrailBtn} onClick={handleStartTrail} >Start Trial</button>
            </div>
            <div className={styles.footerSmText} >
              By placing your order, you agree to our <Link to={InvexRoutes.PrivacyPolicy.path} >terms of service</Link> and <Link to={InvexRoutes.PrivacyPolicy.path} >privacy policy.</Link>
            </div>
            {/* <span className={styles.footerSmText} >After your trial ends, you will be charged $89.99 per year starting March 15, 2024. You can always cancel before then.</span> */}
            <div className={styles.poweredBy} >Powered by <strong>Chargebee</strong></div>

          </div>
        </div>
      </div>
      <div className={styles.footerSection} >
     
      </div>
     
    </>
  )
}

export default NewPayment