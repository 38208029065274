// import React, { useEffect, useState } from 'react'
// import { Card, Grid, CardHeader, Button, CardContent, Box, Modal } from "@material-ui/core";
// import styles from './mostActive.module.css'
// import { MARKET_MOVERS } from '../../MacroEconomics/Constants';
// import axios from 'axios';
// import MarketTable from '../MarketTable';
// import { MOST_ACTIVE_COLUMNS } from '../../Symbol/FinancialStatement/Constants';
// import { getToken } from '../../../Common/CommonFunctions';
// import { NormalFormat } from '../../../Common/NumberFormat';
// import InvexLoader from '../../../Common/InvexLoader';
// import image from '../../../Common/Images/4632221.png'
// import MarketInfo from '../MarketInfo/MarketInfo';
// import { getData } from '../../../MarketApi';
// import { useRecoilState } from 'recoil';
// import { AtomActiveTableData, AtomMostActiveTab } from '../../../../Atoms/MarketAtom';

// const MostActive = ({}) => {
//   // Variables
//   const [activeTabIndex, setActiveTabIndex] = useRecoilState(AtomMostActiveTab)
//   const [tableData, setTableData] = useRecoilState(AtomActiveTableData);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//    const [selectedKey, setSelectedKey] = useState({
//     header: "",
//     key: "",
//   });

//   const getAllSymbols = async () => {
//     setIsLoading(true);
//     let url = "";
//     switch (activeTabIndex) {
//       case "most-active":
//         url = "https://financialmodelingprep.com/api/v3/stock_market/actives?apikey=b1360803f80dd08bdd0211c5c004ad03"
//           break;
//         case "market-gainers":
//         url = "https://financialmodelingprep.com/api/v3/stock_market/gainers?apikey=b1360803f80dd08bdd0211c5c004ad03"
//           break;
//         case "market-losers":
//         url = "https://financialmodelingprep.com/api/v3/stock_market/losers?apikey=b1360803f80dd08bdd0211c5c004ad03"
//           break;
//       default:
//         return
//     }
//     try {
//       const response = await axios({
//         url: url,
//         method: "get"
//       });
   
//       let obj = {};
//       let _chargePer = {};
//       const symbolsList = [];
//       for (const symbol of response?.data ?? []) {
//         symbolsList.push(symbol.symbol);
//         _chargePer[symbol.symbol] = symbol?.changesPercentage
//       }
//       try {
//         const payload = symbolsList.toString();
//         const response = await axios({
//           url: `https://financialmodelingprep.com/api/v3/profile/${payload}?apikey=b1360803f80dd08bdd0211c5c004ad03`,
//           method: "get"
//         });
//         let resList = [];
//         for (const res of response?.data ?? []) {
//           const newObj = {};
//           newObj["symbol"] = res?.symbol;
//           newObj["price"] = res?.price?.toFixed(2);
//           newObj["changes"] = _chargePer[res?.symbol]?.toFixed(2) + "%";
//           newObj["marketCap"] = NormalFormat(res?.mktCap);
//           newObj["volume"] = NormalFormat(res?.volAvg);
//           newObj["valuvation"] = "";
//           newObj["pricing"] = "";
//           newObj["industry"] = res?.industry;
//           newObj["range"] = res?.range;
//           resList.push(newObj);
//           obj[res?.symbol] = newObj
//         }
//         try {
//           const body = {
//             symbols: symbolsList,
//           };
//           const AuthHeader = getToken();

//           const response = await axios.post(`https://cp2.invexwealth.com/get_valuation_price`, body, {
//             headers: {
//               Authorization: `Bearer ${AuthHeader}`,
//             },
//           });
//       //        const resp = await getData(body);
//       // console.log("resp",resp);
//           let _list = []
//           for (const data of response?.data ?? []) {
//             let _newObj = { ...obj[data?.symbol] };
//             _newObj["valuvation"] = (_newObj?.industry === "Financial Services" || _newObj?.industry === "Real Estate") ? data?.ermValuation?.toFixed(2) : data?.fcffValuation?.toFixed(2);
//             _newObj["pricing"] = data?.pricing?.toFixed(2);
//             _list.push(_newObj)
//           }
//           setTableData(_list);
//           setIsLoading(false);

//         } catch (error) {
//           console.log("error", error);
//           setIsLoading(false);
//         }
//       } catch (error) {
//         console.log("error", error);
//         setIsLoading(false);
//       }
//     } catch (error) {
//     setIsLoading(false);

//       console.log("error", error);
//     }
//   }

//   const handleInfo = (key) => {
//       setIsOpen(true);
//     setSelectedKey({
//       header: key?.label,
//       key: key?.key
//     })
//   }

//   // Callbacks
//   useEffect(() => {
//     if (activeTabIndex === "most-active" || activeTabIndex === "market-gainers" || activeTabIndex === "market-gainers") {
//       getAllSymbols();
//     }
//   }, [activeTabIndex])

//   // Template
//   const renderTabView = () => {
//     return <>
//       <Grid item xs={12} sm={12} md={12} lg={12} className="mb-10">
//         <Box sx={{ border: "1px solid #e3e3e3", borderRadius: "10px" }}>
//           <div className={`${styles.tabWrapper} top_button_panel v2 mt-4 mb-3`}>
//             {MARKET_MOVERS?.map(
//               (key) => {
//                 return (
//                   <>
//                     <button
//                       key={key.value}
//                       type="button"
//                       className={`btn ${styles.tabBtn} ${activeTabIndex === key?.value ? styles.activeBtn : styles.deActiveBtn}`}
//                       onClick={() => {
//                         setActiveTabIndex(key?.value);
//                       }}
//                     >
//                       {key?.label}
//                     </button>
//                       <button
//                                 className={`${styles.iconBtn} ${activeTabIndex === key?.value ? styles.activeIconBtn : ""}`}
//                                 onClick={() => handleInfo(key)}
//                               >
//                                 <div className={styles.iconWrapper}>
//                                   <img src={image} />
//                                 </div>
//                               </button>
//                   </>
//                 );
//               }
//             )}
//           </div>
//         </Box>
//       </Grid>
//     </>
//   }

//   const renderComingSoon = () => {
//     return <div className={styles.comingSoonWrp} >Coming Soon...</div>
//   }

//   const renderBody = () => {
//     switch (activeTabIndex) {
//       case "most-active":
//         return <MarketTable data={tableData ?? []} colData={MOST_ACTIVE_COLUMNS} />
//       case "market-gainers":
//         return <MarketTable data={tableData ?? []} colData={MOST_ACTIVE_COLUMNS} />
//       case "market-losers":
//         return <MarketTable data={tableData ?? []} colData={MOST_ACTIVE_COLUMNS} />
//       case "top-undervalued":
//         return renderComingSoon();
//       case "top-overvalued":
//         return renderComingSoon();
//       case "top-underprice":
//         return renderComingSoon();
//       case "top-overprice":
//         return renderComingSoon();
//       default:
//         break;
//     }
//    return
//   }

//   const renderHeading = () => {
//     switch (activeTabIndex) {
//            case "most-active":
//         return "Today's Most Active Stocks"
//       case "market-gainers":
//         return "Today's Market Gainers Stocks"
//       case "market-losers":
//         return "Today's Market losers Stocks"
//       case "top-undervalued":
//         return "Today's Top Undervalued Stocks";
//       case "top-overvalued":
//         return "Today's Top Overvalued Stocks";
//       case "top-underprice":
//         return "Today's Top Underprice Stocks";
//       case "top-overprice":
//         return "Today's Top Overprice Stocks";
//       default:
//         break;
//     }
//   }

//   return (
//     <div>
//       {/* {renderTabView()}
//       <div className={styles.heading} >{renderHeading()}</div>
//       {!isLoading && renderBody()}
//       {isLoading && <InvexLoader height={240} />}
//       {isOpen && <MarketInfo selectedKey={selectedKey} setIsOpen={setIsOpen} isOpen={isOpen} />} */}
//     </div>
//   )
// }

// export default MostActive

import React,{useEffect} from 'react'
import MarketTable from '../MarketTable'
import { MOST_ACTIVE_COLUMNS } from '../../Symbol/FinancialStatement/Constants'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AtomActiveLoader, AtomActiveTableData, AtomMostActiveTab } from '../../../../Atoms/MarketAtom'
import styles from './mostActive.module.css'
import MostActiveTab from '../MostActiveTab/MostActiveTab'
import InvexLoader from '../../../Common/InvexLoader'
import { HelmetTitle, TechSEOPageOnTitle } from '../../../../helpers/TechSEO'
import InvexRoutes from '../../../../InvexRoutes'
import MarketTab from '../MarketTab/MarketTab'
import InsightCornor from '../InsightCornor/InsightCornor'

const MostActive = () => {
  // Variables
  const tableData = useRecoilValue(AtomActiveTableData)
  const setActiveTabIndex = useSetRecoilState(AtomMostActiveTab)
  const loader = useRecoilValue(AtomActiveLoader)

  // Callbacks
  useEffect(() => {
    setActiveTabIndex("most-active")
  }, []);
  
    const getPageSeo = () => {
    let obj = {
      pageHeadTitle: "Today's Most Active Stocks",
      pageTitle: TechSEOPageOnTitle("Today's Most Active Stocks - AmassingInvestment.com"),
      pageHeadDescription: "Discover Today's Most Active Stocks with High Volume. Stay updated, identify trends, real-time insights, news and boost ROI with informed decisions.",
    };
    return obj;
  };
  const seoData = getPageSeo();
  const getHelmet = () => {
    return HelmetTitle(seoData, InvexRoutes.ActiveStocks.path);
  };

  

 
 
  return (
    <div className='container' >
      <MarketTab />
      {getHelmet()}
       <MostActiveTab />
      <h1 className={styles.heading} >Today's Most Active Stocks</h1>
      {loader && <InvexLoader height={240} />}
      {
        !loader &&
        <>
      <MarketTable data={tableData ?? []} colData={MOST_ACTIVE_COLUMNS} />  
        </>
      }
      <InsightCornor />
    </div>
  )
}

export default MostActive