import React,{useState, useEffect} from 'react'
import { AtomActiveLoader, AtomActiveTableData, AtomMostActiveTab } from '../../../../Atoms/MarketAtom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styles from './mostActive.module.css'
import MarketInfo from '../MarketInfo/MarketInfo';
import axios from 'axios'
import { NormalFormat } from '../../../Common/NumberFormat';
import { dash, getToken, underScore } from '../../../Common/CommonFunctions';
import { Grid, Box } from "@material-ui/core";
import image from '../../../Common/Images/4632221.png'
import { MARKET_MOVERS } from '../../MacroEconomics/Constants';
import InvexRoutes from '../../../../InvexRoutes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getInsightCornor, getMarketData, getValuvationPrice } from '../../../api/Symbol';
import { SECTOR_WISE_INDUSTRY } from '../../Screener/Constants';
import { fetchMarketSymbols } from '../../../api/V2/user';
const MostActiveTab = () => {
  // Variables
    const activeTabIndex = useRecoilValue(AtomMostActiveTab)
  const setTableData = useSetRecoilState(AtomActiveTableData);
  const setIsLoading = useSetRecoilState(AtomActiveLoader);
  const [isOpen, setIsOpen] = useState(false);
   const [selectedKey, setSelectedKey] = useState({
    header: "",
    key: "",
   });
  const path = useLocation().pathname;

  const getCurrentPath = (pathName) => {
    const _path = pathName;
    let currentIndex = ""
    switch (_path) {
      case InvexRoutes.OverpricedStocks.path:
          currentIndex = "top-overprice"
        break;
      case InvexRoutes.UnderPricedStocks.path:
          currentIndex = "top-underprice"
        break;
      case InvexRoutes.OverValuedStocks.path:
          currentIndex = "top-overvalued"
        break;
      case InvexRoutes.UnderValuedStocks.path:
          currentIndex = "top-undervalued"
        break;
      case InvexRoutes.MarketLosers.path:
          currentIndex = "market-losers"
        break;
      case InvexRoutes.MarketGainer.path:
          currentIndex = "market-gainers"
        break;
      case InvexRoutes.ActiveStocks.path:
          currentIndex = "most-active"
        break;
    }
    return currentIndex
  }

  const navigate = useNavigate();
  const getAllSymbols = async () => {
    setIsLoading(true);
    let url = "";
    const currentPath = getCurrentPath(path);
    switch (currentPath) {
      case "most-active":
        url = "https://financialmodelingprep.com/api/v3/stock_market/actives?apikey=b1360803f80dd08bdd0211c5c004ad03"
          break;
        case "market-gainers":
        url = "https://financialmodelingprep.com/api/v3/stock_market/gainers?apikey=b1360803f80dd08bdd0211c5c004ad03"
          break;
        case "market-losers":
        url = "https://financialmodelingprep.com/api/v3/stock_market/losers?apikey=b1360803f80dd08bdd0211c5c004ad03"
          break;
    }
    try {
      const symbolsList = [];
      let obj = {};
      let _chargePer = {};
      let IsMarket = (currentPath === "top-undervalued" || currentPath === "top-overvalued" || currentPath === "top-underprice" || currentPath === "top-overprice") ? true : false;
      if (IsMarket) {
        let _slug = currentPath === "top-undervalued" ? "most-undervalued" : currentPath === "top-overvalued" ? "most-overvalued" : currentPath === "top-underprice" ? "most-underpriced" : "most-overpriced";  
        const payload = { portfolio_slug: _slug };

        const response = await fetchMarketSymbols(payload);
        let _data = response?.data?.ticker_values ?? [];

        for (const data of _data) {
          symbolsList.push(data.symbol)
        }
      } else {
        const response = await getInsightCornor({
          url: url,
        });
   
      let index = 0;
      for (const symbol of response?.data ?? []) {
       index++
        symbolsList.push(symbol.symbol);
        _chargePer[symbol.symbol] = {
          symbol : symbol?.changesPercentage,
          id: index
          }
        }
      }
      try {
        const payload = symbolsList.toString();
         const response = await getInsightCornor({
          url: `https://financialmodelingprep.com/api/v3/profile/${payload}?apikey=b1360803f80dd08bdd0211c5c004ad03`,
         });
        let resList = [];
        for (const res of response?.data ?? []) {
          const newObj = {};
          newObj["symbol"] = res?.symbol;
          newObj["sector"] = res?.sector?.toLowerCase() + "-industry-analysis";
          newObj["price"] = res?.price?.toFixed(2);
          newObj["changes"] = IsMarket ? res?.changes : _chargePer[res?.symbol]?.symbol?.toFixed(2) + "%";
          newObj["id"] = _chargePer[res?.symbol]?.id;
          newObj["marketCap"] = NormalFormat(res?.mktCap);
          newObj["volume"] = NormalFormat(res?.volAvg);
          newObj["valuvation"] = "";
          newObj["pricing"] = "";
          newObj["industry"] = res?.industry;
          newObj["range"] = res?.range;
          resList.push(newObj);
          obj[res?.symbol] = newObj
        }
        try {
          const response = await getValuvationPrice({ symbols: symbolsList });
          let _list = []
          for (const data of response ?? []) {
            let _newObj = { ...obj[data?.symbol] };
            _newObj["valuvation"] = (_newObj?.industry === "Financial Services" || _newObj?.industry === "Real Estate") ? data?.ermValuation?.toFixed(2) : data?.fcffValuation?.toFixed(2);
            _newObj["pricing"] = data?.pricing?.toFixed(2);
            _list.push(_newObj)
          }
            let _sortedOrder = _list?.sort((a, b) => {
              return a.id > b.id ? 1 : -1;
            });
          setTableData(_sortedOrder);
          setIsLoading(false);

        } catch (error) {
          console.log("error", error);
          setIsLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
        console.log("error", error);
      }
  }
  
    const handleInfo = (key) => {
      setIsOpen(true);
      setSelectedKey({
        header: key?.label,
        key: key?.key
      });
  }

  // Callbacks
  useEffect(() => {
    if (path) {
      getAllSymbols();
    }
  }, [ path])


  const handleRoute = (value) => {
    let path = ""
     switch (value) {
       case "most-active":
       path = InvexRoutes.ActiveStocks.path   
         break;
      case "market-gainers":
         path = InvexRoutes.MarketGainer.path   
         break;
      case "market-losers":
      path = InvexRoutes.MarketLosers.path   
      break;
      case "top-undervalued":
      path = InvexRoutes.UnderValuedStocks.path   
      break;
      case "top-overvalued":
      path = InvexRoutes.OverValuedStocks.path   
      break;
      case "top-underprice":
      path = InvexRoutes.UnderPricedStocks.path   
      break;
      case "top-overprice":
      path = InvexRoutes.OverpricedStocks.path   
      break;
      default:
        break;
    }
    navigate(path)
  }

    const renderTabView = () => {
    return <>
      <Grid item xs={12} sm={12} md={12} lg={12} className="mb-10">
        <Box sx={{ border: "1px solid #e3e3e3", borderRadius: "10px" }}>
          <div className={`${styles.tabWrapper} top_button_panel v2 mt-4 mb-3`}>
            {MARKET_MOVERS?.map(
              (key) => {
                return (
                  <>
                    <button
                      key={key.value}
                      type="button"
                      className={`btn ${styles.tabBtn} ${activeTabIndex === key?.value ? styles.activeBtn : styles.deActiveBtn}`}
                      onClick={() => handleRoute(key?.value)}
                    >
                      {key?.label}
                    </button>
                      <button
                                className={`${styles.iconBtn} ${activeTabIndex === key?.value ? styles.activeIconBtn : ""}`}
                                onClick={() => handleInfo(key)}
                              >
                                <div className={styles.iconWrapper}>
                                  <img src={image} />
                                </div>
                              </button>
                  </>
                );
              }
            )}
          </div>
        </Box>
      </Grid>
    </>
  }
  return (
    <div>
      {renderTabView()}
      {isOpen && <MarketInfo selectedKey={selectedKey} setIsOpen={setIsOpen} isOpen={isOpen} />}
    </div>
  )
}

export default MostActiveTab
