import React,{useEffect} from 'react'
import MarketTable from '../MarketTable'
import { MOST_ACTIVE_COLUMNS } from '../../Symbol/FinancialStatement/Constants'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AtomActiveLoader, AtomActiveTableData, AtomMostActiveTab } from '../../../../Atoms/MarketAtom'
import styles from './marketLoser.module.css'
import MostActiveTab from '../MostActiveTab/MostActiveTab'
import InvexLoader from '../../../Common/InvexLoader'
import { HelmetTitle, TechSEOPageOnTitle } from '../../../../helpers/TechSEO'
import InvexRoutes from '../../../../InvexRoutes'
import MarketTab from '../MarketTab/MarketTab'
import InsightCornor from '../InsightCornor/InsightCornor'

const MarketLosers = () => {
  // Variables
  const tableData = useRecoilValue(AtomActiveTableData)
  const setActiveTabIndex = useSetRecoilState(AtomMostActiveTab)
  const loader = useRecoilValue(AtomActiveLoader)

  // Callbacks
  useEffect(() => {
    setActiveTabIndex("market-losers")
  },[])
 
     const getPageSeo = () => {
    let obj = {
      pageHeadTitle: "Today's Top Stock Losers - AmassingInvestment.com",
      pageTitle: TechSEOPageOnTitle("Today's Top Stock Winners - AmassingInvestment.com"),
      pageHeadDescription: "Discover Today's Top Stock Losers: Analyze price declines, track performance, leverage real-time insights, news and market trends to boost ROI.",
    };
    return obj;
  };
  const seoData = getPageSeo();
  const getHelmet = () => {
    return HelmetTitle(seoData, InvexRoutes.MarketGainer.path);
  };
  return (
    <div className='container'>
      <MarketTab />

      {getHelmet()}
       <MostActiveTab />
      <h1 className={styles.heading} >Today's Market losers Stocks</h1>
            {loader && <InvexLoader height={240} />}

      {!loader && <MarketTable data={tableData ?? []} colData={MOST_ACTIVE_COLUMNS} />}  
      <InsightCornor />
    </div>
  )
}

export default MarketLosers