export const TYPE = {
  balanceSheet: { label: "Balance Sheet", value: "balance-sheet" },
  incomeStatement: { label: "Income Statement", value: "income-statement" },
  cashFlow: { label: "Cash Flow", value: "cash-flow" },
};

// export const BALANCE_SHEET_COLUMNS = [
//   'cashAndCashEquivalents',
//   'shortTermInvestments',
//   'cashAndShortTermInvestments',
//   'netReceivables',
//   'inventory',
//   'otherCurrentAssets',
//   'totalCurrentAssets',
//   'propertyPlantEquipmentNet',
//   'goodwill',
//   'intangibleAssets',
//   'goodwillAndIntangibleAssets',
//   'longTermInvestments',
//   'taxAssets',
//   'otherNonCurrentAssets',
//   'totalNonCurrentAssets',
//   'otherAssets',
//   'totalAssets',
//   'accountPayables',
//   'shortTermDebt',
//   'taxPayables',
//   'deferredRevenue',
//   'otherCurrentLiabilities',
//   'totalCurrentLiabilities',
//   'longTermDebt',
//   'deferredRevenueNonCurrent',
//   'deferredTaxLiabilitiesNonCurrent',
//   'otherNonCurrentLiabilities',
//   'totalNonCurrentLiabilities',
//   'otherLiabilities',
//   'capitalLeaseObligations',
//   'totalLiabilities',
//   'preferredStock',
//   'commonStock',
//   'retainedEarnings',
//   'accumulatedOtherComprehensiveIncomeLoss',
//   'othertotalStockholdersEquity',
//   'totalStockholdersEquity',
//   'totalLiabilitiesAndStockholdersEquity',
//   'minorityInterest',
//   'totalEquity',
//   'totalLiabilitiesAndTotalEquity',
//   'totalInvestments',
//   'totalDebt',
//   'netDebt',
// ];

export const MOST_ACTIVE_COLUMNS = [
  {
    key: "symbol",
    label: "Symbol",
  },
  {
    key: "price",
    label: "Price",
  },
  {
    key: "changes",
    label: "Changes",
  },
  {
    key: "marketCap",
    label: "Market Cap",
  },
  
  {
    key: "volume",
    label: "Volume",
  },
  {
    key: "valuvation",
    label: "DCF Valuation",
  },
  {
    key: "pricing",
    label: "Relative Valuation",
  },
  {
    key: "industry",
    label: "Industry",
    
  },
  {
    key: "range",
    label: "52W Range",
  },
  
]
export const MARKET_GAINERS_COLUMNS = [
  
  {
    key: "symbol",
    label: "Symbol",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "price",
    label: "Price",
  },
  {
    key: "changesPercentage",
    label: "Changes",
  },
  {
    key: "rangeLow",
    label: "52W Low",
  },
  {
    key: "rangeHigh",
    label: "52W High",
  },
  {
    key: "country",
    label: "Country",
  },
 
  
]
export const ECONOMIC_CALENDER_COLUMNS = [
  {
    key: "fDate",
    label: "Date",
  },
  {
    key: "time",
    label: "Time",
  },
   {
    key: "country",
    label: "Country",
  },
  {
    key: "currency",
    label: "Currency",
  },
  {
    key: "event",
    label: "Event",
  },
  {
    key: "actual",
    label: "Actual",
  },
  {
    key: "estimate",
    label: "Estimate",
  },
  {
    key: "previous",
    label: "Previous",
  },
  {
    key: "changePercentage",
    label: "Charge",
  },
  {
    key: "impact",
    label: "Impact",
  },
  
]
export const SOCIAL_SENTIMENT_COLUMNS = [
  {
    key: "rank",
    label: "Rank",
  },
   {
    key: "symbol",
    label: "Symbol",
  },
  {
    key: "sentiment",
    label: "Sentiment",
  },
  {
    key: "lastSentiment",
    label: "Last Sentiment",
  },
  {
    key: "valuvation",
    label: "DCF Valuation",
  },
  {
    key: "pricing",
    label: "Relative Valuation",
  }
  
]
export const SENTIMENT_SHIFT_COLUMNS = [
  {
    key: "rank",
    label: "Rank",
  },
   {
    key: "symbol",
    label: "Symbol",
  },
  {
    key: "sentiment",
    label: "Sentiment",
  },
  {
    key: "sentimentChange",
    label: "Sentiment Change",
  },
  {
    key: "valuvation",
    label: "DCF Valuation",
  },
  {
    key: "pricing",
    label: "Relative Valuation",
  }
  
]
export const EARNING_CALENDER_COLUMNS = [
  {
    key: "fDate",
    label: "Date",
  },
  {
    key: "time",
    label: "Time",
  },
   {
    key: "symbol",
    label: "Symbol",
  },
  {
    key: "title",
    label: "Title",
  },
  {
    key: "publicationDate",
    label: "Publication Date",
  },
  // {
  //   key: "date",
  //   label: "Date",
  // },
  {
    key: "link",
    label: "Link",
  },
  
  
]

export const CURRENT_ASSETS_COLUMNS = [
  {
    key: "cashAndCashEquivalents",
    label: "Cash And Cash Equivalents",
    tooltip: (
      <>
        <p>
          <strong>Definition: </strong> Cash and Cash Equivalents refer to the line item on the balance sheet that reports the value of a
          company&apos;s assets that are cash or can be converted into cash immediately. These include bank accounts and marketable securities, such
          as government bonds and banker&apos;s acceptances.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Cash and cash equivalents are a company&apos;s most liquid assets and are a key indicator of a
          company&apos;s immediate financial health. A high amount of cash and cash equivalents means a company can pay its debt more quickly than a
          company with less liquidity.
        </p>
        <p>
          <strong>Application:</strong> Investors may calculate the &quot;cash ratio,&quot; which is Cash and Cash Equivalents / Current Liabilities.
          This ratio reveals the company&apos;s ability to cover its current liabilities using only cash and cash equivalents. A higher cash ratio
          indicates a better position of the company.
        </p>
      </>
    ),
  },
  {
    key: "shortTermInvestments",
    label: "Short Term Investments",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Short-term investments, also known as marketable securities or temporary investments, are those which can
          easily be converted to cash, typically within 5 years.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Companies often make these investments to earn on idle cash. However, high levels of short-term
          investments may suggest that the company is not effectively utilizing its assets to grow its operations.
        </p>
        <p>
          <strong>Application:</strong> Investors can compare short-term investments to long-term investments to gauge the company&apos;s investment
          strategy. A higher ratio of short-term to long-term investments may suggest a conservative approach.
        </p>
      </>
    ),
  },
  {
    key: "cashAndShortTermInvestments",
    label: "Cash And Short Term Investments",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> This is the sum of cash, cash equivalents, and short-term investments.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> This combined figure can provide a more complete picture of the company&apos;s highly liquid
          assets.
        </p>
        <p>
          <strong>Application:</strong> Investors can use this figure in liquidity ratios like the current and quick ratio. A higher amount can imply
          stronger liquidity and potentially a more conservative investment strategy.
        </p>
      </>
    ),
  },
  {
    key: "netReceivables",
    label: "Net Receivables",
    tooltip: (
      <>
        <p>
          <strong>Definition: </strong> Net Receivables are the total money owed to a company by its customers for goods or services delivered or used
          but not yet paid for. It&apos;s found in the current assets section of the firm&apos;s balance sheet.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> The amount of net receivables provides an insight into the company&apos;s credit sales and
          collections process. If the receivables are high, it indicates that a company might be taking a risk by extending credit to customers.
        </p>
        <p>
          <strong>Application:</strong> Investors can analyze the &quot;Receivables Turnover Ratio&quot; or &quot;Days Sales Outstanding&quot; to
          understand how efficiently a company collects its debt. Lower ratios can indicate better performance.
        </p>
      </>
    ),
  },
  {
    key: "inventory",
    label: "Inventory",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Inventory refers to the raw materials, work-in-progress goods, and completely finished goods that are
          considered to be the portion of a business&apos;s assets that are ready or will be ready for sale.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> The amount of inventory a company holds can indicate the management of production process and sales
          effort. High inventory levels may indicate low sales and, consequently, poor liquidity or storage costs.
        </p>
        <p>
          <strong>Application:</strong> Investors look at the &quot;Inventory Turnover Ratio&quot; to see how often a company replaces its inventory.
          A low turnover rate may point to overstocking, obsolescence, or deficiencies in the product line or marketing effort.
        </p>
      </>
    ),
  },
  {
    key: "otherCurrentAssets",
    label: "Other Current Assets",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Other current assets is a category that includes assets that are not classified under typical current asset
          headings but are expected to be converted into cash or used up within a year.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Other current assets is a catch-all category, so investors should scrutinize these assets carefully
          to understand what they are. It could be prepaid expenses, derivative instruments, or other items that provide economic benefit within a
          year.
        </p>
        <p>
          <strong>Application:</strong> Investors should consider the composition and trends in &quot;Other Current Assets.&quot; Large or growing
          amounts might be a warning sign, as they may contain items that are hard to convert into cash.
        </p>
      </>
    ),
  },
  {
    key: "totalCurrentAssets",
    label: "Total Current Assets",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total current assets is the sum of cash, cash equivalents, accounts receivable, inventory, marketable
          securities, prepaid expenses, and other liquid assets that can be readily converted to cash.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> This measure is a key indicator of a company&apos;s short-term liquidity, the ability of a company
          to meet its short-term obligations.
        </p>
        <p>
          <strong>Application:</strong> Investors often calculate the &quot;Current Ratio&quot; (Total Current Assets / Total Current Liabilities) to
          measure a company&apos;s ability to pay off its short-term liabilities with its short-term assets. A ratio above 1 means that the company
          has more current assets than liabilities, which is generally a positive sign.
        </p>
      </>
    ),
  },
];

export const NON_CURRENT_ASSETS_COLUMNS = [
  {
    key: "propertyPlantEquipmentNet",
    label: "Property Plant Equipment Net",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Property, Plant, and Equipment (PP&amp;E) net represents a company&apos;s tangible fixed assets that are
          expected to be used for more than one year, such as buildings, land, equipment, machinery, vehicles, etc. &quot;Net&quot; indicates that it
          is shown after deducting accumulated depreciation.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> PP&amp;E is a significant component of the total assets for companies in many industries,
          especially for manufacturing, utility, and real estate firms. They are crucial for these companies to generate revenue.
        </p>
        <p>
          <strong>Application:</strong> Investors should look at changes in PP&amp;E net over time. Significant increases might indicate major
          investments for growth, while significant decreases could mean the company is selling off assets or that its assets are depreciating without
          replacement.
        </p>
      </>
    ),
  },
  {
    key: "goodwill",
    label: "Goodwill",
    tooltip: (
      <>
        <p>
          <strong>Definition: </strong>&nbsp;Goodwill is an intangible asset that represents the excess value of an acquired business over the fair
          value of its separately identifiable net assets. Goodwill can include the value of a strong brand name, good customer relations, high
          employee morale, and any patents or proprietary technology.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Goodwill reflects the value that a company has added through its past acquisitions. High goodwill
          can indicate that the company has been successful in making accretive acquisitions.
        </p>
        <p>
          <strong>Application:</strong> Investors should be aware that if an acquisition does not generate the expected returns, the company might
          have to write down the goodwill, which could lead to significant losses.
        </p>
      </>
    ),
  },
  {
    key: "intangibleAssets",
    label: "Intangible Assets",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Intangible assets are non-physical assets that have a useful life of greater than one year. These can include
          patents, copyrights, trademarks, brand recognition, and other intellectual property.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Intangible assets can be a significant source of value for many companies, especially those in
          technology or creative industries.
        </p>
        <p>
          <strong>Application:</strong> When assessing a company, investors should consider the value and durability of its intangible assets. These
          can provide a competitive advantage and generate future earnings.
        </p>
      </>
    ),
  },
  {
    key: "goodwillAndIntangibleAssets",
    label: "Goodwill And Intangible Assets",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> The combination of Goodwill and Intangible Assets forms a significant part of the asset value of a company,
          especially for those in high tech, pharmaceutical, and other innovation-driven industries.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> The collective value of Goodwill and Intangible Assets can represent the hidden value not apparent
          in the physical assets of a company. High amounts can indicate a company&apos;s potential for future growth, due to factors like superior
          intellectual property, strong brand names, or exceptional customer relationships.
        </p>
        <p>
          <strong>Application:</strong> An investor needs to carefully analyze these assets because their impairment can lead to significant financial
          adjustments. An annual impairment test is required for Goodwill and certain Intangible Assets, and any write-down can impact the
          company&apos;s financial health.
        </p>
      </>
    ),
  },
  {
    key: "longTermInvestments",
    label: "Long Term Investments",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong>&nbsp;Long-term investments, also known as non-current investments, are investments a company intends to hold
          for more than a year. These might include bonds, notes, common stocks, preferred stocks, and investments in affiliated companies.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Long-term investments can provide a significant return for companies. They indicate that a company
          has sufficient cash flow and liquidity to invest in its future growth and not just rely on its immediate operational income.
        </p>
        <p>
          <strong>Application:</strong> Investors can view a company&apos;s long-term investments as a sign of financial stability and foresight.
          However, like all investments, these are subject to risk and can decrease in value, impacting the company&apos;s bottom line.
        </p>
      </>
    ),
  },
  {
    key: "taxAssets",
    label: "Tax Assets",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Tax assets are a type of asset that can be used to reduce any future tax liability of the company. They can
          include carry forward losses, tax credits, prepaid taxes, and deferred tax assets.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> A large amount of tax assets can indicate that the company has significant carry-forward losses,
          which may be indicative of past financial struggles. However, these tax assets can also provide a financial benefit in the future by
          reducing the company&apos;s tax liability.
        </p>
        <p>
          <strong>Application:</strong> An investor should consider the amount of tax assets in relation to the company&apos;s profitability. While
          they can provide future tax relief, they are not an indication of profitability.
        </p>
      </>
    ),
  },

  {
    key: "otherNonCurrentAssets",
    label: "Other Non Current Assets",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Other non-current assets are a catch-all category for any long-term assets not included in the other
          categories. These can vary widely from company to company and may include things like long-term prepaid expenses, deferred charges,
          receivables, and investments in unconsolidated subsidiaries.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> While these assets may not form a large part of a company&apos;s total assets, their existence can
          often point to unique aspects of a company&apos;s operations or financial strategies.
        </p>
        <p>
          <strong>Application:</strong> Investors should ensure they understand what these assets are and why the company holds them. If these assets
          form a significant part of the company&apos;s asset structure, the investor should understand why.
        </p>
      </>
    ),
  },

  {
    key: "totalNonCurrentAssets",
    label: "Total Non Current Assets",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total non-current assets are the sum of all long-term assets that a company owns. These are resources that a
          company expects to convert into cash beyond the next 12 months and typically include things like property, plant and equipment, long-term
          investments, and intangible assets such as patents and copyrights.
        </p>
        <p>
          <strong>Relevance to Investors:</strong>&nbsp;Total non-current assets can give an investor an indication of the resources the company has
          invested in its long-term growth and sustainability. High total non-current assets can be a sign of a mature company with significant
          investments in its future.
        </p>
        <p>
          <strong>Application:</strong> When analyzing a company, investors should consider how the company&apos;s non-current assets compare to its
          current assets and liabilities. If a
        </p>
      </>
    ),
  },

  {
    key: "otherAssets",
    label: "Other Assets",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Other assets refer to items that don&apos;t fit into the standard asset categories of current and non-current
          assets. They might include items like long-term prepayments, deferred tax assets, or other unique assets specific to the business.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> While not typically significant, &apos;Other Assets&apos; can be crucial to some businesses, like
          insurance firms, where deferred acquisition costs - a key part of other assets - can be significant. Investors should understand what these
          other assets include, as they may impact the financial stability and future earnings potential of the business.
        </p>
        <p>
          <strong>Application:</strong> Investors should seek clarity on what comprises &apos;Other Assets.&apos; If the category is substantial,
          further investigation is warranted to understand its composition and evaluate if these are high-quality assets that contribute to earnings.
        </p>
      </>
    ),
  },

  {
    key: "totalAssets",
    label: "Total Assets",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total assets are the sum of all current and non-current assets a company owns. It includes everything the
          company owns that has value, such as cash, inventory, property, and investments.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Total assets give investors a comprehensive view of the resources controlled by a company and is an
          indication of the size and financial health of the company.
        </p>
        <p>
          <strong>Application:</strong> Comparing total assets over time can provide insight into whether the company is growing and managing its
          assets effectively. It&apos;s also a key component in many financial ratios, such as return on assets (ROA), that help assess a
          company&apos;s profitability and efficiency.
        </p>
      </>
    ),
  },
];

export const CURRENT_LIABILITIES_COLUMNS = [
  {
    key: "accountPayables",
    label: "Account Payables",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Accounts payable represent the money a company owes to its suppliers or vendors for goods or services received
          but not yet paid for.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> A rising accounts payable could indicate cash flow problems, while decreasing accounts payable
          might suggest the company is paying its suppliers more quickly, possibly indicating strong cash flow. However, an extremely low accounts
          payable relative to industry peers could suggest the company is not taking full advantage of credit terms offered by suppliers.
        </p>
        <p>
          <strong>Application:</strong> Investors should monitor changes in accounts payable relative to changes in sales or cost of goods sold. Large
          increases in accounts payable without corresponding increases in sales or cost of goods sold could signal cash flow problems.
        </p>
      </>
    ),
  },

  {
    key: "shortTermDebt",
    label: "Short Term Debt",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Short term debt, also known as current debt or current portion of long term debt, is the part of the
          company&apos;s debt which is due within the next one year.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Short term debt gives insight into the financial obligations the company has to fulfill within a
          year. Higher amounts might indicate the firm is heavily leveraged, which could be risky if the company&apos;s income isn&apos;t sufficient
          to meet these obligations.
        </p>
        <p>
          <strong>Application:</strong> Investors can use the &apos;Debt Ratio&apos; (Total Debt / Total Assets) to gauge a company&apos;s overall
          debt situation. A high ratio could indicate the company is heavily financed by debt, which may be risky during downturns in the market or
          economy.
        </p>
      </>
    ),
  },

  {
    key: "taxPayables",
    label: "Tax Payables",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Tax payables represent the amount a company owes in taxes that are due within the current year. These taxes
          could be income taxes, sales taxes, payroll taxes, and others depending on the nature of the company&apos;s operations.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Monitoring tax payables gives an investor insight into a company&apos;s tax management strategies
          and the potential future cash outflows dedicated to tax payments.
        </p>
        <p>
          <strong>Application:</strong> Investors should compare tax payables with the income before tax to calculate the effective tax rate. A
          significantly lower effective tax rate than the statutory tax rate might imply aggressive tax planning strategies.
        </p>
      </>
    ),
  },

  {
    key: "deferredRevenue",
    label: "Deferred Revenue",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Deferred revenue, also known as unearned revenue, is the advance payments a company receives for products or
          services that are to be delivered or performed in the future.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> High deferred revenue can indicate that a company has a strong future performance as it already has
          revenue to be recognized in the future. However, it is also a liability because if the company fails to deliver, it would need to return
          these funds.
        </p>
        <p>
          <strong>Application:</strong> Investors can monitor changes in deferred revenue. A consistent increase could indicate growing demand for the
          company&apos;s products or services.
        </p>
      </>
    ),
  },

  {
    key: "otherCurrentLiabilities",
    label: "Other Current Liabilities",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Other current liabilities are any other obligations not classified under specific current liability accounts,
          due within the next year. They can include items such as accrued expenses, dividends payable, customer deposits, etc.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Other current liabilities can sometimes contain significant obligations not expressly disclosed
          elsewhere. Hence, investors should carefully consider this account for a complete picture of a company&apos;s short-term liquidity
          situation.
        </p>
        <p>
          <strong>Application:</strong> Investors should be cautious of sudden increases in other current liabilities, as it may indicate a
          significant expense or obligation not otherwise disclosed.
        </p>
      </>
    ),
  },

  {
    key: "totalCurrentLiabilities",
    label: "Total Current Liabilities",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Non-current liabilities, also known as long-term liabilities, are obligations due beyond one year or the normal
          operating cycle of the business, whichever is longer. These can include items like long-term debt, deferred tax liabilities, and pension
          obligations.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Non-current liabilities are significant as they give insight into the company&apos;s long-term
          financial health. High long-term liabilities can indicate significant future cash outflows, which may affect the company&apos;s
          profitability and stability.
        </p>
        <p>
          <strong>Application:</strong> Investors should consider the ratio of non-current liabilities to total assets to get a sense of the
          company&apos;s leverage. High ratios may indicate higher risk, but they can also result from the company&apos;s growth strategies.
        </p>
      </>
    ),
  },
];

export const NON_CURRENT_LIABILITIES_COLUMNS = [
  {
    key: "longTermDebt",
    label: "Long Term Debt",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Long-term debt refers to any financial obligations that are due more than one year from the current date.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> 
 Long-term debt is critical for investors as it represents a company&apos;s obligations to repay its
          creditors in the long run. A company with excessive long-term debt might face cash flow problems.       </p>
        <p>
          <strong>Application:</strong> Investors can look at the debt-to-equity ratio, which indicates how much of the company&apos;s financing comes
          from debt versus shareholder equity. If this ratio is high, it might indicate that the company has taken on a significant amount of risk.
        </p>
      </>
    ),
  },

  {
    key: "deferredRevenueNonCurrent",
    label: "Deferred Revenue Non Current",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Deferred Revenue Non Current, also known as Unearned Revenue, represents advance payments received by the
          company for goods or services that are to be delivered or performed in the future beyond the timeframe of one year.
        </p>
        <p>
          <strong>Relevance to Investors:</strong>&nbsp;This is important to investors as it represents a liability that the company has to fulfil in
          the future. It indicates that the company has a cash flow now, but a responsibility to deliver a product or service later.
        </p>
        <p>
          <strong>Application:</strong> Investors can compare deferred revenue to total revenue to get a sense of how much of the company&apos;s
          earnings are dependent on fulfilling future obligations. Higher amounts of deferred revenue may indicate more secure future earnings, but
          also point towards future commitments.
        </p>
      </>
    ),
  },

  {
    key: "deferredTaxLiabilitiesNonCurrent",
    label: "Deferred Tax Liabilities Non Current",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Deferred Tax Liabilities Non Current are tax amounts that a company expects to pay in the future, more than a
          year later, due to differences between its accounting methods and the tax regulations it is subject to.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> This metric is crucial to investors as it shows potential future outflows that can impact the
          company&apos;s profits. It also indicates how much the company&apos;s tax expense may increase when these liabilities become due.
        </p>
        <p>
          <strong>Application:</strong> Investors should factor in deferred tax liabilities when considering a company&apos;s future profitability and
          cash flows. High deferred tax liabilities might mean future cash outflows which could impact profitability.
        </p>
      </>
    ),
  },

  {
    key: "otherNonCurrentLiabilities",
    label: "Other Non Current Liabilities",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Other Non Current Liabilities are liabilities that don&apos;t fall under the standard categories, and are not
          due within the current year or operating cycle. They might include items like pension liabilities, warranty obligations, or long-term
          leases.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> These liabilities are crucial for investors to consider as they could have a significant impact on
          the company&apos;s long-term financial health. They could signal potential future cash outflows which might affect the company&apos;s
          profitability and cash flow.
        </p>
        <p>
          <strong>Application:</strong> Investors should analyze these liabilities in relation to the company&apos;s overall financial condition. A
          high amount of Other Non Current Liabilities compared to the company&apos;s assets or earnings may indicate financial risk.
        </p>
      </>
    ),
  },

  {
    key: "totalNonCurrentLiabilities",
    label: "Total Non Current Liabilities",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total Non Current Liabilities is the sum of all the company&apos;s long-term liabilities, such as long-term
          debt, deferred tax liabilities, and other non-current liabilities.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> This number provides investors with a clearer picture of the company&apos;s long-term financial
          obligations. It is a key indicator of the company&apos;s leverage and long-term solvency.
        </p>
        <p>
          <strong>Application:</strong> Investors should consider this number in the context of the company&apos;s long-term assets and cash flows. If
          total non-current liabilities exceed long-term assets, or if the company does not have sufficient cash flow to meet these liabilities, it
          could be a sign of financial distress.
        </p>
      </>
    ),
  },

  {
    key: "otherLiabilities",
    label: "Other Liabilities",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Other Liabilities refers to obligations that do not fall into the standard categories of liabilities. They are
          usually infrequent, unusual, or generated from non-operating activities.
        </p>
        <p>
          <strong>Relevance to Investors:</strong>&nbsp;Investors should not overlook Other Liabilities. Although they are not part of the regular
          business activities, they might still represent substantial financial obligations, possibly indicating hidden risks or upcoming expenses.
        </p>
        <p>
          <strong>Application:</strong> Investors should always question the nature and reason behind these other liabilities. In certain cases, they
          could signal structural issues in the company or potentially lucrative side activities. It is crucial to read the footnotes and management
          discussion in the company&apos;s financial reports for a better understanding of these liabilities.
        </p>
      </>
    ),
  },

  {
    key: "capitalLeaseObligations",
    label: "Capital Lease Obligations",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Capital Lease Obligations are long-term debt-like agreements where a business agrees to lease equipment or
          assets for a certain period, and at the end of the lease, the business usually owns the equipment or asset.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Capital leases significantly impact both the asset and liability side of a company&apos;s balance
          sheet. They are relevant to investors as they indicate that the company has chosen to lease rather than purchase assets, which may have
          implications for the company&apos;s cash flow and capital structure.
        </p>
        <p>
          <strong>Application:</strong> Investors should consider the company&apos;s capital lease obligations in the context of its overall debt
          structure. High levels of capital lease obligations may increase the company&apos;s financial risk, particularly if the leased assets are
          not generating adequate returns.
        </p>
      </>
    ),
  },

  {
    key: "totalLiabilities",
    label: "Total Liabilities",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total liabilities represent the aggregate amount of obligations that a company needs to pay over a period of
          time. They are composed of both current liabilities (due within one year) and non-current liabilities (due after one year).
        </p>
        <p>
          <strong>Relevance to Investors:</strong>&nbsp;Total liabilities provide investors with a sense of the company&rsquo;s obligations. High
          total liabilities can be a warning sign, particularly if they significantly outstrip assets or if the company does not have sufficient cash
          flow to meet these obligations.
        </p>
        <p>
          <strong>Application:</strong> Investors can use the total liabilities to calculate various financial ratios such as the debt to equity ratio
          or the debt ratio to understand the financial leverage of the company. It also assists in analyzing the company&apos;s capital structure and
          risk profile.
        </p>
      </>
    ),
  },
];

export const SHAREHOLDERS_EQUITY_COLUMNS = [
  {
    key: "preferredStock",
    label: "preferredStock",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Preferred stock is a class of ownership in a corporation that has a higher claim on the assets and earnings
          than common stock. Preferred shareholders generally receive dividends before common shareholders and have a higher claim on assets if the
          company is liquidated.
        </p>
        <p>
          <strong>Relevance to Investors:</strong>&nbsp;The issuance of preferred stock can be an indicator of a company&apos;s financial health.
          Companies may issue preferred stock to raise capital without diluting control, as preferred shares typically do not have voting rights.
        </p>
        <p>
          <strong>Application:</strong> Investors should note that high levels of preferred stock relative to common stock can be a sign of higher
          financial risk. Also, since preferred stock dividends are typically fixed, they should be factored into the company&apos;s future cash flow
          obligations.
        </p>
      </>
    ),
  },

  {
    key: "commonStock",
    label: "commonStock",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Common stock is a type of equity ownership in a corporation that represents a claim on part of the
          company&apos;s assets and earnings. Common shareholders usually have voting rights, influencing the company&apos;s management decisions.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> The number of common shares outstanding can give investors a sense of a company&apos;s size and
          market capitalization. It&apos;s essential to understand how much of a company&apos;s equity is composed of common stock, as it indicates
          the level of control that common shareholders possess.
        </p>
        <p>
          <strong>Application:</strong> An increase in common stock can mean that a company is growing and requires more capital, while a decrease
          could mean the company is buying back shares.
        </p>
      </>
    ),
  },

  {
    key: "retainedEarnings",
    label: "retainedEarnings",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Retained earnings are the portion of a company&apos;s profits that is kept or &apos;retained&apos; by the
          company instead of being paid out as dividends to shareholders. It is reinvested back into the business for purposes like paying off debt,
          buying new assets, or saving for future use.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> A consistent increase in retained earnings could be a sign of a profitable company that is
          continually growing, while a decrease could suggest the company is not generating enough profit and could potentially be in financial
          trouble.
        </p>
        <p>
          <strong>Application:</strong> Investors should consider the trend in a company&apos;s retained earnings alongside its investment
          opportunities. High retained earnings with good investment opportunities can lead to growth in the company&apos;s value.
        </p>
      </>
    ),
  },

  {
    key: "accumulatedOtherComprehensiveIncomeLoss",
    label: "accumulatedOtherComprehensiveIncomeLoss",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Accumulated Other Comprehensive Income Loss (AOCI) is an account in the equity section of the balance sheet
          that includes unrealized gains or losses that are not included in the calculation of net income. These can be foreign currency adjustments,
          unrealized gains/losses on certain investments, and pensions adjustments.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> The AOCI account gives investors a clearer picture of a company&apos;s total comprehensive income.
          This insight can be helpful when analyzing companies with significant foreign operations or large pension schemes.
        </p>
        <p>
          <strong>Application:</strong> Investors can use AOCI to gain insight into elements that may affect the company&apos;s future earnings or
          cash flows. For instance, a large unrealized loss on investments may indicate potential future earnings volatility.
        </p>
      </>
    ),
  },

  {
    key: "othertotalStockholdersEquity",
    label: "othertotalStockholdersEquity",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Other Total Stockholders&apos; Equity typically includes components of equity not captured under common stock,
          preferred stock, retained earnings, and accumulated other comprehensive income or loss. This could include items like treasury stock and
          unearned compensation.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> This component provides investors with a broader view of a company&apos;s equity structure and any
          potential liabilities that might not be captured elsewhere.
        </p>
        <p>
          <strong>Application:</strong> Investors should review this section in detail to identify any unusual items. For instance, large amounts of
          treasury stock could signal a company buying back its shares, which could be a sign of management&apos;s confidence in the business.
        </p>
      </>
    ),
  },

  {
    key: "totalStockholdersEquity",
    label: "totalStockholdersEquity",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total Stockholders&apos; Equity, also known as shareholders&apos; equity or owners&apos; equity, represents the
          net value of a company. It is calculated by subtracting total liabilities from total assets.
        </p>
        <p>
          <strong>Relevance to Investors:</strong>&nbsp;It essentially shows what the owners of a company (the shareholders) would receive if all
          assets were sold and all debts paid. A positive shareholders&apos; equity implies the company has sufficient assets to cover its
          liabilities.
        </p>
        <p>
          <strong>Application:</strong> Investors can use the total stockholders&apos; equity figure to determine a company&apos;s book value. If the
          market value is less than the book value, it might suggest that the company is undervalued.
        </p>
      </>
    ),
  },

  {
    key: "totalLiabilitiesAndStockholdersEquity",
    label: "totalLiabilitiesAndStockholdersEquity",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong>&nbsp;Total Liabilities and Stockholders&apos; Equity is the summation of a company&apos;s liabilities and
          shareholders&apos; equity. It equals the total assets of a company, following the fundamental accounting equation: Assets = Liabilities +
          Stockholders&apos; Equity.
        </p>
        <p>
          <strong>Relevance to Investors:</strong>&nbsp;This figure indicates the capital structure of a company. The relative proportions of debt and
          equity tell you how a company finances its operations and growth.
        </p>
        <p>
          <strong>Application:</strong> If a company has more liabilities than equity, it might be seen as risky because it implies that the company
          relies more on debt financing. Conversely, a higher proportion of equity suggests that shareholders own more of the company&apos;s assets.
        </p>
      </>
    ),
  },

  {
    key: "minorityInterest",
    label: "minorityInterest",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Minority Interest, also known as non-controlling interest, refers to the portion of a subsidiary
          corporation&apos;s stock that is not owned by the parent corporation. The value of the shares that the parent company does not own is
          reported on the consolidated balance sheet.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Minority Interest is relevant because it represents an ownership claim by outside investors in a
          subsidiary that a parent company controls. If the parent company owns less than 100% of the subsidiary, the investors must consider the
          minority interest claim.
        </p>
        <p>
          <strong>Application:</strong>&nbsp;Investors should look at minority interest as an indication of the extent of a parent company&apos;s
          control over a subsidiary. A high minority interest may limit the parent company&apos;s decision-making power.
        </p>
      </>
    ),
  },

  {
    key: "totalEquity",
    label: "totalEquity",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total Equity is the net worth of a company. It&apos;s calculated by subtracting total liabilities from total
          assets. Total Equity includes all capital received from investors in exchange for stock (paid-in capital), donated capital and earnings
          retained by the company, and other comprehensive income.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Total Equity can provide investors with a snapshot of a company&apos;s financial health. If Total
          Equity is growing over time, it often means the company is doing a good job of increasing its value, retaining its income, and adding to its
          stock of assets.
        </p>
        <p>
          <strong>Application:</strong> By monitoring a company&apos;s total equity over time, investors can track the company&apos;s progress and its
          ability to generate value for shareholders. Companies with strong and growing equity positions are often good investments.
        </p>
      </>
    ),
  },

  {
    key: "totalLiabilitiesAndTotalEquity",
    label: "totalLiabilitiesAndTotalEquity",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total Liabilities and Total Equity sums up all the money a company owes to others (liabilities) and the total
          value of ownership in the company (equity). It equals the total assets of a company, confirming the fundamental accounting equation: Assets
          = Liabilities + Equity.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> This measure gives investors a complete picture of a company&apos;s financial structure. By looking
          at the balance between liabilities and equity, investors can get a sense of how a company is financing its operations and growth.
        </p>
        <p>
          <strong>Application:</strong> Understanding a company&apos;s balance between liabilities and equity helps investors assess the riskiness of
          a company. A high debt level (liabilities) compared to equity might indicate a risky investment.
        </p>
      </>
    ),
  },
];

export const OTHER_BALANCE_COLUMN = [
  {
    key: "totalInvestments",
    label: "totalInvestments",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total Investments represent the sum of all investments made by the company, both short-term and long-term. This
          includes investments in bonds, stocks, or other companies, as well as interest income and dividends received from these investments.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> The total investments line item provides investors with information about the company&apos;s
          investing activities. A company with a high total investment figure may have a diversified income stream, which could help to cushion the
          company during tough economic times.
        </p>
        <p>
          <strong>Application:</strong> Investors should review the total investments figure in conjunction with the rest of the company&apos;s
          financial statements. A high total investments figure could indicate that the company has a diversified income stream, which could be a
          positive sign. However, if the company is not managing its investments wisely, this could also indicate risk.
        </p>
      </>
    ),
  },
  {
    key: "totalDebt",
    label: "totalDebt",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Total Debt is the sum of all short-term and long-term debts that a company has taken on. This figure gives a
          comprehensive look at the company&apos;s total financial obligations.
        </p>
        <p>
          <strong>Relevance to Investors:</strong>&nbsp;The total debt level of a company is of paramount importance to investors. A high debt level
          relative to equity can signify a company that is risky to invest in due to the financial obligations it has to meet.
        </p>
        <p>
          <strong>Application:</strong> Investors should use the total debt figure to assess a company&apos;s financial health and its ability to meet
          its obligations. Comparing total debt across companies within the same industry can give investors a clearer view of the industry norms and
          help identify companies that are potentially over-leveraged.
        </p>
      </>
    ),
  },
  {
    key: "netDebt",
    label: "netDebt",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Net Debt is calculated by subtracting a company&apos;s total cash and equivalents from its total debt. This
          metric provides insight into a company&apos;s financial health and its ability to pay off its debts using its cash reserves if necessary.
        </p>
        <p>
          <strong>Relevance to Investors:</strong> Net Debt gives investors a clear picture of a company&apos;s effective debt level. It helps assess
          the company&apos;s leverage and liquidity, and gives a more accurate picture of a company&apos;s ability to pay off its debts.
        </p>
        <p>
          <strong>Application:</strong> Investors should use net debt as one of the key metrics in their financial analysis. A company with a high net
          debt may not have enough cash on hand to cover its debt, making it a riskier investment.
        </p>
      </>
    ),
  },
];

export const BALANCE_SHEET_COLUMNS = [
  ...CURRENT_ASSETS_COLUMNS,
  ...NON_CURRENT_ASSETS_COLUMNS,
  ...CURRENT_LIABILITIES_COLUMNS,
  ...NON_CURRENT_LIABILITIES_COLUMNS,
  ...SHAREHOLDERS_EQUITY_COLUMNS,
  ...OTHER_BALANCE_COLUMN,
];


export const INCOME_STATEMENT_COLUMNS = [
  {
    key: "revenue",
    label: "Revenue",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Revenue?</strong>
        </p>
        <p>Revenue, or "sales", is the money a company makes from selling its products or services.</p>
        <p>
          <strong>Why is Revenue relevant to an investor?</strong>
        </p>
        <p>
          Revenue is important because it shows the size of a company and how much demand there is for its products or services. Growing revenue
          usually means the company's products or services are popular and selling well.
        </p>
        <p>
          <strong>How should an investor use Revenue to make better investment decisions?</strong>
        </p>
        <p>
          Investors should watch for trends in revenue. If a company's revenue is growing consistently, it could be a sign of strong demand and
          successful strategies. However, if revenue is falling, it might mean the company is facing problems. Comparing a company's revenue growth
          with its competitors can also provide insights into its market performance.
        </p>
      </>
    ),
  },
  {
    key: "costOfRevenue",
    label: "costOfRevenue",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Cost of Revenue?</strong>
        </p>
        <p>
          Cost of Revenue, or Cost of Goods Sold (COGS), is the money a company spends directly to produce or deliver the goods or services it sells.
        </p>
        <p>
          <strong>Why is Cost of Revenue relevant to an investor?</strong>
        </p>
        <p>
          Cost of Revenue tells investors about the company's efficiency. A lower or decreasing Cost of Revenue means the company is getting better at
          controlling its production costs. A higher or increasing Cost of Revenue might suggest rising costs or inefficiencies.
        </p>
        <p>
          <strong>How should an investor use Cost of Revenue to make better investment decisions?</strong>
        </p>
        <p>
          Investors should look at Cost of Revenue relative to total revenue to see the company's gross profit margin, a key measure of profitability.
          They can also compare this ratio with other companies to gauge the company's cost efficiency. Moreover, keeping track of changes in Cost of
          Revenue over time can alert investors to trends that might affect future profits.
        </p>
      </>
    ),
  },

  {
    key: "grossProfit",
    label: "grossProfit",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Gross Profit?</strong>
        </p>
        <p>
          Gross Profit is the money left over from sales after subtracting the Cost of Revenue. In other words, it's what the company earns before
          subtracting operating expenses and other costs.
        </p>
        <p>
          <strong>Why is Gross Profit relevant to an investor?</strong>
        </p>
        <p>
          Gross Profit gives investors a basic idea of a company's profitability. A high Gross Profit suggests that the company is effective at
          controlling its production costs, while a low Gross Profit could signal that costs are eating into potential profits.
        </p>
        <p>
          <strong>How should an investor use Gross Profit to make better investment decisions?</strong>
        </p>
        <p>
          Investors should compare the Gross Profit Margin, which is Gross Profit divided by Revenue, over time and against other companies in the
          same industry. This can provide insight into the company's pricing strategy, cost control, and ability to generate profit. It's also an
          indicator of the company's financial cushion to cover operating expenses, invest in growth, or withstand downturns.
        </p>
      </>
    ),
  },

  {
    key: "grossProfitRatio",
    label: "grossProfitRatio",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is the Gross Profit Ratio?</strong>
        </p>
        <p>
          The Gross Profit Ratio, also known as Gross Margin, is a measure of a company's profitability. It's calculated by dividing Gross Profit by
          Revenue, and it's often expressed as a percentage.
        </p>
        <p>
          <strong>Why is the Gross Profit Ratio relevant to an investor?</strong>
        </p>
        <p>
          The Gross Profit Ratio tells investors how efficiently a company uses its resources to produce and sell products. A higher ratio means the
          company is retaining more on each dollar of sales to cover its other costs.
        </p>
        <p>
          <strong>How should an investor use the Gross Profit Ratio to make better investment decisions?</strong>
        </p>
        <p>
          Investors should use the Gross Profit Ratio to compare companies within the same industry. A higher ratio compared to competitors can
          indicate a competitive advantage, such as better cost control or a premium product commanding higher prices. It's also important to watch
          for changes in the ratio over time, as this might indicate changes in a company's pricing strategy, cost structure, or market conditions.
        </p>
      </>
    ),
  },

  {
    key: "researchAndDevelopmentExpenses",
    label: "researchAndDevelopmentExpenses",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What are Research and Development Expenses?</strong>
        </p>
        <p>
          Research and Development (R&D) Expenses are the costs a company incurs for activities related to the innovation of new products and
          services, or the improvement of existing ones.
        </p>
        <p>
          <strong>Why are R&D Expenses relevant to an investor?</strong>
        </p>
        <p>
          R&D Expenses can indicate a company's commitment to innovation and future growth. In industries like technology, pharmaceuticals, or
          automotive, high R&D spending is often necessary to stay competitive.
        </p>
        <p>
          <strong>How should an investor use R&D Expenses to make better investment decisions?</strong>
        </p>
        <p>
          Investors should compare R&D Expenses to total revenue to understand how much the company is investing in future growth. High R&D spending
          might suggest the company is preparing for new product launches or working on promising technologies. However, it's essential to balance
          this against profitability, as heavy R&D spending can impact short-term profits but might lead to long-term growth.
        </p>
      </>
    ),
  },

  {
    key: "generalAndAdministrativeExpenses",
    label: "generalAndAdministrativeExpenses",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What are General and Administrative Expenses?</strong>
        </p>
        <p>
          General and Administrative (G&A) Expenses are the costs involved in running the overall operations of a company that aren't tied directly to
          the production of goods or services. They include salaries of non-production employees, rent, utilities, and office supplies.
        </p>
        <p>
          <strong>Why are G&A Expenses relevant to an investor?</strong>
        </p>
        <p>
          G&A Expenses can show how efficiently a company is managed. Lower G&A Expenses may indicate an efficient operation, while higher expenses
          could suggest potential wastefulness
        </p>
        <p>
          <strong>How should an investor use G&A Expenses to make better investment decisions?</strong>
        </p>
        <p>
          Investors should watch for trends in G&A Expenses and compare them to total revenue. If these expenses grow faster than revenue, it could
          signal that the company's management isn't controlling costs effectively.
        </p>
      </>
    ),
  },

  {
    key: "sellingAndMarketingExpenses",
    label: "sellingAndMarketingExpenses",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What are Selling and Marketing Expenses?</strong>
        </p>
        <p>
          Selling and Marketing Expenses are the costs associated with promoting and selling products or services. This could include advertising,
          sales commissions, and promotional materials.
        </p>
        <p>
          <strong>Why are Selling and Marketing Expenses relevant to an investor?</strong>
        </p>
        <p>
          These expenses can reflect how much a company is investing in its growth and market presence. They can also indicate the effectiveness of a
          company's marketing strategies.
        </p>
        <p>
          <strong>How should an investor use Selling and Marketing Expenses to make better investment decisions?</strong>
        </p>
        <p>
          Investors should watch for trends in these expenses relative to revenue. If these expenses are increasing but revenue isn't, it may suggest
          the company's marketing efforts aren't effective.
        </p>
      </>
    ),
  },

  {
    key: "sellingGeneralAndAdministrativeExpenses",
    label: "sellingGeneralAndAdministrativeExpenses",
    type: "CURRENCY",
  },

  {
    key: "otherExpenses",
    label: "otherExpenses",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What are Other Expenses?</strong>
        </p>
        <p>
          Other Expenses are costs that don&apos;t fall into the standard categories. This could include interest paid on debt, restructuring costs,
          or other one-time charges.
        </p>
        <p>
          <strong>Why are Other Expenses relevant to an investor?</strong>
        </p>
        <p>
          These expenses can affect a company&apos;s net income and may reflect unusual or non-recurring events that could impact future
          profitability.
        </p>
        <p>
          <strong>How should an investor use Other Expenses to make better investment decisions?</strong>
        </p>
        <p>
          Investors should understand what&apos;s driving these costs and consider whether they&apos;re likely to continue. If these expenses are high
          due to a one-time event, like a restructuring charge, they might not impact long-term profitability.
        </p>
      </>
    ),
  },

  {
    key: "operatingExpenses",
    label: "operatingExpenses",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What are Operating Expenses?</strong>
        </p>
        <p>
          Operating Expenses are all costs associated with running a business, excluding the cost of producing goods or services. They include
          R&amp;D, G&amp;A, and Selling and Marketing Expenses.
        </p>
        <p>
          <strong>Why are Operating Expenses relevant to an investor?</strong>
        </p>
        <p>
          Operating Expenses show how much it costs to run the company, outside of direct production costs. Lower expenses can lead to higher
          operating profit.
        </p>
        <p>
          <strong>How should an investor use Operating Expenses to make better investment decisions?</strong>
        </p>
        <p>
          Investors should watch for trends in these expenses and compare them to revenue. If these expenses are rising faster than revenue, it could
          indicate efficiency problems.
        </p>
      </>
    ),
  },

  { key: "costAndExpenses", label: "costAndExpenses", type: "CURRENCY" },

  {
    key: "interestIncome",
    label: "interestIncome",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Interest Income?</strong>
        </p>
        <p>Interest Income is money a company earns from its investments, such as bonds or interest-bearing bank accounts.</p>
        <p>
          <strong>Why is Interest Income relevant to an investor?</strong>
        </p>
        <p>Interest Income can contribute to a company&apos;s profitability and show how well it&apos;s managing its surplus cash or investments.</p>
        <p>
          <strong>How should an investor use Interest Income to make better investment decisions?</strong>
        </p>
        <p>
          Investors should consider Interest Income as part of a company&apos;s total earnings. However, since it&apos;s not tied to the core business
          operations, they should also consider earnings without it.
        </p>
      </>
    ),
  },

  {
    key: "interestExpense",
    label: "interestExpense",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Interest Expense?</strong>
        </p>
        <p>Interest Expense is the cost of borrowing money. It&apos;s the interest a company pays on its loans and debts.</p>
        <p>
          <strong>Why is Interest Expense relevant to an investor?</strong>
        </p>
        <p>Interest Expense can affect a company&apos;s profitability and shows how much of its earnings are being used to pay for debt.</p>
        <p>
          <strong>How should an investor use Interest Expense to make better investment decisions?</strong>
        </p>
        <p>
          Investors should watch for trends in Interest Expense. Increasing Interest Expense could mean the company is taking on more debt, which
          could signal potential financial risk.
        </p>
      </>
    ),
  },

  {
    key: "depreciationAndAmortization",
    label: "depreciationAndAmortization",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>Definition:</strong> Depreciation and amortization are non-cash expenses that companies record on their financial statements.
          Depreciation relates to tangible assets like machinery or buildings, while amortization applies to intangible assets like patents or
          trademarks. Both reflect the usage or expiry of assets over time.
        </p>
        <p>
          <strong>Formulas:</strong>
        </p>
        <p>For Straight Line Depreciation: Depreciation = (Cost of Asset &ndash; Salvage Value) / Useful Life of Asset</p>
        <p>Amortization is calculated similarly, but it relates to intangible assets.</p>
        <p>
          <strong>Relevance to the Investor: </strong>Depreciation and amortization expenses directly impact a company&apos;s income statement and
          balance sheet. They offer insights into how much a company is expensing due to the use and aging of its assets, both tangible and
          intangible.
        </p>
        <p>
          Application for Better Investment Decisions: Investors can assess how efficiently a company manages its assets by examining depreciation and
          amortization expenses. Companies with higher expenses could signal major investments in capital or that assets are nearing the end of their
          life cycle.
        </p>
      </>
    ),
  },

  {
    key: "ebitda",
    label: "ebitda",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is EBITDA?</strong>
        </p>
        <p>
          EBITDA stands for Earnings Before Interest, Taxes, Depreciation, and Amortization. It&apos;s a measure of a company&apos;s operating
          performance.
        </p>
        <p>
          <strong>Why is EBITDA relevant to an investor?</strong>
        </p>
        <p>
          EBITDA can give a clearer view of a company&apos;s operational performance by removing the effects of financing and accounting decisions.
        </p>
        <p>
          <strong>How should an investor use EBITDA to make better investment decisions?</strong>
        </p>
        <p>Investors can compare a company&apos;s EBITDA over time or against other companies to assess its performance and profitability.</p>
      </>
    ),
  },

  {
    key: "ebitdaratio",
    label: "ebitdaratio",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is the EBITDA Ratio?</strong>
        </p>
        <p>
          The EBITDA Ratio is EBITDA divided by total revenue. It measures a company&apos;s profitability before subtracting interest, taxes,
          depreciation, and amortization.
        </p>
        <p>
          <strong>Why is the EBITDA Ratio relevant to an investor?</strong>
        </p>
        <p>
          The EBITDA Ratio can show investors how much of a company&apos;s revenue is turning into profit, providing insight into its operational
          efficiency.
        </p>
        <p>
          <strong>How should an investor use the EBITDA Ratio to make better investment decisions?</strong>
        </p>
        <p>
          Investors can use the EBITDA Ratio to compare companies within the same industry. A higher ratio suggests greater operational efficiency and
          profitability.
        </p>
      </>
    ),
  },

  {
    key: "operatingIncome",
    label: "operatingIncome",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Operating Income?</strong>
        </p>
        <p>Operating Income is the profit a company makes from its normal business operations, after subtracting operating expenses from revenue.</p>
        <p>
          <strong>Why is Operating Income relevant to an investor?</strong>
        </p>
        <p>
          Operating Income shows how much profit a company is making from its core business, before interest and taxes, giving a clear view of
          operational profitability.
        </p>
        <p>
          <strong>How should an investor use Operating Income to make better investment decisions?</strong>
        </p>
        <p>
          Investors should compare Operating Income over time and against similar companies. It can provide insights into the company&apos;s
          operational efficiency and its ability to generate profit.
        </p>
      </>
    ),
  },

  {
    key: "operatingIncomeRatio",
    label: "operatingIncomeRatio",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is the Operating Income Ratio?</strong>
        </p>
        <p>
          The Operating Income Ratio is Operating Income divided by total revenue. It shows what portion of revenue is left after paying operating
          expenses.
        </p>
        <p>
          <strong>Why is the Operating Income Ratio relevant to an investor?</strong>
        </p>
        <p>
          This ratio helps investors understand a company&apos;s profitability from its core operations, providing insight into operational
          efficiency.
        </p>
        <p>
          <strong>How should an investor use the Operating Income Ratio to make better investment decisions?</strong>
        </p>
        <p>
          Investors can use the Operating Income Ratio to compare companies in the same industry. A higher ratio suggests the company is more
          efficient at turning revenue into profit.
        </p>
      </>
    ),
  },

  {
    key: "totalOtherIncomeExpensesNet",
    label: "totalOtherIncomeExpensesNet",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Total Other Income Expenses Net?</strong>
        </p>
        <p>
          Total Other Income Expenses Net includes all other income and expenses not included in normal operations. This could be investment gains,
          foreign exchange gains, or losses from selling assets.
        </p>
        <p>
          <strong>Why is Total Other Income Expenses Net relevant to an investor?</strong>
        </p>
        <p>This metric gives investors a complete picture of a company&apos;s non-operating income and expenses, which can affect net income.</p>
        <p>
          <strong>How should an investor use Total Other Income Expenses Net to make better investment decisions?</strong>
        </p>
        <p>
          Investors should understand what&apos;s driving these amounts, as they could include one-time events or reflect changes in a company&apos;s
          strategy.
        </p>
      </>
    ),
  },

  {
    key: "incomeBeforeTax",
    label: "incomeBeforeTax",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Income Before Tax?</strong>
        </p>
        <p>
          Income Before Tax is a company&apos;s income before tax expenses are deducted. It includes income from operations and from non-operating
          activities.
        </p>
        <p>
          <strong>Why is Income Before Tax relevant to an investor?</strong>
        </p>
        <p>Income Before Tax can show investors a company&apos;s profitability from all its activities, not just its core business operations.</p>
        <p>
          <strong>How should an investor use Income Before Tax to make better investment decisions?</strong>
        </p>
        <p>
          Investors can compare Income Before Tax over time and against similar companies to assess a company&apos;s overall profitability and
          financial health.
        </p>
      </>
    ),
  },

  {
    key: "incomeBeforeTaxRatio",
    label: "incomeBeforeTaxRatio",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is the Income Before Tax Ratio?</strong>
        </p>
        <p>The Income Before Tax Ratio is Income Before Tax divided by total revenue. It shows what portion of revenue becomes pre-tax profit.</p>
        <p>
          <strong>Why is the Income Before Tax Ratio relevant to an investor?</strong>
        </p>
        <p>This ratio can provide investors with a clear understanding of a company&apos;s overall profitability from all activities.</p>
        <p>
          <strong>How should an investor use the Income Before Tax Ratio to make better investment decisions?</strong>
        </p>
        <p>
          Investors can compare the Income Before Tax Ratio of different companies to see which are more efficient at turning revenue into pre-tax
          profit.
        </p>
      </>
    ),
  },

  {
    key: "incomeTaxExpense",
    label: "incomeTaxExpense",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Income Tax Expense?</strong>
        </p>
        <p>Income Tax Expense is the amount a company pays in taxes on its taxable income.</p>
        <p>
          <strong>Why is Income Tax Expense relevant to an investor?</strong>
        </p>
        <p>
          Income Tax Expense can impact a company&apos;s net income. Companies with higher tax rates will have lower net income, all else being equal.
        </p>
        <p>
          <strong>How should an investor use Income Tax Expense to make better investment decisions?</strong>
        </p>
        <p>
          Investors can compare a company&apos;s Income Tax Expense over time and with similar companies. Changes could reflect new tax laws or
          changes in the company&apos;s operations.
        </p>
      </>
    ),
  },

  {
    key: "netIncome",
    label: "netIncome",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Net Income?</strong>
        </p>
        <p>
          Net Income is the company&apos;s total earnings, or profit, calculated by taking revenues and adjusting for the cost of doing business,
          depreciation, interest, taxes, and other expenses.
        </p>
        <p>
          <strong>Why is Net Income relevant to an investor?</strong>
        </p>
        <p>
          Net Income is one of the most important indicators of a company&apos;s financial health. It&apos;s the amount of money the company has
          earned over a certain period.
        </p>
        <p>
          <strong>How should an investor use Net Income to make better investment decisions?</strong>
        </p>
        <p>
          Investors should compare a company&apos;s Net Income over time and with similar companies. Changes could indicate the company&apos;s
          profitability is improving or worsening.
        </p>
      </>
    ),
  },

  {
    key: "netIncomeRatio",
    label: "netIncomeRatio",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is the Net Income Ratio?</strong>
        </p>
        <p>The Net Income Ratio is Net Income divided by total revenue. It shows what portion of revenue becomes net profit.</p>
        <p>
          <strong>Why is the Net Income Ratio relevant to an investor?</strong>
        </p>
        <p>The Net Income Ratio gives investors insight into a company&apos;s profitability after all expenses have been deducted.</p>
        <p>
          <strong>How should an investor use the Net Income Ratio to make better investment decisions?</strong>
        </p>
        <p>
          Investors can use this ratio to compare companies. A higher ratio suggests the company is more efficient at turning revenue into net profit.
        </p>
      </>
    ),
  },

  {
    key: "eps",
    label: "eps",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Earnings Per Share (EPS)?</strong>
        </p>
        <p>
          Earnings Per Share (EPS) is the portion of a company&apos;s profit allocated to each outstanding share of common stock. It&apos;s calculated
          by taking Net Income minus dividends on preferred stock and dividing by the number of outstanding shares.
        </p>
        <p>
          <strong>Why is EPS relevant to an investor?</strong>
        </p>
        <p>EPS is a commonly used metric for gauging a company&apos;s profitability. A higher EPS suggests the company is more profitable.</p>
        <p>
          <strong>How should an investor use EPS to make better investment decisions?</strong>
        </p>
        <p>
          Investors can compare the EPS of different companies. A higher EPS suggests the company is more profitable and may be a more attractive
          investment.
        </p>
      </>
    ),
  },

  {
    key: "epsdiluted",
    label: "epsdiluted",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Diluted EPS?</strong>
        </p>
        <p>
          Diluted EPS is a performance metric that shows what the Earnings Per Share would be if all convertible securities were exercised. This
          includes options, convertible bonds, and other securities that can be converted into stock.
        </p>
        <p>
          <strong>Why is Diluted EPS relevant to an investor?</strong>
        </p>
        <p>
          Diluted EPS gives a more conservative view of a company&apos;s profitability by assuming all convertible securities have been converted to
          common stock.
        </p>
        <p>
          <strong>How should an investor use Diluted EPS to make better investment decisions?</strong>
        </p>
        <p>
          Investors should compare the Diluted EPS to the basic EPS. If there&apos;s a big difference, it could mean the company has many convertible
          securities that could dilute earnings in the future.
        </p>
      </>
    ),
  },

  {
    key: "weightedAverageShsOut",
    label: "weightedAverage Shs Out",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Weighted Average Shares Outstanding?</strong>
        </p>
        <p>
          Weighted Average Shares Outstanding is the average number of shares a company had outstanding during a specific period, adjusted for changes
          in the share count.
        </p>
        <p>
          <strong>Why is Weighted Average Shares Outstanding relevant to an investor?</strong>
        </p>
        <p>
          This metric is used in calculations of Earnings Per Share, which is a key profitability metric. It can also reflect share issuance or
          buybacks.
        </p>
        <p>
          <strong>How should an investor use Weighted Average Shares Outstanding to make better investment decisions?</strong>
        </p>
        <p>
          Investors should watch for changes in this number. If it&apos;s decreasing, it could mean the company is buying back shares, which can
          increase EPS.
        </p>
      </>
    ),
  },

  {
    key: "weightedAverageShsOutDil",
    label: "weighted Average Shs Out Dil",
    type: "CURRENCY",
    tooltip: (
      <>
        <p>
          <strong>What is Weighted Average Shares Outstanding Diluted?</strong>
        </p>
        <p>This is similar to the Weighted Average Shares Outstanding, but it includes potential dilution from convertible securities.</p>
        <p>
          <strong>Why is Weighted Average Shares Outstanding Diluted relevant to an investor?</strong>
        </p>
        <p>
          This metric is used in the calculation of Diluted EPS. It can give investors insight into potential dilution from convertible securities.
        </p>
        <p>
          <strong>How should an investor use Weighted Average Shares Outstanding Diluted to make better investment decisions?</strong>
        </p>
        <p>
          Investors should compare this number to the basic Weighted Average Shares Outstanding. If there&apos;s a big difference, it could mean the
          company has many convertible securities that could dilute earnings in the future.
        </p>
      </>
    ),
  },
];

export const OPERATING_CASH_FLOW_COLUMNS = [
  "netIncome",
  "depreciationAndAmortization",
  "deferredIncomeTax",
  "stockBasedCompensation",
  "changeInWorkingCapital",
  "accountsReceivables",
  "inventory",
  "accountsPayables",
  "otherWorkingCapital",
  "otherNonCashItems",
  "netCashProvidedByOperatingActivities",
];

export const INVESTING_CASH_FLOW_COLUMNS = [
  "investmentsInPropertyPlantAndEquipment",
  "acquisitionsNet",
  "purchasesOfInvestments",
  "salesMaturitiesOfInvestments",
  "otherInvestingActivites",
  "netCashUsedForInvestingActivites",
];

export const FINANCING_CASH_FLOW_COLUMNS = [
  "debtRepayment",
  "commonStockIssued",
  "commonStockRepurchased",
  "dividendsPaid",
  "otherFinancingActivites",
  "netCashUsedProvidedByFinancingActivities",
];

export const OTHER_CASH_FLOW_COLUMNS = [
  "effectOfForexChangesOnCash",
  "netChangeInCash",
  "cashAtEndOfPeriod",
  "cashAtBeginningOfPeriod",
  "operatingCashFlow",
  "capitalExpenditure",
  "freeCashFlow",
];

export const CASH_FLOW_COLUMNS = [
  ...OPERATING_CASH_FLOW_COLUMNS,
  ...INVESTING_CASH_FLOW_COLUMNS,
  ...FINANCING_CASH_FLOW_COLUMNS,
  ...OTHER_CASH_FLOW_COLUMNS,
];

export const CASH_FLOW_TOOLTIP = {
  netIncome: (
    <>
      <p>
        <strong>Definition:</strong> Net income, also known as net profit, is the total earnings of a company after subtracting all its costs,
        including the cost of goods sold (COGS), operational expenses, interest, taxes, and other expenses.
      </p>
      <p>
        <strong>Formula:</strong> Net Income = Total Revenue &ndash; Total Expenses
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Net income is an essential metric that reveals a company&apos;s profitability after all costs have
        been accounted for. It provides insight into how effectively a company turns revenue into profit, a key indicator of financial health and
        operational efficiency.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> By tracking changes in net income over time, investors can gain insights into a
        company&apos;s growth trends and profitability. However, investors should also look beyond net income, considering it in conjunction with
        other financial indicators for a more comprehensive view of a company&apos;s financial status.
      </p>
    </>
  ),
  depreciationAndAmortization: (
    <>
      <p>
        <strong>Definition:</strong> Depreciation and amortization are non-cash expenses that companies record on their financial statements.
        Depreciation relates to tangible assets like machinery or buildings, while amortization applies to intangible assets like patents or
        trademarks. Both reflect the usage or expiry of assets over time.
      </p>
      <p>
        Formula<strong>:</strong>
      </p>
      <p>For Straight Line Depreciation: Depreciation = (Cost of Asset &ndash; Salvage Value) / Useful Life of Asset</p>
      <p>Amortization is calculated similarly, but it relates to intangible assets.</p>
      <p>
        <strong>Relevance to the Investor:</strong> Depreciation and amortization expenses directly impact a company&apos;s income statement and
        balance sheet. They offer insights into how much a company is expensing due to the use and aging of its assets, both tangible and intangible.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors can assess how efficiently a company manages its assets by examining
        depreciation and amortization expenses. Companies with higher expenses could signal major investments in capital or that assets are nearing
        the end of their life cycle.
      </p>
    </>
  ),
  deferredIncomeTax: (
    <>
      <p>
        <strong>Definition:</strong> Deferred income tax arises from differences between a company&apos;s taxable income and its accounting income.
        This discrepancy is due to the differing rules and regulations of financial accounting standards and the tax code.
      </p>
      <p>
        Formula<strong>:</strong>
      </p>
      <p>Determining deferred tax liabilities: Deferred Tax Liability = Temporary Difference x Tax Rate</p>
      <p>
        <strong>Relevance to the Investor:</strong> Deferred income tax liabilities and assets indicate a company&apos;s future tax obligations or
        benefits. A high deferred tax liability could signal higher tax expenses in the future, potentially impacting net income and cash flow.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should consider both deferred tax liabilities and assets in their
        analysis of a company&apos;s future prospects. High deferred tax liabilities may indicate potential future cash outflows, while deferred tax
        assets could signify potential tax savings.
      </p>
    </>
  ),
  stockBasedCompensation: (
    <>
      <p>
        <strong>Definition:</strong> Stock-based compensation is a way for companies to reward their employees by offering them shares or options to
        buy shares in the company. It&apos;s often used as an incentive to attract and retain talent.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Stock-based compensation can be an indicator of a company&apos;s future growth and its confidence
        in its own stock. It can also impact the company&apos;s earnings and cash flow, and, when executed, can dilute existing shareholders&apos;
        equity.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> An investor should observe the company&apos;s stock-based compensation strategy.
        A large proportion of compensation delivered as stock might indicate confidence in the company&apos;s future performance. However, it can also
        dilute the shares&apos; value, so investors should balance these aspects.
      </p>
    </>
  ),
  changeInWorkingCapital: (
    <>
      <p>
        <strong>Definition:</strong> Working capital represents a company&apos;s short-term financial health and operational efficiency. It&apos;s
        calculated as current assets minus current liabilities. The change in working capital refers to the difference in this figure from one period
        to another.
      </p>
      <p>
        <strong>Formula:</strong> Change in Working Capital = Working Capital (Current Period) - Working Capital (Previous Period)
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Understanding changes in working capital can help investors get a sense of a company&apos;s
        operational efficiency and short-term financial health. An increase in working capital indicates the company has more resources to cover
        short-term debts and operational expenses.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should track changes in working capital over time to identify trends.
        If working capital is consistently declining, it may indicate potential liquidity issues, while a consistent increase might suggest efficient
        operations and growth.
      </p>
    </>
  ),
  accountsReceivables: (
    <>
      <p>
        <strong>Definition:</strong> Accounts receivables refer to the money owed to a company by its customers for goods or services delivered but
        not yet paid for.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Accounts receivables can offer insight into a company&apos;s cash flow and credit practices. High
        receivables may indicate that a company is selling a lot on credit, which could strain its cash flow.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should keep an eye on a company&apos;s accounts receivables and how it
        changes over time. A growing amount may imply cash flow issues, while a declining trend might suggest improved collection processes or less
        sales on credit.
      </p>
    </>
  ),
  inventory: (
    <>
      <p>
        <strong>Definition:</strong> Inventory represents goods available for sale, in the process of being produced for sale, or to be used in
        producing goods.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Inventory levels provide insight into a company&apos;s sales trends and supply chain management.
        High inventory levels can indicate either strong sales forecasts or weak sales performance, so it&apos;s crucial to consider the context.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should look at inventory turnover ratios to understand how quickly a
        company sells its inventory. Low turnover could indicate weak sales and may lead to obsolete or excess inventory, while high turnover may
        suggest strong sales or efficient inventory management.
      </p>
    </>
  ),
  accountsPayables: (
    <>
      <p>
        <strong>Definition:</strong> Accounts payable represent the money a company owes to suppliers for goods or services purchased on credit.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Accounts payable can signal a company&apos;s short-term liquidity position and its management of
        cash flows. High payables could suggest that a company is relying heavily on supplier financing.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should monitor changes in accounts payable over time. Rapid increases
        could signal potential liquidity problems, while decreasing payables could suggest the company is paying off its debts quicker, potentially
        indicating strong cash flows.
      </p>
    </>
  ),
  otherWorkingCapital: (
    <>
      <p>
        <strong>Definition:</strong> Other working capital includes other current assets and liabilities not accounted for in accounts receivables,
        inventory, and accounts payables, such as prepaid expenses and accrued liabilities.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Other working capital items can provide further insight into a company&apos;s operational
        efficiency and short-term financial health.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should consider all components of working capital, including
        &apos;other&apos; components, when analyzing a company&apos;s liquidity and operational efficiency. Significant changes in these items could
        impact a company&apos;s working capital and cash flow.
      </p>
    </>
  ),
  otherNonCashItems: (
    <>
      <p>
        <strong>Definition:</strong> Other non-cash items are expenses, gains, or losses reported in the income statement but do not involve actual
        cash transactions. These could include gains or losses from foreign exchange or derivatives.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> These items can affect a company&apos;s reported income but not its cash flow, hence affecting key
        metrics like earnings per share.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should understand the nature and impact of these non-cash items when
        analyzing a company&apos;s performance. While they do not impact cash flows, they can distort the profitability picture and influence
        investment decisions.
      </p>
    </>
  ),
  netCashProvidedByOperatingActivities: (
    <>
      <p>
        <strong>Definition:</strong> This represents the cash generated from a company&apos;s core business operations.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> It provides an indication of a company&apos;s ability to generate sufficient cash to maintain and
        grow operations, without resorting to external financing options.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should ideally look for companies that generate a positive cash flow
        from operating activities, as it signifies the company&apos;s core business is sound and can fund its own growth.
      </p>
    </>
  ),
  investmentsInPropertyPlantAndEquipment: (
    <>
      <p>
        <strong>Definition:</strong> Investments in property, plant, and equipment (PPE) represent the cash outflow for buying long-term assets needed
        for a company&apos;s operations.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Large investments in PPE can indicate that a company is gearing up for increased production or
        services, suggesting potential future growth. However, it can also indicate substantial cash outflow in the short term.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should assess whether a company&apos;s investments in PPE align with
        its growth strategy and whether it&apos;s financially capable of making such investments without stressing its cash position.
      </p>
    </>
  ),
  acquisitionsNet: (
    <>
      <p>
        <strong>Definition:</strong> Acquisitions net refers to the net cash used for acquiring businesses, subsidiaries, or other companies.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> A high amount in acquisitions might suggest a company&apos;s growth strategy is heavily reliant on
        buying other companies. It can also indicate a significant cash outflow.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should evaluate whether acquisitions are adding value to the company
        in the long term. Look for whether the acquired businesses contribute positively to earnings in subsequent periods.
      </p>
    </>
  ),
  purchasesOfInvestments: (
    <>
      <p>
        <strong>Definition:</strong> This refers to the cash used for buying investment securities like bonds, stocks, or other financial instruments.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> This can provide insight into how a company uses its excess cash and its investment strategy.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should consider the type of investments the company is making, their
        risk level, and how these investments fit into the company&apos;s broader financial strategy.
      </p>
    </>
  ),
  salesMaturitiesOfInvestments: (
    <>
      <p>
        <strong>Definition:</strong> This refers to the cash inflow from selling investments or the maturity of investment securities.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> The realization of these investments can provide a significant source of cash inflow and indicate
        the company&apos;s investment cycle.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should monitor how well the company manages its investments portfolio,
        whether it&apos;s able to generate positive returns, and the timing of these returns.
      </p>
    </>
  ),
  otherInvestingActivites: (
    <>
      <p>
        <strong>Definition:</strong> These are other activities that don&apos;t fall under the categories above but are part of investing activities.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> These activities can provide additional insight into a company&apos;s investing strategies.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should understand what these activities involve and how they impact
        the overall investing cash flow.
      </p>
    </>
  ),
  netCashUsedForInvestingActivites: (
    <>
      <p>
        <strong>Definition:</strong> This represents the total cash inflow and outflow from all investing activities.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> It provides an overall view of how much cash the company has used or generated from its investing
        activities.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should ideally look for companies that efficiently use their cash for
        investments to generate future growth. A company consistently having negative cash flow from investing activities may not necessarily be a bad
        sign if these investments lead to increased profitability in the future.
      </p>
    </>
  ),
  debtRepayment: (
    <>
      <p>
        <strong>Definition:</strong> Debt repayment refers to the cash outflows used to repay the principal amount of both short-term and long-term
        debt.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> A company with high debt repayment has less cash available for other uses. However, it can also
        indicate the company is financially stable enough to meet its obligations.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should consider the company&apos;s debt repayment schedule in relation
        to its cash flows to determine if the company is able to meet its obligations comfortably.
      </p>
    </>
  ),
  commonStockIssued: (
    <>
      <p>
        <strong>Definition:</strong> This refers to the cash inflow a company receives from issuing common stock to the public.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> When a company issues common stock, it can provide the business with much-needed capital for
        expansion or debt repayment. However, it can also lead to dilution of existing shareholders&apos; equity.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should consider why the company is issuing stock. If it&apos;s to
        finance growth opportunities, it could be a positive sign. However, if it&apos;s to fund ongoing operations or pay off debt, it may be a red
        flag indicating cash flow issues.
      </p>
    </>
  ),
  commonStockRepurchased: (
    <>
      <p>
        <strong>Definition:</strong> This represents the cash outflow a company uses to buy back its own shares from the marketplace.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Share repurchases can indicate that a company believes its stock is undervalued and is a way of
        returning cash to shareholders. It can also increase earnings per share and share price by reducing the number of shares outstanding.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should watch for the reasons behind the stock repurchase. While it can
        be a positive sign of company health, it can also mean the company doesn&apos;t have more productive investment opportunities.
      </p>
    </>
  ),
  dividendsPaid: (
    <>
      <p>
        <strong>Definition:</strong> Dividends paid represents the cash outflow a company distributes to its shareholders as a return on their
        investment.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Dividends can be a sign of a company&apos;s profitability and stability, as they&apos;re typically
        paid out by companies that are profitable and have sufficient cash.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors, particularly income-focused investors, should look at a
        company&apos;s dividend payment history and its dividend yield when making investment decisions.
      </p>
    </>
  ),
  otherFinancingActivites: (
    <>
      <p>
        <strong>Definition:</strong> These activities include any other cash inflows or outflows related to financing that are not included in the
        categories above.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Other financing activities can give additional insight into how a company raises capital or
        returns it to shareholders.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should understand the nature of these activities and how they impact
        the overall financing cash flow.
      </p>
    </>
  ),
  netCashUsedProvidedByFinancingActivities: (
    <>
      <p>
        <strong>Definition:</strong> This is the total cash inflow and outflow from all financing activities.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> It gives an overall view of how much cash a company has generated or used in its financing
        activities.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should look at the trend of net cash from financing activities.
        Consistent cash outflows may mean the company is regularly paying off debt, buying back stock, or paying dividends, which can be positive.
        However, it could also indicate a dependency on external financing to fund operations, which could be a cause for concern.
      </p>
    </>
  ),
  effectOfForexChangesOnCash: (
    <>
      <p>
        <strong>Definition:</strong> This refers to the impact of changes in foreign exchange rates on a company&apos;s cash and cash equivalents held
        in different currencies.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> For companies operating internationally, the effect of forex changes on cash can significantly
        impact the company&apos;s cash position and overall financial performance.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should consider this factor when investing in multinational
        corporations or businesses with foreign operations. Significant changes in currency exchange rates can either positively or negatively affect
        the company&apos;s financials.
      </p>
    </>
  ),
  netChangeInCash: (
    <>
      <p>
        <strong>Definition:</strong> This represents the total change in a company&apos;s cash position during a specific period, considering its
        operating, investing, and financing activities.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> A positive net change indicates that the company&apos;s cash position has increased, while a
        negative net change indicates a decrease. This information helps investors understand the company&apos;s overall liquidity status and how it
        has changed over a specific period.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should track the net change in cash over time to understand how a
        company is managing its cash flow. Significant fluctuations may indicate potential issues with cash flow management.
      </p>
    </>
  ),
  cashAtEndOfPeriod: (
    <>
      <p>
        <strong>Definition:</strong> This is the amount of cash and cash equivalents a company has at the end of a specific accounting period.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> This shows a company&apos;s liquidity position at a specific point in time and can give insight
        into its ability to meet short-term obligations.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should consider a company&apos;s cash position in the context of its
        current liabilities to evaluate its short-term financial health.
      </p>
    </>
  ),
  cashAtBeginningOfPeriod: (
    <>
      <p>
        <strong>Definition:</strong> This is the amount of cash and cash equivalents a company has at the beginning of a specific accounting period.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Comparing cash at the beginning and end of periods can help investors understand how a
        company&apos;s cash position has changed over time.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors can compare the cash at the beginning of the period to cash at the end
        of the period to understand the company&apos;s cash flow changes.
      </p>
    </>
  ),
  operatingCashFlow: (
    <>
      <p>
        <strong>Definition:</strong> Operating Cash Flow (OCF) is the cash generated from a company&apos;s normal business operations. It&apos;s
        calculated by adjusting net income for items such as depreciation, changes in working capital, and taxes.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> Again, it&apos;s an indication of a company&apos;s ability to generate sufficient cash to maintain
        and grow its operations without resorting to external financing options.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors should ideally look for companies that consistently generate a
        positive cash flow from operating activities, as it signifies the company&apos;s core business is sound and can fund its growth.
      </p>
    </>
  ),
  capitalExpenditure: (
    <>
      <p>
        <strong>Definition:</strong> Capital Expenditure (CapEx) refers to the funds used by a company to acquire, maintain, and upgrade physical
        assets such as property, buildings, technology, or equipment.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> CapEx is important for companies to grow and maintain their operations. However, high CapEx can
        also lead to significant cash outflows.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> Investors need to ensure that the company&apos;s CapEx is being used effectively
        to generate higher returns in the future.
      </p>
    </>
  ),
  freeCashFlow: (
    <>
      <p>
        <strong>Definition:</strong> Free cash flow (FCF) is the cash a company generates from its operations after accounting for CapEx. It&apos;s
        calculated as Operating Cash Flow - Capital Expenditure.
      </p>
      <p>
        <strong>Relevance to the Investor:</strong> FCF is often seen as a purer measure of profitability as it shows how much cash a company has left
        over to expand the business, pay dividends, repay debt, or add to its cash reserve.
      </p>
      <p>
        <strong>Application for Better Investment Decisions:</strong> A consistently positive FCF is a good sign as it shows the company has plenty of
        cash to reinvest in the business, reduce debt, or return to shareholders. If a company&apos;s FCF is negative, it may need to use external
        financing sources to fund these activities.
      </p>
    </>
  ),
};
