import React,{useState, useEffect} from 'react'
import styles from './freepopup.module.css'
import CheckIcon from '@mui/icons-material/Check';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import { Box, IconButton, Modal } from '@mui/material';
import { Link } from 'react-router-dom';
import InvexRoutes from '../../../InvexRoutes';
import trailImg from '../../Common/Images/trail.png'
import CloseIcon from '@material-ui/icons/Close';
import { isPrimeAndOpn } from '../CommonFunctions';
import { useNavigate } from 'react-router-dom';

const FreePopup = () => {
  // Variables
  const list = [
    "Exclusive Access to 230+ Industry Reports.",
    "Stock Screener: Explore with limited features.",
    "Portfolio Gallery: Get limited access to visualize your investments.",
    "DCF Calculator: Unlock the manual version for insightful valuations."
  ]
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const messageShown = sessionStorage.getItem('messageShown');
    let timeoutId;
    if (messageShown !== "1" && !isPrimeAndOpn()) {
      if (messageShown === "true") {
        timeoutId   = setTimeout(() => {
          setShowMessage(true);
        }, 10000); 
      }
      if (!messageShown || messageShown === 'false') {
        timeoutId = setTimeout(() => {
          setShowMessage(true);
          sessionStorage.setItem('messageShown', 'true');
        }, 10000); 
      }
      return () => clearTimeout(timeoutId);
    }
  }, []); 

  const handleClose = () => {
    sessionStorage.setItem('messageShown', '1');
    setShowMessage(false);
  };

  const handleNavigate = () => {
    navigate(InvexRoutes.Register.path);
    sessionStorage.setItem('messageShown', '1');
    setShowMessage(false);
  }


  return (
     <Modal open={showMessage} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className={styles.definationWrapper}>
        <div className={styles.wrapper} >
        <div className={styles.closeBtnWrp} >
          <IconButton onClick={handleClose}>
            <CloseIcon />
        </IconButton>
        </div>
          <div className={styles.head} >Unlock Your Financial Potential!</div>
      <div className={styles.mainMenu} >
            <div className={styles.leftMenu} >
              <img src={trailImg} />
        </div>
            <div className={styles.rightMenu} >
              Welcome to Amassing Investment – Your gateway to unparalleled financial insights. Jumpstart your investment journey with our <span className={styles.highlightText} >14-Day Free Trial</span> and get comprehensive access to premium features on <span className={styles.highlightText} >10 selected</span> symbols.
        </div>
      </div>
      <div className={styles.listWrapper} >
        {
          list?.map((val, index) => {
            return <div key={index} className={styles.row} >
              <span className={styles.checkIcon} >
                <CheckIcon fontSize="8" />
              </span>
              <span>{val}</span>
            </div>
          })
        }
      </div>
      <div className={styles.freeTrail} >
             <div className={styles.craditDesign} ><CreditCardOffIcon style={{ marginRight: 10, color: "#000" }} />Start Free - No Credit Card Required!</div>
      </div>
      <div className={styles.btnWrp} >
        <button className={styles.btn} onClick={handleNavigate} >Start 14 Day Free Trial</button>
      </div>
          <div className={styles.account} onClick={handleClose} >Already have an account?
            <Link to={InvexRoutes.Login.path} className={styles.login} >Log in here</Link>
          </div>
<div className={styles.footerText} >Take the first step towards smarter investment decisions today!</div>
        </div>
      </Box>
      </Modal>
  )
}

export default FreePopup