export const ETFTYPE = {
  synopsis: {
    label: "Synopsis",
    value: "",
  },
  // dcfValuation: {
  //   label: "DCF Valuation",
  //   value: "DCF_VALUATION",
  // },
  // relativeValuation: {
  //   label: "Relative Valuation",
  //   value: "RELATIVE_VALUATION",
  // },
  optionAnalysis: {
    label: "Option Analysis",
    value: "OPTION_ANALYSIS",
  },
  technicalAnalysis: {
    label: "Technical Analysis",
    value: "TECHNICAL_ANALYSIS",
  },

  chart: {
    label: "Chart",
    value: "CHART",
  },
};

export const NONETFTYPE = {
  synopsis: {
    label: "Synopsis",
    value: "",
  },
  dcfValuation: {
    label: "DCF Valuation",
    value: "DCF_VALUATION",
  },
  relativeValuation: {
    label: "Relative Valuation",
    value: "RELATIVE_VALUATION_ANALYSIS",
  },
  comparativeValuation: {
    label: "Comparative Analysis",
    value: "COMPARATIVE_ANALYSIS",
  },
  optionAnalysis: {
    label: "Option Analysis",
    value: "OPTION_ANALYSIS",
  },
  financialStatistics: {
    label: "Financial Statistics",
    value: "FINANCIAL_STATISTICS",
  },
  financialStatements: {
    label: "Financial Statements",
    value: "FINANCIAL_STATEMENTS",
  },
  technicalAnalysis: {
    label: "Technical Analysis",
    value: "TECHNICAL_ANALYSIS",
  },
  secFilings: {
    label: "SEC Filings",
    value: "SEC_FILINGS",
  },
  chart: {
    label: "Chart",
    value: "CHART",
  },
};

export const ExtraInViewMore = {
  etf: {
    label: "ETF",
    value: "etf"
  },
  dividends: {
    label: "Dividends",
    value: "dividends"
  },
  earning: {
    label: "Earning",
    value: "earning"
  },
  news: {
    label: "News",
    value: "news"
  },
  topHoldings: {
    label: "Top Holdings",
    value: "etf-top-holdings"
  }
}

export const TYPE = {
  synopsis: {
    label: "Synopsis",
    value: "",
  },
  dcfValuation: {
    label: "DCF Valuation",
    value: "dcf-valuation",
  },
  relativeValuation: {
    label: "Relative Valuation",
    value: "relative-valuation-analysis",
  },
  comparativeValuation: {
    label: "Comparative Analysis",
    value: "comparative-analysis",
  },
  optionAnalysis: {
    label: "Option Analysis",
    value: "option-analysis",
  },
  financialStatistics: {
    label: "Financial Statistics",
    value: "financial-statistics",
  },
  financialStatements: {
    label: "Financial Statements",
    value: "financial-statements",
  },
  technicalAnalysis: {
    label: "Technical Analysis",
    value: "technical-analysis",
  },
  secFilings: {
    label: "SEC Filings",
    value: "sec-fillings",
  },
  chart: {
    label: "Chart",
    value: "price-chart",
  },
};

export const TOP_COMPETITOR_COLUMNS = ["Company", "Price", "Market Cap", "PE Ratio", "EPS (TTM)", "YTD Change", "52 Week Change", "Industry"];

export const yaxisValue = (dataMax) => {
  const arr = [];
  // if (dataMax >= 0 && dataMax < 2) {
  //   arr.push(Math.ceil(dataMax));
  // } else if (dataMax >= 2 && dataMax < 4) {
  //   arr.push(Math.ceil(dataMax));
  // } else if (dataMax >= 4 && dataMax < 10) {
  //   arr.push(Math.ceil(dataMax));
  // } else if (dataMax >= 10 && dataMax < 50) {
  //   arr.push(Math.ceil(dataMax));
  // } else if (dataMax >= 50 && dataMax < 100) {
  //   arr.push(Math.ceil(dataMax));
  // } else if (dataMax >= 100 && dataMax < 500) {
  //   arr.push(Math.ceil(dataMax));
  // } else if (dataMax >= 500 && dataMax < 1000) {
  //   arr.push(Math.ceil(dataMax));
  // } else if (dataMax >= 1000 && dataMax < 5000) {
  //   arr.push(Math.ceil(dataMax));
  // } else if (dataMax >= 5000 && dataMax < 10000) {
  //   arr.push(Math.ceil(dataMax));
  // } else {
  arr.push(Math.ceil(dataMax));
  // }
  return arr[0];
};

export const yaxisMinValue = (dataMin) => {
  const arr = [];
  // if (dataMin >= 0 && dataMin < 2) {
  //   arr.push(Math.ceil(dataMin));
  // } else if (dataMin >= 2 && dataMin < 4) {
  //   arr.push(Math.ceil(dataMin));
  // } else if (dataMin >= 4 && dataMin < 10) {
  //   arr.push(Math.ceil(dataMin));
  // } else if (dataMin >= 10 && dataMin < 50) {
  //   arr.push(Math.ceil(dataMin));
  // } else if (dataMin >= 50 && dataMin < 100) {
  //   arr.push(Math.ceil(dataMin));
  // } else if (dataMin >= 100 && dataMin < 500) {
  //   arr.push(Math.ceil(dataMin));
  // } else if (dataMin >= 500 && dataMin < 1000) {
  //   arr.push(Math.ceil(dataMin));
  // } else if (dataMin >= 1000 && dataMin < 5000) {
  //   arr.push(Math.ceil(dataMin));
  // } else if (dataMin >= 5000 && dataMin < 10000) {
  //   arr.push(Math.ceil(dataMin));
  // } else {
  arr.push(Math.ceil(dataMin) - 2 * Math.ceil(dataMin));
  // }
  return arr[0];
};

export const NumberToWords = (n) => {
  if (n < 0) return false;
  const single_digit = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const double_digit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const below_hundred = ["Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  if (n === 0) return "Zero";
  let rem
  function translate(n) {
    let word = "";
    if (n < 10) {
      word = single_digit[n] + " ";
    } else if (n < 20) {
      word = double_digit[n - 10] + " ";
    } else if (n < 100) {
      rem = translate(n % 10);
      word = below_hundred[(n - (n % 10)) / 10 - 2] + " " + rem;
    } else if (n < 1000) {
      word = single_digit[Math.trunc(n / 100)] + " Hundred " + translate(n % 100);
    } else if (n < 1000000) {
      word = translate(parseInt(n / 1000)).trim() + " Thousand " + translate(n % 1000);
    } else if (n < 1000000000) {
      word = translate(parseInt(n / 1000000)).trim() + " Million " + translate(n % 1000000);
    } else {
      word = translate(parseInt(n / 1000000000)).trim() + " Billion " + translate(n % 1000000000);
    }
    return word;
  }
  const result = translate(n);
  return result.trim() + ".";
};
